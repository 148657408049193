import { Injectable } from "@angular/core";
import { CustomHook } from "../interfaces/Decorators";
import { ComponentConfig } from "../ComponentConfig";
import { DynamicContext } from "../interfaces/DynamicContext";
import { UntypedFormGroup } from "@angular/forms";
import { DynamicComponentService } from "../services/dynamic-component.service";
import { LanguageService } from "src/app/dynamic-components/utils/language.service";
import { SupplierStatus } from "src/app/interfaces/mapping";
import { ProcessStatus } from "src/app/interfaces/workflow";

export class HookDecoratorComposition {
  constructor(
    private service: DynamicComponentService,
    private languageService: LanguageService
  ) {}

  @CustomHook("disableOnEdit")
  disableOnEdit(
    config: ComponentConfig,
    context: DynamicContext,
    formGroup: UntypedFormGroup
  ) {
    return (params: any): void => {
      if (!context.getValue("parentModel").Mapping.IsMigrated) {
        if (
          context.getValue("parentModel").Mapping.status ==
          SupplierStatus.onboarded
        ) {
          config.type = "dropdown";
          formGroup.get(config.name).clearValidators();
          formGroup.get(config.name).clearAsyncValidators();
          formGroup
            .get(config.name)
            .disable({ onlySelf: true, emitEvent: false });
        }
      } else {
        const AP_QC_task = context.getValue("parentModel").Mapping.historyTasks
          ? context
              .getValue("parentModel")
              .Mapping.historyTasks.find((t) => t.name == "AP QC Review")
          : undefined;
        if (AP_QC_task) {
          config.type = "dropdown";
          formGroup.get(config.name).clearValidators();
          formGroup.get(config.name).clearAsyncValidators();
          formGroup
            .get(config.name)
            .disable({ onlySelf: true, emitEvent: false });
        }
      }
    };
  }

  @CustomHook("expression")
  expressionHook(
    config: ComponentConfig,
    context: DynamicContext,
    formGroup: UntypedFormGroup
  ) {
    return (params: any): void => {
      const fn = `return (${params})`;
      const runtime = new Function("config", "context", "formGroup", fn);
      runtime(config, context, formGroup);
    };
  }

  @CustomHook("changeConfig")
  changeConfig(
    config: ComponentConfig,
    context: DynamicContext,
    formGroup: UntypedFormGroup
  ) {
    return (params: any): void => {
      const setFn = `${params}`;
      if (!config.templateOptions) {
        config.templateOptions = {};
      }
      const setRuntime = new Function("config", "languageService", setFn);
      setRuntime(config, this.languageService);
    };
  }

  @CustomHook("hide")
  hide(config: ComponentConfig, context: DynamicContext, formGroup: UntypedFormGroup) {
    return (params: any): void => {
      const control = formGroup.get(config.name);
      if (!control) {
        return;
      }
      if (control.value) {
        return;
      }
      config.hide = true;
      control.clearValidators();
      control.clearAsyncValidators();
      control.setErrors(null);
    };
  }

  @CustomHook("disableOnChina")
    disableOnChina(
    config: ComponentConfig,
    context: DynamicContext,
    formGroup: UntypedFormGroup
  ) {
    return (params: any): void => {
      const runtime = new Function(
        "config",
        "context",
        `return (${params})`
      );
      const result = runtime(config, context);
      if (result) {
        formGroup
          .get(config.name)
          .patchValue("Print", { onlySelf: true, emitEvent: true });
        formGroup.get(config.name).disable();
      } else {
        formGroup
          .get(config.name)
          .patchValue("", { onlySelf: true, emitEvent: true });
        formGroup.get(config.name).enable();
      }
    }
  }
  @CustomHook("disableOptionsOfPreferredOrderingMethod")
  disableOptionsOfPreferredOrderingMethod(
    config: ComponentConfig,
    context: DynamicContext,
    formGroup: UntypedFormGroup
  ) {
    return (params: any): void => {
      const runtime = new Function(
        "config",
        "context",
        `return (${params})`
      );
      const result = runtime(config, context);
      if (result) {
        formGroup
          .get(config.name)
          .patchValue("Print", { onlySelf: true, emitEvent: true });
        formGroup.get(config.name).disable();
        config.tooltip = ''
      } else {
        formGroup
          .get(config.name)
          .patchValue("", { onlySelf: true, emitEvent: true });
        formGroup.get(config.name).enable();
      }
    }
  }

  @CustomHook("removeRequired")
  removeRequired(
    config: ComponentConfig,
    context: DynamicContext,
    formGroup: UntypedFormGroup
  ) {
    return (params: any): void => {
      const control = formGroup.get(config.name);
      if (control) {
        config.required = false;
        control.clearValidators();
        control.clearAsyncValidators();
        control.updateValueAndValidity({ onlySelf: true, emitEvent: true });
      }
    };
  }

  @CustomHook("setRequired")
  setRequired(
    config: ComponentConfig,
    context: DynamicContext,
    formGroup: UntypedFormGroup
  ) {
    return (params: any): void => {
      const control = formGroup.get(config.name);
      if (control) {
        config.required = true;
        control.setValidators(
          this.service.validatorFactory.createValidators(config, context)
        );
        control.setErrors(null);
        control.updateValueAndValidity({ onlySelf: true, emitEvent: true });
      }
    };
  }
}
