import { Injectable } from "@angular/core";
import { CustomHook, CustomValidator } from "../interfaces/Decorators";
import { ComponentConfig } from "../ComponentConfig";
import { DynamicContext } from "../interfaces/DynamicContext";
import {
  FormGroup,
  ValidatorFn,
  AbstractControl,
  ValidationErrors,
} from "@angular/forms";

@Injectable()
export class ValidatorDecoratorComposition {
  private emailPattern =
    /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,})$/;

  // Allow a-z, A-Z alphabates, 0-9 numbers and space betwwen names
  private specialCharactersPattern = /^[a-zA-Z0-9 ]*$/;    
  private specialCharactersPatternForAccountNo = /^[0-9]+$/;
  private onlyTextCharacterPattern = /^[a-zA-Z ]*$/;
  
  @CustomValidator({ name: "email", async: false })
  public email(config: ComponentConfig, context: DynamicContext): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.value) {
        return null;
      }
      const result = this.emailPattern.test(control.value);
      return !result ? { email: true } : null;
    };
  }

  @CustomValidator({name : "specialcharactersfirstname", async: false})
  public specialcharactersfirstname(config: ComponentConfig, context: DynamicContext): ValidatorFn{
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.value) {
        return null;
      }
      const result = this.specialCharactersPattern.test(control.value);
      return result ? null : { specialcharactersfirstname: true }; // : null;
    };
  }

  @CustomValidator({name : "specialcharacterslastname", async: false})
  public specialcharacterslastname(config: ComponentConfig, context: DynamicContext): ValidatorFn{
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.value) {
        return null;
      }
      const result = this.specialCharactersPattern.test(control.value);
      return result ? null : { specialcharacterslastname: true }; // : null;
    };
  }

  @CustomValidator({name : "specialcharacterscompanyname", async: false})
  public specialcharacterscompanyname(config: ComponentConfig, context: DynamicContext): ValidatorFn{
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.value) {
        return null;
      }
      const result = this.specialCharactersPattern.test(control.value);
      return result ? null : { specialcharacterscompanyname: true };
    };
  }

  @CustomValidator({name : "specialcharactersstreetads", async: false})
  public specialcharactersstreetads(config: ComponentConfig, context: DynamicContext): ValidatorFn{
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.value) {
        return null;
      }
      const result = this.specialCharactersPattern.test(control.value);
      return result ? null : { specialcharactersstreetads: true };
    };
  }  

  @CustomValidator({name : "specialCharactersCity", async: false})
  public specialCharactersCity(config: ComponentConfig, context: DynamicContext): ValidatorFn{
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.value) {
        return null;
      }
      const result = this.specialCharactersPattern.test(control.value);
      return result ? null : { specialCharactersCity: true };
    };
  }
 
  @CustomValidator({name : "specialCharactersZipcode", async: false})
  public specialCharactersZipcode(config: ComponentConfig, context: DynamicContext): ValidatorFn{
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.value) {
        return null;
      }
      const result = this.specialCharactersPattern.test(control.value);
      return result ? null : { specialCharactersZipcode: true };
    };
  }

  @CustomValidator({ name: "specialCharactersAccountNo", async: false })
  public specialCharactersAccountNo(config: ComponentConfig, context: DynamicContext): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.value) {
        return null;
      }
      const result = this.specialCharactersPatternForAccountNo.test(control.value);
      return result ? null : { specialCharactersAccountNo: true };
    };
  }

  @CustomValidator({ name: "specialCharacterAccountHolderNm", async: false })
  public specialCharacterAccountHolderNm(config: ComponentConfig, context: DynamicContext): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.value) {
        return null;
      }
      const result = this.onlyTextCharacterPattern.test(control.value);
      return result ? null : { specialCharacterAccountHolderNm: true };
    };
  } 

}
