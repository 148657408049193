import { BuyerService } from "src/app/services/buyer.service";
import { SupplierService } from "src/app/services/supplier.service";
import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  EventEmitter,
  AfterViewInit,
  AfterContentInit,
} from "@angular/core";
import {
  OnBoardingRequestInfoType,
  OnBoardMessage,
} from "src/app/interfaces/buyer";
import { Router, ActivatedRoute } from "@angular/router";
import { CommonService } from "src/app/services/common.service";
import { OnBoardingService } from "../../../services/onboarding.service";
import {
  RequestorDetailsInfo,
  RequestFormInfo,
} from "src/app/interfaces/requestForm";
import { trim } from "src/app/utils";
import {
  SupplierPendingStatus,
  StatusType,
  SupplierStatus,
  LoadingUrlMapping,
} from "src/app/interfaces/mapping";
import { AuthService } from "src/app/services/auth/auth.service";
import {
  DialogType,
  DialogService,
  ButtonItem,
  ButtonCommands,
  ButtonCommand,
} from "src/app/services/dialog.service";
import VMController from "src/app/interfaces/vm";
import { CanComponentDeactivate } from "src/app/can-deactivate.guard";
import {
  SpendCategoryGroup,
  AP_ReviewStatus,
} from "src/app/interfaces/mapping";
import { LoadingService } from "src/app/services/loading.service";
import { environment } from "src/environments/environment";
import { supplierGeographical } from "src/app/shared/shared";
import {
  ProcessNames,
  SetupProcess,
  AllTaskKey,
  Task,
} from "src/app/interfaces/workflow";
import { dropDownSortingByText } from "../../../../../utils/index";
import { RoleName } from "src/app/interfaces/buyer";

const emailPattern =
  /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,})$/;
import { ComponentConfig } from "src/app/dynamic-components/ComponentConfig";
import { DynamicContext } from "src/app/dynamic-components/interfaces/DynamicContext";
import { DynamicFormComponent } from "src/app/dynamic-components/components/dynamic-form/dynamic-form.component";
import { MetadataService } from "src/app/services/metadata.service";
import { FormGroup } from "@angular/forms";
import { DictionaryService } from "src/app/services/dictionary.service";
import { resolve } from "url";
import { promise } from "protractor";
import { reject } from "q";
import { BuyerCommonService } from "../../../services/buyerCommonService";
import { showGlobalPrepopulateData } from "../../../../../../config/config";
import { MatDialog } from '@angular/material/dialog';
import { GlobalPopupComponent } from "src/app/global-popup/global-popup.component";
import { SupplierModelForSaveDraft, createSupplierModelForSaveDraft } from "src/app/interfaces/supplierModelForSaveDraft";
import { LaunchDarklyService } from "src/app/services/launch-darkly.service";
import { isPplusUser } from "../../../services/verifyProcurementPlus";
import { Subscription } from "rxjs";

enum CheckEmailStatus {
  EmailExist = "EmailExist",
  DomainExist = "DomainExist",
  InvalidateEmail = "InvalidateEmail",
  NoProblem = "NoProblem",
}

@Component({
  selector: "app-requestor-details",
  templateUrl: "./requestor-details.component.html",
  styleUrls: ["./requestor-details.component.sass"],
})
export class RequestorDetailsComponent
  implements OnInit, OnDestroy, CanComponentDeactivate
{
  SpendCategoryGroupKey = SpendCategoryGroup;
  SupplierGeographical: string;
  DictBusinessOrganization: any;
  SupplierCountry: Array<string>;
  SpendCommodityGroup: Array<string>;
  SpendCategoryGroup: any;
  SpendSubCategory: any;
  // detailStatus: string   // old status verify
  // apReviewStatus: string   // old status verify
  originalShowISKey: boolean;
  CheckEmailStatus = CheckEmailStatus;
  SupplierPendingStatus = SupplierPendingStatus;
  SupplierStatus = SupplierStatus;
  getConfigsInfo: ComponentConfig[] = [];
  context: DynamicContext;
  isResubmitDisabled = true;
  skipInterception = false;
  alertMsg: string;
  currentValue="";
  globalAnwsers = [];
  originalCommodityValue;
  originalSubCategoryValue;
  globalNameElement:any;
  checkSupplier:any
  dialogRef: any;
  getglobalVal: any;
  isNewVersion:boolean=true;
  isFirstSave:boolean=false;
  supplierName:string;
  isPopupVisible:boolean= false;
  supplierModelForSaveDraft:SupplierModelForSaveDraft=createSupplierModelForSaveDraft();
  initalRequestorDetailsInfoModel:RequestorDetailsInfo;
  @ViewChild("dynamicForm") dynamicForm: DynamicFormComponent;
  get mode(): string {
    return this.onBoardingService.mode;
  }

  get requestorDetailsInfo(): RequestorDetailsInfo {
    return this.onBoardingService.supplierModel.RequestForm
      .RequestorDetailsInfo;
  }
  set requestorDetailsInfo(val) {
    this.onBoardingService.supplierModel.RequestForm
      .RequestorDetailsInfo = val
  }

  get requestFormInfo(): RequestFormInfo {
    return this.onBoardingService.supplierModel.RequestForm.RequestFormInfo;
  }

  get originalSpendCategoryGroupValue(): string {
    return this.dynamicForm.originalValues().SpendCategoryGroup;
  }

  get isTerminate(): boolean {
    return this.onBoardingService.checkIsTerminate();
  }
  get isSaveContitue(): boolean {
    return this.onBoardingService.checkIsSaveContitue();
  }

  // alert: any

  // isValidated: boolean
  // isNew: Boolean = true
  AP_ReviewStatus = AP_ReviewStatus;
  onBoardingRequestInfoType = OnBoardingRequestInfoType;

  vm: VMController<RequestorDetailsInfo> = new VMController();

  get supplierCode(): string {
    return this.onBoardingService.supplierCode;
  }
  currentApprover: boolean = false;
  private currentApproverSubscription: Subscription;

  constructor(
    private router: Router,
    private dialogService: DialogService,
    private onBoardingService: OnBoardingService,
    private commonService: CommonService,
    private dictionaryService: DictionaryService,
    private buyerService: BuyerService,
    private supplierService: SupplierService,
    private metadataService: MetadataService,
    private auth: AuthService,
    private loadingService: LoadingService,
    private buyerCommonService: BuyerCommonService,
    private dialog: MatDialog,
    private launchDarklyService:LaunchDarklyService
  ) {
    this.onBoardingService.stepChangeEvent.emit(1);

      this.launchDarklyService.ldChange.subscribe(any => {
            console.log("Trigger refresh data")
            this.refreshToggle()
        })
        this.currentApproverSubscription = this.commonService.currentApprover$.subscribe(
          (newValue: boolean) => {
            this.currentApprover = newValue;
          }
        );
  }

   refreshToggle():void{
      this.isNewVersion=this.launchDarklyService.getToggle(LaunchDarklyService.LD_ISNEWVERSION)==="true"
    }

  async ngOnInit() {
    this.refreshToggle()
    this.loadingService.showLoading();
    this.skipInterception = false;
    this.context = new DynamicContext();
    this.context.mode = this.mode;
    this.onBoardingService.current_step = 1;
    this.checkSupplier = await this.buyerService.getExistingSupplier(this.supplierCode);
    // workflow load current login user's tasks
    const [getConfigsInformation, data]: any = await Promise.all([
      this.metadataService.getPageConfiguration("buyer-requestform-step1"),
      this.onBoardingService.loadUserTasks(),
    ]);
    this.getConfigsInfo = getConfigsInformation;
    // this.getConfigsInfo = await this.metadataService.getPageConfiguration('buyer-requestform-step1')
    // await this.onBoardingService.loadUserTasks()
    this.onBoardingService.supplierModel.Mapping.paymentTermsIsChange = false;
    this.context.on("alertMsg", (str: any) => {
      this.alertMsg = str;
    });
    this.context.on("SignedQuotationAttachments", (fileList: any) => {
      if (fileList !== "") {
        this.dynamicForm.formGroup
          .get("SignedQuotationAttachments")
          .patchValue(fileList);
      }
    });
    if (!this.requestorDetailsInfo.RequestedDate) {
      this.requestorDetailsInfo.RequestedDate = new Date();
      this.isFirstSave=true;
    }
    if (!this.requestorDetailsInfo.RequestorEid) {
      const userEmail = this.auth.passport.buyer.Email;
      if (userEmail) {
        this.requestorDetailsInfo.RequestorEid = userEmail.substring(
          0,
          userEmail.indexOf("@")
        );
      }
    }
    await this.buyerCommonService.SetContextValue(this.context);

    this.commonService.ExitEvent.subscribe((e) => {
      this.vm.setOriginal(this.dynamicForm.originalValues());
      this.vm.setCurrent(this.dynamicForm.values());
      if (this.vm.isDirty()) {
        e.preventDefault();
        // Chrome requires returnValue to be set
        e.returnValue = "";
      }
    });
    this.vm.setOriginal(this.requestorDetailsInfo);

    // work flow comments:
    // this.detailStatus = this.onBoardingService.supplierModel.Mapping.detailStatus
    // this.apReviewStatus = this.onBoardingService.supplierModel.Mapping.AP_reviewStatus
    this.dynamicForm.subscribeToFormChange(() => {
      // add settimeout for bug 1474813
      setTimeout(() => {
        this.isResubmitDisabled =
          JSON.stringify(this.dynamicForm.originalValues()) ===
          JSON.stringify(this.dynamicForm.values());
      });
    });
    // this.detailStatus = this.onBoardingService.supplierModel.Mapping.detailStatus
    // this.apReviewStatus = this.onBoardingService.supplierModel.Mapping.AP_reviewStatus

    this.dynamicForm.subscribeToModelChange(
      "SupplierGeographical",
      async (value) => {
        this.checkContractStatus(
          value,
          this.dynamicForm.formGroup.get("ContractStatus").value
        );
        this.checkContractStatusforItaly(
          value,
          this.dynamicForm.formGroup.get("ContractStatus").value,
          this.dynamicForm.formGroup.get("SupplierCountry").value
        );
        this.checkContractStatusforGallia(
          value,
          this.dynamicForm.formGroup.get("SupplierCountry").value,
          this.dynamicForm.formGroup.get("ContractNegotiationsForGallia").value,
          this.dynamicForm.formGroup.get("SpendCommodityGroup").value
        );
        this.checkContractStatusforGalliaForNO(
          value,
          this.dynamicForm.formGroup.get("SupplierCountry").value,
          this.dynamicForm.formGroup.get("ContractStatusForGallia").value,
          this.dynamicForm.formGroup.get("SpendCommodityGroup").value
        );
      }
    );
    this.dynamicForm.subscribeToModelChangeFordialog("ContractStatus", async (value) => {
      this.checkContractStatus(
        this.dynamicForm.formGroup.get("SupplierGeographical").value,
        value
      );
      this.checkContractStatusforItaly(
        this.dynamicForm.formGroup.get("SupplierGeographical").value,
        value,
        this.dynamicForm.formGroup.get("SupplierCountry").value
      );
    });
    this.dynamicForm.subscribeToModelChangeFordialog(
      "SupplierCountry",
      async (value) => {
        this.checkContractStatusforItaly(
          this.dynamicForm.formGroup.get("SupplierGeographical").value,
          this.dynamicForm.formGroup.get("ContractStatus").value,
          value
        );
        this.checkContractStatusforGallia(
          this.dynamicForm.formGroup.get("SupplierGeographical").value,
          value,
          this.dynamicForm.formGroup.get("ContractNegotiationsForGallia").value,
          this.dynamicForm.formGroup.get("SpendCommodityGroup").value
        );
        this.checkContractStatusforGalliaForNO(
          this.dynamicForm.formGroup.get("SupplierGeographical").value,
          value,
          this.dynamicForm.formGroup.get("ContractStatusForGallia").value,
          this.dynamicForm.formGroup.get("SpendCommodityGroup").value
        );
      }
    );
    this.dynamicForm.subscribeToModelChangeFordialog(
      "ContractNegotiationsForGallia", 
      async (value) => {
        if(this.currentValue!==value){
        this.checkContractStatusforGallia(
          this.dynamicForm.formGroup.get("SupplierGeographical").value,
          this.dynamicForm.formGroup.get("SupplierCountry").value,
          value,
          this.dynamicForm.formGroup.get("SpendCommodityGroup").value
        )
        this.currentValue=value
      }
    });
    this.dynamicForm.subscribeToModelChangeFordialog(
      "SpendCommodityGroup", 
      async (value) => {
          this.checkContractStatusforGallia(
          this.dynamicForm.formGroup.get("SupplierGeographical").value,
          this.dynamicForm.formGroup.get("SupplierCountry").value,
          this.dynamicForm.formGroup.get("ContractNegotiationsForGallia").value,
          value
        );
         this.checkContractStatusforGalliaForNO(
          this.dynamicForm.formGroup.get("SupplierGeographical").value,
          this.dynamicForm.formGroup.get("SupplierCountry").value,
          this.dynamicForm.formGroup.get("ContractStatusForGallia").value,
          value
        );
        this.updateVaild(
          this.dynamicForm.formGroup.get("SupplierGeographical").value,
          this.dynamicForm.formGroup.get("SupplierCountry").value,
          this.dynamicForm.formGroup.get("ContractNegotiationsForGallia").value,
          this.dynamicForm.formGroup.get("ContractStatusForGallia").value
        )
        this.checkSpendCommodityGroupStatus(
          value, this.originalCommodityValue,this.onBoardingService.supplierModel.RequestForm.RequestorDetailsInfo.RequestorEid
        );
    });
    this.dynamicForm.subscribeToModelChange(
      "SpendCategoryGroup",
      async (value) => {
        if(value && (this.originalCommodityValue==this.dynamicForm.formGroup.get("SpendCommodityGroup").value)){
        this.checkSpendCategoryGroupStatus(
            value, this.originalSubCategoryValue,this.onBoardingService.supplierModel.RequestForm.RequestorDetailsInfo.RequestorEid
          );
        }
        else{
           //do nothing to avoid trigerring dialog 2 times
       }
      }
    );

    this.dynamicForm.subscribeToModelChangeFordialog(
      "ContractStatusForGallia", 
      async (value) => {
    this.checkContractStatusforGalliaForNO(
        this.dynamicForm.formGroup.get("SupplierGeographical").value,
        this.dynamicForm.formGroup.get("SupplierCountry").value,
        value,
        this.dynamicForm.formGroup.get("SpendCommodityGroup").value
      )
     });
    this.loadingService.hideLoading();
    // console.log('MODE', this.onBoardingService.mode)
    if ((this.onBoardingService.mode == 'edit' || this.onBoardingService.mode == 'create') && showGlobalPrepopulateData ){
        console.log("Set Set setTimeout");
        this.formAutofill()
        this.originalCommodityValue = this.dynamicForm.model.SpendCommodityGroup;
        this.originalSubCategoryValue = this.dynamicForm.model.SpendCategoryGroup;
    }
    if(this.checkPplus()){await this.commonService.checkAccess(this.auth.passport.profile.email, this.supplierCode)};
  }
  ngOnDestroy() {
    if (this.currentApproverSubscription) {
      this.currentApproverSubscription.unsubscribe();
    }
  }
  ngAfterViewInit(){
    this.dynamicForm.updateFormValues()
  }
  isSupplierNameDuplicate(): boolean {
    this.supplierName =
      this.onBoardingService.supplierModel.Mapping.searchTerm.supplierName;
    return this.supplierName.startsWith("DUPLICATE-");
  }
  async formAutofill(){
    // console.log("formAutofill - supplierCode ", this.supplierCode);
    // console.log("formAutofill - supplierTsmId ",this.onBoardingService.supplierModel.Mapping.TsmId);
    let payload = {
      supplierCode: this.supplierCode, 
      SupplierCompanyName: this.requestorDetailsInfo.SupplierCompanyName,
      supplierTsmId: this.onBoardingService.supplierModel.Mapping.TsmId,
      countryServed: this.requestorDetailsInfo.SupplierCountry,
      RoleCode: this.auth.passport.buyer.RoleCode
    }
    // console.log('formAutofill Global payload', payload);
    if(payload.supplierCode && payload.supplierTsmId){
      let getGlobalQAs;
      if (localStorage.getItem("globalqa") == null) {
        const getGlobalQAs = await this.buyerService.getGlobalFieldData(payload);
        localStorage.setItem("globalqa", JSON.stringify(getGlobalQAs))
      }
      else {
        getGlobalQAs = JSON.parse(localStorage.getItem("globalqa"));
      }
      // console.log('formAutofill getAllAnswers', getGlobalQAs)
      if (this.getConfigsInfo && this.getConfigsInfo.length && getGlobalQAs) {
        this.getConfigsInfo.forEach(item => {
          if (item['type'] == 'layout'){
            item['fieldset'].forEach(field => {
              // console.log('field', field)
              // console.log('Global', field['global'])
              if (field['global']){
                const filterAnwsers = getGlobalQAs.filter((question) => question['QuestionName'] == field['name'])
                this.globalAnwsers.push(...filterAnwsers)
              }
            })
          }
        })
      }

      // console.log('formAutofill globalAnwsers', this.globalAnwsers)
      let requestorDetailsInfoData = Object.assign({}, this.requestorDetailsInfo)
      this.globalAnwsers.forEach((anwserItem) => {
        // console.log('formAutofill anwserItem', anwserItem);
        if(anwserItem != null && anwserItem != undefined ){
          // console.log('formAutofill this.onBoardingService.supplierModel.RequestForm', this.onBoardingService.supplierModel.RequestForm);  
          if((this.requestorDetailsInfo[anwserItem['QuestionName']] == "") || (this.requestorDetailsInfo[anwserItem['QuestionName']] == null) || (this.requestorDetailsInfo[anwserItem['QuestionName']] == undefined)){
            // console.log('formAutofill Prepopulate Values', anwserItem['Answer']);    
            requestorDetailsInfoData[anwserItem['QuestionName']] = anwserItem['Answer']
          }
        }  
      })
      this.requestorDetailsInfo = requestorDetailsInfoData;
      // console.log('formAutofill this.requestorDetailsInfo', this.requestorDetailsInfo);  
    }
    setTimeout(() => {              
      console.log("settimeout");
      const elements: HTMLInputElement[] = [];
    
      for (let gobalVal of this.globalAnwsers) {
        let formControlType: string;
        
        if (gobalVal['AnswerInputField'] === 'dropdown') {
          formControlType = 'select';
        } else if (gobalVal['AnswerInputField'] === 'input') {
          formControlType = 'input';
        } else if (gobalVal['AnswerInputField'] === 'radio') {
          formControlType = 'radio';
        }
    
        const actualElement = document.querySelector<HTMLInputElement>(`[id^="${gobalVal['QuestionName']}${formControlType}"]`);
        
        if (actualElement) {
          elements.push(actualElement);
        }
      }
      
      for (const element of elements) {
        element.addEventListener("change", (e) => {
          // console.log("Field change??? ===>", e);
          const targetElement = e.currentTarget as HTMLElement;
          if (targetElement) {
            targetElement.style.border = 'solid 1px #414187';
          }
        });
      }
      this.initalRequestorDetailsInfoModel = trim(this.dynamicForm.originalValues());
    });
   }
  
   highlightInput() {
    const elements: HTMLInputElement[] = [];

    for (let gobalVal of this.globalAnwsers) {
      let formControlType: string;

      if (gobalVal['AnswerInputField'] === 'dropdown') {
        formControlType = 'select';
      } else if (gobalVal['AnswerInputField'] === 'input') {
        formControlType = 'input';
      } else if (gobalVal['AnswerInputField'] === 'radio') {
        formControlType = 'radio';
      }

      const actualElement = document.querySelector<HTMLInputElement>(`[id^="${gobalVal['QuestionName']}${formControlType}"]`);

      if (actualElement) {
        elements.push(actualElement);
      }
    }''

    for (const element of elements) {
      element.style.border = '2px solid orange';
    }
  }
  
  openGlobalPopup() {
    this.dialogRef = this.dialog.open(GlobalPopupComponent, {
      width: '450px',
    });
  
    this.dialogRef.afterClosed().subscribe(result => {
      for (this.getglobalVal of this.globalAnwsers) {
        const supplierControl = this.dynamicForm.formGroup.controls[this.getglobalVal.QuestionName];
        if (supplierControl) {
          if (supplierControl.dirty) {
            if (result === 'onConfirmClick') {
              this.router.navigate([`buyer/onboarding/${this.supplierCode}/step2`]);
            } else if (result === 'onCancelClick') {
              supplierControl.setValue(this.getglobalVal.Answer);
              this.highlightInput();
            }
          }
        }
      }
    });
  }

    checkAndOpenGlobalPopup() {
      const globalDirty = this.globalAnwsers.some(globalAnswerVal => {
        const control = this.dynamicForm.formGroup.controls[globalAnswerVal.QuestionName];
        return control && control.dirty;
    });
      if (globalDirty) {
        this.openGlobalPopup();
    }
    else if(!globalDirty){
      this.router.navigate([`buyer/onboarding/${this.supplierCode}/step2`]);
    }
  }

  async canDeactivate() {
    if (this.skipInterception) {
      return true;
    }
    this.vm.setOriginal(this.dynamicForm.originalValues());
    this.vm.setCurrent(this.dynamicForm.values());
    if (this.vm.isDirty()) {
      const result = await this.dialogService.saveConfirm();
      if (result === "save" && this.isSaveContitue) {
        const sstRejectToBuyer_task = this.onBoardingService.getUserTaskByKey(
          ProcessNames.generic_supplierSetup,
          SetupProcess.SST_rejectToBuyer
        );
        if (sstRejectToBuyer_task) {
          this.initOriginalCategoryValue();
          const spendCategoryGroup =
            this.dynamicForm.formGroup.get("SpendCategoryGroup").value;
          if (
            this.SpendCategoryGroupKey[spendCategoryGroup] !==
            this.originalShowISKey
          ) {
            if (!this.originalShowISKey) {
              this.resubmitWithCategoryChange();
              return false;
            }
          }
        }
        if (!(await this.saveDraft())) {
          return false;
        }
      }
      if (result === "cancel") {
        return false;
      }
    }

    return true;
  }
  
  async btnNext() {
    const sstRejectToBuyer_task = this.onBoardingService.getUserTaskByKey(
      ProcessNames.generic_supplierSetup,
      SetupProcess.SST_rejectToBuyer
    );
    if (sstRejectToBuyer_task) {
      this.originalShowISKey =
        this.SpendCategoryGroupKey[this.originalSpendCategoryGroupValue];
      const spendCategoryGroup =
        this.dynamicForm.formGroup.get("SpendCategoryGroup").value;
      if (
        this.SpendCategoryGroupKey[spendCategoryGroup] !==
        this.originalShowISKey
      ) {
        if (!this.originalShowISKey) {
          this.resubmitWithCategoryChange();
        } else {
          this.normalSave();
        }
      } else {
        this.normalSave();
      }
    } else {
      this.normalSave();
    }
  }
  //this.auth.passport.buyer.RoleCode
  handleSubmit()
  {
    if(this.validate()) 
      {
      if(isPplusUser(this.auth.passport.buyer.RoleCode)){
        this.isPopupVisible = true; 
      }
      else{   
        this.btnNext();
      }
    } 
    else
    {
      this.normalSave();
    } 
  }
  // onConfirm() {
  //   this.isPopupVisible = false; 
  //   this.btnNext(); 
  // }
  onCancel() {
    this.isPopupVisible = false; 
  }
  normalSave() {
    this.saveDraft().then((d: boolean) => {
      if (d) {
        this.vm.saved();
        this.checkAndOpenGlobalPopup();
      }
    });
  }

  validate() {
   return this.dynamicForm.valide();
   
  }

  async resubmit() {
    this.context.changeSubmitState();
    this.resubmitSuccessDialog()
  }

  initOriginalCategoryValue() {
    this.originalShowISKey =
      this.SpendCategoryGroupKey[this.originalSpendCategoryGroupValue];

    // workflow comments: remove set status list
    // if (this.originalShowISKey) {
    //   this.onBoardingService.supplierModel.Mapping.AP_reviewStatus = AP_ReviewStatus.form_resubmitted
    //   this.onBoardingService.setStatusHistories(AP_ReviewStatus.form_resubmitted, StatusType.AP_review)
    // }
  }

  resubmitWithCategoryChange() {
    this.saveDraft().then((data: boolean) => {
      if (data) {
        this.vm.saved();
        this.dialogService
          .dialog(
            `Supplier Category has changed for this supplier and you will be required to answer the Information Security and GDPR risk assessment questionaires`,
            ButtonCommands.Close,
            DialogType.warning,
            "Please Note"
          )
          .then((result: boolean) => {
            this.router.navigate([
              `buyer/onboarding/${this.onBoardingService.supplierCode}/step3`,
            ]);
          });
      }
    });
    return true;
  }

  get ShowResubmitButton() {
    if(isPplusUser(this.auth.passport.buyer.RoleCode) && this.checkSupplier?.data === true){
      return false
    }
    return this.onBoardingService.checkResubmit();
  }

  resubmitSuccessDialog() {
    this.resubmitForm().then((data: boolean) => {
      if (data) {
        this.vm.saved();
        this.dialogService
          .dialog(
            `Your change request has been successfully submitted.`,
            ButtonCommands.Ok,
            DialogType.success,
            "Please Note"
          )
          .then((result: boolean) => {
            this.router.navigate([`buyer`]);
          });
      }
    });
    return true;
  }

  // resubmit request form
  async resubmitForm(): Promise<boolean> {
    this.context.changeSubmitState();
    this.dynamicForm.removeFormTouch();
    if (!this.validate()) {
      return false;
    }
    if (this.alertMsg !== "") {
      this.dialogService.dialog(
        this.alertMsg,
        ButtonCommands.Close,
        DialogType.warning,
        "Please Note"
      );
      return false;
    }
    await this.updateSupplierMapping();
    // get executable task GUID: taskInstId
    if (
      this.onBoardingService.checkTaskIsComplete(
        ProcessNames.generic_supplierSetup,
        SetupProcess.supplier_accepted
      )
    ) {
      this.onBoardingService.getExecuteTask(
        ProcessNames.generic_supplierSetupSubProcess,
        AllTaskKey.SST_rejectToBuyer
      );
      if (!this.onBoardingService.supplierModel.Mapping.executeTask) {
        return false;
      }
    }
    if(this.isNewVersion){
      return await this.onResubmitForm()
    }else{
      return await this.existingResubmitform()
    }
  }

  async onResubmitForm(){
    const saveUrl = `${environment.buyerGateway}${LoadingUrlMapping.resubmitRequestForm}`;
    this.loadingService.openLoading(saveUrl);
    await this.onBoardingService.resubmitFormEnhanced(this.supplierModelForSaveDraft),
    this.loadingService.closeLoading()
    return true;
  }

  async existingResubmitform(){
    const saveUrl = `${environment.gateway}${LoadingUrlMapping.resubmitRequestForm}`;
    this.loadingService.openLoading(saveUrl);
    const saveList = [
      await this.supplierService.saveProfileFormDraft(
        this.onBoardingService.supplierModel
      ),
      await this.onBoardingService.resubmitForm(),
    ];
    Promise.all(saveList);
    return true;
  }

  // resubmit request form
  async changeRequest(): Promise<boolean> {
    return true;
  }

  // save draft request form
  async saveDraft(): Promise<boolean> {
    this.context.changeSubmitState();
    this.dynamicForm.removeFormTouch();
    if (!this.validate()) {
      return false;
    }
    if (this.alertMsg !== "") {
      this.dialogService.dialog(
        this.alertMsg,
        ButtonCommands.Close,
        DialogType.warning,
        "Please Note"
      );
      return false;
    }
    // if (!this.selectedCompanyWithCountry()) {
    //   const result = await this.dialogService.dialog(`No Company Codes have been selected for ${this.alertInformation}.
    //   Please choose at least one company code for this county/these countries.`, ButtonCommands.Close, DialogType.alert,
    //     OnBoardMessage.APResultSendSuccess)
    //   return false
    // }
    await this.updateSupplierMapping();
    
    if (this.isNewVersion) {
      if (JSON.stringify(this.supplierModelForSaveDraft.ChangeHistory)!="{}") {
       return await this.onSavingDraft()
      }else{
        return true
      }
    }else{
     return await this.existingSaveDraft()
    }
  }
  async onSavingDraft(){
    const saveUrl = `${environment.buyerGateway}${LoadingUrlMapping.saveRequestorDetails}`;
    this.loadingService.openLoading(saveUrl);
    await this.onBoardingService.saveDraftForRequestorDetails(this.supplierModelForSaveDraft);   
    this.loadingService.closeLoading();
    return true;
  }
  async existingSaveDraft(){
    const saveUrl = `${environment.gateway}${LoadingUrlMapping.saveRequestDraft}`;
    this.loadingService.openLoading(saveUrl);
    if (
      this.onBoardingService.checkTaskIsComplete(
        ProcessNames.generic_supplierSetup,
        SetupProcess.requestor_draft
      )
    ) {
      const saveList = [
        await this.supplierService.saveProfileFormDraft(
          this.onBoardingService.supplierModel
        ),
        await this.onBoardingService.saveDraft(),
      ];
      Promise.all(saveList);
    } else {
      await this.onBoardingService.saveDraft();
    }
    return true;
  }
  async loadSupplier() {
    const data: any = await this.supplierService.querySupplierProfile(
      this.supplierCode
    );
    if (data.isSuccess) {
      return data.data.SupplierProfile;
    }
  }
  // update supplier mapping info
  private async updateSupplierMapping() {
    this.onBoardingService.supplierModel.RequestForm.RequestorDetailsInfo =
      trim(this.dynamicForm.values());
    if (this.onBoardingService.mode === "create") {
      const tempRequestor = {
        createdOn: new Date().toUTCString(),
        createdBy: this.auth.passport.profile.email,
      };
      Object.assign(
        this.onBoardingService.supplierModel.Mapping.requestor,
        tempRequestor
      );
      this.onBoardingService.supplierModel.Mapping.searchTerm.createBuyer =
        this.auth.passport.buyer.Email;
          }
    this.requestorDetailsInfo.SupplierContactPoint =
      this.onBoardingService.supplierModel.RequestForm.RequestorDetailsInfo.SupplierContactFirstName.concat(
        " ",
        this.onBoardingService.supplierModel.RequestForm.RequestorDetailsInfo
          .SupplierContactLastName
      );
    this.onBoardingService.supplierModel.RequestForm.RequestorDetailsInfo.SupplierContactPoint =
      this.requestorDetailsInfo.SupplierContactPoint;
    this.onBoardingService.supplierModel.Mapping.supplierEmail =
      this.onBoardingService.supplierModel.RequestForm.RequestorDetailsInfo.SupplierPOCEmail;
    this.onBoardingService.supplierModel.Mapping.searchTerm.supplierName =
      this.requestorDetailsInfo.SupplierCompanyName;
    this.onBoardingService.supplierModel.Mapping.searchTerm.companyCode =
      this.requestorDetailsInfo.CompanyCode;
    this.onBoardingService.supplierModel.Mapping.setUpCountry =
      this.requestorDetailsInfo.SupplierCountry;
    this.onBoardingService.supplierModel.Mapping.geographicalUnit =
      this.requestorDetailsInfo.SupplierGeographical;
    this.onBoardingService.supplierModel.Mapping.searchTerm.category =
      this.requestorDetailsInfo.SpendCategoryGroup;
    if (
      this.onBoardingService.checkTaskIsComplete(
        ProcessNames.generic_supplierSetup,
        SetupProcess.requestor_draft
      ) && !this.isNewVersion
    ) {
      this.onBoardingService.supplierModel.SupplierProfile =
        await this.loadSupplier();
      // becasuse data that from TSM include some Supplier  info, cause the keys is not empty when the supplier does not accept the invitation
      const existKeys = Object.keys(this.onBoardingService.supplierModel.SupplierProfile.SupplierGeneral);
      const updateFlag = existKeys.includes('PrimaryContactFirstNm') || existKeys.includes('PrimaryContactLastNm') || existKeys.includes('PrimaryContactNm');
      if (updateFlag) {
        this.onBoardingService.supplierModel.SupplierProfile.SupplierGeneral.PrimaryContactFirstNm =
          this.onBoardingService.supplierModel.RequestForm.RequestorDetailsInfo.SupplierContactFirstName;
        this.onBoardingService.supplierModel.SupplierProfile.SupplierGeneral.PrimaryContactLastNm =
          this.onBoardingService.supplierModel.RequestForm.RequestorDetailsInfo.SupplierContactLastName;
        this.onBoardingService.supplierModel.SupplierProfile.SupplierGeneral.PrimaryContactNm =
          this.onBoardingService.supplierModel.SupplierProfile.SupplierGeneral.PrimaryContactFirstNm.concat(
            " ",
            this.onBoardingService.supplierModel.SupplierProfile.SupplierGeneral
              .PrimaryContactLastNm
          );
      }
    }

    if (this.isNewVersion) {
      this.setSupplieModelForSaveDraft()
    }
  }

  setSupplieModelForSaveDraft(){
    this.supplierModelForSaveDraft.RequestForm.RequestorDetailsInfo=this.onBoardingService.supplierModel.RequestForm.RequestorDetailsInfo;
    if(this.supplierModelForSaveDraft.RequestForm.RequestorDetailsInfo.MIXFasttrack !== ''){
      this.supplierModelForSaveDraft.RequestForm.RequestorDetailsInfo.MIXFasttrack = String(this.supplierModelForSaveDraft.RequestForm.RequestorDetailsInfo.MIXFasttrack);   
    }
    this.supplierModelForSaveDraft.Mapping=this.onBoardingService.supplierModel.Mapping
    this.initalRequestorDetailsInfoModel.SupplierContactPoint =
    this.initalRequestorDetailsInfoModel.SupplierContactFirstName.concat(
      " ",
      this.initalRequestorDetailsInfoModel.SupplierContactLastName
    );

    if (this.isFirstSave) {
      this.initalRequestorDetailsInfoModel.RequestedDate=null;
      this.initalRequestorDetailsInfoModel.RequestorEid="";
    }
    this.supplierModelForSaveDraft.ChangeHistory =this.commonService.getChangeHistory(this.initalRequestorDetailsInfoModel,this.supplierModelForSaveDraft.RequestForm.RequestorDetailsInfo,"RequestorDetailsInfo")
  }

  async terminate() {
    const terminateResult = await this.dialogService.dialog(
      `Please note that if you terminate this request, the current process will be stopped with no option to reactivate.
                                          The process will need to be started again from the beginning.`,
      ButtonCommands.ProceedCancel
    );
    if (terminateResult === ButtonCommand.yes) {
      const variables = {
        isTerminate: true,
      };
      this.onBoardingService.getExecuteTask(
        ProcessNames.generic_supplierSetup,
        AllTaskKey.requestor_draft,
        variables
      );
      if (!this.onBoardingService.supplierModel.Mapping.executeTask) {
        return false;
      }
      this.onBoardingService.requestFormTerminate().then((d: any) => {
        if (d.isSuccess) {
          this.dialogService
            .dialog(
              "You have terminated successfully.",
              ButtonCommands.Close,
              DialogType.success,
              OnBoardMessage.APResultSendSuccess
            )
            .then((res: any) => {
              if (res === ButtonCommand.close) {
                const TsmId: string =
                  this.onBoardingService.supplierModel.Mapping.TsmId;
                const apFlag: boolean =
                  this.auth.passport.buyer.RoleCode === "AP_SST" ||
                  this.auth.passport.buyer.RoleCode === "AP_QC";
                if (!!TsmId && !apFlag) {
                  // let TsmLink:string = environment.landing_url
                  const env = sessionStorage.getItem("tsmEnv");
                  const url =
                    env === "dev"
                      ? environment.landingdev_url
                      : env === "perf"
                      ? environment.landingperf_url
                      : env === "hotfix"
                      ? environment.landinghotfix_url
                      : env === "stage"
                      ? environment.landingstage_url
                      : env === "test"
                      ? environment.landingtest_url
                      : environment.landing_url;
                  window.location.href =
                    url + "?login_hint=" + this.auth.passport.buyer.Email;
                  // window.location.href= TsmLink
                } else {
                  this.router.navigate([`buyer`]);
                }
              }
            });
        }
      });
    }
  }


  async checkSpendCommodityGroupStatus(newCommodity: string, originalCommodity: string, requestor : string) {
    // Change in Category
    if(this.auth.passport.buyer.RoleCode !== RoleName.ProcurementReviewerEditor){
      if (originalCommodity && originalCommodity != newCommodity) {
        let categoryChangeResult; 
        if(this.auth.passport.buyer.RoleCode !== 'S_CM') {
           categoryChangeResult = await this.dialogService.dialog(
            `<div style="text-align: center;">
            This change in category will not be permitted for this role. If still want to change please ask
            requestor to add in TSM. <br>
            Requestor ${requestor}
            </div>` ,
            ButtonCommands.CancelClose
          );
        } else {
         categoryChangeResult = await this.dialogService.dialog(
          // this message need to change..
          `<div style="text-align: center;">
          This change in category/sub-category will result in needing a new Risk Assessment.
          Please ask the Requestor or Supplier Manager to complete the Risk Assessment in TSM.<br>
          Requestor ${requestor}
          </div>` ,
          ButtonCommands.ProceedCancel
         );
        }
        if (categoryChangeResult === ButtonCommand.no 
          || categoryChangeResult === ButtonCommand.cancel 
          || categoryChangeResult === ButtonCommand.close) {
          this.dynamicForm.formGroup.get("SpendCommodityGroup").setValue(originalCommodity);
          this.dynamicForm.formGroup.get("SpendCategoryGroup").setValue( this.originalSubCategoryValue);
        }
      }
    }
    
  }

  async checkSpendCategoryGroupStatus(newSubCategory: string, originalSubCategory: string, requestor : string) {
    // Change in Sub Category
    if(this.auth.passport.buyer.RoleCode !== RoleName.ProcurementReviewerEditor){
      if (originalSubCategory && newSubCategory != originalSubCategory) {
        if(this.auth.passport.buyer.RoleCode !== 'S_CM') {
          this.dynamicForm.formGroup.get("SpendCategoryGroup").setValue(originalSubCategory);
        } else {
          const categoryChangeResult = await this.dialogService.dialog(
            `<div style="text-align: center;">
            This change in category/sub-category will result in needing a new Risk Assessment.
            Please ask the Requestor or Supplier Manager to complete the Risk Assessment in TSM.<br>
            Requestor ${requestor}
            </div>` ,
            ButtonCommands.ProceedCancel
          );
          if (categoryChangeResult === ButtonCommand.no) {
            this.dynamicForm.formGroup.get("SpendCategoryGroup").setValue(originalSubCategory);
          }
        }
      }
    }
  }

  async checkContractStatus(supplierGeographical: string, contractStatus: string) {
    // Middle East
    if (supplierGeographical === "24" && contractStatus === "N") {
      this.dialogService.dialog(
        `For any Purchases above $5000 USD, Procurement Operations should be consulted for guidance`,
        ButtonCommands.Close,
        DialogType.warning,
        "Please Note"
      );
    }
  }
  async checkContractStatusforItaly(
    supplierGeographical: string,
    contractStatus: string,
    SupplierCountry: Array<string>,
  ) {
    //ICEG&&IT
    // if (
    //   supplierGeographical === "20" &&
    //   SupplierCountry.includes("IT") &&
    //   contractStatus === "N"
    // ) {
    //   const res = await this.dialogService.dialog(
    //     `Please contact with Accenture Procurement team and get approval email of  the Sourcing Category Management Manager. Without approval email, the flow cannot be proceeded.`,
    //     ButtonCommands.GotItCancel,
    //     DialogType.warning,
    //     "Please Note"
    //   );
    //   if (res == 3) {
    //     this.dynamicForm.formGroup.get("ContractStatus").setValue(null);
    //   } else if (res == 4) {
    //     this.skipInterception = true;
    //     this.router.navigate([`buyer`]);
    //   }
    // }
  }
  async checkContractStatusforGallia(
    supplierGeographical: string,
    SupplierCountry: Array<string>,
    ContractNegotiationsForGallia: string,
    SpendCommodityGroup:Array<string>,
  ) {
    //Gallia
    if (
      supplierGeographical === "16" &&
      (SupplierCountry.includes("BE")|| SupplierCountry.includes("LU")|| SupplierCountry.includes("FR")|| SupplierCountry.includes("NL")|| SupplierCountry.includes("MU")|| SupplierCountry.includes("MA")|| SupplierCountry.includes("VN") ) &&
      (ContractNegotiationsForGallia === "N")&& (SpendCommodityGroup.includes("21")||SpendCommodityGroup.includes("13"))
    ) {
      if(SupplierCountry.includes("MU")||SupplierCountry.includes("MA")||SupplierCountry.includes("VN")){
        const res = await this.dialogService.dialog(
        `Procurement team has to be involved for any amount >10K$ (and from 0$ for Technology and Flexible Workforce categories), if appropriate please log a Request into <a href="https://support.accenture.com/procurement?id=e2e_guided_questions_page"  target = "_blank">BSR tool</a>.\n If Procurement is not involved on your category (Real Estate, Sponsorship, Meeting & Events) or if the amount is below the Procurement involvement threshold, please do not hesitate to contact Legal in order to generate the appropriate contract.`,
        ButtonCommands.Close,
        DialogType.warning,
        "Please Note"
        )
      }else{
      const res = await this.dialogService.dialog(
       `Procurement team has to be involved for any amount >25K$ (and from 0$ for Technology and Flexible Workforce categories), if appropriate please log a Request into <a href="https://support.accenture.com/procurement?id=e2e_guided_questions_page"  target = "_blank">BSR tool</a>.\n If Procurement is not involved on your category (Real Estate, Sponsorship, Meeting & Events) or if the amount is below the Procurement involvement threshold, please do not hesitate to contact Legal in order to generate the appropriate contract.`,
        ButtonCommands.Close,
        DialogType.warning,
        "Please Note"
      )
      }
    }
    // if(!!ContractNegotiationsForGallia && supplierGeographical === "16")
    // this.dynamicForm.formGroup.get("ContractNegotiationsForGallia").patchValue(ContractNegotiationsForGallia,{ onlySelf: true, emitEvent: true });
  }
  async checkContractStatusforGalliaForNO(
    supplierGeographical: string,
    SupplierCountry: Array<string>,
    ContractStatusForGallia: string,
    SpendCommodityGroup: Array<string>,
  ) {
    //Gallia
    if (
      supplierGeographical === "16" &&
      (SupplierCountry.includes("BE") || SupplierCountry.includes("LU") || SupplierCountry.includes("FR") || SupplierCountry.includes("NL") || SupplierCountry.includes("MU") || SupplierCountry.includes("MA") || SupplierCountry.includes("VN")) &&
      (ContractStatusForGallia === "12") && (SpendCommodityGroup.includes("21") || SpendCommodityGroup.includes("13"))

    ) {
      if (SupplierCountry.includes("MU") || SupplierCountry.includes("MA") || SupplierCountry.includes("VN")) {
        const res = await this.dialogService.dialog(
          `Procurement team has to be involved for any amount >10K$ (and from 0$ for Technology and Flexible Workforce categories), if appropriate please log a Request into <a href="https://support.accenture.com/procurement?id=e2e_guided_questions_page"  target = "_blank">BSR tool</a>.\n If Procurement is not involved on your category (Real Estate, Sponsorship, Meeting & Events) or if the amount is below the Procurement involvement threshold, please do not hesitate to contact Legal in order to generate the appropriate contract.`,
          ButtonCommands.Close,
          DialogType.warning,
          "Please Note"
        )
      } else {
        const res = await this.dialogService.dialog(
          `Procurement team has to be involved for any amount >25K$ (and from 0$ for Technology and Flexible Workforce categories), if appropriate please log a Request into <a href="https://support.accenture.com/procurement?id=e2e_guided_questions_page" target = "_blank">BSR tool</a>.\n If Procurement is not involved on your category (Real Estate, Sponsorship, Meeting & Events) or if the amount is below the Procurement involvement threshold, please do not hesitate to contact Legal in order to generate the appropriate contract.`,
          ButtonCommands.Close,
          DialogType.warning,
          "Please Note"
        )
      }
    }
    // if(!!ContractStatusForGallia && supplierGeographical === "16")
    //  this.dynamicForm.formGroup.get("ContractStatusForGallia").patchValue(ContractStatusForGallia,{ onlySelf: true, emitEvent: true });
  }
  
  checkPplus(){
    if(this.checkSupplier?.data === true){
      return isPplusUser(this.auth.passport.buyer.RoleCode)
    }
    else{
      return false;
    }
  }

  async updateVaild(
    supplierGeographical: string,
    SupplierCountry: Array<string>,
    ContractNegotiationsForGallia: string,
    ContractStatusForGallia: string
  ) {
    //Gallia
    if (supplierGeographical === "16" &&
      (SupplierCountry.includes("BE")|| SupplierCountry.includes("LU")|| SupplierCountry.includes("FR")|| SupplierCountry.includes("NL")|| SupplierCountry.includes("MU")|| SupplierCountry.includes("MA")|| SupplierCountry.includes("VN") ))
      {
      if(ContractNegotiationsForGallia === "N"){
      this.dynamicForm.formGroup.get("ContractNegotiationsForGallia").updateValueAndValidity({ onlySelf: true, emitEvent: true });
      }
      if(ContractStatusForGallia==="12"){
        this.dynamicForm.formGroup.get("ContractStatusForGallia").updateValueAndValidity({ onlySelf: true, emitEvent: true });
      }
    }
    // if(!!ContractNegotiationsForGallia && supplierGeographical === "16")
    // this.dynamicForm.formGroup.get("ContractNegotiationsForGallia").patchValue(ContractNegotiationsForGallia,{ onlySelf: true, emitEvent: true });
  }  

  async ppReject(){
    await this.dialogService.ppReject(DialogType.ppReject);
  }
}


