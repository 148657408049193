// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rounded-container {
  display: -webkit-inline-box;
}

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup-content {
  background-color: #f5f5f5;
  border: 1px solid #ccc;
  padding: 20px 5px;
  text-align: left;
  width: 600px;
  max-width: 90%;
}

form {
  background-color: #f5f5f5;
  padding: 20px;
  max-width: 100%;
  margin: 0 auto;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}
form .form-group {
  margin-bottom: 1.5rem;
}
form .form-group label {
  font-size: 1.1rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
  display: block;
  color: #495057;
}
form .form-group .form-input {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #ced4da;
  border-radius: 4px;
  font-size: 1.1rem;
  color: #495057;
  background-color: white;
  box-sizing: border-box;
}
form .form-group .form-input:focus {
  outline: none;
  border-color: #007bff;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
form .form-buttons {
  display: flex;
  justify-content: center;
  gap: 10px;
}
form .form-buttons .btn {
  padding: 0.75rem 1.25rem;
  font-size: 1.1rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  display: inline-block;
  text-align: center;
  transition: background-color 0.3s ease;
}
form .form-buttons .btn.primary {
  background-color: #4E4E87;
  color: white;
}
form .form-buttons .btn.primary:hover {
  background-color: rgb(88, 84, 185);
}
form .form-buttons .btn.secondary {
  background-color: #4E4E87;
  color: white;
}
form .form-buttons .btn.secondary:hover {
  background-color: rgb(88, 84, 185);
}

.processCollapse {
  display: none;
}

.background-color-fix1 {
  background-color: rgb(2, 86, 171);
}

.background-color-fix2 {
  background-color: rgb(88, 84, 185);
  display: inline-block;
  width: 20%;
}

.form-control {
  display: inline-block;
  width: 60%;
}

.people-field {
  display: inline-block;
  width: 60%;
}

.custom-btn {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  border: none;
  background-color: black;
  cursor: pointer;
  position: relative;
  transform: translateY(-50%);
  position: absolute;
  transform: translateY(-50%);
  top: -5px;
  left: 75px;
}
.custom-btn .custom-icon {
  font-size: 18px;
  color: white;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
