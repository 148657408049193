import {
    Pipe,
    PipeTransform
} from "@angular/core";
import * as moment from "moment";
@Pipe({
    name: "customDate"
}) export class CustomDatePipe implements PipeTransform {
    constructor() { }
    transform(value: any, format: string) {
        if (!!value) {
            const currentLanguage = localStorage.getItem("language")
            const localLocale = moment(value);
            if (!!currentLanguage) {
                localLocale.locale( CultureCountryMapping[currentLanguage] ? CultureCountryMapping[currentLanguage]: currentLanguage);
            }
            return localLocale.format(format)
        }
    }
} 
// Add current language list here, please add key once rollout new countryconst
const CultureCountryMapping = {
    cn: "zh-cn",
    en: "en",
    es: "es",
    fr: "fr",
    it: "it",
    jp: "ja",
    pt: "pt",
    br: "br",
    mx: "mx"
}