import { BuyerACLPermissionService } from "./../../services/buyerACLPermission.service";
import { CommonService } from "./../../../../services/common.service";
import { SupplierService } from "src/app/services/supplier.service";
import { OnBoardingService } from "./../../services/onboarding.service";
import { AuthService } from "src/app/services/auth/auth.service";
import { BuyerRoleACLPageName } from "src/app/interfaces/buyer";
import { environment } from "../../../../../environments/environment";
import { LoadingService } from "src/app/services/loading.service";

import {
  RoleName,
  OnBoardingBtnDesc,
  OnBoardMessage,
  DelimiterStr,
  PermissionType,
  PermissionControl,
  PermissionControlType,
  OnBoardingRequestInfoType,
  BIBDA,
  GDPR,
  InformationSecurity,
} from "./../../../../interfaces/buyer";
import {
  SpendCategoryGroup,
  SupplierStatus,
  RiskAssessmentStatus,
  StatusModel,
  StatusType,
  SapResultModel,
  AP_ReviewStatus,
  GstRegistrationSapItem,
  SapResultItem,
} from "../../../../interfaces/mapping";
import { ChangeRequestDetailStatus } from "src/app/interfaces/changeRequest";
import { IListProfileItems } from "./../../../../interfaces/profileDetails";
import {
  ButtonCommands,
  DialogType,
} from "./../../../../services/dialog.service";
import { DialogService } from "src/app/services/dialog.service";
import { Component, OnInit, OnDestroy, ViewChild } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { DashBoardService } from "../../services/dashboard.service";
import { BuyerService } from "src/app/services/buyer.service";
import {
  AllTaskKey,
  ProcessNames,
  SetupProcess,
  MigrateProcess,
  SupplierChangeRequestProcess,
  Task,
} from "src/app/interfaces/workflow";
import { ComponentConfig } from "src/app/dynamic-components/ComponentConfig";
import { DynamicContext } from "src/app/dynamic-components/interfaces/DynamicContext";
import { DynamicFormComponent } from "src/app/dynamic-components/components/dynamic-form/dynamic-form.component";
import { MetadataService } from "src/app/services/metadata.service";
import {
  RequestorDetailsInfo,
  RequestFormInfo,
  RiskAssessmentInfo,
  DeclarationInfo,
} from "src/app/interfaces/requestForm";
import {
  SupplierCompliance,
  SupplierDeclaration,
  SupplierFinancial,
  SupplierGeneral,
  SupplierExistingBusinessRelationship,
  SupplierOrganization,
} from "src/app/interfaces/supplierProfile";
import { BuyerCommonService } from "../../services/buyerCommonService";
import { HoldOnService } from "src/app/services/HoldOn";
import { HoldOn } from "src/app/interfaces/HoldOn";
import { DictionaryService } from "src/app/services/dictionary.service";
import { LanguageService } from "src/app/dynamic-components/utils/language.service";
import { SupplierDetailService } from "./supplier-detail.service";
import { isPplusUser, isRiskMitigation } from "../../services/verifyProcurementPlus";
import { Subscription } from "rxjs";
import { WorkFlowTransactionModel } from "src/app/interfaces/supplierModel";
import { LaunchDarklyService } from "src/app/services/launch-darkly.service";
enum FormType {
  Buyer = "buyer",
  Supplier = "supplier",
}

enum FieldType {
  Date = "Date",
  Dropdown = "Drop Down",
  FileUpload = "File Upload",
  MultipleChoice = "Multiple Choice",
  Object = "Object",
  RadioButton = "Radio Button",
  Text = "Text",
}

enum RoleCode {
  Business = "BI",
  Requestor = "requestor",
  SourcingCategoryManagement = "S_CM",
  DiversitySustainability = "DS",
  ISLegalGDPR = "IS_Legal_GDPR",
  APSupplierSetupTeam = "AP_SST",
  APQualityControl = "AP_QC",
  BuyerAdmin = "buyerAdmin",
  SystemAdmin = "systemAdmin",
  AdditionalIntervention = "additionalIntervention"
}

enum APType {
  Reject = "reject",
  Approve = "approve",
  Terminate = "terminate",
  SAPApprove = "sapapprove",
}
const {system} = JSON.parse(localStorage.getItem("externalEmails")) ?? {};
const DEFAULT_REQUESTOR = system;

@Component({
  selector: "app-supplier-detail",
  templateUrl: "./supplier-detail.component.html",
  styleUrls: ["./supplier-detail.component.sass"],
})
export class SupplierDetailComponent implements OnInit , OnDestroy{
  requestorDetailsInfo: RequestorDetailsInfo;
  requestFormInfo: RequestFormInfo;
  riskAssessmentInfo: RiskAssessmentInfo;
  declarationInfo: DeclarationInfo;
  supplierGeneral: SupplierGeneral;
  SupplierExistingBusinessRelationship: SupplierExistingBusinessRelationship;
  supplierOrganization: SupplierOrganization;
  supplierFinancial: SupplierFinancial;
  supplierCompliance: SupplierCompliance;
  supplierDeclaration: SupplierDeclaration;
  getCollapse1ConfigsInfo: ComponentConfig[] = [];
  getCollapse2ConfigsInfo: ComponentConfig[] = [];
  getCollapse3ConfigsInfo: ComponentConfig[] = [];
  getCollapse4ConfigsInfo: ComponentConfig[] = [];
  getCollapse5ConfigsInfo: ComponentConfig[] = [];
  getCollapse6ConfigsInfo: ComponentConfig[] = [];
  getCollapse7ConfigsInfo: ComponentConfig[] = [];
  getCollapse8ConfigsInfo: ComponentConfig[] = [];
  getCollapse9ConfigsInfo: ComponentConfig[] = [];
  getCollapse10ConfigsInfo: ComponentConfig[] = [];
  context: DynamicContext;
  isShow: boolean;
  isOnHold: boolean;
  @ViewChild("dynamicView5") dynamicForm: DynamicFormComponent;
  // new params
  profileList: any = [];
  supplierName: string;
  supplierCountryCode: string;
  formType = FormType;
  fieldType = FieldType;
  roleCode = RoleCode;
  sstType = APType;
  qcType = APType;
  status: string;
  bibdaResult;
  biBdaResultDS = {
    dp_biBdaScore: "",
  };
  PO;
  baseUrl = environment.gateway;
  sapResultList: Array<SapResultModel>;
  activeSapResultList: Array<SapResultItem> = [];
  blockedSapResultList: Array<SapResultItem> = [];
  gstRegistrationSapList: Array<GstRegistrationSapItem>;
  isSaveDisabled: boolean;
  isSAPResultCommit: boolean;
  showAllButton = false;
  showTooltip: boolean;
  holdOndInfo: any;
  checkSupplier:any;
  userRoleDesc = this.authService.passport.buyer.RoleCode;
  SupplierStatus = SupplierStatus;
  spendCategoryData: string;
  // old params
  supplierCode;
  // supplierModel: SupplierModel
  supplier;
  currentStatus = "";
  isDisableButton = true;
  onboardBtnDesc = OnBoardingBtnDesc.OnBoard;
  onboardInfo: any;
  activeDes = "Requestor Details";
  RoleName = RoleName;
  permissionList: any;
  titleList: any;
  mainTitleList: any = [];
  listHistory: any = [];
  bibda: BIBDA = {};
  gdpr: GDPR = {};
  informationSecurity: InformationSecurity = {};
  isValidated: boolean;
  BuyerCompanyNb = "accenture";
  isDisableAfterClick = false;
  isDisplayOnBPBtn = false;
  supplierSpendCommodityCategory: string;
  companyCode: Array<string>;
  supplierCountry: Array<string>;
  category: any = [];
  industryCode: any = [];
  purposeCode: any = [];
  isDisplayPurposeCode: boolean;
  hasCountryUSIN = false;
  monthDef = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  confirmPPlusRole = false;
  isRiskAssessmentNewSupplier: boolean;
  APQCApproved: boolean;
  currentApprover: boolean = false;
  private currentApproverSubscription: Subscription;
  isSupplierMigrated: boolean;
  stepProcurementPlusReview:WorkFlowTransactionModel;
  isSAPIntegrationFlag: boolean = false;
  isSAPMU: boolean = false;
  isAPSSTApproved: boolean;
  isSAPIntegrationDone: boolean;
  isSAPIntegrationPending: boolean;
  isSAPIntegrationInitiated: boolean;
  isSAPIntegrationFalied: boolean;
  isSAPIntegrationsBtnShow: boolean;
  isShowApproveBtn: boolean;
  SAPIntegrationInitiated : string;
  SAPIntegrationFalied: string;
  SAPIntegrationDone: string;
  SAPIntegrationPending: string;
  isUSMU : boolean = false;
  constructor(
    private route: ActivatedRoute,
    private dialogService: DialogService,
    private router: Router,
    private dashBoardService: DashBoardService,
    private buyerService: BuyerService,
    private supplierService: SupplierService,
    private onboardingService: OnBoardingService,
    private commonService: CommonService,
    private dictionaryService: DictionaryService,
    private authService: AuthService,
    private buyerACLPermissionService: BuyerACLPermissionService,
    private metadataService: MetadataService,
    private buyerCommonService: BuyerCommonService,
    private holdOnService: HoldOnService,
    public loadingService: LoadingService,
    private languageService: LanguageService,
    private supplierDetailService: SupplierDetailService,
    private launchDarklyService:LaunchDarklyService
  ) {
    this.onboardingService.mode = 'view';
    this.dashBoardService.isDashBoard = false;
    this.dashBoardService.title = "Supplier Profile";
    this.onboardingService.profileStage = "supplierDetail";
    this.currentApproverSubscription = this.commonService.currentApprover$.subscribe(
      (newValue: boolean) => {
        if (this.stepProcurementPlusReview && this.stepProcurementPlusReview.Status === "in_progress") {
          this.currentApprover = false;
        } else {
          this.currentApprover = newValue;
        }
      }
    );
   
    this.isSAPIntegrationFlag =
      this.launchDarklyService.getToggle(
        LaunchDarklyService.LD_SAPINTEGRATIONFLAG
      ) === "true";
      console.log('isSAPIntegrationFlag - ' + this.isSAPIntegrationFlag);
    this.SAPIntegrationInitiated = AP_ReviewStatus.SAP_SST_initiated;
    this.SAPIntegrationFalied = AP_ReviewStatus.SAP_SST_failure;
    this.SAPIntegrationDone = AP_ReviewStatus.SAP_SST_approve;
    this.SAPIntegrationPending = AP_ReviewStatus.SAP_SST_pending;
  }

  async ngOnInit() {
    this.loadingService.showLoading();
    this.isRiskAssessmentNewSupplier = this.onboardingService.supplierModel.isRiskAssessmentNewSupplier;
    console.log('this.isRiskAssessmentNewSupplier', this.isRiskAssessmentNewSupplier);
    this.route.params.subscribe((param) => {
      this.supplierCode = param.supplierCode;
    });
    const data = await this.dictionaryService.getDictionary("BI_BDA_Result");
    if (data) {
      data.map((t) => {
        this.biBdaResultDS[t.Code] = t.Items;
      });
    }
    this.onboardingService.supplierCode = this.supplierCode;
    await this.onboardingService.loadSupplierRequestForm();
    const [onHold]: Array<any> = await Promise.all([
      this.holdOnService.getLatestActiveHoldonBysupplierCode(this.supplierCode),
      //await this.onboardingService.loadSupplierRequestForm(),
      await this.onboardingService.loadSupplierProfile(),
    ]);


    this.status = this.onboardingService.supplierModel.Mapping.status;
    this.PO = this.onboardingService.supplierModel.Mapping.PO;
    if (
      Object.keys(
        this.onboardingService.supplierModel.RequestForm.RequestorDetailsInfo
      ).length > 0
    ) {
      this.requestorDetailsInfo =
        this.onboardingService.supplierModel.RequestForm.RequestorDetailsInfo;
      this.mainTitleList.push({
        MainTitle: "Requestor Details",
        Type: "buyer",
        isShow: true,
      });
    }
    if (this.requestorDetailsInfo.IsEmployee) {
      const employeeValue = this.requestorDetailsInfo.IsEmployee.toString()
        .toLowerCase()
        .trim();
      if (employeeValue === "true" || employeeValue === "yes") {
        this.requestorDetailsInfo.IsEmployee = "yes";
      } else {
        this.requestorDetailsInfo.IsEmployee = "no";
      }
    } else {
      this.requestorDetailsInfo.IsEmployee = "no";
    }
    
    if (
      Object.keys(
        this.onboardingService.supplierModel.RequestForm.RequestFormInfo
      ).length > 0
    ) {
      this.requestFormInfo =
        this.onboardingService.supplierModel.RequestForm.RequestFormInfo;
      this.mainTitleList.push({
        MainTitle: "Supplier & Spend Details",
        Type: "buyer",
        isShow: false,
      });
    }
    if (
      Object.keys(
        this.onboardingService.supplierModel.RequestForm.RiskAssessmentInfo
      ).length > 0
    ) {
      this.riskAssessmentInfo =
        this.onboardingService.supplierModel.RequestForm.RiskAssessmentInfo;
      this.mainTitleList.push({
        MainTitle: "Risk Assessment",
        Type: "buyer",
        isShow: false,
      });
    }
    if (
      Object.keys(
        this.onboardingService.supplierModel.RequestForm.DeclarationInfo
      ).length > 0
    ) {
      this.declarationInfo =
        this.onboardingService.supplierModel.RequestForm.DeclarationInfo;
      this.mainTitleList.push({
        MainTitle: "Declaration",
        Type: "buyer",
        isShow: false,
      });
    }
    if (this.onboardingService.supplierModel.SupplierProfile) {
      if (
        Object.keys(
          this.onboardingService.supplierModel.SupplierProfile.SupplierGeneral
        ).length > 0
      ) {
        this.supplierGeneral =
          this.onboardingService.supplierModel.SupplierProfile.SupplierGeneral;
        this.mainTitleList.push({
          MainTitle: "General",
          Type: "supplier",
          isShow: false,
        });
      }

      if (
        Object.keys(
          this.onboardingService.supplierModel.SupplierProfile.SupplierExistingBusinessRelationship
        ).length > 0
      ) {
        this.SupplierExistingBusinessRelationship =
          this.onboardingService.supplierModel.SupplierProfile.SupplierExistingBusinessRelationship;
        this.mainTitleList.push({
          MainTitle: "Existing Business Relationship",
          Type: "supplier",
          isShow: false,
        });
      }
      
      if (
        (Object.keys(
          this.onboardingService.supplierModel.SupplierProfile
            .SupplierOrganization
        ).length > 0) && 
        (Object.values(
          this.onboardingService.supplierModel.SupplierProfile
          .SupplierOrganization
          ).filter((i) => [null, undefined, ''].indexOf(i) === -1).length > 0)
      ) {
        this.supplierOrganization =
          this.onboardingService.supplierModel.SupplierProfile.SupplierOrganization;
        this.mainTitleList.push({
          MainTitle: "Organization",
          Type: "supplier",
          isShow: false,
        });
      }
      
      if (
        Object.keys(
          this.onboardingService.supplierModel.SupplierProfile.SupplierFinancial
        ).length > 0
      ) {
        this.supplierFinancial =
          this.onboardingService.supplierModel.SupplierProfile.SupplierFinancial;
        this.mainTitleList.push({
          MainTitle: "Financial",
          Type: "supplier",
          isShow: false,
        });
      }
      if (
        Object.keys(
          this.onboardingService.supplierModel.SupplierProfile
            .SupplierCompliance
        ).length > 0
      ) {
        this.supplierCompliance =
          this.onboardingService.supplierModel.SupplierProfile.SupplierCompliance;
        this.mainTitleList.push({
          MainTitle: "Compliance",
          Type: "supplier",
          isShow: false,
        });
      }
      if (
        Object.keys(
          this.onboardingService.supplierModel.SupplierProfile
            .SupplierDeclaration
        ).length > 0
      ) {
        this.supplierDeclaration =
          this.onboardingService.supplierModel.SupplierProfile.SupplierDeclaration;
        this.mainTitleList.push({
          MainTitle: "Supplier-Declaration",
          Type: "supplier",
          isShow: false,
        });
      }
    }
    if (this.PO) {
      this.requestorDetailsInfo.PO = this.PO;
    }
    if (onHold && onHold.data.length > 0) {
      this.isOnHold = true;
      this.requestorDetailsInfo.HoldStatus = "On Hold";
      onHold.data.forEach((hold: HoldOn) => {
        const date = hold.ExpirationDate;
        const cd = new Date(date);
        const day = cd.getDate() > 9 ? cd.getDate() : "0" + cd.getDate();
        const year = cd.getFullYear();
        const month = cd.getMonth();
        this.requestorDetailsInfo.HoldExpiryDate =
          this.monthDef[month] + " " + day + "," + year + ".";
      });
    }

    this.spendCategoryData =
      this.onboardingService.supplierModel.RequestForm.RequestorDetailsInfo.SpendCategoryGroup;
    this.supplierName =
      this.onboardingService.supplierModel.RequestForm.RequestorDetailsInfo.SupplierCompanyName;
    this.supplierSpendCommodityCategory =
      this.onboardingService.supplierModel.RequestForm.RequestorDetailsInfo.SpendCommodityGroup;
    this.initRoleData();
    this.isSaveDisabled = this.disableBtn();
    this.isSAPResultCommit = this.disableSapSaveBtn();
    this.showAllButton = true;
    this.companyCode =
      this.onboardingService.supplierModel.RequestForm.RequestorDetailsInfo.CompanyCode;

    this.supplierCountry =
      this.onboardingService.supplierModel.RequestForm.RequestorDetailsInfo.SupplierCountry;
    this.requestorDetailsInfo.PaymentPurposeCode = "";

    this.isUSMU = this.supplierCountry.includes('US') ? true : false;
    console.log('isUSMU - ' + this.isUSMU)

    this.isSAPMU = this.supplierCountry.includes('Ca') || this.supplierCountry.includes('US') ||
    this.supplierCountry.includes('PR') || this.supplierCountry.includes('IE') 
    || this.supplierCountry.includes('GB') || this.supplierCountry.includes('ZA') ? true : false;
    console.log('isSAPMU - ' + this.isSAPMU)

    this.isAPSSTApproved = this.onboardingService.supplierModel.Mapping.assessmentRate.sapResult.filter(
      (item) => item.type === AP_ReviewStatus.SST_approve || item.type === AP_ReviewStatus.SAP_SST_approve
    ).length > 0 ? true : false;    
    console.log('isAPSSTApproved - ' + this.isAPSSTApproved)     

    let lastSapResult = this.onboardingService.supplierModel.Mapping.assessmentRate.sapResult[
      this.onboardingService.supplierModel.Mapping.assessmentRate.sapResult.length - 1];

    this.isSAPIntegrationInitiated = lastSapResult?.type === AP_ReviewStatus.SAP_SST_initiated;    
    console.log('isSAPIntegrationInitiated - ' + this.isSAPIntegrationInitiated);

    this.isSAPIntegrationDone = lastSapResult?.type === AP_ReviewStatus.SAP_SST_approve ? true : false;    
    console.log('isSAPIntegrationDone - ' + this.isSAPIntegrationDone);
    
    this.isSAPIntegrationPending = lastSapResult?.type === AP_ReviewStatus.SAP_SST_pending;
    console.log('isSAPIntegrationPending - ' + this.isSAPIntegrationPending);

    this.isSAPIntegrationFalied = lastSapResult?.type === AP_ReviewStatus.SAP_SST_failure;
    console.log('isSAPIntegrationFalied - ' + this.isSAPIntegrationFalied);

    this.isSAPIntegrationsBtnShow = this.isSAPIntegrationsButtonShow();
    console.log('isSAPIntegrationsButtonShow - ' + this.isSAPIntegrationsBtnShow);

    this.isShowApproveBtn = this.isShowApproveButton();
    console.log('isShowApproveButton - ' + this.isShowApproveBtn);

    const dicts = this.dictionaryService.getDictionaryFromCache(
      "SpendCommodityGroupforPurpose"
    );
    let categoryGroup = [];
    dicts
      .map((p) => p.Items)
      .forEach((p) => (categoryGroup = categoryGroup.concat(p)));
    if (
      categoryGroup.filter(
        (p) => p.Code === this.requestorDetailsInfo.SpendCategoryGroup
      ).length !== 0
    ) {
      const category = categoryGroup.filter(
        (p) => p.Code === this.requestorDetailsInfo.SpendCategoryGroup
      )[0].Items;
      this.supplierCountry.forEach((element) => {
        this.purposeCode = category.filter((p) => p.Code === element);
        if (this.purposeCode.length > 0) {
          this.requestorDetailsInfo.PaymentPurposeCode +=
            element.toString() +
            "-" +
            this.purposeCode[0].Text.toString() +
            "   ";
        }
      });
    }

    this.context = new DynamicContext();
    this.context.set(
      "requestFormModel",
      this.onboardingService.supplierModel.RequestForm
    );
    this.context.set(
      "supplierModel",
      this.onboardingService.supplierModel.SupplierProfile
    );
    const [setBuyerContext, userTasks, supplierDetailConfig] =
      await Promise.all([
        this.buyerCommonService.SetContextValue(this.context),
        this.onboardingService.loadUserTasks(),
        this.metadataService.getPageConfiguration("supplier-detail"),
      ]);
    /*     for( let i=0; i<supplierDetailConfig.organization[0].fieldset.length; i++) {
      if(supplierDetailConfig.organization[0].fieldset[i].name == 'organizationCollapse' && this.context.getValue('isANZ')) {
        supplierDetailConfig.organization[0].fieldset[i].label = 'Organisation Size & Type'
      }
    } */
    this.context.mode = "view";

    const result: any = await this.buyerService.getPaymentTermList({
      companyCode:
        this.onboardingService.supplierModel.RequestForm.RequestorDetailsInfo
          .CompanyCode,
      geographicalUnit:
        this.onboardingService.supplierModel.Mapping.geographicalUnit,
      category:
        this.onboardingService.supplierModel.RequestForm.RequestorDetailsInfo
          .SpendCategoryGroup,
      spendCommodityGroup:
        this.onboardingService.supplierModel.RequestForm.RequestorDetailsInfo
          .SpendCommodityGroup,
      enterpriseType:
        this.onboardingService.supplierModel.SupplierProfile
          .SupplierOrganization.EnterpriseType,
    });

    // Match standard payment term
    if (result && result.isSuccess) {
      const standardPaymentTerms = result.data;
      this.context.set("standardPaymentTerms", standardPaymentTerms);

      if (
        Array.isArray(this.onboardingService.supplierModel.RequestForm?.RequestFormInfo?.PaymentTermList)
      ) {
        this.onboardingService.supplierModel.RequestForm.RequestFormInfo.PaymentTermList.forEach(
          (paymentTermsItem: any) => {
            const standardPaymentTerm = standardPaymentTerms.find(
              (p: any) => p.CompanyCode === paymentTermsItem.CompanyCode
            );
            if (standardPaymentTerm) {
              paymentTermsItem.TimeLimit = standardPaymentTerm.PaymentTerm;
            }
          }
        );
      }
    }

    this.isDisplayPurposeCode =
      this.supplierCountry.includes("TH") ||
      this.supplierCountry.includes("MY") ||
      this.supplierCountry.includes("SG");
    this.context.set("isDisplayPurposeCode", this.isDisplayPurposeCode);
    this.getCollapse1ConfigsInfo = supplierDetailConfig.requestorDetails;
    this.getCollapse2ConfigsInfo = supplierDetailConfig.supplierAndSpendDetails;
    this.getCollapse3ConfigsInfo = supplierDetailConfig.riskASsessment;
    this.getCollapse4ConfigsInfo = supplierDetailConfig.declaration;
    this.getCollapse5ConfigsInfo = supplierDetailConfig.general;
    this.getCollapse6ConfigsInfo = supplierDetailConfig.organization;
    this.getCollapse7ConfigsInfo = supplierDetailConfig.finalcial;
    this.getCollapse8ConfigsInfo = supplierDetailConfig.compliance;
    this.getCollapse9ConfigsInfo = supplierDetailConfig.supplierDeclaration;
    this.getCollapse10ConfigsInfo = supplierDetailConfig.existingBusinessRelationship;
    this.loadingService.hideLoading();  

    const { activeSapResultList, blockedSapResultList } = this.supplierDetailService.filterSapIdStatus(this.sapResultList, this.onboardingService.supplierModel.Mapping);
    this.activeSapResultList = activeSapResultList;
    this.blockedSapResultList = blockedSapResultList;
    console.log('this.activeSapResultList', this.activeSapResultList);
    console.log('this.blockedSapResultList', this.blockedSapResultList);
    this.checkSupplier = await this.buyerService.getExistingSupplier(this.supplierCode);
    if (this.checkSupplier?.data === true) {
      try {
        await this.onboardingService.loadNewSupplierRequestForm();
        if (this.onboardingService.supplierSteps.length > 0) {
          this.stepProcurementPlusReview = this.onboardingService.supplierSteps.find(step => step.StepId === 20);
        }
      }
      catch (err) {
        console.error("Exception in newSupplierProcess method ${err.message}")
      }
      await this.commonService.checkAccess(this.authService.passport.profile.email, this.supplierCode)
    };
  }

  ngOnDestroy() {
    if (this.currentApproverSubscription) {
      this.currentApproverSubscription.unsubscribe();
    }
  }
  
  initRoleData() {
    if (this.isBIBDA() || this.isRequester() || this.isS_CM()) {
      this.bibdaResult =
        this.onboardingService.supplierModel.Mapping.assessmentRate.bibdaResult;
      this.bibdaResult.bibdaCaseId =
        this.onboardingService.supplierModel.RequestForm.RiskAssessmentInfo.BI_BDA_1a;
    }
    this.APQCApproved = this.onboardingService.supplierModel.Mapping.assessmentRate.sapResult.filter(
      (item) => item.type === AP_ReviewStatus.QC_approve || item.type === ChangeRequestDetailStatus.APPROVED_BY_AP_QC
    ).length > 0 ? true : false;
    this.isSupplierMigrated = this.onboardingService.supplierModel.Mapping.IsMigrated;
    this.sapResultList =
      this.onboardingService.supplierModel.Mapping.assessmentRate.sapResult.filter(
        (item) => item.type !== DialogType.terminateSupplier
      );    
    this.sapResultList.forEach((res) => {
      if (res.sapResultList) {
        const USINList = res.sapResultList.filter(
          (sap) => sap.countryCode === "US" || sap.countryCode === "IN"
        );
        this.hasCountryUSIN = USINList.length > 0 ? true : false;
      }
    });
    this.gstRegistrationSapList =
      this.onboardingService.supplierModel.Mapping.gstSapItem;
    // Whether history link to display in dashboard header.
    if (this.onboardingService.supplierModel.Mapping.IsMigrated) {
      this.dashBoardService.isMigrated = true;
    } else {
      this.dashBoardService.isMigrated = false;
    }
  }

  get isBIBDAFieldDiseditable() {
    return this.isSaveDisabled;
  }

  get isSapFiledDiseditable() {
    return this.isSAPResultCommit;
  }

  get disabledSaveBtn() {
    if (this.userRoleDesc === this.roleCode.BuyerAdmin || this.userRoleDesc === this.roleCode.SystemAdmin) {
      return this.isBIBDAFieldDiseditable && this.isSapFiledDiseditable;
    } else if (this.userRoleDesc === this.roleCode.Business) {
      return this.isBIBDAFieldDiseditable;
    } else if (this.userRoleDesc === this.roleCode.APSupplierSetupTeam) {
      return this.isSapFiledDiseditable;
    }
  }

  get disabledSaveBtnforOnhold() {
    if (this.userRoleDesc === this.roleCode.BuyerAdmin || this.userRoleDesc === this.roleCode.SystemAdmin) {
      return this.isBIBDAFieldDiseditable && this.isSapFiledDiseditable;
    } else if (this.userRoleDesc === this.roleCode.Business) {
      return this.isBIBDAFieldDiseditable;
    } else if (this.userRoleDesc === this.roleCode.APSupplierSetupTeam) {
      if (this.isOnHold) {
        return this.isOnHold;
      }
      return this.isSapFiledDiseditable;
    } else if (this.userRoleDesc === this.roleCode.APQualityControl) {
      if (this.isOnHold) {
        return this.isOnHold;
      }
    }
  }

  
  isSupplierNameDuplicate(): boolean {
    return this.supplierName.startsWith("DUPLICATE-");
  } 
  get isShowBiBdaInputForm() {
    if (
      !this.onboardingService.isBIApplicable ||
      this.onboardingService.supplierModel.Mapping.status ===
        SupplierStatus.terminated
    ) {
      return false;
    }
    if (this.bibdaResult) {
      const bibdaFormIndex =
        this.buyerACLPermissionService.pagePermissions.indexOf(
          BuyerRoleACLPageName.BIBDAResultPage
        );
      if (!this.isSaveDisabled) {
        return (this.userRoleDesc === this.roleCode.Business)
          && bibdaFormIndex !== -1
          && this.onboardingService.supplierModel.Mapping.searchTerm.createBuyer !== DEFAULT_REQUESTOR
      } else {
        return (
          (this.userRoleDesc === this.roleCode.Business ||
            this.userRoleDesc === this.roleCode.BuyerAdmin ||
            this.userRoleDesc === this.roleCode.SystemAdmin ||
            this.userRoleDesc === this.roleCode.Requestor ||
            this.userRoleDesc === this.roleCode.SourcingCategoryManagement) &&
          this.onboardingService.supplierModel.Mapping.searchTerm
            .createBuyer !== DEFAULT_REQUESTOR
        );
      }
    }
    return false;
  }

  get isShowAPReviewBtn() {
    const apReviewIndex =
      this.buyerACLPermissionService.pagePermissions.indexOf(
        BuyerRoleACLPageName.SAPResultPage
      );
    if (this.userRoleDesc === this.roleCode.APSupplierSetupTeam) {
      return (
        apReviewIndex !== -1 &&
        (this.onboardingService.getUserTaskByKey(
          ProcessNames.generic_supplierSetupSubProcess,
          SetupProcess.SST_review
        ) ||
          this.onboardingService.getUserTaskByKey(
            ProcessNames.generic_supplierMigrateSetup,
            MigrateProcess.SST_review
          ) ||
          this.onboardingService.getUserTaskByKey(
            ProcessNames.generic_supplierChangeRequest,
            SupplierChangeRequestProcess.SST_review
          ))
      );
    }
    if (this.userRoleDesc === this.roleCode.APQualityControl) {
      return (
        apReviewIndex !== -1 &&
        (this.onboardingService.getUserTaskByKey(
          ProcessNames.generic_supplierSetupSubProcess,
          SetupProcess.QC_review
        ) ||
          this.onboardingService.getUserTaskByKey(
            ProcessNames.generic_supplierMigrateSetup,
            MigrateProcess.QC_review
          ) ||
          this.onboardingService.getUserTaskByKey(
            ProcessNames.generic_supplierChangeRequest,
            SupplierChangeRequestProcess.QC_review
          ))
      );
    }
  }

  disableBtn() {
    let isDisableBtn = false;

    if (!this.isDisableButton) {
      return true;
    }

    if (this.isBIBDA()) {
      if (this.bibdaResult.isCommited) {
        isDisableBtn = true;
      } else {
        isDisableBtn = false;
      }
    }
    if (this.isRequester()) {
      if (this.bibdaResult.isCommited) {
        isDisableBtn = true;
      }
    }

    if (this.isS_CM()) {
      if (this.bibdaResult.isCommited) {
        isDisableBtn = true;
      }
    }
    // return false
    return isDisableBtn || this.isDisableAfterClick;
  }

  disableSapSaveBtn() {
    const isDisableBtn = false;

    if (!this.isDisableButton) {
      return true;
    }

    return isDisableBtn || this.isDisableAfterClick;
  }

  async sstBtn(type) {
    let taskInst: Task;
    const userTaskAry = this.onboardingService.userTasks.filter(
      (t) =>
        t.name === AllTaskKey.SST_review &&
        t.processDefName !== ProcessNames.generic_buyerChangeRequest
    );
    if (userTaskAry && userTaskAry.length > 0) {
      taskInst = userTaskAry.slice(-1)[0];
    }
    if (type === this.sstType.Reject) {
      await this.dialogService.apSstReject(taskInst, DialogType.sstReject);
    }
    if (type === this.sstType.Terminate) {
      taskInst.variables = {
        isTerminate: true,
      };
      await this.dialogService.apSstTerminate(
        taskInst,
        DialogType.sstTerminate
      );
    }
    if (type === this.sstType.Approve) {
      taskInst.variables = {
        SST_Approve: true,
      };
      await this.dialogService.apSstApprove(taskInst, DialogType.sstApprove);
    }
    if(type === this.sstType.SAPApprove){
      taskInst.variables = {
        SST_Approve: true,
      };
      await this.dialogService.sapapSstApprove(taskInst, DialogType.sstApprove);
    }
  }

  async qcBtn(type) {
    let taskInst: Task;
    const userTaskAry = this.onboardingService.userTasks.filter(
      (t) =>
        t.name === AllTaskKey.QC_review &&
        t.processDefName !== ProcessNames.generic_buyerChangeRequest
    );
    if (userTaskAry && userTaskAry.length > 0) {
      taskInst = userTaskAry.slice(-1)[0];
    }
    if (type === this.qcType.Reject) {
      taskInst.variables = {
        QC_Reject: true,
      };
      await this.dialogService.apQcReject(taskInst, DialogType.qcReject);
    }
    if (type === this.qcType.Terminate) {
      taskInst.variables = {
        isTerminate: true,
      };
      await this.dialogService.apQcTerminate(taskInst, DialogType.qcTerminate);
    }
    if (type === this.qcType.Approve) {
      taskInst.variables = {
        QC_Approve: true,
      };
      await this.dialogService.apQcApprove(taskInst, DialogType.qcApprove);
      }
    }

  async additionalInterventionBtnReject() {
    await this.dialogService.additionalInterventionReject(DialogType.additionalInterventionReject);
  }

  async additionalInterventionBtnApprove() {
    await this.dialogService.additionalInterventionApprove(DialogType.additionalInterventionApprove);
  }

  showMessage() {
    if (this.isOnHold) {
      this.dialogService.dialog(
        `You are unable to approve this set-up request as this profile is currently on hold.
      Please ensure this hold is removed prior to approving this set-up request.`,
        ButtonCommands.Close,
        DialogType.warning,
        "Please Note"
      );
    }
  }

  async btnNext() {
    if (!this.validate()) {
      return;
    }
    if (
      (!(await this.disableBtn()) || !(await this.disableSapSaveBtn())) &&
      this.isDisableButton
    ) {
      this.isDisableButton = false;
      if (this.isBIBDA() && !this.bibdaResult.isCommited) {
        this.isSaveDisabled = true;
        this.bibdaResult.isCommited = true;
        if (await this.SaveBI_BDA()) {
          // await this.SaveProcess(OnBoardingStatus.RiskAssessment, OnBoardMessage.CommonRequestSentSuccessfully)
          await this.dialogService.dialog(
            "BI/BDA risk assessment saved successfully.",
            ButtonCommands.Close,
            DialogType.success,
            OnBoardMessage.ACLResultSendSuccess
          );
          this.router.navigate(["buyer"]);
        }
      }
    }
    this.isDisableButton = true;
  }

  navigate(item) {
    this.context.emit(item, { method: "open" });
    const element = document.getElementById(item);
    window.scroll(0, element.offsetTop - 0);
  }

  isBIBDA(): boolean {
    if (
      this.userRoleDesc === this.roleCode.Business ||
      this.userRoleDesc === this.roleCode.BuyerAdmin || 
      this.userRoleDesc === this.roleCode.SystemAdmin
    ) {
      return true;
    }
    return false;
  }

  isRequester(): boolean {
    if (this.roleCode.Requestor === this.userRoleDesc) {
      return true;
    }
    return false;
  }

  isS_CM(): boolean {
    if (this.roleCode.SourcingCategoryManagement === this.userRoleDesc) {
      return true;
    }
    return false;
  }

  async SaveBI_BDA() {
    // await this.onboardingService.setStatusHistories(RiskAssessmentStatus.BIBDA, StatusType.risk)
    this.onboardingService.supplierModel.RequestForm.RiskAssessmentInfo.BI_BDA_1a =
      this.bibdaResult.bibdaCaseId;
    this.onboardingService.supplierModel.Mapping.assessmentRate.bibdaResult =
      this.bibdaResult;
    // exec task
    this.onboardingService.getExecuteTask(
      ProcessNames.generic_supplierSetup,
      AllTaskKey.BI
    );
    if (!this.onboardingService.supplierModel.Mapping.executeTask) {
      return false;
    }
    const result: any = await this.buyerService.saveBI(
      this.onboardingService.supplierModel
    );
    if (result.isSuccess) {
      // await this.buyerService.saveRequestForm(this.onboardingService.supplierModel)
      // await this.onboardingService.checkIsOnboarded()
      return true;
    }
    return false;
  }

  //   get isHideTerminate(): boolean {
  //     for (const t of this.onboardingService.supplierModel.Mapping.historyTasks) {
  //       if (t && t.processDefName === ProcessNames.generic_supplierSetupSubProcess) {
  //         if ((t.name === AllTaskKey.SST_review && t.variablesObj && t.variablesObj.SST_Approve)
  //           || t.name === AllTaskKey.SST_rejectToSupplier
  //           || t.name === AllTaskKey.SST_rejectToBuyer) {
  //             return true
  //         }
  //     }
  //   }
  //   return false
  // }
  validate() {
    if (
      this.isBIBDA() &&
      (!this.bibdaResult.score ||
        !this.bibdaResult.bibdaComments ||
        !this.bibdaResult.bibdaCaseId)
      // || (this.isAPSupplierSetupTeam() && (!this.sapResult.sapComments || !this.sapResult.sapId ||
      // !this.isSAPIDValid))
    ) {
      this.isValidated = true;
      return false;
    }
    return true;
  }

  btnOnboardProcess() {
    this.loadingService.showLoading();
    this.router.navigate([`buyer/onboarding/process/${this.supplierCode}`]);
  }

  isSAPIntegrationsButtonShow(): boolean {
    if (this.isSAPIntegrationFlag && this.isSAPMU && this.isAPSSTApproved === false && this.isSupplierMigrated === false
       && ((this.isAPSSTApproved === false && this.isSAPIntegrationFalied ===false)
       || (this.isAPSSTApproved === false && this.isSAPIntegrationInitiated) || this.isSAPIntegrationFalied ) 
       && this.isSAPIntegrationPending === false) {
      return true;
    }
    return false;
  }

  isShowApproveButton(): boolean {
    if(this.isSAPMU === false || this.isAPSSTApproved 
      || this.isSupplierMigrated || this.isSAPIntegrationDone 
      || this.isSAPIntegrationPending || this.isSAPIntegrationFlag === false){
      return true;
    }
    return false;
  }

  checkRiskMitigationRole() {
      return isRiskMitigation(this.authService.passport.buyer.RoleCode)
  }
  checkProcLeadRole() {
    return this.buyerCommonService.isProcurementLeadLAEditor();
  }
}
