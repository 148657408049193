import { Component, OnInit, Output, EventEmitter } from "@angular/core";
import { DashBoardService } from "../../services/dashboard.service";
import { OnBoardingService } from "../../services/onboarding.service";
import { Router, NavigationEnd, ActivatedRoute, NavigationStart } from "@angular/router";
import { AuthService } from "src/app/services/auth/auth.service";
import { RoleName } from "src/app/interfaces/buyer";
import { NotificationService } from "src/app/services/notification.service";
import { SupplierService } from "src/app/services/supplier.service";
import { LanguageService } from "../../../../dynamic-components/utils/language.service";
import { LoadingService } from "src/app/services/loading.service";
import { environment } from "../../../../../environments/environment";
import { SupplierStatus } from "src/app/interfaces/mapping";
import { CommonService } from "src/app/services/common.service";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.sass"],
})
export class HeaderComponent implements OnInit {
  title = "Supplier Details";
  isDisable = false;
  userRole: string;
  roleName = RoleName;
  isBuyerAdmin = false;
  showTsmSearchHeader = false;
  isFromTSM = false;
  TsmId: string;
  isHideDshbdHelp = false;
  @Output() searchChange = new EventEmitter();

  placeHolder: string;
  public isButtonDisabled: boolean;

  get supplierCode(): string {
    return this.dashBoardService.supplierCode;
  }

  get isMigrated(): boolean {
    return this.dashBoardService.isMigrated;
  }
  dashBoardService: DashBoardService;
  CSNSP3Flag: boolean = false;

  constructor(
    private _dashBoardService: DashBoardService,
    private router: Router,
    private route: ActivatedRoute,
    private supplierService: SupplierService,
    private authService: AuthService,
    private notificationService: NotificationService,
    private languageService: LanguageService,
    private onboardingService: OnBoardingService,
    public loadingService: LoadingService,
    private commonService: CommonService
  ) {
    this.dashBoardService = _dashBoardService;
    this.placeHolder = this.languageService.getValue("quickSearch");
  }

  async ngOnInit() {
    this.isButtonDisabled = environment.env !== 'local';
    // if (!this.dashBoardService.isDashBoard && !(this.dashBoardService.title == 'Help & Support')) {
    //   await this.onboardingService.loadSupplierRequestForm();
    // }
    this.title = this.dashBoardService.title || this.title;
    if (this.router.url.search("/buyer/onboarding/process/") !== -1) {
      const result: any = await this.supplierService.getSupplierStatusInfo(
        this.dashBoardService.supplierCode
      );

      if(result) {
        let isDeleted = result.data.isDeleted;
        let supplierStatus = result.data.status;

        if (
          ((this.authService.passport.buyer.RoleCode === "buyerAdmin") || (this.authService.passport.buyer.RoleCode === "systemAdmin")) &&
          isDeleted && (supplierStatus !== SupplierStatus.deactivated)
        ) {
          this.isBuyerAdmin = true;
        }
      }
    }
    this.isFromTSM = this.onboardingService.isFromTsm();
    const getCSNSP3Flag = await this.commonService.getCSNSP3Flag(Date.now().toString());
    this.CSNSP3Flag = (getCSNSP3Flag as { data: boolean })?.data;
    // if enter dashboard and help page from TSM
    if (this.dashBoardService.isSearchInTsm) {
      this.isFromTSM = true;
      this.TsmId = this.dashBoardService.tsmKey1;
      // hide the help and dashboard icon at nav-side
      this.isHideDshbdHelp = true;
    }

    this.notificationService.loadData();
  }

  simpleSearch() {
    this.searchChange.emit();
  }

  backTo() {
    this.isDisable = true;
  }

  /**
   * if enter dashboard from tsm, touch the method
   */
  backToTsm() {
    this.isFromTSM = true;
    this.isHideDshbdHelp = true;
    this.isDisable = true;
  }

  cancelEvent(event) {
    this.isDisable = false;
  }

  navToHistory() {
    this.router.navigate([
      `buyer/history/${this.dashBoardService.supplierCode}/0`,
    ], {replaceUrl: true});
  }
  navToSupplierManagement() {
    this.router.navigate([
      `buyer/supplierManagement/users/${this.dashBoardService.supplierCode}`,
    ], {replaceUrl: true});
  }
  navigateToRequestFrom() {
    this.router.navigate([`buyer/onboarding/0/step1`], {replaceUrl: true});
  }

  navigateToHelpPage() {
    this.router.navigate([`buyer/helpPage`], {replaceUrl: true});
  }

  navigateToDashboard() {
    this.router.navigate([`buyer`], {replaceUrl: true});
  }

  navigateToNewDashboard() {
    this.commonService.setShowTransactions(false); 
    this.router.navigate([`buyer/csnsDashboard`], {replaceUrl: true});
  }
}
