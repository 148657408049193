import { Language } from "./language.interface";

export const languageList: Array<Language> = [
  { key: "en", value: "English", code: "english", default: true },
  { key: "it", value: "Italiano", code: "italiano" },
  { key: "es", value: "Spanish", code: "spainish" },
  { key: "pt", value: "Portuguese", code: "portuguese" },
  { key: "fr", value: "Français", code: "french" },
  { key: "cn", value: "简体中文", code: "chinese" },
  { key: "jp", value: "日本語", code: "japanese" },
  { key:"br", value: "Português (Brasil)", code:"Brazil"},
  {key:"mx", value: "LATAM Español", code:"Mexico"}
];
