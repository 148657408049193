import { Component, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { CommonService } from "../../../../../services/common.service";
import { SupplierExistingBusinessRelationship } from "../../../../../interfaces/supplierProfile";
import { ProfileService } from "../../../services/profile.service";
import { LoadingUrlMapping } from "src/app/interfaces/mapping";
import { trim } from "src/app/utils";
import { ButtonCommands, DialogService, DialogType } from "src/app/services/dialog.service";
import VMController from "src/app/interfaces/vm";
import { CanComponentDeactivate } from "src/app/can-deactivate.guard";
import { environment } from "src/environments/environment";
import { LoadingService } from "src/app/services/loading.service";
import { DynamicContext } from "src/app/dynamic-components/interfaces/DynamicContext";
import { DynamicFormComponent } from "src/app/dynamic-components/components/dynamic-form/dynamic-form.component";
import { MetadataService } from "src/app/services/metadata.service";
import { ComponentConfig } from "src/app/dynamic-components/ComponentConfig";
import { UntypedFormGroup } from "@angular/forms";
import { SupplierCommonService } from "../../../services/supplierCommon.service";
import { AllTaskKey } from "src/app/interfaces/workflow";
import { LanguageService } from "src/app/dynamic-components/utils/language.service";

@Component({
  selector: 'app-existing-business-relationship',
  imports: [],
  templateUrl: './existing-business-relationship.component.html',
  styleUrl: './existing-business-relationship.component.sass'
})
export class ExistingBusinessRelationshipComponent implements OnInit, CanComponentDeactivate {
  context: DynamicContext;
  getConfigsInfo: ComponentConfig[] = [];
  originalConfig: ComponentConfig[] = [];
  formGroup: UntypedFormGroup = new UntypedFormGroup({});
  isResubmitDisabled = true;
  isDisabledSaveBtn = false;
  initialSupplierEBRModel: SupplierExistingBusinessRelationship;

  get existingBusinessRelationshipInfo(): SupplierExistingBusinessRelationship {
    return this.profileService.supplierModel.SupplierProfile.SupplierExistingBusinessRelationship;
  }

  set existingBusinessRelationshipInfo(val) {
    this.profileService.supplierModel.SupplierProfile.SupplierExistingBusinessRelationship = val;
  }

  get mode(): string {
    return this.profileService.mode;
  }

  get showResubmitBtn() {
    if (this.dynamicForm) {
      if (
        !this.isView &&
        !this.dynamicForm.disabled &&
        this.dynamicForm.formGroup
      ) {
        this.isResubmitDisabled =
          JSON.stringify(this.dynamicForm.originalValues()) ===
          JSON.stringify(this.dynamicForm.values());
      }
    }
    return this.profileService.checkResubmit();
  }
  get isView() {
    return this.profileService.isView;
  }
  @ViewChild("dynamicForm") dynamicForm: DynamicFormComponent;
  vm: VMController<SupplierExistingBusinessRelationship> = new VMController();
  constructor(private profileService: ProfileService,
    private loadingService: LoadingService,
    private metadataService: MetadataService,
    private supplierCommonService: SupplierCommonService,
    private commonService: CommonService,
    private router: Router,
    private dialogService: DialogService,
    private languageService: LanguageService,
  ) {
    this.profileService.stepChangeEvent.emit(1.5);
  }

  async ngOnInit() {
    this.loadingService.showLoading();
    this.profileService.current_step = 1.5;
    this.context = new DynamicContext();
    const [getConfigsInformation] = await Promise.all([
      this.metadataService.getPageConfiguration("supplier-profile-step1_5"),
      this.profileService.loadUserTasks(),
      this.supplierCommonService.SetContextValue(this.context),
    ]);
    if (!this.profileService.isView) {
      this.getConfigsInfo = this.originalConfig;
    }
    this.context.mode = this.mode;
    this.getConfigsInfo = getConfigsInformation;
    this.commonService.ExitEvent.subscribe((e) => {
      this.vm.setOriginal(this.dynamicForm.originalValues());
      this.vm.setCurrent(this.dynamicForm.values());
      if (this.vm.isDirty()) {
        e.preventDefault();
        // Chrome requires returnValue to be set
        e.returnValue = "";
      }
    });
    if (!this.isView) {
      this.dynamicForm.subscribeToFormChange(() => {
        setTimeout(() => {
          this.isResubmitDisabled =
            JSON.stringify(this.dynamicForm.originalValues()) ===
            JSON.stringify(this.dynamicForm.values());
        });
      });
    }
    this.loadingService.hideLoading();

    if (!this.isView) {
      setTimeout(() => {
        this.initialSupplierEBRModel = trim(this.dynamicForm.originalValues());
      });
    }
  }

  btnNext() {
    this.router.navigate([
      `supplier/profile/${this.profileService.supplierCode}/step2`,
    ]);
  }
  
  async btnSave() {
    this.saveAndNavigate();
  }

  saveAndNavigate() {
    this.saveDraft().then((data: boolean) => {
      if (data) {
        this.vm.saved();
        this.router.navigate([
          `supplier/profile/${this.profileService.supplierCode}/step2`,
        ]);
      }
    });
  }

  async saveDraft(): Promise<boolean> {
    this.context.changeSubmitState();
    this.dynamicForm.removeFormTouch();
    if (!this.validate()) {
      return false;
    }
    await this.updateSupplierMapping();
    this.bindDataToSupplierProfileModel();
    await this.saveNewDraft();
    this.loadingService.closeLoading();
    this.loadingService.hideLoading();
    return true;
  }

  async saveNewDraft() {
    const saveUrl = `${environment.supplierGateway}${LoadingUrlMapping.existingBusinessRelationshipSaveDraft}`;
    this.loadingService.openLoading(saveUrl);
    await this.profileService.saveExistingBusinessRelationshipDraft()
  }

  validate() {
    return this.dynamicForm.valide();
  }

  private async updateSupplierMapping() {
    this.profileService.supplierModel.SupplierProfile.SupplierExistingBusinessRelationship =
      trim(this.dynamicForm.values());
  }

  bindDataToSupplierProfileModel() {
    const currentValues = this.dynamicForm.values();
    this.vm.setOriginal(this.initialSupplierEBRModel);
    this.vm.setCurrent(currentValues);

    this.profileService.createSupplierProfileModel();
    this.profileService.supplierProfileModel.SupplierProfile.SupplierExistingBusinessRelationship = this.profileService.supplierModel.SupplierProfile.SupplierExistingBusinessRelationship;
    this.profileService.supplierProfileModel.Mapping = this.profileService.supplierModel.Mapping;

    this.profileService.supplierProfileModel.ChangeHistory = this.commonService.getChangeHistory(this.initialSupplierEBRModel, this.profileService.supplierModel.SupplierProfile.SupplierExistingBusinessRelationship, "ExistingBusinessRelationshipInfo");
    if (!this.vm.isChange()) {
      return false;
    }
  }

  async resubmit() {
    this.context.changeSubmitState();
    this.dynamicForm.removeFormTouch();
    if (!this.validate()) {
      return;
    }

    if (
      this.profileService.getUserTaskByTaskKey(AllTaskKey.SST_rejectToSupplier)
    ) {
      this.resubmitForm().then((data: boolean) => {
        if (data) {
          this.vm.saved();
          this.dialogService
            .dialog(
              this.languageService.getValue(
                "static.supplier.components.existingbusinessrelationship.change_request"
              ),
              ButtonCommands.Ok,
              DialogType.success,
              this.languageService.getValue(
                "static.supplier.components.existingbusinessrelationship.please_note"
              )
            )
            .then(() => {
              this.router.navigate([`supplier/landing`]);
            });
        }
      });
    } else {
      this.changeRequest().then((data: boolean) => {
        if (data) {
          this.vm.saved();
          this.dialogService
            .dialog(
              this.languageService.getValue(
                "static.supplier.components.existingbusinessrelationship.change_request"
              ),
              ButtonCommands.Ok,
              DialogType.success,
              this.languageService.getValue(
                "static.supplier.components.general.please_note"
              )
            )
            .then(() => {
              this.router.navigate([`supplier/landing`]);
            });
        }
      });
    }
  }

  async resubmitForm(): Promise<boolean> {
    this.context.changeSubmitState();
    this.dynamicForm.removeFormTouch();
    if (!this.validate()) {
      return false;
    }
    await this.updateSupplierMapping();
    // get executable task GUID: taskInstId
    this.profileService.getResubmitTask();
    if (!this.profileService.supplierModel.Mapping.executeTask) {
      return false;
    }
    this.bindDataToSupplierProfileModel();
    await this.savenewresubmit();
    return true;
  }

  async changeRequest(): Promise<boolean> {
    this.context.changeSubmitState();
    this.dynamicForm.removeFormTouch();
    if (!this.validate()) {
      return false;
    }
    await this.updateSupplierMapping();
    this.bindDataToSupplierProfileModel();
    await this.newChangeRequestform();
    return true;
  }

  async savenewresubmit() {
    const saveUrl = `${environment.supplierGateway}${LoadingUrlMapping.supplierProfileResubmit}`;
    this.loadingService.openLoading(saveUrl);
    const saveList = [
      await this.profileService.newresubmitForm(),
    ];
    Promise.all(saveList);
    this.loadingService.closeLoading()
  }

  async newChangeRequestform() {
    const saveUrl = `${environment.supplierGateway}${LoadingUrlMapping.newRequestChangeSupplierProfile}`;
    this.loadingService.openLoading(saveUrl);
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    await this.profileService.newchangeRequestForm(),
      this.loadingService.closeLoading()
  }

  async canDeactivate() {
    if (this.isView) {
      return true;
    }
    this.vm.setOriginal(this.dynamicForm.originalValues());
    this.vm.setCurrent(this.dynamicForm.values());
    if (this.vm.isDirty()) {
      const result = await this.dialogService.saveConfirm();
      if (result === "save") {
        if (!(await this.saveDraft())) {
          return false;
        }
      }
      if (result === "cancel") {
        return false;
      }
    }
    return true;
  }
}
