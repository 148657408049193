import { Inject, Injectable, Type } from "@angular/core";
import { AppConfigService } from "../services/app-config.service";
import { RebarAuthModule } from "./rebar.auth.module";
import {
  MsalService,
  MsalBroadcastService,
  MSAL_GUARD_CONFIG,
  MsalGuardConfiguration,
} from "@azure/msal-angular";
import {
  EventType,
  InteractionStatus,
  RedirectRequest,
  EventMessage,
  AuthenticationResult,
} from "@azure/msal-browser";
import { environment } from "../../../environments/environment";
import { Observable, Subject, of, EMPTY } from "rxjs";
import { filter, map, takeUntil } from "rxjs/operators";
import { AuthService, LoginStatus } from "src/app/services/auth/auth.service";
import Passport from "src/app/services/auth/passport";
import { Profile } from "src/app/interfaces/profile";
import { DictionaryService } from "src/app/services/dictionary.service";
import { switchMap, catchError } from "rxjs/operators";
import { DOCUMENT } from '@angular/common';
import { HttpService } from "src/app/services/api/http.service";

interface tsmType {
  supplierCode: string;
  key1: string;
  key2: string;
  email: string;
  language?: string;
  mixFasttrack?: string;
  classification?: "individual" | "company"
}

@Injectable({
  providedIn: RebarAuthModule,
})
export class RebarAuthService {
  private readonly destroying$ = new Subject<void>();
  public authObserver$: Observable<EventMessage>;
  
  constructor(
    private config: AppConfigService,
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private msalService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private authService: AuthService,
    private dictionaryService: DictionaryService,
    @Inject(DOCUMENT) private document: Document,
    private http: HttpService
  ) {
    console.log("-------- start rebar auth --------")
    if (this.authenticationEnabled()) {
      this.msalBroadcastService.inProgress$
        .pipe(
          filter(
            (status: InteractionStatus) => status === InteractionStatus.None
          ),
          takeUntil(this.destroying$)
        )
        .subscribe(() => {
          console.log("-------- start to verify active user --------")
          this.checkAndSetActiveAccount();
          if (this.authenticationEnabled() && !this.isUserAuthenticated()) {
            if (window.location.hash) {
              console.log("-------- start to build tsm obj --------")
              const tsm = this.buildTsmObj();

              if (!!tsm) {
                console.log("-------- tsm obj => yes --------")
                window.location.hash = "";
                let tsmState = "";
                console.log(tsm, 'TSM OBJ')
                if (tsm.supplierCode) {
                  if (tsm.mixFasttrack !== undefined) {
                    tsmState = "__tsmstp1_" + tsm.supplierCode + "_mixFasttrack";
                  } else {
                    tsmState = "__tsmstp1_" + tsm.supplierCode;
                  }
                } else if (tsm.key1 && tsm.key2 && !tsm.email) {
                  tsmState = "__tsmsrch_" + tsm.key1 + "_" + tsm.key2;
                } else if (tsm.key1 && tsm.key2 && tsm.email) {
                  tsmState =
                    "__tsmland_" + tsm.key1 + "_" + tsm.key2 + "_" + tsm.email;
                }
                // console.log("-------- tsm state is %s -------", tsmState)
                const msalRequest = this.msalGuardConfig.authRequest as any;
                msalRequest.state = tsmState;
              }
            }

            console.log("-------- trigger to login --------")
            this.login();
          }
        });

      this.authObserver$ = this.msalBroadcastService.msalSubject$.pipe(
        filter(
          (event: EventMessage) => event.eventType === EventType.LOGIN_SUCCESS
        ),
        takeUntil(this.destroying$)
      );
    } else {
      this.authObserver$ = of(null);
    }
  }

  public buildTsmObj(): tsmType {
    const hash = window.location.hash;
    const hashArray = hash.substring(11).split("?");

    if (hashArray.length === 2) {
      const tsmPath = hashArray[0];
      const tsmParamArray = hashArray[1].split("&");

      let tsmObj = {};
      tsmParamArray.forEach((queryString: string, index: number) => {
        const params = queryString.split("=");
        if (params[0] === "env") {
          sessionStorage.setItem("tsmEnv", params[1]);
        } else {
          tsmObj[params[0]] = params[1];
        }
      });

      return tsmObj as tsmType;
    }

    return null
  }

  public isUserAuthenticated(): boolean {
    return this.msalService.instance.getAllAccounts().length > 0;
  }

  public authenticationEnabled(): boolean {
    return environment.providers !== "mock";
  }

  public setActiveAccount(account: AuthenticationResult): void {
    this.msalService.instance.setActiveAccount(account.account);
  }

  checkAndSetActiveAccount(): void {
    /**
     * If no active account set but there are accounts signed in, sets first account to active account
     * To use active account set here, subscribe to inProgress$ first in your component
     * Note: Basic usage demonstrated. Your app may require more complicated account selection logic
     */
    const activeAccount = this.msalService.instance.getActiveAccount();
    // console.log(activeAccount, '.... activeAccount');
    // console.log(this.msalService.instance.getAllAccounts().length, '.... this.msalService.instance.getAllAccounts().length');

    if (
      !activeAccount &&
      this.msalService.instance.getAllAccounts().length > 0
    ) {
      const accounts = this.msalService.instance.getAllAccounts();
      // console.log(accounts, '.... accounts');
      this.msalService.instance.setActiveAccount(accounts[0]);
    }
  }

  public login(): void {
    if (this.authenticationEnabled()) {
      const activeAccount = this.msalService.instance.getActiveAccount();

      if (!activeAccount) {
        if (this.msalService.instance.getAllAccounts().length > 0) {
          const accounts = this.msalService.instance.getAllAccounts();
          this.msalService.instance.setActiveAccount(accounts[0]);
        } else {
          if (this.msalGuardConfig.authRequest) {
            this.msalService.loginRedirect({
              ...this.msalGuardConfig.authRequest,
            } as RedirectRequest);
          } else {
            this.msalService.loginRedirect();
          }
        }
      }
    }
  }

  public loginWithCustomizeFlow(userFlowRequest) {
    const scopes = ["openid", "profile", "offline_access"];
    const protectedScopes = this.config.config["msal"]["supplier"]["auth_b2c"]["framework"]["protectedResourceMap"] as Iterable<
      readonly [string, string[]]>
    new Map(protectedScopes).forEach(p => {
      p.forEach(scope => {
        if (!scopes.includes(scope)) {
          scopes.push(scope)
        }
      })
    })
    userFlowRequest.scopes = scopes
    this.msalService.loginRedirect(userFlowRequest)
  }

  public logout(): void {
    let currentUser = this.msalService.instance.getActiveAccount();
    if (environment.role === "supplier" && currentUser.tenantId === this.config.config["msal"]["supplier"]["auth_b2b"]["accentureTenantId"]) {
      this.logOutSupplierFromAccentureTenant(currentUser.tenantId);
    } else {
      this.msalService.logout();
    }
  }

  logOutSupplierFromAccentureTenant(tenantId) {
    localStorage.clear();
    let redirectUri = this.config.config["msal"]["supplier"]["auth_b2b"]["redirectUri"]
    this.document.location.href = "https://login.microsoftonline.com/" + tenantId + "/oauth2/v2.0/logout?post_logout_redirect_uri=" + redirectUri
  }

  public getUser(): any {
    return this.msalService.instance.getActiveAccount();
  }

  public async initialBasicInfo() {
    await this.msalService.instance.initialize();
    try {
      if (!this.authService.passport) {
        this.checkAndSetActiveAccount()
        const currentUser = this.getUser();
        if (!currentUser) {
          return false;
        }


        const roles: Array<string> = (currentUser.idTokenClaims as any).roles

        let userRole = "Default_Access"
        if (roles && roles.length > 0) {
          let specialRole = roles.filter(
            (role) => role !== "Default_Access" && role !== "User"
          )[0]

          userRole = specialRole ? specialRole : "Default_Access"
        }

        // console.log("-----rebar auth service-----userRole: ", userRole)

        const profile: Profile = {
          email: currentUser.username || (currentUser.idTokenClaims as any).email,
          oid: (currentUser.idTokenClaims as any).oid,
          name: currentUser.name,
          sub: (currentUser.idTokenClaims as any).sub,
          unique_name: (currentUser.idTokenClaims as any).preferred_username,
          nonce: (currentUser.idTokenClaims as any).once,
          role: userRole
        };
        this.authService.passport = new Passport(profile);
        // this.authService.loginStatus = LoginStatus.Success

        await this.dictionaryService.loadAllDictionary();
      }
    // const {procurementSupport} = JSON.parse(localStorage.getItem("externalEmails")) ?? {};
    // if(localStorage.getItem("externalEmails")== undefined){
    //   await this.setExternalEmails()
    // }
    await this.setExternalEmails()


    } catch (err) {
      console.error("an error has been occurred while init basic information, %s", err)
      return false
    }

    return true
  }

  async setExternalEmails() {
    const url = `${environment.gateway}/fetchExternalEmail`;
    const response = await this.http.GetPromise(url);
    localStorage.setItem(
      "externalEmails", JSON.stringify(response)
      );
  }

  public isB2C(): boolean {
    return (
      environment.role === "supplier" &&

      ["dev", "test", "stage", "perf", "hotfix", "prod"].includes(environment.env)
    );
  }

  public async getCurrentToken(): Promise<string> {
    let scopes = ["user.read"];
    if (this.isB2C()) {
      scopes = ["openid", "profile", "offline_access"];
      const protectedScopes = this.config.config["msal"][environment.role]["auth_b2c"]["framework"]["protectedResourceMap"] as Iterable<
        readonly [string, string[]]>
      new Map(protectedScopes).forEach(p => {
        p.forEach(scope => {
          if (!scopes.includes(scope)) {
            scopes.push(scope)
          }
        })
      })
    }

    let account;
    if (this.msalService.instance.getActiveAccount()) {
      account = this.msalService.instance.getActiveAccount();
    } else {
      account = this.msalService.instance.getAllAccounts()[0];
    }

    const msal = this.config.config["msal"][environment.role]
    const msalAuth =
      environment.role === "supplier" &&

        ["dev", "test", "stage", "perf", "hotfix", "prod"].includes(environment.env)
        ? msal["auth_b2c"]
        : msal["auth_b2b"];
    const protectedResourceMap = new Map(
      msalAuth["framework"]["protectedResourceMap"] as Iterable<
        readonly [string, string[]]
      >
    );

    const authRequest = {
      protectedResourceMap,
      account
    }

    const acquireTokenInteractively = (
      authRequest,
      scopes: string[]
    ): Observable<AuthenticationResult> => {
      const redirectStartPage = window.location.href;
      this.msalService.acquireTokenRedirect({
        ...authRequest,
        scopes,
        redirectStartPage,
      });
      return EMPTY;
    }
    return this.msalService
      .acquireTokenSilent({ scopes, account })
      .pipe(
        catchError(() => {
          return acquireTokenInteractively(authRequest, scopes);
        }),
        switchMap((result: AuthenticationResult) => {
          if (!result.accessToken) {
            return acquireTokenInteractively(authRequest, scopes);
          }

          return of(result);
        }),
        switchMap((result: AuthenticationResult) => {
          return of(`${result.tokenType} ${result.accessToken}`);
        })
      ).toPromise()
  }
  public getUserClaims(): undefined | unknown {
    return this.msalService.instance.getAllAccounts()[0]?.idTokenClaims;
 }
}
