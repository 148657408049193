// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.form-group {
  margin-bottom: 2.5rem;
}

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup-content {
  background-color: #f5f5f5;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 20px 30px;
  text-align: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 400px;
  max-width: 90%;
}
.popup-content p {
  font-size: 16px;
  color: #333;
  margin-bottom: 20px;
  line-height: 1.5;
}
.popup-content button {
  padding: 10px 20px;
  margin: 0 10px;
  border: 1px solid #888;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
}
.popup-content button:hover {
  background-color: #e6e6e6;
}
.popup-content button.primary {
  background-color: #6c63ff;
  color: #fff;
  border: none;
}
.popup-content button.primary:hover {
  background-color: #5a52d4;
}
.popup-content button.secondary {
  background-color: #fff;
  color: #333;
}
.popup-content button.secondary:hover {
  background-color: #f0f0f0;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
