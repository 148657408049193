
import { RoleName } from "src/app/interfaces/buyer";

export function isPplusUser(role){   
    if(role === RoleName.ProcurementReviewerEditor){
      return true;
    }
    else{
      return false;
    }
  }

  export function isRiskMitigation(role){   
    if(role === RoleName.Risk_Mitigation_LA_Editor){
      return true;
    }
    else{
      return false;
    }
  }