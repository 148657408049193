// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.supplier-table .mat-column-sno {
  flex: 0 0 100px;
}

.supplier-table-container {
  padding: 50px 50px 10px 50px;
}

.supplier-table {
  width: 100%;
  border-collapse: collapse;
}

mat-header-cell {
  font-weight: bold;
  border-bottom: 2px solid #b87ed4;
  text-align: left !important;
}

mat-cell.mat-column-sno {
  font-weight: bold;
}

mat-cell {
  color: black;
  font-size: 14px;
  text-align: left !important;
}

a.supplier-link {
  color: #6e6e6e;
  text-decoration: underline;
  cursor: pointer;
}

mat-row:nth-child(odd) {
  background-color: #D3C3EB;
}

mat-row:nth-child(even) {
  background-color: white;
}

mat-paginator {
  margin-top: 10px;
}

mat-header-row {
  border-top: 2px solid #b87ed4;
  border-bottom: 2px solid #b87ed4;
}

mat-row:last-child {
  border-bottom: 2px solid #b87ed4;
}

.supplier-table-container {
  background: white;
}
.supplier-table-container .pagination-display {
  display: flex !important;
  flex-direction: row;
  align-items: center;
  justify-content: center !important;
}
.supplier-table-container .pagination-display .my-mat-pagination mat-paginator .mat-mdc-paginator-outer-container .mat-mdc-paginator-container {
  justify-content: center !important;
}

.page-number-btn {
  border: none !important;
  padding: 10px;
  background: white;
  outline: none;
}

::ng-deep .supplier-table-container .pagination-display .my-mat-pagination mat-paginator .mat-mdc-paginator-outer-container .mat-mdc-paginator-container {
  justify-content: center !important;
}
::ng-deep .supplier-table-container .pagination-display .my-mat-pagination mat-paginator .mat-mdc-paginator-outer-container .mat-mdc-paginator-container .mat-mdc-paginator-page-size {
  display: none !important;
}
::ng-deep .supplier-table-container .pagination-display .my-mat-pagination mat-paginator .mat-mdc-paginator-outer-container .mat-mdc-paginator-container .mat-mdc-paginator-range-actions .mat-mdc-paginator-range-label {
  display: none !important;
}

.page-number-btn.selected {
  background-color: #1976D2;
  color: white;
  font-weight: bold;
  border-radius: 4px;
}

.page-number-btn {
  margin-top: 10px;
}

.main_bg {
  min-height: calc(100vh - 55px - 3rem);
  background: #f1f1f1;
  position: relative;
}

.page-padding {
  padding: 2.1rem;
}

.main_container {
  background: #fff;
  border-top-left-radius: 2rem;
  border-bottom-right-radius: 2rem;
  padding: 2.1rem;
  position: relative;
}

.back_dashboard {
  padding-left: 20px;
  padding-bottom: 5px;
}

.drop_shadow {
  box-shadow: 0 0 3px 3px rgba(204, 204, 204, 0.4);
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
