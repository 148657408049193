import { Component, ViewChild, OnInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { NewSetupProcessId, ProcessNames, SetupProcess } from 'src/app/interfaces/workflow';
import { DashBoardService } from 'src/app/modules/buyer/services/dashboard.service';
import { isPplusUser } from 'src/app/modules/buyer/services/verifyProcurementPlus';
import { AuthService } from 'src/app/services/auth/auth.service';
import { BuyerService } from 'src/app/services/buyer.service';
import { CommonService } from 'src/app/services/common.service';
import { DictionaryService } from 'src/app/services/dictionary.service';
import { LoadingService } from 'src/app/services/loading.service';
import { WorkflowService } from 'src/app/services/workflow.service';

@Component({
  selector: 'app-csns-dashboard',
  standalone: true,
  imports: [],
  templateUrl: './csns-dashboard.component.html',
  styleUrl: './csns-dashboard.component.sass'
})
export class CsnsDashboardComponent implements OnInit, OnDestroy {
  displayedColumns: string[] = ['sno', 'supplierName', 'supplierId', 'onboardingCountry', 'status'];
  dataSource = new MatTableDataSource([]);
  dictCountry: any = {};
  checkSupplier: any;
  StepId = NewSetupProcessId;
  currentApprover: boolean = false;
  private currentApproverSubscription: Subscription;
  suppliers: any = [];
  total = 0
  newquery: {
    searchTerm: any;
    currentPage: number;
    itemsPerPage: number;
    orderType: string;
    needInit: boolean;
  } = {
      searchTerm: {},
      currentPage: 1,
      itemsPerPage: 20,
      orderType: "DESC",
      needInit: false,
    };
  constructor(
      private router: Router,
      private workflowService: WorkflowService,
      private auth: AuthService,
      private buyerService: BuyerService,
      private dictionaryService: DictionaryService,
      private dashBoardService: DashBoardService,
      private commonService: CommonService,
      public loadingService: LoadingService,
      private cdr: ChangeDetectorRef
    ) {
      this.dashBoardService.isDashBoard = false;
      this.dashBoardService.title = "Supplier Onboarding Process";
    }
  @ViewChild(MatPaginator) paginator!: MatPaginator;

  async ngOnInit() {
    this.commonService.setShowTransactions(false); 
    this.dictCountry = await this.dictionaryService.getDictionary("Country");
    await this.loadData();
  }

  async loadData() {
    this.loadingService.showLoading();
    try {
      const search = await this.buyerService.newDashboardSearch(this.newquery);
      this.suppliers = search?.data?.results || [];
      this.total = search?.data?.total;
      this.paginator.length = this.total;
      await this.addSupplierCountryName(this.suppliers, this.dictCountry);
      const mappedSupplierList = this.suppliers?.map((supplier, index) => ({
        sno: (this.newquery.currentPage - 1) * this.newquery.itemsPerPage + index + 1,
        supplierName: supplier?.RequestForm?.RequestorDetailsInfo?.SupplierCompanyName || 'TBD',
        supplierId: supplier?.Mapping?.supplierCode || 'TBD',
        onboardingCountry: supplier?.RequestForm?.RequestorDetailsInfo?.SupplierCountryName || '',
        status: supplier?.Mapping?.status || ''
      }));
      this.dataSource = new MatTableDataSource(mappedSupplierList);
      if (this.paginator) {
        this.paginator.length = this.total;
        this.paginator.pageIndex = this.newquery.currentPage - 1;
    };
    } catch (error) {
      console.error("Error loading data:", error);
    } finally {
      this.loadingService.hideLoading();
    }
  }
  
  async addSupplierCountryName(data: any[], dictCountry: any[]) {
    data?.forEach(item => {
      const countryCode = Array.isArray(item?.RequestForm?.RequestorDetailsInfo?.SupplierCountry) && item?.RequestForm?.RequestorDetailsInfo?.SupplierCountry.length > 0 ? item.RequestForm.RequestorDetailsInfo.SupplierCountry[0] : '';
      const country = dictCountry.find(country => country.Code === countryCode);
      if (country) {
        item.RequestForm.RequestorDetailsInfo.SupplierCountryName = country.Text;
      }
    });
  }

  async onPageChange(event) {
    this.newquery.currentPage = event.pageIndex + 1;
    await this.loadData();
    this.cdr.detectChanges();
  }

  get totalPages(): number {
    const totalPages = Math.ceil(this.total / this.newquery.itemsPerPage);
    return totalPages;
  }
  
  get nextPageDisabled(): boolean {
    const disabled = this.newquery.currentPage >= this.totalPages;
    return disabled;
  }
  
  get previousPageDisabled(): boolean {
    const disabled = this.newquery.currentPage <= 1;
    return disabled;
  }

  getDisplayedPages() {
    const displayedPages = [];
    const totalPages = this.totalPages;
    const currentPage = this.newquery.currentPage;
    if (currentPage > 3) { displayedPages.push(1); }
    if (currentPage - 2 > 0) { displayedPages.push(currentPage - 2); }
    if (currentPage - 1 > 0) { displayedPages.push(currentPage - 1); }
    displayedPages.push(currentPage);
    if (currentPage + 1 <= totalPages) { displayedPages.push(currentPage + 1); }
    if (currentPage + 2 <= totalPages) { displayedPages.push(currentPage + 2); }
    if (!displayedPages.includes(totalPages) && totalPages !== currentPage) {
      displayedPages.push(totalPages);
    }
    displayedPages.sort((a, b) => a - b);
    const finalPages = [];
    let prevPage = null;
    for (let i = 0; i < displayedPages.length; i++) {
      const current = displayedPages[i];
      if (prevPage !== null && current - prevPage > 1) { finalPages.push('...'); }
      finalPages.push(current);
      prevPage = current;
    }
    return finalPages;
  }

  backToDashboard() {
    if (this.dashBoardService.isTsmEnterOnboarding) {
      this.router.navigate([
        `buyer/search/${this.dashBoardService.tsmKey1}/${this.dashBoardService.tsmKey2}`,
      ]);
    } else {
      this.router.navigate([`buyer`]);
    }
  }
  gotobackonkeydown(event) {
    if(event.keyCode == 13){
      this.backToDashboard()
    }
  }

  async onSupplierNameClick(supplierId: string) {
    const clickedSupplier = this.suppliers?.find(s => s.Mapping.supplierCode === supplierId);
    if (clickedSupplier) {
      await this.nav(supplierId);
    }
  }
  async nav(supplierCode) {
      this.checkSupplier = await this.buyerService.getExistingSupplier(supplierCode);
      let data:any = {};
      data = await this.buyerService.getSupplierSteps(supplierCode);
      if(isPplusUser(this.auth.passport.buyer.RoleCode) && this.checkSupplier?.data === true){
        await this.commonService.checkAccess(this.auth.passport.profile.email, supplierCode)
        if (data) {
          for(let status of data.data){
            if(status.StepId === this.StepId.Procurement_Plus_Review && (status.Status === 'rejected' || status.Status === 'completed')){
              this.router.navigate([`buyer/onboarding/process/${supplierCode}`]);
              return
            }
            else if(status.StepId === this.StepId.Procurement_Plus_Review && status.Status === 'in_progress')
              await this.setAccessStatus(status)
              if(this.currentApprover){
                this.router.navigate([`buyer/onboarding/${supplierCode}/step1`]);
                return
              }
          }
        }
        this.router.navigate([`buyer/onboarding/process/${supplierCode}`]);
      }
      else{
        const userTasks = await this.workflowService.loadUserTasks(
          this.auth.passport.buyer.RoleCode,
          supplierCode
        );
        if (
          this.workflowService.getTaskByKey(
            ProcessNames.generic_supplierSetup,
            SetupProcess.requestor_draft,
            userTasks
          )
        ) {
          this.router.navigate([`buyer/onboarding/${supplierCode}/step1`]);
        } else {
          this.router.navigate([`buyer/onboarding/process/${supplierCode}`]);
        }
      }
  }
  async setAccessStatus(stepProcurementPlusReview: any){
    this.currentApprover = false;
    this.currentApproverSubscription = this.commonService.currentApprover$.subscribe(
      (newValue: boolean) => {
        if (stepProcurementPlusReview && stepProcurementPlusReview.Status === "in_progress") {
          this.currentApprover = newValue;
        } else {
          this.currentApprover = false;
        }
      }
    );
  }

  ngOnDestroy(): void {
    if (this.currentApproverSubscription) {
      this.currentApproverSubscription.unsubscribe();
    }
    this.commonService.setShowTransactions(true); 
  }
}
