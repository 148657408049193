import { Component, OnInit, HostListener, ViewChild } from '@angular/core';
import { BuyerService } from "src/app/services/buyer.service";
import { AuthService } from "src/app/services/auth/auth.service";
import { LoadingService } from "src/app/services/loading.service";
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-manage-group-members',
  templateUrl: './manage-group-members.component.html',
  styleUrl: './manage-group-members.component.sass'
})
export class ManageGroupMembersComponent implements OnInit {
  @ViewChild('groupForm') groupForm: NgForm;
  groupList: string[] = [];
  groupMembersList: string[] = [];
  newGroupName: string;
  newGroupMember: string;
  newMemberEmail: string;
  isCreatingGroupPopupVisible: boolean = false;
  isAddingGroupMemberPopupVisible: boolean = false;
  enteredGroupName: string = '';
  enteredGroupMember: string = '';
  groupSuccessMessage: boolean = false;
  groupFailedMessage: boolean = false;
  groupMemberSuccessMessage: boolean = false;
  groupMemberFailedMessage: boolean = false;
  filteredData: string[] = [];
  textData: string = "";
  isListVisible: boolean = false;
  loggedInUser: string;
  showForm: boolean = false;
  errorMessage : string;
  warningMessage : string;

  @HostListener('document:click', ['$event'])
  onOutsideClick(event: MouseEvent): void {
    const clickedInside = event.target && (event.target as HTMLElement).closest('.dropdown-list') || (event.target as HTMLElement).closest('.group-input');
    
    if (!clickedInside) {
      this.isListVisible = false;
    }
  }

  constructor(private buyerService: BuyerService,
    private authService: AuthService,
    public loadingService: LoadingService) { }

  ngOnInit(): void {
    this.loadingService.showLoading();
    this.buyerService.getGroupList().then((data => {
      const response: any = data;
      this.groupList = response.data.map((group: any) => group.groupName)
      this.filteredData = []
      this.loadingService.hideLoading();
    }),
      error => {
        console.error('Error fetching group names:', error);
        this.loadingService.hideLoading();
      }
    )
    this.loggedInUser = this.authService.passport.profile.email;
  }

  onInputChange() {
    if (this.textData) {
      this.filteredData = this.groupList.filter(item =>
        item.toLowerCase().includes(this.textData.toLowerCase())
      );
      this.isListVisible = this.filteredData.length > 0;
    } else {
      this.isListVisible = false;
      this.filteredData = [];
      this.groupMembersList = [];
    }
  }

  onItemSelect(item: string) {
    this.loadingService.showLoading();
    this.textData = item;
    this.isListVisible = false;
    this.buyerService.searchGroup(this.textData).then((data => {
      const groupMemberResponse: any = data;
      this.groupMembersList = groupMemberResponse.data.map((member: any) => member.email);
      this.loadingService.hideLoading();
    }),
      error => {
        console.error('Error fetching group members:', error);
        this.loadingService.hideLoading();
      }
    )
  }

  onInputBlur() {
    if(this.textData){
      setTimeout(() => {
        if (!this.textData) {
          this.isListVisible = false;
        }
      }, 100);
    }    
  }

  groupCreation() {
    this.errorMessage = '';
    this.warningMessage = '';
    if((!this.newGroupName || this.newGroupName.trim() === '') && (!this.newGroupMember || this.newGroupMember.trim() === '')){
      this.errorMessage = 'Please enter "Group Name" and "Group Member Email ID"';
      return;
    }
    if(!this.newGroupName && this.newGroupMember){
      this.warningMessage = 'Please enter "Group Name"'
      return;
    }
    if(this.newGroupName && !this.newGroupMember){
      this.warningMessage = 'Please enter "Group Member Email ID"'
      return;
    }
    if(!this.groupForm.valid){
      return;
    }
    this.loadingService.showLoading();
    const groupData = {
      groupName: this.newGroupName,
      email: this.newGroupMember,
      createdBy: this.loggedInUser
    }
    this.groupSuccessMessage = false;
    this.groupFailedMessage = false;
    this.enteredGroupName = this.newGroupName;
    this.buyerService.createGroup(groupData).then((data => {
      const resData: any = data;
      if (resData && resData.data && resData.data.message && resData.data.message === 'Group with this name already exists') {
        this.groupFailedMessage = true;
        this.loadingService.hideLoading();
        return;
      } else {
        this.groupList = [];
        this.buyerService.getGroupList().then((data => {
          const response: any = data;
          const latestGroupsList = response.data.map((group: any) => group.groupName);
          this.groupList.push(...latestGroupsList);
          this.filteredData = [];
        }))
        this.onItemSelect(this.newGroupName);
        this.newGroupName = '';
        this.newGroupMember = '';
        this.groupSuccessMessage = true;
        this.loadingService.hideLoading();
        this.showForm = true;
      }
    }),
      error => {
        console.error('Error while creating group :', error);
        this.loadingService.hideLoading();
      }
    )
  }

  cancelGroupCreation(): void {
    this.isCreatingGroupPopupVisible = false;
    this.newGroupName = '';
    this.newGroupMember = '';
    this.groupSuccessMessage = false;
    this.groupFailedMessage = false;
    this.showForm = false;
    this.errorMessage = '';
    this.warningMessage = '';
  }

  clearWarningMessages() {
    this.warningMessage = '';
  }

  addGroupMember() {
    this.errorMessage = '';
    if(!this.newMemberEmail || this.newMemberEmail.trim() === ''){
      this.errorMessage = 'Please enter "Group Member Email ID"';
      return;
    }
    if(!this.groupForm.valid){
      return;
    }
    this.loadingService.showLoading();
    const data = {
      groupName: this.textData,
      email: this.newMemberEmail,
      updatedBy: this.loggedInUser
    }
    this.groupMemberSuccessMessage = false;
    this.groupMemberFailedMessage = false;
    this.enteredGroupMember = this.newMemberEmail;
    this.buyerService.addGroupMember(data).then((data => {
      const resData: any = data;
      if (resData && resData.data && resData.data.message && resData.data.message === 'Group with this member already exists') {
        this.groupMemberFailedMessage = true;
        this.loadingService.hideLoading();
        return;
      } else {
        this.onItemSelect(this.textData);
        this.newMemberEmail = '';
        this.groupMemberSuccessMessage = true;
        this.showForm = true;
        this.loadingService.hideLoading();
      }
    }),
      error => {
        console.error('Error while updating group member:', error);
        this.loadingService.hideLoading();
      }
    )
  }
  cancelAddingGroupMember(): void {
    this.isAddingGroupMemberPopupVisible = false;
    this.newMemberEmail = '';
    this.groupMemberSuccessMessage = false;
    this.groupMemberFailedMessage = false;
    this.showForm = false;
    this.errorMessage = '';
  }
}
