import { Injectable } from "@angular/core";
import {
  SupplierSetupSteps,
  RiskScore,
  RejectInfo,
  ApproveInfo,
} from "src/app/interfaces/process";
import { OnBoardingService } from "./onboarding.service";
import {
  ProcessNames,
  HistoryTask,
  AllTaskKey,
  HistoryProcessInstance,
  WorkflowEndType,
  SetupProcess,
  BuyerChangeRequestProcess,
} from "src/app/interfaces/workflow";
import {
  RiskAssessmentStatus,
  SpendCategoryGroup,
  SupplierStatus,
  SapResultModel,
} from "src/app/interfaces/mapping";
import * as moment from "moment";
import { WorkflowService } from "src/app/services/workflow.service";
import { format } from "util";
import { DialogType } from "src/app/services/dialog.service";
import { cloneDeep } from "src/app/utils";

@Injectable({
  providedIn: "root",
})
export class SupplierSetupProcessService {
  get supplierSetupTaskHistory(): Array<any> {
    return this.onboardingService.supplierModel.Mapping.historyTasks.filter(
      (task) =>
        task.processDefName === ProcessNames.generic_supplierSetup ||
        task.processDefName === ProcessNames.generic_supplierSetupSubProcess
    );
  }
  get buyerChangeRequestGDPRTaskHistory(): Array<any> {
    return this.onboardingService.supplierModel.Mapping.historyTasks.filter(
      (task) =>
        task.processDefName === ProcessNames.generic_buyerChangeRequest && 
        task.name === BuyerChangeRequestProcess.GDPR
    );
  }
  get spendCategoryGroupValue(): string {
    if (this.onboardingService.supplierModel.RequestForm.RequestorDetailsInfo.IndependentContractor == "Y") {
      SpendCategoryGroup['5002'] = false
      SpendCategoryGroup['5021'] = false
    } else {
      SpendCategoryGroup['5002'] = true
      SpendCategoryGroup['5021'] = true
    }
    
    return SpendCategoryGroup[
      this.onboardingService.supplierModel.RequestForm.RequestorDetailsInfo
        .SpendCategoryGroup
    ];
  }

  get BI_Score(): string {
    if(Object.entries(this.onboardingService.supplierModel.RequestForm.RiskAssessmentInfo).length > 0){
      if (
        this.onboardingService.supplierModel.RequestForm.RiskAssessmentInfo?.BI_BDA_1 === "12"
      ) {
        return RiskScore.notApplicable;
      }
      if (
        this.onboardingService.supplierModel.Mapping.assessmentRate["bibdaResult"]
          .isCommited
      ) {
        return this.onboardingService.supplierModel.Mapping.assessmentRate[
          "bibdaResult"
        ].score;
      } else {
        return RiskScore.notStart;
      }
    }
    else{
      return RiskScore.notApplicable;
    }    
  }

  get RiskAssessmentStatus(): string {
    // if (this.spendCategoryGroupValue) {
    //   return RiskScore.notApplicable;
    // }
    if (this.onboardingService.riskAssessment) {
      return this.onboardingService.riskAssessment.Status;
    }
    return RiskScore.pending;
  }
  circleSteps = [];
  currTitleStep: any;
  isTerminated = false;
  isDeactivated = false;
  ssresubmitStateValue = false;
  private supplierSetupSteps = SupplierSetupSteps;
  private rejectInfo = RejectInfo;
  private approveInfo = ApproveInfo;
  private processHistories: HistoryProcessInstance[];

  constructor(
    private onboardingService: OnBoardingService,
    private workflowService: WorkflowService
  ) { }
  // load supplier setup process
  async loadSupplierSetupProcess() {
    this.supplierSetupSteps = cloneDeep(SupplierSetupSteps);
    if (
      this.onboardingService.supplierModel.Mapping.status ===
      SupplierStatus.terminated
    ) {
      this.isTerminated = true;
    } else {
      // bug 958823
      this.isTerminated = false;
    }

    this.isDeactivated =
      this.onboardingService.supplierModel.Mapping.status ===
      SupplierStatus.deactivated
        ? true
        : false;

    // update task steps
    let apSubProcessId: string;
    this.supplierSetupTaskHistory.map((item: HistoryTask) => {
      if (item.name === SetupProcess.SST_review) {
        apSubProcessId = item.processInstId;
      }
      return item;
    });
    this.initSupplierSetup_APTasks();
    await this.initSupplierSetup_EndSteps();

    let includebuyerChangeRequestGDPRTask = this.supplierSetupTaskHistory;
    if(this.buyerChangeRequestGDPRTaskHistory.length) {
      let supplierSetupTaskHistory = this.supplierSetupTaskHistory.filter(item => item.name !== SetupProcess.GDPR);
      includebuyerChangeRequestGDPRTask = supplierSetupTaskHistory.concat(this.buyerChangeRequestGDPRTaskHistory);
    }
    includebuyerChangeRequestGDPRTask.forEach((item: HistoryTask) => {
      // ap task handled separately
      if (apSubProcessId && item.processInstId === apSubProcessId) {
        return;
      }
      // IS and GDPR work together
      if (item.name === SetupProcess.GDPR) {
        const tmpItem: any = {
          UpdateAt: item.endTime,
          status: RiskAssessmentStatus.IS,
        };
        const IS_step = this.supplierSetupSteps[RiskAssessmentStatus.IS];
        if (IS_step) {
          IS_step.done = true;
          IS_step.step = tmpItem;
        }
      }
      this.updateSupplierSetup_TaskSteps(item);
    });
    this.supplierSetupSteps_Handling();

    this.hideStepsForInvalidDate();
  }

  supplierSetupSteps_Handling() {
    // buyer process don't show SAP
    let showSteps = Object.values(this.supplierSetupSteps).filter(
      (item) => item.status !== RiskAssessmentStatus.SAP
    );
    // Whether to complete IS and GDPR
    if (!this.spendCategoryGroupValue) {
      showSteps = showSteps.filter(
        (item) =>
          item.status !== RiskAssessmentStatus.IS &&
          item.status !== SetupProcess.GDPR
      );
    }
    // Bug: 530942 Handling older profile GDPR value
    showSteps = this.disableGDPR(showSteps, SpendCategoryGroup);
    
    // Whether to complete BI/BDA
    if (this.BI_Score === RiskScore.notApplicable) {
      showSteps = showSteps.filter((item) => item.status !== SetupProcess.BI);
    }

    // temite and complete and (Terminated is not done)is show only complete
    if (
      showSteps.filter((item) => item.status === "Onboarded") &&
      showSteps.filter((item) => item.status === "Terminated")
    ) {
      if (
        showSteps.filter((item) => item.status === "Terminated")[0].done ===
        false
      ) {
        showSteps = showSteps.filter((item) => item.status !== "Terminated");
        const requestorDraftStep =
          this.supplierSetupSteps[SetupProcess.requestor_draft];
        requestorDraftStep.show = true;
      }
    }
    // unfinished task
    const unfinishedTasks = showSteps.filter((item) => !item.done);

    // complete task
   let completedTasks = showSteps.filter((item) => item.done);

   completedTasks = completedTasks
     .filter((item) => item.status !== "Deactivated")
     .sort(
       (first, second) =>
         moment(first.step.UpdateAt).unix() -
         moment(second.step.UpdateAt).unix()
     );

   // Add "Deactivated" items back to the list without sorting them
   const deactivatedItems = showSteps.filter(
     (item) => item.status === "Deactivated"
   );
   completedTasks = [...completedTasks, ...deactivatedItems];

    // all pending step array
    let allSteps;
    if (
      this.onboardingService.supplierModel.Mapping.status ===
      SupplierStatus.terminated
    ) {
      const terminateTask = completedTasks.find(
        (ct) => ct.status === "Terminated"
      );
      completedTasks = completedTasks.filter(
        (ct) => ct.status !== "Terminated"
      );
      completedTasks.push(terminateTask);
      allSteps = completedTasks;
    } else {
      allSteps = [...completedTasks, ...unfinishedTasks];
    }
    //ensure allSteps 'Onboarded' is end
    // const on=allSteps.filter(item => item.status==="Onboarded")[0]
    // allSteps=allSteps.filter(item => item.status!=="Onboarded")
    // allSteps.push(on)
    // all step circles
    this.circleSteps = allSteps;
    //  current step
    if(!this.ssresubmitStateValue)
  {
      this.currTitleStep = Object.assign([], this.circleSteps)
      .reverse()
      .find((item) => !!item && item.done);
   }
     else{
      this.currTitleStep = Object.assign([], this.circleSteps)
      .find((item) => !!item && item.status == SetupProcess.profile_resubmit);
     }
     this.circleSteps = this.circleSteps.filter(((item) => !!item && item.status !== SetupProcess.profile_resubmit));
    }


  // update general task steps
  private updateSupplierSetup_TaskSteps(task: HistoryTask) {
    const tmpItem: any = {
      UpdateAt: task.endTime,
      status: task.name,
    };
    const step = this.supplierSetupSteps[task.name];
    if (step) {
      step.done = true;
      step.step = tmpItem;
    }
  }

  private disableGDPR(showSteps, SpendCategoryGroup) {
    let disableGDPRDate = new Date("2024-08-30T00:00:00.000Z"); // As per R13 PROD release date disabling GDPR for existing suppliers based on 2 subcategories.
    let convertGDPRDate : any =  disableGDPRDate.toISOString();
    let createdDate : any = this.onboardingService.supplierModel.RequestForm.RequestorDetailsInfo.RequestedDate;
    let spendcat = this.onboardingService.supplierModel.RequestForm.RequestorDetailsInfo.SpendCategoryGroup
    if (convertGDPRDate > createdDate) {
      if (spendcat === '2402') {
        SpendCategoryGroup['2402'] = false
        showSteps = showSteps.filter(
          (item) =>
          item.status !== RiskAssessmentStatus.IS &&
          item.status !== SetupProcess.GDPR
        );
      } else if (spendcat === '2403') {
        SpendCategoryGroup['2403'] = false
        showSteps = showSteps.filter(
          (item) =>
          item.status !== RiskAssessmentStatus.IS &&
          item.status !== SetupProcess.GDPR
        );
      }     
    }
    return showSteps;
  }
  
  
  // init terminate and on boarded steps
  private async initSupplierSetup_EndSteps() {
    if (this.isDeactivated) {
      if (
        this.onboardingService.supplierModel.Mapping.status ===
        SupplierStatus.deactivated
      ) {
        const deactivatedData: any =
          this.onboardingService.supplierModel.Mapping.historyTasks.filter(
            (item) => item.name === SupplierStatus.deactivated
          );
        // console.log(deactivatedData);
        // const createdDt: string = moment(deactivatedData[0].startTime).format(
        //   "DD-MMM-YYYY"
        // );
        this.updateSetup_DeactivatedSteps(deactivatedData[0].startTime);
      }
    }

    if (this.isTerminated) {
      const sapResult: SapResultModel[] =
        this.onboardingService.supplierModel.Mapping.assessmentRate.sapResult;
      sapResult.forEach((item: SapResultModel) => {
        if (item.type === DialogType.terminateSupplier) {
          const createdDt: string = moment(item.createdOn).format(
            "DD-MMM-YYYY"
          );
          this.updateSetup_TerminatedSteps(createdDt);
        }
      });
      // judge terminate status for batch terminate supplier
      this.supplierSetupTaskHistory.forEach((item: HistoryTask) => {
        if (item.variablesObj && item.variablesObj.IsSupplierTerminated) {
          const createdDt: string = moment(item.variablesObj.CreatedDt).format(
            "DD-MMM-YYYY"
          );
          this.updateSetup_TerminatedSteps(createdDt);
        }
      });
    }
    await this.loadProcessHistories();
    if (this.processHistories && this.processHistories.length > 0) {
      // bug 921290
      // const setupProcess = this.processHistories.find((process: HistoryProcessInstance) => process.name === ProcessNames.generic_supplierSetup)
      // if (!setupProcess) {
      //   return
      // }
      // if (setupProcess.endType && setupProcess.endType === WorkflowEndType.terminateEnd) {
      //   this.updateSupplierSetup_TerminatedSteps(setupProcess)
      // } else {
      //   this.updateSupplierSetup_CompletedSteps(setupProcess)
      // }
      if (
        this.processHistories.find(
          (item) =>
            item.endType === WorkflowEndType.terminateEnd &&
            item.name !== ProcessNames.generic_buyerChangeRequest
        )
      ) {
        const setupProcess = this.processHistories.find(
          (item) => item.endType === WorkflowEndType.terminateEnd
        );
        this.updateSupplierSetup_TerminatedSteps(setupProcess);
      }
      if (
        this.processHistories.find(
          (process: HistoryProcessInstance) =>
            process.name === ProcessNames.generic_supplierChangeRequest
        ) ||
        this.processHistories.find(
          (process: HistoryProcessInstance) =>
            process.name === ProcessNames.generic_buyerChangeRequest
        ) ||
        (this.processHistories.find(
          (process: HistoryProcessInstance) =>
            process.name === ProcessNames.generic_supplierSetup
        ) &&
          this.processHistories.find(
            (process: HistoryProcessInstance) =>
              process.name === ProcessNames.generic_supplierSetup
          ).status === "complete" &&
          this.processHistories.find(
            (process: HistoryProcessInstance) =>
              process.name === ProcessNames.generic_supplierSetup
          ).endType === WorkflowEndType.noneEnd)
      ) {
        const setupProcess = this.processHistories.find(
          (process: HistoryProcessInstance) =>
            process.name === ProcessNames.generic_supplierSetup
        );
        this.updateSupplierSetup_CompletedSteps(setupProcess);
      }
    }
  }

  // init ap subProcess task steps
  private initSupplierSetup_APTasks() {
    const sstReviewComplete: boolean =
      this.workflowService.checkAPTaskIsComplete(
        ProcessNames.generic_supplierSetupSubProcess,
        SetupProcess.SST_review,
        this.supplierSetupTaskHistory
      );
    const sstReviewReject: boolean = this.workflowService.checkAPTaskIsReject(
      ProcessNames.generic_supplierSetupSubProcess,
      SetupProcess.SST_review,
      this.supplierSetupTaskHistory
    );
    const ssresubmitState:boolean = this.workflowService.checkAPTaskPendingReview(
      ProcessNames.generic_supplierSetupSubProcess,
      SetupProcess.SST_rejectToSupplier,
      this.supplierSetupTaskHistory
    );
    this.ssresubmitStateValue = ssresubmitState;
     const qcReviewComplete: boolean =
      this.workflowService.checkAPTaskIsComplete(
        ProcessNames.generic_supplierSetupSubProcess,
        SetupProcess.QC_review,
        this.supplierSetupTaskHistory
      );
    const qcReviewReject: boolean = this.workflowService.checkAPTaskIsReject(
      ProcessNames.generic_supplierSetupSubProcess,
      SetupProcess.QC_review,
      this.supplierSetupTaskHistory
    );
    // sst reject
    if (!ssresubmitState){
      if (sstReviewReject) {
        const lastSSTTask = this.getSupplierSetup_SSTTask();
        this.updateSupplierSetup_TaskSteps(lastSSTTask);
        let rejectTo: string;
        if (lastSSTTask.variablesObj.SST_RejectTo === "supplier") {
          rejectTo = "supplier";
        } else if (lastSSTTask.variablesObj.SST_RejectTo === "buyer") {
          rejectTo = "requestor";
        }
        const isSSTReject = true;
        const sstIntro = format(this.rejectInfo.SST_Reject_intro, rejectTo);
        const sstBody = format(this.rejectInfo.SST_Reject_body, rejectTo);
        this.updateSupplierSetup_APTaskSteps(
          lastSSTTask,
          isSSTReject,
          sstIntro,
          sstBody
        );
        // sst approve
      } else if (sstReviewComplete) {
        const lastSSTTask = this.getSupplierSetup_SSTTask();
        this.updateSupplierSetup_TaskSteps(lastSSTTask);
        const isSSTReject = false;
        const sstIntro = this.approveInfo.SST_Approve_intro;
        const sstBody = this.approveInfo.SST_Approve_body;
        this.updateSupplierSetup_APTaskSteps(
          lastSSTTask,
          isSSTReject,
          sstIntro,
          sstBody
        );
        // qc reject
        if (qcReviewReject) {
          const lastQCTask = this.getSupplierSetup_QCTask();
  
          if (
            moment(lastSSTTask.endTime).unix() > moment(lastQCTask.endTime).unix()
          ) {
            return;
          }
          this.updateSupplierSetup_TaskSteps(lastQCTask);
          const isQCReject = true;
          const qcIntro = this.rejectInfo.QC_Reject_intro;
          const qcBody = this.rejectInfo.QC_Reject_body;
          this.updateSupplierSetup_APTaskSteps(
            lastQCTask,
            isQCReject,
            qcIntro,
            qcBody
          );
          // qc approve
        } else if (qcReviewComplete) {
          const lastQCTask = this.getSupplierSetup_QCTask();
          this.updateSupplierSetup_TaskSteps(lastQCTask);
          const qcIntro = this.approveInfo.QC_Approve_intro;
          const qcBody = this.approveInfo.QC_Approve_body;
          const isQCReject = false;
          this.updateSupplierSetup_APTaskSteps(
            lastQCTask,
            isQCReject,
            qcIntro,
            qcBody
          );
        }
      } // termianted when pending AP sst review and AP QC reject  
      else if (qcReviewReject) {
        const lastSSTTask = this.getSupplierSetup_SSTTaskforQCreject();
        this.updateSupplierSetup_TaskSteps(lastSSTTask);
        const isSSTReject = false;
        const sstIntro = this.approveInfo.SST_Approve_intro;
        const sstBody = this.approveInfo.SST_Approve_body;
        this.updateSupplierSetup_APTaskSteps(
          lastSSTTask,
          isSSTReject,
          sstIntro,
          sstBody
        );
        // qc reject
  
        const lastQCTask = this.getSupplierSetup_QCTask();
        if (
          moment(lastSSTTask.endTime).unix() > moment(lastQCTask.endTime).unix()
        ) {
          return;
        }
        this.updateSupplierSetup_TaskSteps(lastQCTask);
        const isQCReject = true;
        const qcIntro = this.rejectInfo.QC_Reject_intro;
        const qcBody = this.rejectInfo.QC_Reject_body;
        this.updateSupplierSetup_APTaskSteps(
          lastQCTask,
          isQCReject,
          qcIntro,
          qcBody
        );
      }
    }
  }
  // get sst last task when AP QC reject and terminate 
  private getSupplierSetup_SSTTaskforQCreject(): HistoryTask {
    const sstTasks = this.supplierSetupTaskHistory
      .filter((item: HistoryTask) => SetupProcess.SST_review === item.name)
      .sort(
        (first, second) =>
          moment(first.endTime).unix() - moment(second.endTime).unix()
      );
    const lastSSTTask = sstTasks[sstTasks.length - 2];
    return lastSSTTask;
  }
  // get sst last task
  private getSupplierSetup_SSTTask(): HistoryTask {
    const sstTasks = this.supplierSetupTaskHistory
      .filter((item: HistoryTask) => SetupProcess.SST_review === item.name)
      .sort(
        (first, second) =>
          moment(first.endTime).unix() - moment(second.endTime).unix()
      );
    const lastSSTTask = sstTasks[sstTasks.length - 1];
    return lastSSTTask;
  }

  // get qc last task
  private getSupplierSetup_QCTask(): HistoryTask {
    const qcTasks = this.supplierSetupTaskHistory
      .filter((item: HistoryTask) => SetupProcess.QC_review === item.name)
      .sort(
        (first, second) =>
          moment(first.endTime).unix() - moment(second.endTime).unix()
      );
    const lastQCTask = qcTasks[qcTasks.length - 1];
    return lastQCTask;
  }

  // update complete steps
  private updateSupplierSetup_CompletedSteps(process: HistoryProcessInstance) {
    if (!process) {
      return;
    }
    const tmpItem: any = {
      UpdateAt: process.endTime,
      status: SupplierStatus.onboarded,
    };
    const step = this.supplierSetupSteps[SupplierStatus.onboarded];
    if (step) {
      step.done = true;
      step.step = tmpItem;
    }
  }

  // update complete steps
  private updateSupplierSetup_TerminatedSteps(process: HistoryProcessInstance) {
    const tmpItem: any = {
      UpdateAt: process.endTime,
      status: SupplierStatus.terminated,
    };
    const step = this.supplierSetupSteps[SupplierStatus.terminated];
    if (step) {
      step.done = true;
      step.show = true;
      step.step = tmpItem;
    }
    // requestor form terminate: not show requestor draft step
    if (
      this.supplierSetupTaskHistory.length === 1 &&
      this.supplierSetupTaskHistory[0].variablesObj.isTerminate === true
    ) {
      const requestorDraftStep =
        this.supplierSetupSteps[SetupProcess.requestor_draft];
      requestorDraftStep.show = false;
    }
    // await supplier accept form terminate: not show await step
    if (
      this.supplierSetupTaskHistory.length === 2 &&
      this.supplierSetupTaskHistory[1].variablesObj.isSupplierTerminated ===
      true
    ) {
      const requestorDraftStep =
        this.supplierSetupSteps[SetupProcess.supplier_accepted];
      requestorDraftStep.show = false;
    }
    this.supplierSetupTaskHistory.forEach((singleTask)=>{
      this.updateSupplierSetupStepsShow(singleTask);
    });
  }

  async updateSupplierSetupStepsShow(singleTask: any) {
    if (singleTask.name === AllTaskKey.profile_draft && !singleTask.variablesObj.CreatedDt && !singleTask.variablesObj.UserRole) {
      this.supplierSetupSteps[SetupProcess.profile_draft].show = false;
    } else if (singleTask.name === AllTaskKey.supplier_accepted && !singleTask.variablesObj.CreatedDt && !singleTask.variablesObj.UserRole) {
      this.supplierSetupSteps[SetupProcess.supplier_accepted].show = false;
    } else if (singleTask.name === AllTaskKey.SST_review) {
      if (singleTask.variablesObj && singleTask.candidateGroup == 'AP_SST'
        && !singleTask.variablesObj.SST_Approve
        && (!!singleTask.variablesObj.SST_RejectTo && (singleTask.variablesObj.SST_RejectTo !== "buyer" || singleTask.variablesObj.SST_RejectTo !== "supplier"))
        && singleTask.variablesObj.isSupplierTerminated
      ) {
        this.supplierSetupSteps[SetupProcess.SST_review].show = false;
      }
    } else if (singleTask.name === AllTaskKey.QC_review) {
      if (singleTask.variablesObj
        && !singleTask.variablesObj.QC_Approve
        && !singleTask.variablesObj.QC_Reject
        && singleTask.variablesObj.isSupplierTerminated
      ) {
        this.supplierSetupSteps[SetupProcess.QC_review].show = false;
      }
    } else if (singleTask.name === AllTaskKey.requestor_draft) {
      if (singleTask.variablesObj && singleTask.variablesObj.isSupplierTerminated && !singleTask.variablesObj.CreatedDt && !singleTask.variablesObj.UserRole) {
        this.supplierSetupSteps[SetupProcess.SST_review].show = false;
      }
    } else if (singleTask.name === AllTaskKey.GDPR && !singleTask.variablesObj.CreatedDt && !singleTask.variablesObj.UserRole) {
      this.supplierSetupSteps[SetupProcess.GDPR].show = false;
      this.supplierSetupSteps[RiskAssessmentStatus.IS].show = false
    } else if (singleTask.name === AllTaskKey.BI && !singleTask.variablesObj.CreatedDt && !singleTask.variablesObj.UserRole) {
      this.supplierSetupSteps[SetupProcess.BI].show = false;
    }
  }

  // update ap task steps
  private updateSupplierSetup_APTaskSteps(
    task: HistoryTask,
    isReject: boolean,
    intro: string,
    body: string
  ) {
    const step = this.supplierSetupSteps[task.name];
    if (step) {
      step.reject = isReject;
      step.intro = intro;
      step.body = body;
    }
  }

  // load process histories and update steps
  private async loadProcessHistories() {
    const data = await this.workflowService.getProcessHistoriesByBusinessKey(
      this.onboardingService.supplierModel.Mapping.supplierCode
    );
    if (data.isSuccess) {
      this.processHistories = data.data;
    }
  }

  clear() {
    Object.values(this.supplierSetupSteps).map((item) => {
      item.done = false;
      item.reject = false;
      item.terminate = false;
      if (item.status === SupplierStatus.terminated) {
        item.terminate = true;
      } else if (item.status === SupplierStatus.onboarded) {
        item.title = "Completed";
        item.body = "Supplier has been onboarded";
        item.intro = "Supplier has been onboarded";
      }
    });
  }

  private updateSetup_TerminatedSteps(createdDt: string) {
    const tmpItem: any = {
      UpdateAt: createdDt,
      status: SupplierStatus.terminated,
    };
    const step = this.supplierSetupSteps[SupplierStatus.terminated];
    if (step) {
      step.done = true;
      step.show = true;
      step.step = tmpItem;
    }
  }

  private async updateSetup_DeactivatedSteps(createdDt: string) {
    const tmpItem: any = {
      UpdateAt: createdDt,
      status: SupplierStatus.deactivated,
    };
    const step = this.supplierSetupSteps[SupplierStatus.deactivated];
    if (step) {
      step.done = true;
      step.show = true;
      step.step = tmpItem;
      step.terminate = true;
    }
  }

  /**
   * Hides the stpes if the provided date is invalid or not present.
   * Ensures the UI only displays steps when a valid date is available in API response
   */
  private hideStepsForInvalidDate = () => { 
    if(this.isDeactivated) {
      Object.entries(this.supplierSetupSteps).forEach(([key, item]) => {
        if(!item.done) {
          item.show = false;
        } 
      })
    }
  }
}
