import { Component,OnInit } from '@angular/core';
import { BaseComponent } from "src/app/dynamic-components/components/base/base.component";
 
@Component({
  selector: 'app-textarea',
  standalone: true,
  imports: [],
  templateUrl: './textarea.component.html',
  styleUrl: './textarea.component.sass'
})
export class TextareaComponent extends BaseComponent implements OnInit {
  constructor() {
    super();
  }
 
  ngAfterViewInit(): void {
    var element = document.querySelector('.textarea-content');
    element.closest('.dynamic-row').classList.add('block-display')
  }

  
}
 