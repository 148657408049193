import { Component, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { CommonService } from "../../../../../services/common.service";
import { ProfileService } from "src/app/modules/supplier/services/profile.service";
import {
  ButtonCommands,
  DialogType,
  DialogService,
} from "src/app/services/dialog.service";
import { OrganizationStatus } from "src/app/interfaces/supplier";
import { SupplierOrganization } from "src/app/interfaces/supplierProfile";
import { cloneDeep, trim } from "src/app/utils";
import VMController from "src/app/interfaces/vm";
import { LoadingUrlMapping } from "src/app/interfaces/mapping";
import { CanComponentDeactivate } from "src/app/can-deactivate.guard";
import { environment } from "src/environments/environment";
import { LoadingService } from "src/app/services/loading.service";
import { AllTaskKey } from "src/app/interfaces/workflow";

import { ComponentConfig } from "src/app/dynamic-components/ComponentConfig";
import { MetadataService } from "src/app/services/metadata.service";
import { DynamicContext } from "src/app/dynamic-components/interfaces/DynamicContext";
import { DynamicFormComponent } from "src/app/dynamic-components/components/dynamic-form/dynamic-form.component";
import { DynamicViewComponent } from "src/app/dynamic-components/components/dynamic-view/dynamic-view.component";
import { DictionaryService } from "src/app/services/dictionary.service";
import { SupplierCommonService } from "../../../services/supplierCommon.service";
import { OnBoardingService } from "../../../../buyer/services/onboarding.service";
import { LanguageService } from "../../../../../dynamic-components/utils/language.service";
import { AuthService } from "src/app/services/auth/auth.service";
import { SupplierService } from "../../../../../services/supplier.service";
import { showGlobalPrepopulateData } from "../../../../../../config/config";
import { MatDialog } from '@angular/material/dialog';
import { GlobalPopupComponent } from "src/app/global-popup/global-popup.component";
import { LaunchDarklyService } from "src/app/services/launch-darkly.service";

enum METLocation {
  BH = "BH",
  QA = "QA",
  SA = "SA",
  AE = "AE",
}
@Component({
  selector: "app-profile-organization",
  templateUrl: "./organization.component.html",
  styleUrls: ["./organization.component.sass"],
})
export class ProfileOrganizationComponent
  implements OnInit, CanComponentDeactivate
{
  private organizationStatus: OrganizationStatus = {};
  globalAnwsers = [];
  globalNameElement:any;
  dialogRef: any;
  getglobalVal: any;
  isNewVersion:boolean=true;
  getConfigsInfo: ComponentConfig[] = [];
  originalConfig: ComponentConfig[] = [];
  initialFormModel: any;
  context: DynamicContext;
  @ViewChild("dynamicForm") dynamicForm: DynamicFormComponent;
  @ViewChild("dynamicView") dynamicView: DynamicViewComponent;

  get organization(): SupplierOrganization {
    return this.profileService.supplierModel.SupplierProfile
      .SupplierOrganization;
  }

  set organization(val) {
    this.profileService.supplierModel.SupplierProfile.SupplierOrganization = val
  }

  get clearBuyerBI_DBA() {
    return this.organizationStatus.BuyerStepBI_BDA_1;
  }

  get isView() {
    if (!this.profileService.isView) {
      this.getConfigsInfo = this.originalConfig;
    }
    return this.profileService.isView;
  }

  isNew = true;
  alert = false;

  vm: VMController<SupplierOrganization> = new VMController();
  isResubmitDisabled = true;
  constructor(
    private router: Router,
    private onBoardingService: OnBoardingService,
    private commonService: CommonService,
    private profileService: ProfileService,
    private dialogService: DialogService,
    private supplierService: SupplierService,
    private metadataService: MetadataService,
    private dictionaryService: DictionaryService,
    private auth: AuthService,
    private loadingService: LoadingService,
    private languageService: LanguageService,
    private supplierCommonService: SupplierCommonService,
    private dialog: MatDialog,
    private launchDarklyService:LaunchDarklyService
  ) {
    this.launchDarklyService.ldChange.subscribe(any => {
          console.log("Trigger refresh data")
          this.refreshToggle()
      })
    this.profileService.stepChangeEvent.emit(2);
  }
  refreshToggle():void{
      this.isNewVersion=this.launchDarklyService.getToggle(LaunchDarklyService.LD_ISNEWVERSION)==="true"
    }

  async ngOnInit() {
    this.loadingService.showLoading();
    this.onBoardingService.supplierCode = this.profileService.supplierCode;

    await this.onBoardingService.loadSupplierRequestForm();
    this.context = new DynamicContext();
    if (this.isView) {
      this.context.mode = "formView";
    }
    this.context.set("originalOrganization", cloneDeep(this.organization));
    // load current login user's task
    await this.profileService.loadUserTasks();
    await this.supplierCommonService.SetContextValue(this.context);
        this.getConfigsInfo = await this.metadataService.getPageConfiguration(
      "seller-profile-organazition"
    );
    this.originalConfig = cloneDeep(this.getConfigsInfo);
    this.profileService.current_step = 2;
    this.commonService.ExitEvent.subscribe((e) => {
      this.vm.setOriginal(this.dynamicForm.originalValues());
      this.vm.setCurrent(this.dynamicForm.values());
      if (this.vm.isDirty()) {
        e.preventDefault();
        // Chrome requires returnValue to be set
        e.returnValue = "";
      }
    });
    this.context.set(
      "parentModel",
      cloneDeep(this.profileService.supplierModel)
    );
    if (!this.isView) {
      this.dynamicForm.subscribeToFormChange(() => {
        setTimeout(() => {
          this.isResubmitDisabled =
            JSON.stringify(this.dynamicForm.originalValues()) ===
            JSON.stringify(this.dynamicForm.values());
        });
      });
      this.dynamicForm.subscribeToModelChange(
        "SupplierRegisteredCountry",
        () => {
          if (this.dynamicForm.formGroup.get("UploadRegistrationDocuments")) {
            this.dynamicForm.formGroup
              .get("UploadRegistrationDocuments")
              .updateValueAndValidity({ onlySelf: true, emitEvent: true });
          }
        }
      );
      this.dynamicForm.subscribeToModelChange(
        "BusinessRegistrationType",
        () => {
          if (this.dynamicForm.formGroup.get("UploadRegistrationDocuments")) {
            this.dynamicForm.formGroup
              .get("UploadRegistrationDocuments")
              .updateValueAndValidity({ onlySelf: true, emitEvent: true });
          }
        }
      );
      this.dynamicForm.subscribeToModelChange("BusinessRegistrationNb", () => {
        if (this.dynamicForm.formGroup.get("UploadRegistrationDocuments")) {
          this.dynamicForm.formGroup
            .get("UploadRegistrationDocuments")
            .updateValueAndValidity({ onlySelf: true, emitEvent: true });
        }
      });
    }
    this.loadingService.hideLoading();
    if (!this.isView && showGlobalPrepopulateData ){
      this.formAutofill()
    }
  }

  getAllFieldSet(config){
    let finalData = []
    function fetchFields(items){
        items.forEach(item => {
            let itemCopy = Object.assign({}, item)
            if (itemCopy.name){
                delete itemCopy.fieldset
                finalData.push(itemCopy)
            }
            if(item.fieldset && item.fieldset.length){
              fetchFields(item.fieldset)
            }
        })
    }
    fetchFields(config)
    return finalData
  }

  async formAutofill() {
    let payload = {
      supplierCode: this.profileService.supplierCode,
      SupplierCompanyName: this.profileService.supplierModel.SupplierProfile.SupplierGeneral.CompanyName,
     supplierTsmId: this.profileService.supplierModel.Mapping.TsmId,
         countryServed: this.profileService.supplierModel.RequestForm.RequestorDetailsInfo.SupplierCountry,
      RoleCode: this.auth.passport.supplierMember.RoleCode
    }
    if(payload.supplierCode && payload.supplierTsmId){
      let getGlobalQAs;
      if (localStorage.getItem("globalqa") == null) {
        const getGlobalQAs = await this.supplierService.getGlobalFieldData(payload);
        localStorage.setItem("globalqa", JSON.stringify(getGlobalQAs))
      }
      else {
        getGlobalQAs = JSON.parse(localStorage.getItem("globalqa"));
      }
      if (this.getConfigsInfo && this.getConfigsInfo.length && getGlobalQAs) {
      const configFields = this.getAllFieldSet(this.getConfigsInfo)
        configFields.forEach(field => {
          if (field['global']) {
            const filterAnwsers = getGlobalQAs.filter((question) => question['QuestionName'] == field['name'])
            this.globalAnwsers.push(...filterAnwsers)
          }
        })
    }
    let organizationData = Object.assign({}, this.organization) 
    this.globalAnwsers.forEach((anwserItem) => {
      if (anwserItem != null && anwserItem != undefined) { 
        if((this.organization[anwserItem['QuestionName']] == "") || (this.organization[anwserItem['QuestionName']] == null) || (this.organization[anwserItem['QuestionName']] == undefined)){    
          organizationData[anwserItem['QuestionName']] = anwserItem['Answer']
        }
      }
    })
    this.organization = organizationData  
    // this is to copy general company address data to registration docs data
    let updatedRegistrationDocuments = [];
    if(this.profileService.supplierModel.SupplierProfile.SupplierGeneral.UploadCompanysAddress && this.profileService.supplierModel.RequestForm.RequestorDetailsInfo.SpendCommodityGroup == "22") {
      updatedRegistrationDocuments = this.profileService.supplierModel.SupplierProfile.SupplierGeneral.UploadCompanysAddress;
      this.organization = {
        ...this.organization,
        UploadRegistrationDocuments: updatedRegistrationDocuments
      };
    }

    setTimeout(() => {
      console.log('formAutofill Calling updateFormValues');  
      this.dynamicForm.updateFormValues()
      this.initialFormModel = trim(this.dynamicForm.values());
      if(updatedRegistrationDocuments && updatedRegistrationDocuments.length > 0) {
        this.initialFormModel.UploadRegistrationDocuments = organizationData.UploadRegistrationDocuments;
      }
    });
  }
  setTimeout(() => {   
         // In Future need to get localStorage value from database when userlogin into the system 
    if(!localStorage.getItem('organizationsaved') && localStorage.getItem('organizationsaved') !== 'yes') {
        this.SaveFirsttime();
      }           
    console.log("settimeout");
    const elements: HTMLInputElement[] = [];

    for (let gobalVal of this.globalAnwsers) {
      let formControlType: string;

      if (gobalVal['AnswerInputField'] === 'dropdown') {
        formControlType = 'select';
      } else if (gobalVal['AnswerInputField'] === 'input') {
        formControlType = 'input';
      } else if (gobalVal['AnswerInputField'] === 'radio') {
        formControlType = 'radio';
      }

      const actualElement = document.querySelector<HTMLInputElement>(`[id^="${gobalVal['QuestionName']}${formControlType}"]`);

      if (actualElement) {
        elements.push(actualElement);
      }
    }

    for (const element of elements) {
      element.addEventListener("change", (e) => {
        const targetElement = e.currentTarget as HTMLElement;
        if (targetElement) {
          targetElement.style.border = 'solid 1px #414187';
        }
      });
    }
  });
}

highlightInput() {
  const selectedElements = this.globalAnwsers.filter(globalval => this.dynamicForm.formGroup.controls[globalval.QuestionName]?.value !== globalval.Answer);

  if(selectedElements.length > 0) {
   this.globalAnwsers.forEach(eachItem => {
    const formControl = this.formControlType(eachItem)
    let actualElement: HTMLInputElement = document.querySelector<HTMLInputElement>(`[id^="${eachItem['QuestionName']}${formControl}"]`);
     if (actualElement) {
      actualElement = (formControl === 'label' && eachItem['QuestionName'] === 'TypeOfCompany') ?  actualElement.parentElement.children[1] as HTMLInputElement  : actualElement;
      actualElement.style.border = this.formControlType(eachItem) === 'label' ? 'none' : 'solid 1px #414187';
      this.dynamicForm.formGroup.controls[eachItem.QuestionName].setValue(eachItem.Answer);
     }
  });
  setTimeout(() => {
    
   selectedElements.forEach(element => {
    const formControl = this.formControlType(element)
    let actualElement = document.querySelector<HTMLInputElement>(`[id^="${element['QuestionName']}${this.formControlType(element)}"]`);

     if (actualElement) {
      actualElement = (formControl === 'label' && element['QuestionName'] ==='TypeOfCompany') ?  actualElement.parentElement.children[1] as HTMLInputElement : actualElement;
      actualElement.style.border = '2px solid orange';
     }
   }); 
  },1000)
  }
}

formControlType(globalValue) {
  const inputField = globalValue['AnswerInputField'];
  switch (inputField) {
    case 'dropdown':
    case 'radio':
    case 'uploader':
      return 'label';
    case 'input':
    case 'textbox':
      return 'input';
    default:
      return '';
  }
}
isGlobalAnswersChange(initglobalanswers, changedglobalanswers): boolean {
  const hasChanges = initglobalanswers.some(
    (item) =>
      changedglobalanswers[item.QuestionName] &&
      changedglobalanswers[item.QuestionName].value !== item.Answer
  );
  return hasChanges;
}
  openGlobalPopup() {
    this.dialogRef = this.dialog.open(GlobalPopupComponent, {
      width: '450px',
    });

    this.dialogRef.afterClosed().subscribe(result => {
      if (result === 'onConfirmClick') {
        if (this.isGlobalAnswersChange(this.globalAnwsers, this.dynamicForm.formGroup.controls)) {
          this.saveDraft().then((data: boolean) => {
            if (data) {
              this.vm.saved();
              this.router.navigate([
                `supplier/profile/${this.profileService.supplierCode}/step3`,
              ]);
            }
          });
        }

      } else if (result === 'onCancelClick') {
        this.highlightInput();
        for (this.getglobalVal of this.globalAnwsers) {
          const supplierControl =
            this.dynamicForm.formGroup.controls[this.getglobalVal.QuestionName];
          supplierControl?.setValue(this.getglobalVal.Answer);
        }
      }
    });
  }

  openGlobalPopupForResubmit() {
    this.dialogRef = this.dialog.open(GlobalPopupComponent, {
      width: '450px',
    });

    this.dialogRef.afterClosed().subscribe(result => {
      if (result === 'onConfirmClick') {
        if (this.isGlobalAnswersChange(this.globalAnwsers, this.dynamicForm.formGroup.controls)) {
          this.changeRequest().then((data: boolean) => {
            if (data) {
              this.vm.saved();
              this.dialogService
                .dialog(
                  this.languageService.getValue(
                    "static.supplier.components.general.change_request"
                  ),
                  ButtonCommands.Ok,
                  DialogType.success,
                  this.languageService.getValue(
                    "static.supplier.components.general.please_note"
                  )
                )
                .then((result: boolean) => {
                  this.router.navigate([`supplier/landing`]);
                });
            }
          });
        }
      } else if (result === 'onCancelClick') {
        this.highlightInput();
        for (this.getglobalVal of this.globalAnwsers) {
          const supplierControl =
            this.dynamicForm.formGroup.controls[this.getglobalVal.QuestionName];
          supplierControl.setValue(this.getglobalVal.Answer);
        }
      }
    });
  }

  checkAndOpenGlobalPopup() {
    const globalDirty = this.globalAnwsers.some(globalAnswerVal => {
      const control = this.dynamicForm.formGroup.controls[globalAnswerVal.QuestionName];
      if(control && this.dynamicForm.formGroup.controls[globalAnswerVal.QuestionName].value !== globalAnswerVal.Answer) {
        return true
      } else {
        return false
      }
    });
    return globalDirty;
  }

  async canDeactivate() {
    if (this.isView) {
      return true;
    }
    this.vm.setOriginal(this.dynamicForm.originalValues());
    this.vm.setCurrent(this.dynamicForm.values());
    if (this.vm.isDirty()) {
      const result = await this.dialogService.saveConfirm();
      if (result === "save") {
        if (!(await this.saveDraft())) {
          return false;
        }
      }
      if (result === "cancel") {
        return false;
      }
    }
    return true;
  }
  validate() {
    if (this.isView) {
      return true;
    } else {
      return this.dynamicForm.valide();
    }
  }

  get showResubmitBtn() {
    if (this.dynamicForm) {
      if (
        !this.isView &&
        !this.dynamicForm.disabled &&
        this.dynamicForm.formGroup
      ) {
        this.isResubmitDisabled =
          JSON.stringify(this.dynamicForm.originalValues()) ===
          JSON.stringify(this.dynamicForm.values());
      }
    }
    return this.profileService.checkResubmit();

    // workflow comments: remove supplier change request
    // return (this.apReviewStatus === AP_ReviewStatus.SST_reject || this.profileService.supplierModel.Mapping.status === 'Onboarded')
    //   && !this.isView && !this.profileService.checkIsMigrateSubmit()
  }
  async btnNext() {
    this.router.navigate([
      `supplier/profile/${this.profileService.supplierCode}/step3`,
    ]);
  }
  async resubmit() {
    this.context.changeSubmitState();
    this.dynamicForm.removeFormTouch();
    if (!this.validate()) {
      return;
    }
    // resubmit
    if(this.checkAndOpenGlobalPopup()){
      this.openGlobalPopupForResubmit();
    }

    if (
      this.profileService.getUserTaskByTaskKey(AllTaskKey.SST_rejectToSupplier)
    ) {
      this.resubmitForm().then((data: boolean) => {
        if (data) {
          this.vm.saved();
          this.dialogService
            .dialog(
              this.languageService.getValue(
                "static.supplier.components.organization.change_request"
              ),
              ButtonCommands.Ok,
              DialogType.success,
              this.languageService.getValue(
                "static.supplier.components.organization.please_note"
              )
            )
            .then((result: boolean) => {
              this.router.navigate([`supplier/landing`]);
            });
        }
      });
    } else {
      this.changeRequest().then((data: boolean) => {
        if (data) {
          this.vm.saved();
          this.dialogService
            .dialog(
              this.languageService.getValue(
                "static.supplier.components.organization.change_request"
              ),
              ButtonCommands.Ok,
              DialogType.success,
              this.languageService.getValue(
                "static.supplier.components.organization.please_note"
              )
            )
            .then((result: boolean) => {
              this.router.navigate([`supplier/landing`]);
            });
        }
      });
    }
}

  async saveAndNavigate() {
    if(this.checkAndOpenGlobalPopup()){
      this.openGlobalPopup();
    }
    else{
    this.saveDraft().then((data: boolean) => {
      if (data) {
        this.vm.saved();
        this.router.navigate([
          `supplier/profile/${this.profileService.supplierCode}/step3`,
        ]);
       }
    });
  }
  }

  // resubmit request form
  async resubmitForm(): Promise<boolean> {
    this.context.changeSubmitState();
    this.dynamicForm.removeFormTouch();
    if (!this.validate()) {
      return false;
    }
    if (this.alert) {
      return false;
    }
    await this.updateSupplierMapping();
    // get executable task GUID: taskInstId
    this.profileService.getResubmitTask();
    if (!this.profileService.supplierModel.Mapping.executeTask) {
      return false;
    }
    if (this.isNewVersion) {
      this.bindDataToSupplierProfileModel()
      await this.saveNewresubmit();
    } else {
      await this.saveExistingresubmit();
    }
    return true;
  }
  async saveExistingresubmit() {
    const saveUrl = `${environment.gateway}${LoadingUrlMapping.resubmitSupplierProfile}`;
    this.loadingService.openLoading(saveUrl);
    await this.profileService.resubmitForm();
  }
  async saveNewresubmit() {
    const saveUrl = `${environment.supplierGateway}${LoadingUrlMapping.supplierProfileResubmit}`;
    this.loadingService.openLoading(saveUrl);
    await this.profileService.newresubmitForm();
    this.loadingService.closeLoading()
  }

  bindDataToSupplierProfileModel() {
    this.profileService.createSupplierProfileModel();
    this.profileService.supplierProfileModel.SupplierProfile.SupplierOrganization = this.profileService.supplierModel.SupplierProfile.SupplierOrganization;
    this.profileService.supplierProfileModel.Mapping = this.profileService.supplierModel.Mapping;
    let initalSupplierModel: SupplierOrganization = this.initialFormModel
    // let initalSupplierModel: SupplierOrganization = trim(this.dynamicForm.originalValues());
    let currentValues: SupplierOrganization = trim(this.dynamicForm.values());
     // In Future need to get localStorage value from database when userlogin into the system 
	    // and this change is done as part of 555132 Bug
      if(!localStorage.getItem('organizationsaved') && localStorage.getItem('organizationsaved') !== 'yes') {
	      this.profileService.supplierProfileModel.ChangeHistory = this.commonService.getChangeHistoryForFirstSave(initalSupplierModel, currentValues, "SupplierOrganization");
	    } else {
        this.profileService.supplierProfileModel.ChangeHistory = this.commonService.getChangeHistory(initalSupplierModel, this.profileService.supplierProfileModel.SupplierProfile.SupplierOrganization, "SupplierOrganization")
      }
  }

  // resubmit request form
  async changeRequest(): Promise<boolean> {
    this.context.changeSubmitState();
    this.dynamicForm.removeFormTouch();
    if (!this.validate()) {
      return false;
    }
    if (this.alert) {
      return false;
    }
    await this.updateSupplierMapping();
    if (this.isNewVersion) {
      this.bindDataToSupplierProfileModel();
      await this.newChangeRequestform();
    } else {
      await this.existingChangeRequestform();
    }
    return true;
  }

  async newChangeRequestform(){
    const saveUrl = `${environment.supplierGateway}${LoadingUrlMapping.newRequestChangeSupplierProfile}`;
    this.loadingService.openLoading(saveUrl);
    await this.profileService.newchangeRequestForm(),
    this.loadingService.closeLoading()
  }

  async existingChangeRequestform(){
    const saveUrl = `${environment.gateway}${LoadingUrlMapping.requestChangeSupplierProfile}`;
    this.loadingService.openLoading(saveUrl);
    await this.profileService.changeRequestForm();
  }

  // save draft request form
  async saveDraft(): Promise<boolean> {
    this.context.changeSubmitState();
    this.dynamicForm.removeFormTouch();
    if (this.dynamicForm.formGroup.get("IsSupplierAnIndividualOrCompany") && this.dynamicForm.formGroup.get("IsSupplierAnIndividualOrCompany").value == ' ') {
      this.dynamicForm.formGroup.get("IsSupplierAnIndividualOrCompany").patchValue('');
    }
    if (!this.validate()) {
      return false;
    }
    if (this.alert) {
      return false;
    }
    await this.updateSupplierMapping();
    if (this.isNewVersion) {
      this.bindDataToSupplierProfileModel();
      if (JSON.stringify(this.profileService.supplierProfileModel.ChangeHistory)!="{}") {
        await this.saveNewDraft();
      }
    } else {
       await this.saveExistingDraft();
    }
    this.loadingService.closeLoading();
    this.loadingService.hideLoading();
    return true;
  }

  async saveNewDraft() {
    const saveUrl = `${environment.supplierGateway}${LoadingUrlMapping.organazitionSaveDraft}`;
    this.loadingService.openLoading(saveUrl);
    await this.profileService.saveOrganizationDraft();
  }

  async saveExistingDraft() {
    const saveUrl = `${environment.gateway}${LoadingUrlMapping.saveProfileDraft}`;
    this.loadingService.openLoading(saveUrl);
    await this.profileService.saveDraft();
  }

  async SaveFirsttime(): Promise<boolean> {
    this.profileService.supplierModel.SupplierProfile.SupplierOrganization = trim(
      this.dynamicForm.values()
    );
    if (this.isNewVersion) {
      this.bindDataToSupplierProfileModel();
      await this.saveNewDraft();
    } else {
       await this.saveExistingDraft();
    }
    localStorage.setItem('organizationsaved', 'yes');
    this.dynamicForm.updateFormValues();
    this.loadingService.closeLoading();
    this.loadingService.hideLoading();
    return true;
  }

  // update supplier mapping info
  private async updateSupplierMapping() {
    this.profileService.supplierModel.SupplierProfile.SupplierOrganization =
      trim(this.dynamicForm.values());
    this.profileService.supplierModel.Mapping.profile.BRN =
      this.organization.BusinessRegistrationNb;
    this.profileService.supplierModel.Mapping.searchTerm.BRN =
      this.organization.BusinessRegistrationNb || this.organization.BRN;
    if (this.profileService.profile_step < this.profileService.current_step) {
      this.profileService.profile_step = 2;
    }
  }

  async checkOnblur() {
    if (this.organization.BusinessRegistrationNb === "") {
      return;
    }
    const result = await this.profileService.checkField(
      this.organization.BusinessRegistrationNb
    );
    if (!result) {
      this.alert = true;
    } else {
      this.alert = false;
    }
  }
}
