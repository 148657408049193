// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ui-confirmdialog {
  width: 65rem;
}

.ui-dialog {
  position: fixed;
  padding: 1.4rem;
  border: 1px solid #D5D5D5;
  background: #fcfcfc;
}

.header-icon {
  border: 2px solid #fcfcfc;
  margin-top: -60px;
  display: inline-block;
  background: #ffffff;
  vertical-align: middle;
}

.header-icon-success {
  color: #00a5a6;
}

.header-icon-warning {
  color: #CC1E32;
}

.header-icon-question {
  color: #AB8C30;
}

.header {
  color: #333;
  font-size: 18px;
  font-weight: medium;
}

.content {
  margin-bottom: 2.1rem;
  padding: 2rem;
  max-height: 30rem;
  overflow-y: auto;
}

.footer {
  text-align: center;
}

.ui-shadow {
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.3);
}

.ui-corner-all {
  border-radius: 10px;
}

.text-center {
  text-align: center !important;
}

.right-button {
  text-align: right !important;
  margin-top: 2rem;
}

.spanDescInline {
  display: inline-block !important;
  font-weight: 100;
  margin-left: 5px;
}

.form-group span {
  margin-bottom: 1rem;
  font-weight: bold;
  color: #333;
  line-height: 1.25rem;
}

.selectReason {
  display: flex !important;
  flex-direction: row !important;
  align-items: flex-end !important;
}

.labelWidth {
  width: 10% !important;
}

.dropDownWidth {
  width: 60% !important;
}

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1200 !important;
}

.popup-content {
  background-color: #f5f5f5;
  border: 1px solid #050505;
  padding: 20px 30px;
  text-align: left;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 400px;
  max-width: 90%;
}
.popup-content p {
  font-size: 16px;
  color: #333;
  margin-bottom: 20px;
  line-height: 1.5;
}
.popup-content .button-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
.popup-content .button-container button {
  padding: 10px 30px;
  margin: 0 15px;
  border: 1px solid #050505;
  border-radius: 0;
  font-size: 14px;
  cursor: pointer;
}
.popup-content .button-container button.secondary {
  background-color: grey;
  color: white;
}
.popup-content .button-container button.secondary:hover {
  background-color: #f0f0f0;
  color: grey;
}

.is-invalid {
  border: 1px solid #d30e0e;
}

.is-mandatory {
  color: #d30e0e !important;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
