import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
  HostListener,
} from "@angular/core";
import { environment } from "src/environments/environment";
import { ProfileService } from "../../../modules/supplier/services/profile.service";

enum LanguageTopicTag {
  English = "Privacy Statement",
  Germany = "Datenschutzerklärung", 
  Italiy = "Informativa Privacy ",
  Spanish = "Declaración de privacidad",
  Portuguese = "Declaração de privacidade",
  French = "Déclaration sur la confidentialité",
  Vietnam = "Tuyên bố về quyền riêng tư",
  Thai= "คำชี้แจงสิทธิส่วนบุคคล",
  Indonesia = "pernyataan privasi ",
  Chinese = '隐私权声明',
  Japanese = 'プライバシーに関する声明',
  Brazil = "Declaração de privacidade",
  Mexico = "Declaración de privacidad",
  
}
enum LanguageOfClose {
  English = "Close",
  Germany = "Schließen",
  Italiy = "Chiudi",
  Spanish = "Cerrar",
  Portuguese = "Fechar",
  French = "Fermer",
  Vietnam = "Đóng",
  Thai = "ปิด",
  Indonesia = "Tutup",
  Chinese = '关闭',
  Japanese = 'クローズ',
  Brazil = "Fechar",
  Mexico = "Cerrar",
}
enum LanguageOfAccept {
  English = "Accept",
  Germany = "Akzeptieren",
  Italiy = "Accetta",
  Spanish = "Aceptar",
  Portuguese = "Aceitar",
  French = "J’accepte",
  Vietnam = "Chấp nhận",
  Thai = "ยอมรับ",
  Indonesia = "Terima",
  Chinese = '接受',
  Japanese = '承認',
  Brazil = 'Aceitar',
  Mexico = "Aceptar",
}
@Component({
  selector: "app-privacy-statement-poppage",
  templateUrl: "./privacy-statement-poppage.component.html",
  styleUrls: ["./privacy-statement-poppage.component.sass"],
})
export class PrivacyStatementPoppageComponent implements OnInit {
  @Input() footerLink: string;
  @Output() acceptEvent: EventEmitter<boolean>;
  @ViewChild("acceptScroll") acceptScroll: ElementRef;

  selectLanguageShow = false;
  TopicTag = LanguageTopicTag.English;
  Close = LanguageOfClose.English;
  Accept = LanguageOfAccept.English;
  selectedLanguage = "English";
  displayLanguageText = "English";
  languageArr = ["Bahasa Indonesia","Deutsch","English","Français","Italiano","Português","Español","ไทย","Tiếng Việt","简体中文","日本語", "Português (Brasil)", "LATAM Español"];
   // languageArr = ["English", "Deutsch", "Italiano", "Spanish", "Portuguese", "Français", "Vietnamese","Thai","Bahasa Indonesia","简体中文","日本語"];
  language = [];
  chinaGU: string;
  display:string;

  // acceptButtonDisabled: boolean
  // buttonDisabled: boolean

  constructor(
    private profileService: ProfileService
  ) {
    this.acceptEvent = new EventEmitter();
  }

  ngOnInit() {
    if(this.profileService.supplierModel.SupplierProfile.SupplierGeneral.Country == 'CN') {
      this.languageArr = ["English", "简体中文"];
    } 
    this.chinaGU = this.profileService.supplierModel.SupplierProfile.SupplierGeneral.Country;
    const countryServed = this.profileService.supplierModel.RequestForm.RequestorDetailsInfo.SupplierCountry;
    if(Array.isArray(countryServed) && countryServed.length === 1 && countryServed.includes('CN')) {
      this.selectLanguageClick("简体中文")
    }
    this.language = this.languageArr.sort(function(A,B) {
      return A.localeCompare(B)
    })

    // console.log(this.language,'===============>');

    if(environment.role === "supplier"){
      this.display  = 'text'
   }
    // console.log(this.language,'===============>');

    // this.acceptButtonDisabled = false
    // this.buttonDisabled = true
  }
  // @HostListener('scroll', ['$event'])
  // onscroll(event: any) {
  //   if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight - 100) {
  //     this.acceptButtonDisabled = true
  //   } else {
  //     this.acceptButtonDisabled = false
  //   }
  // }
  selectLanguageClick(ev: string) {
    this.selectedLanguage = ev;
    this.selectLanguageShow = false;
    this.displayLanguageText = ev;
    if (ev === "Deutsch") {
      ev = "Germany";
    } else if (ev === "Italiano") {
      ev = "Italiy";
    } else if (ev === "Français") {
      ev = "French";
    } else if (ev === "简体中文") {
      ev = "Chinese";
    } else if (ev === "Tiếng Việt") {
      ev = "Vietnam";
    } else if (ev === "Bahasa Indonesia") {
      ev = "Indonesia";
    }  else if (ev === "日本語") {
      ev = "Japanese";
    }else if (ev === "Português") {
      ev = "Portuguese";
    }else if (ev === "Español") {
      ev = "Spanish";
    }else if (ev === "ไทย") {
      ev = "Thai";
    }else if(ev === "Português (Brasil)"){
      ev = "Brazil";
    } else if(ev === "LATAM Español"){
      ev = "Mexico";
    }
    this.TopicTag = LanguageTopicTag[ev];
    this.Close = LanguageOfClose[ev];
    this.Accept = LanguageOfAccept[ev];
  }

  acceptModal(flag): void {
    // console.log(flag,'==================>');
    
    if (flag === "close") {
      this.acceptEvent.emit(false);
    } else if (flag === "accept") {
      this.acceptEvent.emit(true);
    }
  }
}
