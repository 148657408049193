import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
  HostListener,
  OnDestroy,
} from "@angular/core";
import {
  OnBoardingRequestInfoType,
  RequestFormStatus,
  OnBoardMessage,
  RoleName,
} from "src/app/interfaces/buyer";
import { Router } from "@angular/router";
import {
  AP_ReviewStatus,
  StatusType,
  SupplierPendingStatus,
  SupplierStatus,
  LoadingUrlMapping,
} from "src/app/interfaces/mapping";
import { CommonService } from "src/app/services/common.service";
import {
  DialogService,
  ButtonCommands,
  DialogType,
  ButtonItem,
  ButtonCommand,
} from "src/app/services/dialog.service";
import { showGlobalPrepopulateData} from "../../../../../../config/config";
import { OnBoardingService } from "../../../services/onboarding.service";
import { BuyerService } from "src/app/services/buyer.service";
import { RequestFormInfo } from "src/app/interfaces/requestForm";
import { cloneDeep, trim } from "src/app/utils";
import VMController from "src/app/interfaces/vm";
import { CanComponentDeactivate } from "src/app/can-deactivate.guard";
import { ProfileService } from "../../../../supplier/services/profile.service";
import { LoadingService } from "src/app/services/loading.service";
import { environment } from "src/environments/environment";
import {
  ProcessNames,
  SetupProcess,
  AllTaskKey,
  Task,
} from "src/app/interfaces/workflow";
import { MetadataService } from "src/app/services/metadata.service";
import { DynamicContext } from "src/app/dynamic-components/interfaces/DynamicContext";
import { UntypedFormGroup, Validators } from "@angular/forms";
import { ComponentConfig } from "src/app/dynamic-components/ComponentConfig";
import { DynamicFormComponent } from "src/app/dynamic-components/components/dynamic-form/dynamic-form.component";
import { AuthService } from "src/app/services/auth/auth.service";
import { DictionaryService } from "src/app/services/dictionary.service";
import { BuyerCommonService } from "src/app/modules/buyer/services/buyerCommonService";
import { LanguageService } from "src/app/dynamic-components/utils/language.service";
import { MatDialog } from '@angular/material/dialog';
import { GlobalPopupComponent } from "src/app/global-popup/global-popup.component";
import { SupplierModelForSaveDraft, createSupplierModelForSaveDraft } from "src/app/interfaces/supplierModelForSaveDraft";
import { LaunchDarklyService } from "src/app/services/launch-darkly.service";
import { isPplusUser } from "../../../services/verifyProcurementPlus";
import { Subscription } from "rxjs";

enum CheckCompanyCode {
  UK_Ltd = "3003",
  Ireland = "3100",
}
enum CheckSpendCategoryGroup {
  Restaurants = "9",
  Entertainment = "11",
  EventServices = "27",
  Transportation = "17",
  VideoGraphy = "32",
  VenueServices = "20",
}

@Component({
  selector: "app-onboarding-request-form",
  templateUrl: "./request-form.component.html",
  styleUrls: ["./request-form.component.sass"],
})
export class RequestFormComponent implements OnInit,OnDestroy,CanComponentDeactivate {
  getConfigsInfo: ComponentConfig[] = [];
  @ViewChild("dynamicForm") dynamicForm: DynamicFormComponent;
  private requestFormStatus: RequestFormStatus = {};
  get requestForm(): RequestFormInfo {
    return this.onBoardingService.supplierModel.RequestForm.RequestFormInfo;
  }
  get supplierCode(): string {
    return this.onBoardingService.supplierCode;
  }
  get isTerminate(): boolean {
    return this.onBoardingService.checkIsTerminate();
  }
  get isSaveContitue(): boolean {
    return this.onBoardingService.checkIsSaveContitue();
  }

  set requestForm(val) {
    this.onBoardingService.supplierModel.RequestForm
      .RequestFormInfo = val
  }

  onBoardingInfoType = OnBoardingRequestInfoType;
  vm: VMController<RequestFormInfo> = new VMController();
  supplierName: string
  isValidated: boolean;
  skipInterception: boolean = false;
  showAlertPanelO = false;
  showAlertPanel1 = false;
  showAlertPanel = false;
  AP_ReviewStatus = AP_ReviewStatus;
  SupplierPendingStatus = SupplierPendingStatus;
  SupplierStatus = SupplierStatus;
  disableTimeLimit = false;
  isDisabledSaveBtn = false;
  isResubmitDisabled = true;
  globalAnwsers = [];
  globalNameElement:any;
  checkSupplier:any;
  dialogRef: any;
  getglobalVal: any;
  env = environment.env;
  @ViewChild("supplierTooltip") supplierTooltip: ElementRef;
  @ViewChild("estimateSpendTooltip") estimateSpendTooltip: ElementRef;
  formGroup: UntypedFormGroup;
  context: DynamicContext;
  isNewVersion:boolean=true;
  initalRequestFormInfoModel: RequestFormInfo;
  supplierModelForSaveDraft:SupplierModelForSaveDraft=createSupplierModelForSaveDraft();
  isFirstLoad: boolean = true;
  currentApprover: boolean = false;
  private currentApproverSubscription: Subscription;
  constructor(
    private router: Router,
    private commonService: CommonService,
    private onBoardingService: OnBoardingService,
    private dialogService: DialogService,
    private buyerService: BuyerService,
    private profileService: ProfileService,
    private loadingService: LoadingService,
    private metadataService: MetadataService,
    private dictionaryService: DictionaryService,
    private auth: AuthService,
    private buyerCommonService: BuyerCommonService,
    private languageService: LanguageService,
    private dialog: MatDialog,
    private launchDarklyService:LaunchDarklyService
  ) {
    this.onBoardingService.stepChangeEvent.emit(2);

    this.launchDarklyService.ldChange.subscribe(any => {
      console.log("Trigger refresh data")
      this.refreshToggle()
    })
    this.currentApproverSubscription = this.commonService.currentApprover$.subscribe(
      (newValue: boolean) => {
        this.currentApprover = newValue;
      }
    );
  }

   refreshToggle():void{
      this.isNewVersion=this.launchDarklyService.getToggle(LaunchDarklyService.LD_ISNEWVERSION)==="true";
    }

  async ngOnInit() {
    this.refreshToggle()
    this.loadingService.showLoading();
    this.skipInterception = false;
    this.context = new DynamicContext();
    await this.buyerCommonService.SetContextValue(this.context);
    this.onBoardingService.current_step = 2;
    this.onBoardingService.supplierModel.Mapping.paymentTermsIsChange = false;
    this.checkSupplier = await this.buyerService.getExistingSupplier(this.supplierCode);

    // workflow load current login user's tasks
    await this.onBoardingService.loadUserTasks();
    this.isValidated = false;
    if (
      this.onBoardingService.getUserTaskByKey(
        ProcessNames.generic_supplierSetup,
        SetupProcess.requestor_draft
      )
    ) {
      await this.onBoardingService.loadSupplierProfile();
    }
    this.context.on("ApproveAttachments", (fileList: any) => {
      if (fileList !== "") {
        this.dynamicForm.formGroup
          .get("ApproveAttachments")
          .patchValue(fileList);
      }
    });
    this.context.on("ProcurementApproval", (fileList: any) => {
      if (fileList !== "") {
        this.dynamicForm.formGroup
          .get("ProcurementApproval")
          .patchValue(fileList);
      }
    });
    this.context.set(
      "SpendCategoryGroup",
      this.onBoardingService.supplierModel.RequestForm.RequestorDetailsInfo
        .SpendCategoryGroup
    );
    await this.buyerCommonService.SetContextValue(this.context);
    if (!this.requestForm.AddRequestFormAttachments) {
      this.requestForm.AddRequestFormAttachments = [];
    }
    const SpendCategoryGroup =
      this.onBoardingService.supplierModel.RequestForm.RequestorDetailsInfo
        .SpendCategoryGroup;
    const GeographicalCode =
      this.onBoardingService.supplierModel.RequestForm.RequestorDetailsInfo
        .SupplierGeographical;
    this.context.set("GeographicalCode", GeographicalCode);
    SpendCategoryGroup === "2"
      ? (this.requestFormStatus.isShowUploadMail = true)
      : (this.requestFormStatus.isShowUploadMail = false);

    this.disableTimeLimit = true;

    this.loadCheckQuestion();
    this.dynamicForm.subscribeToFormChange(() => {
      setTimeout(() => {
        this.isResubmitDisabled =
          JSON.stringify(this.dynamicForm.originalValues()) ===
          JSON.stringify(this.dynamicForm.values());
      });
    });
    this.commonService.ExitEvent.subscribe((e) => {
      this.vm.setOriginal(this.dynamicForm.originalValues());
      this.vm.setCurrent(this.dynamicForm.values());
      if (this.vm.isDirty()) {
        e.preventDefault();
        // Chrome requires returnValue to be set
        e.returnValue = "";
      }
    });

    // Match standard payment term
    const result: any = await this.buyerService.getPaymentTermList({
      companyCode:
        this.onBoardingService.supplierModel.RequestForm.RequestorDetailsInfo
          .CompanyCode,
      geographicalUnit:
        this.onBoardingService.supplierModel.Mapping.geographicalUnit,
      category:
        this.onBoardingService.supplierModel.RequestForm.RequestorDetailsInfo
          .SpendCategoryGroup,
      spendCommodityGroup:
        this.onBoardingService.supplierModel.RequestForm.RequestorDetailsInfo
          .SpendCommodityGroup,
      // enterpriseType:
      //   this.onBoardingService.supplierModel.SupplierProfile
      //     .SupplierOrganization.EnterpriseType,
    });
    if (result && result.isSuccess) {
      const standardPaymentTerms = result.data;
      this.context.set("standardPaymentTerms", standardPaymentTerms);

      if (
        Array.isArray(this.onBoardingService.supplierModel.RequestForm?.RequestFormInfo?.PaymentTermList)
      ) {
        this.onBoardingService.supplierModel.RequestForm.RequestFormInfo.PaymentTermList.forEach(
          (paymentTermsItem: any) => {
            const standardPaymentTerm = standardPaymentTerms.find(
              (p: any) => p.CompanyCode === paymentTermsItem.CompanyCode
            );
            if (standardPaymentTerm) {
              paymentTermsItem.TimeLimit = this.languageService.getValue(
                standardPaymentTerm.LanguageKey
              );
            }
          }
        );
      }
    }

    this.context.set(
      "parentModel",
      cloneDeep(this.onBoardingService.supplierModel)
    );
    this.getConfigsInfo = await this.metadataService.getPageConfiguration(
      "buyer-requestform-step2"
    );
    this.dynamicForm.subscribeToModelChangeFordialog("ProcurementPlus",
      async (value) => {
        if (!(this.onBoardingService.supplierModel.RequestForm.RequestFormInfo.ProcurementPlus === "N" && this.isFirstLoad)) {
          await this.requestorPopupForBSR(value);
        } else {
          this.isFirstLoad = false;
        }
    })

    this.loadingService.hideLoading();
    // console.log('MODE', this.onBoardingService.mode)
    if ((this.onBoardingService.mode == 'edit' || this.onBoardingService.mode == 'create') && showGlobalPrepopulateData ){
        this.formAutofill()
    }

    this.initalRequestFormInfoModel = this.onBoardingService.supplierModel.RequestForm.RequestFormInfo;
  
  if(this.checkPplus()){await this.commonService.checkAccess(this.auth.passport.profile.email, this.supplierCode)};
  }

  ngOnDestroy() {
    if (this.currentApproverSubscription) {
      this.currentApproverSubscription.unsubscribe();
    }
  }

  isSupplierNameDuplicate(): boolean {
    this.supplierName =
      this.onBoardingService.supplierModel.Mapping.searchTerm.supplierName;
    return this.supplierName.startsWith("DUPLICATE-");
  }

  async formAutofill(){
    // console.log("formAutofill - supplierCode ", this.supplierCode);
    // console.log("formAutofill - supplierTsmId ",this.onBoardingService.supplierModel.Mapping.TsmId);

    let payload = {
      supplierCode: this.supplierCode, 
      SupplierCompanyName: this.onBoardingService.supplierModel.RequestForm.RequestorDetailsInfo.SupplierCompanyName,
      supplierTsmId: this.onBoardingService.supplierModel.Mapping.TsmId,
      countryServed: this.onBoardingService.supplierModel.RequestForm.RequestorDetailsInfo.SupplierCountry,
      RoleCode: this.auth.passport.buyer.RoleCode
    }
    // console.log('formAutofill Global payload', payload);
    // console.log('Request form object in step2 : ', this.requestForm);
    if(payload.supplierCode && payload.supplierTsmId){
      let getGlobalQAs;
      if (localStorage.getItem("globalqa") == null) {
        const getGlobalQAs = await this.buyerService.getGlobalFieldData(payload);
        localStorage.setItem("globalqa", JSON.stringify(getGlobalQAs))
      }
      else {
        getGlobalQAs = JSON.parse(localStorage.getItem("globalqa"));
      }
      // console.log('formAutofill getAllAnswers', getGlobalQAs)
      if (this.getConfigsInfo && this.getConfigsInfo.length && getGlobalQAs) {
        this.getConfigsInfo.forEach(item => {
          if (item['type'] == 'layout'){
            item['fieldset'].forEach(field => {
              // console.log('field', field)
              // console.log('Global', field['global'])
              if (field['global']){
                const filterAnwsers = getGlobalQAs.filter((question) => question['QuestionName'] == field['name'])
                this.globalAnwsers.push(...filterAnwsers)
              }
            })
          }
        })
      }

      // console.log('formAutofill globalAnwsers', this.globalAnwsers)
      let requestFormInfoData = Object.assign({}, this.requestForm)
      this.globalAnwsers.forEach((anwserItem) => {
        // console.log('formAutofill anwserItem', anwserItem);
        if(anwserItem != null && anwserItem != undefined ){
          // console.log('formAutofill requestFormInfo', this.requestForm);  
          if((this.requestForm[anwserItem['QuestionName']] == "") || (this.requestForm[anwserItem['QuestionName']] == null) || (this.requestForm[anwserItem['QuestionName']] == undefined)){
            // console.log('formAutofill Prepopulate Values', anwserItem['Answer']);    
            requestFormInfoData[anwserItem['QuestionName']] = anwserItem['Answer']
          }
        }  
      })
      this.requestForm = requestFormInfoData
      // console.log('formAutofill this.requestorDetailsInfo', this.requestForm);   
      setTimeout(() => {
        console.log('formAutofill Calling updateFormValues');  
        this.dynamicForm.updateFormValues()
      }); 
    }
    setTimeout(() => {              
      console.log("settimeout");
      const elements: HTMLInputElement[] = [];
    
      for (let gobalVal of this.globalAnwsers) {
        let formControlType: string;
    
        if (gobalVal['AnswerInputField'] === 'dropdown') {
          formControlType = 'select';
        } else if (gobalVal['AnswerInputField'] === 'input') {
          formControlType = 'input';
        } else if (gobalVal['AnswerInputField'] === 'radio') {
          formControlType = 'radio';
        }
    
        const actualElement = document.querySelector<HTMLInputElement>(`[id^="${gobalVal['QuestionName']}${formControlType}"]`);
    
        if (actualElement) {
          elements.push(actualElement);
        }
      }
    
      for (const element of elements) {
        element.addEventListener("change", (e) => {
          // console.log("Field change??? ===>", e);
          const targetElement = e.currentTarget as HTMLElement;
          if (targetElement) {
            targetElement.style.border = 'solid 1px #414187';
          }
        });
      }
    });
  }
 
  highlightInput() {
    const elements: HTMLInputElement[] = [];

    for (let gobalVal of this.globalAnwsers) {
      let formControlType: string;

      if (gobalVal['AnswerInputField'] === 'dropdown') {
        formControlType = 'select';
      } else if (gobalVal['AnswerInputField'] === 'input') {
        formControlType = 'input';
      } else if (gobalVal['AnswerInputField'] === 'radio') {
        formControlType = 'radio';
      }

      const actualElement = document.querySelector<HTMLInputElement>(`[id^="${gobalVal['QuestionName']}${formControlType}"]`);

      if (actualElement) {
        elements.push(actualElement);
      }
    }''

    for (const element of elements) {
      element.style.border = '2px solid orange';
    }
  }

  openGlobalPopup() {
    this.dialogRef = this.dialog.open(GlobalPopupComponent, {
      width: '450px',
    });
  
    this.dialogRef.afterClosed().subscribe(result => {
      for (this.getglobalVal of this.globalAnwsers) {
        const supplierControl = this.dynamicForm.formGroup.controls[this.getglobalVal.QuestionName];
        if (supplierControl) {
          if (supplierControl.dirty) {
            if (result === 'onConfirmClick') {
         this.router.navigate([`buyer/onboarding/${this.onBoardingService.supplierCode}/step3`,]);
        } else if (result === 'onCancelClick') {
          supplierControl.setValue(this.getglobalVal.Answer);
          this.highlightInput();
        }
      }
    }
  }
});
}
  
  checkAndOpenGlobalPopup() {
      const globalDirty = this.globalAnwsers.some(globalAnswerVal => {
        const control = this.dynamicForm.formGroup.controls[globalAnswerVal.QuestionName];
        return control && control.dirty;
    });
      if (globalDirty) {
        this.openGlobalPopup();
    }
    else if(!globalDirty){
      this.router.navigate([`buyer/onboarding/${this.onBoardingService.supplierCode}/step3`,]);
    }
    }

  async canDeactivate() {
    if(this.skipInterception){
      return true;
    }
    this.vm.setOriginal(this.dynamicForm.originalValues());
    this.vm.setCurrent(this.dynamicForm.values());
    if (this.vm.isDirty()) {
      const result = await this.dialogService.saveConfirm();
      if (result === "save" && this.isSaveContitue) {
        if (!(await this.saveDraft())) {
          return false;
        }
      }
      if (result === "cancel") {
        return false;
      }
    }

    return true;
  }

  loadCheckQuestion() {
    this.requestFormStatus.isEnterRegistrationNumValidated =
      this.transformQuestion(this.requestForm.WhetherChooseSuppliers);
    this.requestFormStatus.isPurchaseValidated = this.transformQuestion(
      this.requestForm.BuyingRelationship
    );
  }

  transformQuestion(value) {
    if (value === "12" || value === "11") {
      return true;
    }
    return false;
  }

  validate() {
    return this.dynamicForm.valide();
  }

  plannedDateSetValue() {
    const formValue = trim(this.dynamicForm.values());
    const date1 = formValue.plannedStartDate;
    const startCd = new Date(date1);
    const startDay =
      startCd.getDate() > 9 ? startCd.getDate() : "0" + startCd.getDate();
    const startYear = startCd.getFullYear();
    const startMonth =
      startCd.getMonth() + 1 > 9
        ? startCd.getMonth() + 1
        : "0" + (startCd.getMonth() + 1);
    const date2 = formValue.plannedEndDate;
    const endCd = new Date(date2);
    const endDay =
      endCd.getDate() > 9 ? endCd.getDate() : "0" + endCd.getDate();
    const endYear = endCd.getFullYear();
    const endMonth =
      endCd.getMonth() + 1 > 9
        ? endCd.getMonth() + 1
        : "0" + (endCd.getMonth() + 1);
    const startDate = startMonth + "/" + startDay + "/" + startYear;
    const endDate = endMonth + "/" + endDay + "/" + endYear;

    if (formValue.plannedStartDate) {
      this.onBoardingService.supplierModel.RequestForm.RequestFormInfo.plannedDate =
        startDate + "-" + endDate;
    }
  }

  async saveAndNavigate() {
    this.saveDraft().then((data: boolean) => {
      if (data) {
        this.vm.saved();
        this.checkAndOpenGlobalPopup();
      }
    });
  }

  async resubmit() {
    this.resubmitForm().then((data: boolean) => {
      if (data) {
        this.vm.saved();
        this.dialogService
          .dialog(
            `Your change request has been successfully submitted.`,
            ButtonCommands.Ok,
            DialogType.success,
            "Please Note"
          )
          .then((result: boolean) => {
            this.router.navigate([`buyer`]);
          });
      }
    });
  }

  // update supplier mapping info
  private async updateSupplierMapping() {
    this.onBoardingService.supplierModel.RequestForm.RequestFormInfo = trim(
      this.dynamicForm.values()
    );
    if (
      this.onBoardingService.requestor_step <
      this.onBoardingService.current_step
    ) {
      this.onBoardingService.requestor_step = 2;
    }
  }

  // save draft request form
  async saveDraft(): Promise<boolean> {
    this.context.changeSubmitState();
    this.dynamicForm.removeFormTouch();
    if (!this.validate()) {
      return false;
    }
    await this.updateSupplierMapping();
   

    this.onBoardingService.supplierModel.RequestForm.RequestFormInfo = trim(
      this.dynamicForm.values()
    );
    this.plannedDateSetValue();
    if (
      JSON.stringify(
        this.onBoardingService.supplierModel.RequestForm.RequestFormInfo
          .PaymentTermList
      ) !== JSON.stringify(this.dynamicForm.model.PaymentTermList)
    ) {
      this.onBoardingService.supplierModel.Mapping.paymentTermsIsChange = true;
    }

    if(this.isNewVersion){
      this.setSupplieModelForSaveDraft()
      if (JSON.stringify(this.supplierModelForSaveDraft.ChangeHistory)!="{}") {
       return await this.onSavingDraft()
      }else{
        return true
      }
    }else{
      return this.existingSaveDraft()
    }
    
  }

  setSupplieModelForSaveDraft(){
    this.supplierModelForSaveDraft.RequestForm.RequestFormInfo=this.onBoardingService.supplierModel.RequestForm.RequestFormInfo;
    this.supplierModelForSaveDraft.Mapping=this.onBoardingService.supplierModel.Mapping;
    console.log('initalRequestFormInfoModel',this.initalRequestFormInfoModel);
    console.log('RequestFormInfo',this.supplierModelForSaveDraft.RequestForm.RequestFormInfo);
    this.supplierModelForSaveDraft.ChangeHistory =this.commonService.getChangeHistory(this.initalRequestFormInfoModel, this.supplierModelForSaveDraft.RequestForm.RequestFormInfo,"RequestFormInfo")
    console.log('changehistory',this.supplierModelForSaveDraft.ChangeHistory);
  }

  async existingSaveDraft(){
    const saveUrl = `${environment.gateway}${LoadingUrlMapping.saveRequestDraft}`;
    this.loadingService.openLoading(saveUrl);
    await this.onBoardingService.saveDraft();
    return true; 
  }

  async onSavingDraft(){
    const saveUrl = `${environment.buyerGateway}${LoadingUrlMapping.saveRequestformInfo}`;
    this.loadingService.openLoading(saveUrl);
    console.log('supplierModelForSaveDraft',this.supplierModelForSaveDraft);
    await this.onBoardingService.saveDraftForRequestFormInfo(this.supplierModelForSaveDraft);
    this.loadingService.closeLoading();
    return true; 
  }

  // resubmit request form
  async resubmitForm(): Promise<boolean> {
    this.context.changeSubmitState();
    this.dynamicForm.removeFormTouch();
    if (!this.validate()) {
      return false;
    }
    const requestFormInfo = trim(this.dynamicForm.values());
    if (
      JSON.stringify(requestFormInfo.PaymentTermList) !==
      JSON.stringify(this.dynamicForm.model.PaymentTermList)
    ) {
      this.onBoardingService.supplierModel.Mapping.paymentTermsIsChange = true;
    }
    await this.updateSupplierMapping();
    this.plannedDateSetValue();
    // get executable task GUID: taskInstId
    if (
      this.onBoardingService.checkTaskIsComplete(
        ProcessNames.generic_supplierSetup,
        SetupProcess.supplier_accepted
      )
    ) {
      this.onBoardingService.getExecuteTask(
        ProcessNames.generic_supplierSetupSubProcess,
        AllTaskKey.SST_rejectToBuyer
      );
      if (!this.onBoardingService.supplierModel.Mapping.executeTask) {
        return false;
      }
    }

    if(this.isNewVersion){
      this.setSupplieModelForSaveDraft()
      return await this.onResubmitForm()
    }else{
      return await this.existingResubmitform()
    }
  }

    async onResubmitForm(){
    const saveUrl = `${environment.buyerGateway}${LoadingUrlMapping.resubmitRequestForm}`;
    this.loadingService.openLoading(saveUrl);
    await this.onBoardingService.resubmitFormEnhanced(this.supplierModelForSaveDraft),
    this.loadingService.closeLoading()
    return true;
  }

  async existingResubmitform(){
    const saveUrl = `${environment.gateway}${LoadingUrlMapping.resubmitRequestForm}`;
    this.loadingService.openLoading(saveUrl);
    await this.onBoardingService.resubmitForm();
    return true;
  }

  get ShowResubmitButton() {
    if(isPplusUser(this.auth.passport.buyer.RoleCode) && this.checkSupplier?.data === true){
      return false
    }
    return this.onBoardingService.checkResubmit();
  }

  async terminate() {
    const terminateResult = await this.dialogService.dialog(
      `Please note that if you terminate this request, the current process will be stopped with no option to reactivate.
                                          The process will need to be started again from the beginning.`,
      ButtonCommands.ProceedCancel
    );
    if (terminateResult === ButtonCommand.yes) {
      const variables = {
        isTerminate: true,
      };
      this.onBoardingService.getExecuteTask(
        ProcessNames.generic_supplierSetup,
        AllTaskKey.requestor_draft,
        variables
      );
      if (!this.onBoardingService.supplierModel.Mapping.executeTask) {
        return false;
      }
      this.onBoardingService.requestFormTerminate().then((d: any) => {
        if (d.isSuccess) {
          this.vm.saved();
          this.dialogService
            .dialog(
              "You have terminated successfully.",
              ButtonCommands.Close,
              DialogType.success,
              OnBoardMessage.APResultSendSuccess
            )
            .then((res: any) => {
              if (res === ButtonCommand.close) {
                const TsmId: string =
                  this.onBoardingService.supplierModel.Mapping.TsmId;
                const apFlag: boolean =
                  this.auth.passport.buyer.RoleCode === "AP_SST" ||
                  this.auth.passport.buyer.RoleCode === "AP_QC";
                if (!!TsmId && !apFlag) {
                  // let TsmLink:string = environment.landing_url
                  const env = sessionStorage.getItem("tsmEnv");
                  const url =
                    env === "dev"
                      ? environment.landingdev_url
                      : env === "perf"
                      ? environment.landingperf_url
                      : env === "hotfix"
                      ? environment.landinghotfix_url
                      : env === "stage"
                      ? environment.landingstage_url
                      : env === "test"
                      ? environment.landingtest_url
                      : environment.landing_url;
                  window.location.href =
                    url + "?login_hint=" + this.auth.passport.buyer.Email;
                } else {
                  this.router.navigate([`buyer`]);
                }
              }
            });
        }
      });
    }
  }

  checkPplus() {
    if (this.checkSupplier?.data === true) {
      return isPplusUser(this.auth.passport.buyer.RoleCode)
    }
    else {
      return false;
    }
  }
  async requestorPopupForBSR(selectedOption: string) {
    if (selectedOption === "N") {
      const res = await this.dialogService.dialog(
        `As additional information may be required to proceed with supplier onboarding, it is recommended that a <a href="https://support.accenture.com/procurement?id=e2e_guided_questions_page" target = "_blank">BSR</a> ticket be raised to engage P+ prior to raising a supplier onboarding request.`,
        ButtonCommands.Ok,
        DialogType.uploadPopup,
        "Note to the requestor:")
    }
  }
  async ppReject(){
    await this.dialogService.ppReject(DialogType.ppReject);
  }
}
