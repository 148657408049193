import {
  SupplierMappingDTO,
  SupplierPendingStatus,
  RiskAssessmentStatus,
  AP_ReviewStatus,
  SupplierStatus,
  StatusModel,
  StatusType,
  StatusHistoriesTitle,
} from "./mapping";
import { SupplierProfile, createSupplierProfile } from "./supplierProfile";
import { RequestForm, createRequestForm } from "./requestForm";
import { Upload } from "../shared/components/dialog-invitation/upload.interface";

export interface WorkFlowTransactionModel {
Suppliercode: number;
StepId: number;
SequenceId: number;
StepName: string;
Status: string;
ActionedByEmail: string;
ActionCounter?: number;
AssignedToEmail?: string;
AssignedGroup?: string;
StartDateTime?: Date;
EndDateTime?: Date;
Comments?: string;
StepTitle?:string;
}
export interface SupplierModel {
  SupplierCode?: string;
  RequestForm?: RequestForm;
  SupplierProfile?: SupplierProfile;
  Mapping?: SupplierMappingDTO;
  AssessmentRate?: any;
  RequestorChangeRequest?: any;
  uploadEvidenceAttachments?: Array<Upload>;
  isNewSupplier?: boolean;
  isRiskAssessmentNewSupplier?: boolean;
  ChangeHistory?: any;
}

export function createSupplierModel(supplierCode: string): SupplierModel {
  return {
    SupplierCode: supplierCode,
    RequestForm: createRequestForm(),
    SupplierProfile: createSupplierProfile(),
    Mapping: {},
    AssessmentRate: null,
  };
}

// export function updateStatusModel(statusHistories: Array<StatusModel>,
//   AP_statusHistories: Array<StatusModel>,
//   status: string,
//   createdBy: string,
//   statusType: string,
//   roleName: string,
//   rejectTo: string = '') {

//   let keyStatus: string
//   let isAPReview = false
//   let historiesTitle: StatusHistoriesTitle = StatusHistoriesTitle['requestor_draft']
//   if (statusType === StatusType.pending) {
//     keyStatus = Object.keys(SupplierPendingStatus).filter(item => SupplierPendingStatus[item] === status)[0]
//     historiesTitle = StatusHistoriesTitle[keyStatus]
//   } else if (statusType === StatusType.risk) {
//     keyStatus = Object.keys(RiskAssessmentStatus).filter(item => RiskAssessmentStatus[item] === status)[0]
//     historiesTitle = StatusHistoriesTitle[keyStatus]
//   } else if (statusType === StatusType.AP_review) {
//     keyStatus = Object.keys(AP_ReviewStatus).filter(item => AP_ReviewStatus[item] === status)[0]
//     historiesTitle = StatusHistoriesTitle[keyStatus]
//     isAPReview = true
//   } else if (statusType === StatusType.Buyer_request_change || statusType === StatusType.Supplier_request_change) {
//     keyStatus = Object.keys(AP_ReviewStatus).filter(item => AP_ReviewStatus[item] === status)[0]
//     historiesTitle = StatusHistoriesTitle[keyStatus]
//   } else {
//     keyStatus = Object.keys(SupplierStatus).filter(item => SupplierStatus[item] === status)[0]
//     historiesTitle = StatusHistoriesTitle[keyStatus]
//   }
//   if (rejectTo) {
//     rejectTo = rejectTo.substring(rejectTo.lastIndexOf(' ') + 1, rejectTo.length)
//   }
//   const model: StatusModel = {}
//   model[keyStatus] = {
//     status: status,
//     createdBy: createdBy,
//     createdOn: new Date().toUTCString(),
//     isStarted: true,
//     isDisplay: true,
//     isAPReview: isAPReview,
//     role: roleName,
//     historiesTitle: historiesTitle,
//     rejectTo: rejectTo
//   }

//   if (statusType === StatusType.AP_review || statusType === StatusType.Supplier_request_change || statusType === StatusType.Buyer_request_change) {
//     AP_statusHistories.push(model)
//   } else {
//     statusHistories.push(model)
//   }
// }

// export function checkDetailStatus(mapping: SupplierMappingDTO, status: SupplierPendingStatus) {
//   let keyStatus: any

//   const m = mapping.statusHistories.find((model: StatusModel) => {
//     const [key] = Object.keys(model)
//     if (model[key].status === status) {
//       keyStatus = key
//       return true
//     }
//     return false
//   })
//   if (m) {
//     return m[keyStatus].isStarted
//   } else {
//     return false
//   }
// }

// export function checkAPStatus(mapping: SupplierMappingDTO, status: AP_ReviewStatus) {
//   let keyStatus: any

//   const m = mapping.AP_statusHistories.find((model: StatusModel) => {
//     const [key] = Object.keys(model)
//     if (model[key].status === status) {
//       keyStatus = key
//       return true
//     }
//     return false
//   })
//   if (m) {
//     return m[keyStatus].isStarted
//   } else {
//     return false
//   }
// }
