// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.link-mare-panel {
  position: absolute;
  height: auto;
  background: #fff;
  color: #000;
  border-radius: 5px;
  padding: 5px 0;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.12), 0 3px 6px rgba(0, 0, 0, 0.24);
  outline: 10;
  z-index: 9999;
}

.left {
  float: left;
  margin-top: 0.8rem;
  margin-right: 2rem;
}

.subLabel {
  display: inline-block;
}

.labelWith {
  width: max-content;
}

.subLabelPosition {
  position: absolute;
}

.drop-input {
  width: calc(100% - 24px);
  height: 100%;
  background: #f6f5fa;
  border: 0;
  color: #999;
}

.text-container {
  display: inline !important;
  position: relative;
  padding-left: 33px;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  font-weight: normal;
  color: #333;
  margin-right: 3rem;
  padding-top: 0.3rem;
}

.drop-panel {
  height: 250px;
  border-left: solid 0.9px #ddd;
  border-bottom: solid 0.9px #ddd;
  border-right: solid 0.9px #ddd;
  padding: 0px;
  overflow-y: auto;
  position: absolute;
  background-color: #fff;
  z-index: 9999;
  width: 100%;
}

.search_bg {
  display: inline-flex;
  background: #fff;
  width: 100%;
  border: solid 1px #efefef;
  border-radius: 1.5rem;
  padding-left: 5px;
  padding-top: 3px;
}
.search_bg .material-icons {
  margin-right: 0.5rem;
}

.icon-drop {
  color: #ddd;
}

.icon-drop-arrow {
  color: #000;
}

.search-box {
  border: none;
  background: #fff;
  font-size: 14px;
  width: 80%;
}

.search-box .search-container {
  position: absolute;
  top: 100px;
  right: 100px;
}

label-drop {
  padding-top: 2px;
}

.line-drop {
  margin: 13px 0px;
}

.disable_input:disabled {
  background: #dcdcdc;
}

p {
  line-height: 1.5;
  font-weight: normal;
}

.pplusrosterpicker-drop-panel {
  border-left: solid 0.9px #ddd;
  border-bottom: solid 0.9px #ddd;
  border-right: solid 0.9px #ddd;
  background-color: #fff;
  border: 1px solid balck;
  width: 100%;
  overflow-y: auto;
  position: absolute;
  z-index: 9;
}

.pplusrosterpicker-drop-option {
  height: 20px;
  margin-top: 1rem;
}
.pplusrosterpicker-drop-option:first-child {
  margin-top: 0;
  padding: 20px 0px;
}

.pplusrosterpicker-drop-option:hover {
  cursor: pointer;
}

.pplusrosterpicker-drop-option-span {
  display: block;
  padding: 0rem 0px 0px 1rem;
}

.input-container {
  position: relative;
}

.noRecordError {
  position: absolute;
  background: #FFE8E6;
  color: #633752;
  padding: 0.75rem;
  border-radius: 0.25rem;
  font-size: 0.9rem;
}

.dropdownScroll {
  width: 100%;
  max-height: 150px;
  overflow-y: auto;
  border: none;
  margin: 0 auto;
  position: relative;
  top: 0px;
  border: 1px solid;
}

.dropdownScroll::-webkit-scrollbar {
  width: 8px;
}

.dropdownScroll::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

.dropdownScroll::-webkit-scrollbar-thumb {
  background: #6457B1;
  border-radius: 10px;
}

.dropdownScroll::-webkit-scrollbar-thumb:hover {
  background: #555;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
