import { Pipe, PipeTransform } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { LanguageService } from "../../dynamic-components/utils/language.service";
import { environment } from "src/environments/environment";

@Pipe({ name: "langStr" })
export class LangStrPipe implements PipeTransform {
  constructor(private languageService: LanguageService) {}

  transform(value) {
    console.log("Language Switch", environment.languageSwitch)
    return environment.languageSwitch
      ? this.languageService.getValue(value)
      : value;
  }
}
