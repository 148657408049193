export interface BuyerUser {
  ID?: number;
  Name?: string;
  Email?: string;
  RoleCode?: string;
  OID?: string;
  CompanyNb?: string;
  Enabled?: boolean;
  Status?: string;
  RoleName?: string;
  SystemAdmin?: string;
}

export class OnBoardingRequestInfo {
  BuyerCompanyNm?: string;
  SupplierCompanyNm?: string;
  CreatedAt?: string;
  Step?: string;
  Status?: string;
}

export class BIBDA {
  Result?: string;
}

export class GDPR {
  Result?: string;
}

export class InformationSecurity {
  Result?: string;
}

export class SearchTermDS {
  dp_CompanyCode?: any;
  dp_Category?: any;
  dp_DetailStatus?: any;
}

export class RiskAssessmentDS {
  dp_BI_BDA_1?: any;
  dp_BI_BDA_1c?: any;
  dp_InformationSecurity_1?: any;
  dp_InformationSecurity_1a?: any;
  dp_InformationSecurity_1b?: any;
  dp_InformationSecurity_1ca?: any;
  dp_InformationSecurity_1cb?: any;
  dp_InformationSecurity_1cc?: any;
  dp_InformationSecurity_1cd?: any;
  dp_InformationSecurity_1ce?: any;
  dp_InformationSecurity_1cf?: any;
  dp_InformationSecurity_1d?: any;
  dp_InformationSecurity_1e?: any;
  dp_InformationSecurity_1g?: any;
  dp_InformationSecurity_2?: any;
  dp_InformationSecurity_2a?: any;
  dp_InformationSecurity_3?: any;
  dp_InformationSecurity_3a?: any;
}

export class DeclarationDS {
  dp_declaration_1?: any;
  dp_declaration_2?: any;
  dp_declaration_3?: any;
  dp_declaration_5?: any;
}

export class DeclarationInfo {
  Declaration_1?: string;
  Declaration_2?: string;
  Declaration_3?: string;
  Declaration_4?: string;
  Declaration_5?: string;
  Declaration_6?: string;
}

export class RequestFormStatus {
  isEnterRegistrationNumValidated?: boolean;
  isPurchaseValidated?: boolean;
  isShowUploadMail?: boolean;
}

export class RiskAssessmentStatus {
  BI_BDA_1?: boolean;
  InformationSecurity_1?: boolean;
  InformationSecurity_1a?: boolean;
  InformationSecurity_1c?: boolean;
  InformationSecurity_1ca_Others?: boolean;
  InformationSecurity_1cd?: boolean;
  InformationSecurity_1e?: boolean;
  InformationSecurity_1g?: boolean;
  InformationSecurity_2?: boolean;
  InformationSecurity_3?: boolean;
  IsInfoSecurity_Q1_5?: boolean;
  IsInformationSecurity?: boolean;
}

export enum OnBoardingRequestInfoType {
  RELATIONSHIP = "relationship",
  REQUESTOR_DETAILS = "requestorDetails",
  REQUEST_FORM = "requestForm",
  DECLARATION = "declaration",
  RISK_ASSESSMENT = "riskAssessment",
  BI_BDA = "BIBDA",
  INFORMATION_SECURITY = "informationSecurity",
  GDPR = "gdpr",
}

export enum OnBoardingStatus {
  New = "New",
  Pending = "Pending",
  Accepted = "Accepted",
  Rejected = "Rejected",
  Started = "Started",
  BuyerDetails = "BuyerDetails",
  RiskAssessment = "RiskAssessment",
  IntegrateSAP = "IntegrateSAP",
  BI_BDA = "BI_BDA",
  InformationSecurity = "InformationSecurity",
  GDPR = "GDPR",
  Completed = "Completed",
}

export enum OnBoardingBtnDesc {
  StartOnboard = "Start Onboarding Process",
  OnBoard = "On Board Supplier",
  CreateSupplierInSAP = "Create Supplier in SAP",
  SupplierCreatedInSAP = "Supplier created in SAP",
  SaveRiskAssessment = "Save Risk Assessment",
}

export enum PermissionType {
  Supplier = "Supplier",
  Buyer = "Buyer",
}

export enum PermissionControlType {
  SupplierPublic = "SupplierPublic",
  SupplierAll = "SupplierAll",
  BuyerAll = "BuyerAll",
}

export enum SupplierCardStatus {
  NotAssociated = "Not Associated",
  OnBoarded = "On Boarded",
  Pending = "Pending",
}

export enum RoleName {
  Business = "BI",
  Requestor = "requestor",
  SourcingCategoryManagement = "S_CM",
  DiversitySustainability = "DS",
  ISLegalGDPR = "IS_Legal_GDPR",
  APSupplierSetupTeam = "AP_SST",
  APQualityControl = "AP_QC",
  BuyerAdmin = "buyerAdmin",
  OtherRequestor = "otherRequestor",
  SystemAdmin = "systemAdmin",
  ProcurementReviewerEditor = "Procurement_Reviewer_Editor",
  VSS_LA_Editor = "VSS_LA_Editor",
  Risk_Mitigation_LA_Editor = "Risk_Mitigation_LA_Editor",
  Procurement_Lead_LA_Editor = "Procurement_Lead_LA_Editor",
}

export enum RoleCode {
  BI = "Business Intermediaries",
  requestor = "Requestor",
  S_CM = "Sourcing Category Management",
  DS = "Diversity & Sustainability",
  IS_Legal_GDPR = "IS/Legal/GDPR",
  AP_SST = "AP Supplier Setup Team",
  AP_QC = "AP Quality Control",
  buyerAdmin = "Buyer Admin",
  supplier = "Supplier",
  systemAdmin = "System Admin",
  Procurement_Reviewer_Editor = "Procurement_Reviewer_Editor",
  Supply_Chain_Team_Reader = "Supply_Chain_Team_Reader",
  VSS_LA_Editor = "VSS_LA_Editor",
  Risk_Mitigation_LA_Editor = "Risk_Mitigation_LA_Editor",
  Procurement_Lead_LA_Editor = "Procurement_Lead_LA_Editor",
}
// export const DashBoardPermissionControl = new Map([
//   [RoleName.Requestor, []],
//   [RoleName.AccountsPayable, [OnBoardingStatus.Accepted, OnBoardingStatus.New, OnBoardingStatus.Rejected, OnBoardingStatus.Started]],
//   [RoleName.GDPR, [OnBoardingStatus.Accepted, OnBoardingStatus.New, OnBoardingStatus.Rejected, OnBoardingStatus.Started]],
//   [RoleName.InformationSecurity, [OnBoardingStatus.Accepted, OnBoardingStatus.New, OnBoardingStatus.Rejected, OnBoardingStatus.Started]],
//   [RoleName.LegalRiskAssess, [OnBoardingStatus.Accepted, OnBoardingStatus.New, OnBoardingStatus.Rejected, OnBoardingStatus.Started]]])

export const PermissionControl = new Map([
  [OnBoardingStatus.New, PermissionControlType.SupplierPublic],
  [OnBoardingStatus.Accepted, PermissionControlType.SupplierAll],
  [OnBoardingStatus.Rejected, PermissionControlType.SupplierPublic],
  [OnBoardingStatus.Started, PermissionControlType.SupplierAll],
  [OnBoardingStatus.BuyerDetails, PermissionControlType.BuyerAll],
  [OnBoardingStatus.IntegrateSAP, PermissionControlType.BuyerAll],
  [OnBoardingStatus.RiskAssessment, PermissionControlType.BuyerAll],
  [OnBoardingStatus.InformationSecurity, PermissionControlType.BuyerAll],
  [OnBoardingStatus.GDPR, PermissionControlType.BuyerAll],
  [OnBoardingStatus.Completed, PermissionControlType.BuyerAll],
]);

export enum DelimiterStr {
  first = "@@",
}
export enum OnBoardMessage {
  RequestSentSuccessfully = "<div style='padding: 5px; line-height: 20px;'> \
    Your request has been successfully sent to the supplier. You \
    will receive a notification once the supplier has accepted your request. <br><br>\
    Please come back to this page after the supplier has accepted the request. You can then start \
    the on boarding process by clicking the \"<strong>Start onboarding Process</strong> \" button. </div>",
  SAPRequestSentSuccessfully = "<div style='padding: 5px; line-height: 20px;'> \
    Integrated with SAP successfully  </div>",
  CommonRequestSentSuccessfully = "<div style='padding: 5px; line-height: 20px;'> \
    Risk Assessment result has been saved successfully</div>",
  RequestSentSuccessTitle = "Request Sent Successfully",
  RequestSentFailedTitle = "Request Sent Failed",
  ACLResultSendSuccess = "Successful",
  APResultSendSuccess = "Please Notice",
  RequestSentFail = "<div style='padding: 5px; line-height: 20px;'> \
    You request has been sent failed. Please contact your Admin </div>",
}

export enum BuyerRoleACLPagePath {
  DashboardPath = "",
  OnboardingProcessPath = "onboarding/process/:supplierCode",
  ViewSupplierProfilePath = "supplier/:supplierCode",
  TransactionHistoryPath = "history/:code/:step",
  UserManagementPath = "management",
  RequestorFormPath = "onboarding/:code",
}

export enum BuyerRoleACLPageName {
  DashboardName = "Dashboard Page",
  OnboardingProcessName = "Onboarding Process Page",
  ViewSupplierProfileName = "View Supplier Profile Page",
  TransactionHistoryName = "Transaction History Page",
  UserManagementName = "User Management Page",
  RequestorFormName = "Requestor Form Page",
  RequestorLandingPage = "Requestor Landing Page",
  PrivacyStatementPage = "Privacy Statement Page",
  BIBDAResultPage = "BI BDA Result Page",
  SAPResultPage = "SAP Result Page",
  RiskAssessmentSummaryPage = "Risk Assessment Summary Page",
}

export enum BuyerRoleACLButton {
  NewSupplierRequestButton = "New Supplier Request Button",
  RequestorformEditButton = "Requestor form Edit Button",
  OnboardingProcessPageButton = "Onboarding Process Page Button",
  ViewSupplierProfilePageButton = "View Supplier Profile Page Button",
  TransactionHistoryPageButton = "Transaction History Page Button",
  UserManagementPageButton = "User Management Page Button",
  ReportSRCMButton = "Report SRCM Button",
  MigrationInvitaionButton = "Send Migrate Invitation Button",
  APReportDownloadButton = "AP Report Download Button",
  MetricsReportDownloadButton = "Metrics Report Download Button",
  EditHoldButton = "Edit Hold Button",
  ApplyHoldonButton = "Apply Holdon Button",
  CSSReportDownloadButton = "CSS Report Download Button",
  PPlusReportDownloadButton = "PPlus Report Download Button",
}

export enum BuyerChangeRequestItem {
  Company = "Company",
  PaymentTerm = "PaymentTerm",
  AmendSpendCommodityCategoryGroup = "AmendSpendCommodityCategoryGroup",
}

export enum EditHold {
  Remove = "Remove",
  Extend = "Extend",
}
