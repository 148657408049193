import { AbstractControl, ValidationErrors, AsyncValidatorFn, ValidatorFn } from '@angular/forms'
import { Injectable, ViewChild } from '@angular/core'
import { DynamicComponentService } from 'src/app/dynamic-components/services/dynamic-component.service'
import { OnBoardingService } from 'src/app/modules/buyer/services/onboarding.service'
import { Observable, Subscription } from 'rxjs'
import { CustomValidator } from 'src/app/dynamic-components/interfaces/Decorators'
import { DynamicContext } from 'src/app/dynamic-components/interfaces/DynamicContext'
import { ComponentConfig } from 'src/app/dynamic-components/ComponentConfig'
import { BuyerService } from 'src/app/services/buyer.service'
import { ProfileService } from 'src/app/modules/supplier/services/profile.service'
import { DictionaryService } from 'src/app/services/dictionary.service'
import { SpendCategoryGroup, SupplierStatus } from 'src/app/interfaces/mapping'
import { supplierGeographical } from 'src/app/shared/shared'
import { ProcessNames, SetupProcess } from 'src/app/interfaces/workflow'
import { LanguageService } from '../../dynamic-components/utils/language.service'
import { TranslateService } from '@ngx-translate/core'
import * as moment from 'moment'
import { DynamicFormComponent } from 'src/app/dynamic-components/components/dynamic-form/dynamic-form.component'
import { ConfirmService } from 'src/app/services/dialog.service'
@Injectable()
export class CustomValidators {
  @ViewChild("dynamicForm") dynamicForm: DynamicFormComponent;
  subscription: Subscription;
  groupmember: any;
  groupMemberList: any;
  domainFreeList: string[] = [];
  constructor(
    private service: DynamicComponentService,
    private onBoardingService: OnBoardingService,
    private buyerService: BuyerService,
    private profileService: ProfileService,
    private dictionaryService: DictionaryService,
    private languageService: LanguageService,
    private translateService: TranslateService,
    private confirmService: ConfirmService
  ) { }  

  @CustomValidator({ name: "exists", async: false })
  public exists(config: ComponentConfig, context: DynamicContext): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      return control.value === "exists" ? { exists: true } : null;
    };
  }
  public async getCompanyAndCountryData() {
    const companyCodes = this.dictionaryService.getDictionaryFromCache(
      "requestor-details.CompanyCode"
    );
    const country = this.dictionaryService.getDictionaryFromCache(
      "requestor-details.SupplierCountry"
    );
    return [companyCodes, country];
  }

  @CustomValidator({ name: "supplierEmailExists", async: true })
  public supplierEmailExists(
    config: ComponentConfig,
    context: DynamicContext
  ): AsyncValidatorFn {
    let prevValue;
    let currentError;
    return (
      control: AbstractControl
    ):
      | Promise<ValidationErrors | null>
      | Observable<ValidationErrors | null> => {
      return new Promise((resolve, reject) => {
        if (!control.value) {
          console.log("control.value");
          return resolve(null);
        }
        if (prevValue === control.value) {
          if (control.untouched) {
            return resolve(null);
          }
          if (!control.valid && currentError) {
            return resolve(currentError);
          }
        }
        prevValue = control.value
        const TsmIdValue = context.getValue('TsmIdValue')
        this.onBoardingService.checkEmail(control.value, TsmIdValue).then(res => {
          if (res === 'EmailExist') {
            config.errorMessage['supplierEmailExists'] = this.languageService.getValue('Validators.supplierEmailExists.supplierEmailExists')
            currentError = { 'supplierEmailExists': false }
            return resolve(currentError)
          }
          else if (res === 'DomainExist') {
            config.errorMessage['supplierEmailExists'] = this.languageService.getValue('Validators.supplierEmailExists.supplierDomainEmailExists')
            currentError = { 'supplierEmailExists': false }
            return resolve(currentError)
          } else if (res === 'InBlacklist') {
            config.errorMessage['supplierEmailInBlacklist'] = this.languageService.getValue('Validators.supplierEmailExists.supplierEmailInBlacklist')
            currentError = { 'supplierEmailInBlacklist': true }
            return resolve(currentError)

          } else if (res === 'InTSM') {
            config.errorMessage['supplierEmailInBlacklist'] = 'This email address already exists in the True Supplier Marketplace. Please enter a supplier email address which has not been used to register for onboarding before.'
            //config.errorMessage['supplierEmailInBlacklist'] = `This email address has already been used as the supplier point of contact for multiple profiles, exceeding the usage limitations in the True Supplier Marketplace. Please enter another point of contact to invite your supplier to complete their organization's onboarding process.`           
            currentError = { 'supplierEmailInBlacklist': true }
            return resolve(currentError)
          }
          else {
            // console.log(res)
            currentError = null
            return resolve(null)
          }
        })
      })
    }
  }

  @CustomValidator({ name: "verifyMemberEid", async: true })
  public verifyMemberEid(
    config: ComponentConfig,
    context: DynamicContext
  ): AsyncValidatorFn {
    let typedValueInInputElement;
    let currentError;
    return (
      control: AbstractControl
    ):
      | Promise<ValidationErrors | null>
      | Observable<ValidationErrors | null> => {
      return new Promise((resolve, reject) => {
        if (!control.value) {
          console.log("control.value");
          return resolve(null);
        }
        if (typedValueInInputElement === control.value) {
          if (control.untouched) {
            return resolve(null);
          }
          if (!control.valid && currentError) {
            return resolve(currentError);
          }
        }
        typedValueInInputElement = control.value;
        let apiCalled = false;
        if (typedValueInInputElement.length === 1 && !apiCalled) {
          this.buyerService.pPlusRoster('PplusRoster').then((res: any[]) => {
            this.groupMemberList = res;
            const finalList = this.groupMemberList.data.map(person => person.email);
            this.domainFreeList = finalList.map(email => email.split('@')[0]); 
            apiCalled = false;
          }).catch(error => {
            console.error('Error fetching PplusRoster Members:', error);
          });          
        }  
        let matchFound = this.domainFreeList.some(group => typedValueInInputElement === group);
        if (!matchFound) {
          config.errorMessage['verifyMemberEid'] = this.languageService.getValue('Validators.ValidatingGroupMemberEid.ValidatingErrorMessage')
          currentError = { 'verifyMemberEid': true }
          return resolve(currentError)
        } else {
          currentError = null
          return resolve(null)
        }
      })
    }
  }

  

  // @CustomValidator({ name: 'showOneTimeAlertMsg', async: false })
  // public showOneTimeAlertMsg(config: ComponentConfig, context: DynamicContext): ValidatorFn {
  //   return (control: AbstractControl): ValidationErrors | null => {
  //     if (!context["fieldMap"].get("GeographicalCode")) {
  //       let formGroup = control.parent
  //       let GeographicalCode = formGroup.get("SupplierGeographical").value;
  //       if (GeographicalCode === "26") {
  //         const purchaseType = formGroup.get('OnetimeOrRecurringPurchases').value
  //         if (purchaseType === "10") {
  //           config.errorMessage['showOneTimeAlertMsg'] = this.languageService.getValue('Validators.showOneTimeAlertMsg.showOneTimeAlertMsg')
  //           const currentError = { 'showOneTimeAlertMsg': false }
  //           return currentError
  //         }
  //       }
  //     } else {
  //       let GeographicalCode = context["fieldMap"].get("GeographicalCode").value;
  //       if (GeographicalCode === "26") {
  //         let formGroup = control.parent;
  //         const purchaseType = formGroup.get('OnetimeOrRecurringPurchases').value
  //         if (purchaseType === "10") {
  //           config.errorMessage['showOneTimeAlertMsg'] = this.languageService.getValue('Validators.showOneTimeAlertMsg.showOneTimeAlertMsg')
  //           const currentError = { 'showOneTimeAlertMsg': false }
  //           return currentError
  //         }
  //       }
  //       return null
  //     }
  //   }
  // }

  //Determine if the company is present in the selected country mj
  @CustomValidator({ name: "companyShowInExitCountry", async: true })
  public companyShowInExitCountry(
    config: ComponentConfig,
    context: DynamicContext,
    languageService: LanguageService
  ): AsyncValidatorFn {
    return (
      control: AbstractControl
    ):
      | Promise<ValidationErrors | null>
      | Observable<ValidationErrors | null> => {
      let prevValue;
      let currentError;

      return new Promise((resolve, reject) => {
        if (!control.value) {
          return resolve(null);
        }
        if (prevValue === control.value) {
          if (!control.valid) {
            return resolve(currentError);
          }
          return resolve(null);
        }
        this.getCompanyAndCountryData().then((data) => {
          const formGroup = control.parent;
          const supplierCountry =
            formGroup.get("SupplierCountry") &&
            formGroup.get("SupplierCountry").value;
          const currentValues = formGroup.get("CompanyCode").value;
          const empty = [];
          // select at least one Company Code per Country
          for (let index = 0; index < data[0].length; index++) {
            const ele = data[0][index];
            if (supplierCountry.indexOf(ele["Code"]) > -1) {
              const count = ele["Items"].filter((item) => {
                return currentValues.indexOf(item["Code"]) > -1;
              }).length;
              if (count == 0) {
                empty.push(ele["Code"]);
              }
            }
          }
          // the alert is different when select different country
          if (empty.length > 0) {
            let countryAlert =
              "&lt;" +
              data[1].filter((d) => d.Code === empty[0])[0]["Text"] +
              "&gt;.";
            let countryAlert2 = "this county.";
            //more country
            if (empty.length > 1) {
              countryAlert2 = "these countries.";
              countryAlert = "&lt;";
              for (let i = 0; i < empty.length; i++) {
                const j = empty[i];
                countryAlert += data[1].filter((d) => d.Code === j)[0]["Text"];
                if (i != empty.length - 1) {
                  countryAlert += ",";
                }
              }
              countryAlert += "&gt;";
            }
            const value = this.languageService.getValue(
              "Validators.companyShowInExitCountry.CompanyCodeDictionary"
            );
            const params = [countryAlert, countryAlert2];

            const alt = this.languageService.getValueWithParams(value, params);

            context.emit("alertMsg", alt);
          } else {
            context.emit("alertMsg", "");
          }
          return resolve(null);
        });
      });
    };
  }

  //One time purchase alert display logic exclude PH(28) - Philippines(PH)
  @CustomValidator({ name: 'OneTimeVendorErrorMsg', async: true })
  public OneTimeVendor(config: ComponentConfig, context: DynamicContext, languageService: LanguageService): AsyncValidatorFn {
    return (control: AbstractControl): Promise<ValidationErrors | null> => {
      let formGroup = control.parent
      return new Promise((resolve, reject) => {
        const OnetimeOrRecurringPurchases = formGroup.get('OnetimeOrRecurringPurchases').value
        if (OnetimeOrRecurringPurchases === '10') {
          const selectGu = context.getValue('parentModel').RequestForm.RequestorDetailsInfo.SupplierGeographical
          const selectCountries = context.getValue('parentModel').RequestForm.RequestorDetailsInfo.SupplierCountry
          const selectCompanyCodes = context.getValue('parentModel').RequestForm.RequestorDetailsInfo.CompanyCode

          const guLists = this.languageService.getValue('Validators.OneTimeMessage.Items')
          const excludeCompanyCodes = this.languageService.getValue('Validators.OneTimeMessage.ExcludeCompanyCodes')
          const ukiaCompanyCodesFri = this.languageService.getValue('Validators.OneTimeMessage.UkiAExcludeCompanyCodesFri')
          const ukiaCompanyCodesSec = this.languageService.getValue('Validators.OneTimeMessage.UkiAExcludeCompanyCodesSec')

          //if GU = Japan
          if (selectGu == '22') {
            config.errorMessage['OneTimeVendorErrorMsg'] = this.languageService.getValue('Validators.OneTimeMessage.OneTimeVendorMessageForJapan')
            const currentError = { 'OneTimeVendorErrorMsg': false }
            return resolve(currentError)
          }
          else {
            //select GU is in display onetime message scope (except Japan)
            if (guLists.filter(guList => guList.Code == selectGu).length > 0) {
              let errorMsg = null
              let errorCountry = null
              let commonMessage = null
              const countryLists = guLists.filter(guList => guList.Code == selectGu)[0].Items;
              //Add Country new msg
              countryLists.forEach(countryList => {
                if (selectCountries.indexOf(countryList.Code) != -1) {
                  //Get currency and emailAddress                
                  let countryMsg = null
                  let currency = null
                  let emailAddress = null
                  commonMessage = this.languageService.getValue('Validators.OneTimeMessage.OneTimeVendorCommonMessage')
                  currency = countryList.Currency
                  emailAddress = countryList.EmailAddress
                  const params = [currency, emailAddress]
                  countryMsg = this.languageService.getValueWithParams(commonMessage, params)
                  if (!!errorCountry) {
                    errorCountry = errorCountry + '<br><br>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;' + countryMsg
                  } else {
                    errorCountry = countryMsg
                  }
                }
              })
              //Check company code 
              if (selectGu == '26' && selectCountries.length > 0 && selectCompanyCodes.length > 0) {
                let friNum = 0
                let secNum = 0
                let errorCompanyCode = null
                selectCompanyCodes.forEach(selectCompanyCode => {
                  if (ukiaCompanyCodesFri.filter(p => p == selectCompanyCode).length > 0) {
                    friNum++;
                  }
                })
                selectCompanyCodes.forEach(selectCompanyCode => {
                  if (ukiaCompanyCodesSec.filter(p => p == selectCompanyCode).length > 0) {
                    secNum++;
                  }
                })
                //Company code Error Message
                const ukiaErrorCompanyCodeFri = this.languageService.getValue('Validators.OneTimeMessage.ukiaCompanyCodeErrorMessageFir')
                const ukiaErrorCompanyCodeMsg = this.languageService.getValue('Validators.OneTimeMessage.showOneTimeAlertMsg')
                if (friNum > 0 && secNum == 0) {
                  errorCompanyCode = ukiaErrorCompanyCodeFri
                } else if (secNum > 0 && friNum == 0) {
                  errorCompanyCode = ukiaErrorCompanyCodeMsg
                } else {
                  errorCompanyCode = ukiaErrorCompanyCodeFri + '<br><br>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;' + ukiaErrorCompanyCodeMsg
                }
                //Country Error message + Company code Error Message
                if (friNum == selectCompanyCodes.length || secNum == selectCompanyCodes.length || (friNum + secNum) == selectCompanyCodes.length) {
                  errorMsg = errorCompanyCode
                }
                else {
                  if ((friNum == 0 || secNum == 0) && ((friNum + secNum) < selectCompanyCodes.length)) {
                    if (!!errorCountry) {
                      errorMsg = errorCountry
                    } else {
                      return resolve(null)
                    }
                  } else {
                    if (!!errorCountry) {
                      errorMsg = errorCountry + '<br><br>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;' + errorCompanyCode
                    } else {
                      errorMsg = errorCompanyCode
                    }
                  }
                }

                config.errorMessage['OneTimeVendorErrorMsg'] = errorMsg
                const currentError = { 'OneTimeVendorErrorMsg': false }
                return resolve(currentError)
              }
              //ANZ - 11, SEA - 12
              //All company code not include in the exclude scope, so all these included country should display new msg
              else if ((selectGu == '11' || selectGu == '12') && selectCountries.length > 0 && selectCompanyCodes.length > 0) {

                let excludeNum = 0
                let errorCompanyCode = null
                selectCompanyCodes.forEach(selectCompanyCode => {
                  if (excludeCompanyCodes.filter(excludeCompanyCode => excludeCompanyCode == selectCompanyCode).length > 0) {
                    excludeNum++;
                  }
                })
                if (excludeNum > 0) {
                  errorCompanyCode = this.languageService.getValue('Validators.OneTimeMessage.OneTimeVendorMessageIsNotPH')
                }
                if (!(excludeNum > 0 && excludeNum == selectCompanyCodes.length)) {
                  if (!!errorCountry) {
                    if (excludeNum == 0 && selectCompanyCodes.length > 0) {
                      errorMsg = errorCountry
                    } else {
                      errorMsg = errorCompanyCode + '<br><br>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;' + errorCountry
                    }
                  } else {
                    return resolve(null)
                  }
                } else {
                  errorMsg = errorCompanyCode
                }

                config.errorMessage['OneTimeVendorErrorMsg'] = errorMsg
                const currentError = { 'OneTimeVendorErrorMsg': false }
                return resolve(currentError)
              }
              //United States--27, Canada--29, ASG--13, Nordics--25, Iberia--19
              else if ((selectGu == '27' || selectGu == '29' || selectGu == '13' || selectGu == '25' || selectGu == '19') && selectCountries.length > 0 && selectCompanyCodes.length > 0) {
                let excludeNum = 0
                selectCompanyCodes.forEach(selectCompanyCode => {
                  if (excludeCompanyCodes.filter(p => p == selectCompanyCode).length > 0) {
                    excludeNum++;
                  }
                })
                //No new country msg and no exclude msg  ==> return null
                if (!(excludeNum > 0 && excludeNum == selectCompanyCodes.length)) {
                  if (!!errorCountry) {
                    errorMsg = errorCountry
                    config.errorMessage['OneTimeVendorErrorMsg'] = errorMsg
                    const currentError = { 'OneTimeVendorErrorMsg': false }
                    return resolve(currentError)
                  } else {
                    return resolve(null)
                  }
                } else {
                  return resolve(null)
                }
              }
              //Gallia---16
              else if (selectGu == '16') {
                countryLists.forEach(countryList => {
                  if (selectCountries.indexOf(countryList.Code) != -1) {
                    let countryMsg = null
                    let currency = null
                    let emailAddress = null
                    commonMessage = this.languageService.getValue('Validators.OneTimeMessage.OneTimeVendorMessageForGallia')
                    currency = countryList.Currency
                    emailAddress = countryList.EmailAddress
                    const params = [currency, emailAddress]
                    countryMsg = this.languageService.getValueWithParams(commonMessage, params)
                    if (!!errorCountry) {
                      config.errorMessage['OneTimeVendorErrorMsg'] = countryMsg
                      const currentError = { 'OneTimeVendorErrorMsg': false }
                      return resolve(currentError)
                    } else {
                      return resolve(null)
                    }
                  }
                  else {
                    return resolve(null)
                  }
                })
              }
              //Other GU
              else {
                //Since no exclude company codes, so no need to check company codes message.
                //Only need to check user select countries whether in display new message countries' scope.
                // If yes, display new msg, if no, return null.              
                if (!!errorCountry) {
                  errorMsg = errorCountry
                  config.errorMessage['OneTimeVendorErrorMsg'] = errorMsg
                  const currentError = { 'OneTimeVendorErrorMsg': false }
                  return resolve(currentError)
                } else {
                  return resolve(null)
                }
              }
            }
            //select GU is out of the GU scope display onetime message 
            else {
              return resolve(null)
            }
          }
        }
        //user not select one time purchase choice
        else {
          return resolve(null)
        }
      })
    }
  }

  @CustomValidator({ name: 'RelationshipIDExists', async: true })
  public RelationshipIDExists(config: ComponentConfig, context: DynamicContext): AsyncValidatorFn {
    let prevValue
    let currentError
    return (control: AbstractControl): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> => {
      return new Promise((resolve, reject) => {
        if (!control.value) {
          return resolve(null);
        }
        if (prevValue === control.value) {
          if (!control.valid) {
            return resolve(currentError);
          }
          return resolve(null);
        }
        prevValue = control.value;
        this.buyerService
          .checkRiskAssessment(control.value)
          .then((res: any) => {
            if (!res.data) {
              currentError = { RelationshipIDExists: true };
              return resolve(currentError);
            } else {
              currentError = null;
              const params = [res.data.Status, res.data.Status];
              const value = this.languageService.getValue(
                "Validators.RelationshipIDExists.prompts"
              );
              config.templateOptions.prompts =
                this.languageService.getValueWithParams(value, params);
              return resolve(null);
            }
          });
      });
    };
  }

  @CustomValidator({ name: "ContractStatusISNo", async: true })
  public ContractStatusISNo(
    config: ComponentConfig,
    context: DynamicContext
  ): ValidatorFn {
    return (
      control: AbstractControl
    ):
      | Promise<ValidationErrors | null>
      | Observable<ValidationErrors | null> => {
      return new Promise((resolve, reject) => {
        const formGroup = control.parent;
        const purchaseType = formGroup.get("ContractStatusForPH").value;
        if (purchaseType === "N") {
          config.errorMessage["ContractStatusISNo"] =
            this.languageService.getValue(
              "Validators.ContractStatusISNo.ContractStatusISNo"
            );
          const currentError = { ContractStatusISNo: true };
          return resolve(currentError);
        }
        return resolve(null);
      });
    };
  }

  @CustomValidator({ name: "OneTimeVendorMessage", async: false })
  public OneTimeVendorMessage(
    config: ComponentConfig,
    context: DynamicContext
  ): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      let formGroup = control.parent
      const isPH = formGroup.get('SupplierGeographical')
      // const isAsen = context.getValue('isAsen')
      // const isANZ = context.getValue('isANZ')
      const OnetimeOrRecurringPurchases = formGroup.get('OnetimeOrRecurringPurchases').value
      if (OnetimeOrRecurringPurchases === '10') {
        // if ((isAsen || isANZ) && !isPH) {
        //   config.errorMessage['OneTimeVendorMessage'] = this.languageService.getValue('Validators.OneTimeVendorMessage.OneTimeVendorMessageIsNotPH')
        //   const currentError = { 'OneTimeVendorMessage': false }
        //   return currentError
        // }
        if (isPH) {
          config.errorMessage["OneTimeVendorMessage"] =
            this.languageService.getValue(
              "Validators.OneTimeVendorMessage.OneTimeVendorMessageIsPH"
            );
          const currentError = { OneTimeVendorMessage: false };
          return currentError;
        }
      }
    };
  }

  // @CustomValidator({ name: 'CompanyCodeErrorMessage', async: false })
  // public CompanyCodeErrorMessage(config: ComponentConfig, context: DynamicContext): ValidatorFn {
  //   return (control: AbstractControl): ValidationErrors | null => {
  //     let formGroup = control.parent;
  //     let companyCodes = context.getValue('parentModel').RequestForm.RequestorDetailsInfo.CompanyCode
  //     let fistCondition = companyCodes.find((companyCode: string) => {
  //       return companyCode == '3029' || companyCode == '3030' || companyCode == '3104'
  //     })
  //     let secondCondition = companyCodes.find((companyCode: string) => {
  //       return companyCode == '3003' || companyCode == '3004' || companyCode == '3005' || companyCode == '3040' || companyCode == '0700' || companyCode == '0701' ||
  //         companyCode == '0702' || companyCode == '0703' || companyCode == '0704' || companyCode == '0705' || companyCode == '0706' || companyCode == '0707' ||
  //         companyCode == '0708' || companyCode == '0709' || companyCode == '0710' || companyCode == '3100' || companyCode == '3105'
  //     })
  //     const OnetimeOrRecurringPurchases = formGroup.get('OnetimeOrRecurringPurchases').value
  //     if (OnetimeOrRecurringPurchases == '10') {
  //       if (fistCondition && secondCondition) {
  //         let a = control
  //         config.errorMessage['CompanyCodeErrorMessage'] = this.languageService.getValue('Validators.CompanyCodeErrorMessage.CompanyCodeErrorMessageFir&Sec')
  //         const currentError = { 'CompanyCodeErrorMessage': false }
  //         return currentError
  //       }
  //       if (fistCondition) {
  //         config.errorMessage['CompanyCodeErrorMessage'] = this.languageService.getValue('Validators.CompanyCodeErrorMessage.CompanyCodeErrorMessageFir')
  //         const currentError = { 'CompanyCodeErrorMessage': false }
  //         return currentError

  //       }
  //       if (secondCondition) {
  //         config.errorMessage['CompanyCodeErrorMessage'] = this.languageService.getValue('Validators.CompanyCodeErrorMessage.CompanyCodeErrorMessageSec')
  //         const currentError = { 'CompanyCodeErrorMessage': false }
  //         return currentError
  //       }
  //     }

  //   }
  // }

  @CustomValidator({ name: "additionalDocument", async: false })
  public additionalDocument(
    config: ComponentConfig,
    context: DynamicContext
  ): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const originalModel = context.parent.getValue("parentModel");
      const status = originalModel.Mapping.status;
      //for migrate
      if (originalModel.Mapping.IsMigrated) {
        return null;
      }
      if (status !== SupplierStatus.onboarded) {
        return null;
      }
      // eslint-disable-next-line max-len
      if (
        !originalModel.SupplierProfile.SupplierFinancial
          .PaymentBankingInformationList ||
        !originalModel.SupplierProfile.SupplierFinancial.PaymentBankingInformationList.filter(
          (p) =>
            !!control.parent.controls["paymentBankingInformationId"] &&
            p.paymentBankingInformationId ===
            control.parent.controls["paymentBankingInformationId"].value
        ) ||
        originalModel.SupplierProfile.SupplierFinancial.PaymentBankingInformationList.filter(
          (p) =>
            !!control.parent.controls["paymentBankingInformationId"] &&
            p.paymentBankingInformationId ===
            control.parent.controls["paymentBankingInformationId"].value
        ).length === 0
      ) {
        return null;
      }
      // eslint-disable-next-line max-len
      const originalFinancialInfo =
        originalModel.SupplierProfile.SupplierFinancial.PaymentBankingInformationList.filter(
          (p) =>
            p.paymentBankingInformationId ===
            control.parent.controls["paymentBankingInformationId"].value
        )[0];
      const bankNm = control.parent.get("BankNm").value;
      const accountHolderNm = control.parent.get("AccountHolderNm").value;
      const account = control.parent.get("Account").value;
      const bankKey = control.parent.get("BankKey").value;
      const BIC_SwiftCd = control.parent.get("BIC_SwiftCd").value;
      if (
        originalFinancialInfo["BankNm"] == bankNm &&
        originalFinancialInfo["AccountHolderNm"] == accountHolderNm &&
        originalFinancialInfo["Account"] == account &&
        originalFinancialInfo["BankKey"] == bankKey &&
        originalFinancialInfo["BIC_SwiftCd"] == BIC_SwiftCd
      ) {
        return null;
      }
      if (!originalFinancialInfo[config.name]) {
        return null;
      }
      const oldSize = originalFinancialInfo[config.name].length;
      const newSize = control.value.length;
      let path: any = [];
      let newPath: any = [];
      path = originalFinancialInfo[config.name];
      newPath = control.value;
      if (newSize === 0) {
        return null;
      }
      if (oldSize === newSize) {
        if (JSON.stringify(path) === JSON.stringify(newPath)) {
          return { additionalDocument: true };
        }
      }
      if (oldSize > newSize) {
        let sameFile = 0;
        newPath.forEach((element) => {
          if (path.filter((p) => p.filePath === element.filePath).length > 0) {
            sameFile++;
          }
        });
        if (sameFile === newSize) {
          return { additionalDocument: true };
        }
      }
      return null;
    };
  }

  @CustomValidator({
    name: "amendBusinessRegistrationDocumentsAfterOnboarded",
    async: false,
  })
  public amendBusinessRegistrationDocumentsAfterOnboarded(
    config: ComponentConfig,
    context: DynamicContext
  ): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const originalModel = context.getValue("parentModel");
      const status = originalModel.Mapping.status;
      //for migrate UploadRegistrationDocuments
      if (originalModel.Mapping.IsMigrated) {
        return null;
      }
      if (
        this.onBoardingService.supplierModel.RequestForm.RequestorDetailsInfo
          .SupplierGeographical === supplierGeographical.PH ||
        this.onBoardingService.supplierModel.RequestForm.RequestorDetailsInfo
          .SupplierGeographical === supplierGeographical.ASEAN
      ) {
        return null;
      }
      if (status !== SupplierStatus.onboarded) {
        return null;
      }
      if (context.getValue("isIN")) {
        return null;
      }
      const originalOrganization = context.getValue("originalOrganization");
      // has filter fields
      let supplierRegisteredCountry;
      let businessRegistrationType;
      if (control.parent.value.hasOwnProperty("SupplierRegisteredCountry")) {
        supplierRegisteredCountry = control.parent.get(
          "SupplierRegisteredCountry"
        ).value;
      } else {
        supplierRegisteredCountry = "";
        originalOrganization["SupplierRegisteredCountry"] = "";
      }
      if (control.parent.value.hasOwnProperty("BusinessRegistrationType")) {
        businessRegistrationType = control.parent.get(
          "BusinessRegistrationType"
        ).value;
      } else {
        businessRegistrationType = ""
        originalOrganization['BusinessRegistrationType'] = ""
      }
      if (control.parent.get('BusinessRegistrationNb')) {
        const businessRegistrationNb = control.parent.get('BusinessRegistrationNb').value
        if (originalOrganization['SupplierRegisteredCountry'] == supplierRegisteredCountry
          && originalOrganization['BusinessRegistrationType'] == businessRegistrationType
          && originalOrganization['BusinessRegistrationNb'] == businessRegistrationNb) {
          return null
        }
      }

      // const originalDocuments = originalOrganization['UploadRegistrationDocuments']
      // const currentDocuments = control.parent.get('UploadRegistrationDocuments').value
      // if (currentDocuments && originalDocuments && JSON.stringify(originalDocuments) !== JSON.stringify(currentDocuments)) {
      //   return null
      // }
      const oldFiles =
        this.profileService.supplierModel.SupplierProfile.SupplierOrganization[
        config.name
        ];
      if (!oldFiles) {
        return null;
      }
      const oldSize = oldFiles.length;
      const newSize = control.value.length;
      let path: any = [];
      let newPath: any = [];
      path = oldFiles;
      newPath = control.value;
      if (newSize === 0) {
        return null;
      }
      if (oldSize === newSize) {
        if (JSON.stringify(path) !== JSON.stringify(newPath)) {
          return null;
        }
      }
      if (oldSize > newSize) {
        let sameFile = 0;
        newPath.forEach((element) => {
          if (path.filter((p) => p.filePath === element.filePath).length > 0) {
            sameFile++;
          }
        });
        if (sameFile !== newSize) {
          return null;
        }
      }
      config.errorMessage["amendBusinessRegistrationDocumentsAfterOnboarded"] =
        this.languageService.getValue(
          "Validators.amendBusinessRegistrationDocumentsAfterOnboarded.amendBusinessRegistrationDocumentsAfterOnboarded"
        );
      return { amendBusinessRegistrationDocumentsAfterOnboarded: true };
    };
  }

  @CustomValidator({ name: "TypeOfServiceIsTEOrBoth", async: false })
  public TypeOfServiceIsTE(
    config: ComponentConfig,
    context: DynamicContext
  ): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const formGroup = control.parent;
      const purchaseType = formGroup.get("TypeOfServiceRequired").value;
      if (purchaseType === "T&E" || purchaseType === "Both") {
        config.errorMessage["TypeOfServiceIsTEOrBoth"] =
          this.languageService.getValue(
            "Validators.TypeOfServiceIsTEOrBoth.TypeOfServiceIsTEOrBoth"
          );
        const currentError = { TypeOfServiceIsTEOrBoth: false };
        return currentError;
      }
      if (purchaseType === "ResultBased") {
        return null;
      }
    };
  }

  @CustomValidator({ name: "WarningMessageForDeclaration", async: false })
  public WarningMessageForDeclaration(
    config: ComponentConfig,
    context: DynamicContext
  ): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      // let formGroup = control.parent
      // const purchaseType = formGroup.get('Declaration_1').value
      if (control.value === "10") {
        config.errorMessage["WarningMessageForDeclaration"] =
          this.languageService.getValue(
            "Validators.WarningMessageForDeclaration.WarningMessageForDeclaration"
          );
        const currentError = { WarningMessageForDeclaration: false };
        return currentError;
      } else {
        return null;
      }
    };
  }

  @CustomValidator({ name: "WarningForOtherPESONetregistered", async: false })
  public WarningForOtherPESONetregistered(
    config: ComponentConfig,
    context: DynamicContext
  ): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      // const supplierCountry = context.getValue('parentModel').RequestForm.RequestorDetailsInfo.SupplierCountry[0]

      if (control.value && control.value === "Y") {
        config.errorMessage["WarningForOtherPESONetregistered"] =
          this.languageService.getValue(
            "Validators.WarningForOtherPESONetregistered.WarningForOtherPESONetregistered"
          );
        const currentError = { WarningForOtherPESONetregistered: false };
        return currentError;
      } else {
        return null;
      }
    };
  }

  @CustomValidator({ name: "WarningForContractNegotiations", async: false })
  public WarningForContractNegotiations(
    config: ComponentConfig,
    context: DynamicContext
  ): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const formGroup = control.parent;
      let SupplierGeographical = formGroup.get("SupplierGeographical").value;
      let SupplierCountry = formGroup.get("SupplierCountry").value;
      let SpendCommodityGroup = formGroup.get("SpendCommodityGroup").value;
      if (SupplierGeographical === "16" &&
        (SupplierCountry.includes("BE") || SupplierCountry.includes("LU") || SupplierCountry.includes("FR") || SupplierCountry.includes("NL") || SupplierCountry.includes("MU") || SupplierCountry.includes("MA") || SupplierCountry.includes("VN")) &&
        (control.value === "N" || control.value === "12") && (!!SpendCommodityGroup && !SpendCommodityGroup.includes("21") && !SpendCommodityGroup.includes("13"))) {
        if (SupplierCountry.includes("MU") || SupplierCountry.includes("MA") || SupplierCountry.includes("VN")) {
          config.errorMessage["WarningForContractNegotiations"] = 'Procurement team has to be involved for any amount >10K$ (and from 0$ for Technology and Flexible Workforce categories), if appropriate please log a Request into <a href="https://support.accenture.com/procurement?id=e2e_guided_questions_page" target = "_blank">BSR tool</a>. If Procurement is not involved on your category (Real Estate, Sponsorship, Meeting & Events) or if the amount is below the Procurement involvement threshold, please do not hesitate to contact Legal in order to generate the appropriate contract.';
        } else {
          config.errorMessage["WarningForContractNegotiations"] = 'Procurement team has to be involved for any amount >25K$ (and from 0$ for Technology and Flexible Workforce categories), if appropriate please log a Request into <a href="https://support.accenture.com/procurement?id=e2e_guided_questions_page" target = "_blank">BSR tool</a>. If Procurement is not involved on your category (Real Estate, Sponsorship, Meeting & Events) or if the amount is below the Procurement involvement threshold, please do not hesitate to contact Legal in order to generate the appropriate contract.';
        }
        const currentError = { WarningForContractNegotiations: false };
        return currentError;
      } else {
        return null;
      }
    };
  }

  @CustomValidator({
    name: "WarningForBusinessRegistrationSection",
    async: false,
  })
  public WarningForBusinessRegistrationSection(
    config: ComponentConfig,
    context: DynamicContext
  ): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const supplierCountry =
        context.getValue("parentModel").RequestForm.RequestorDetailsInfo
          .SupplierCountry[0];

      if (supplierCountry === "Ca" && control.value && control.value === "Ca") {
        config.errorMessage["WarningForBusinessRegistrationSection"] =
          this.languageService.getValue(
            "Validators.WarningForBusinessRegistrationSection.WarningForBusinessRegistrationSectionCA"
          );
        const currentError = { WarningForBusinessRegistrationSection: false };
        return currentError;
      } else if (
        supplierCountry === "Ca" &&
        control.value &&
        control.value !== "Ca"
      ) {
        config.errorMessage["WarningForBusinessRegistrationSection"] =
          this.languageService.getValue(
            "Validators.WarningForBusinessRegistrationSection.WarningForBusinessRegistrationSectionNotCA"
          );
        const currentError = { WarningForBusinessRegistrationSection: false };
        return currentError;
      } else {
        return null;
      }
    };
  }

  @CustomValidator({ name: "WarningMessageForISOrGDPR", async: false })
  public WarningMessageForISOrGDPR(
    config: ComponentConfig,
    context: DynamicContext
  ): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      // let formGroup = control.parent
      // const purchaseType = formGroup.get('Declaration_1').value
      // console.log(control.value);
      if (
        SpendCategoryGroup[control.value] &&
        this.onBoardingService.checkTaskIsComplete(
          ProcessNames.generic_supplierSetup,
          SetupProcess.requestor_draft
        ) &&
        !this.onBoardingService.checkTaskIsComplete(
          ProcessNames.generic_supplierSetup,
          SetupProcess.supplier_accepted
        )
      ) {
        config.errorMessage["WarningMessageForISOrGDPR"] =
          this.languageService.getValue(
            "Validators.WarningMessageForISOrGDPR.WarningMessageForISOrGDPR"
          );
        const currentError = { WarningMessageForISOrGDPR: false };
        return currentError;
      } else {
        return null;
      }
    };
  }

  @CustomValidator({ name: "EEstimatedMsg", async: false })
  public EEstimatedMsg(
    config: ComponentConfig,
    context: DynamicContext
  ): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const isNA = context.getValue("isCanada") || context.getValue("isUnitedStates");
      const SupplierCountryArray =
        context.getValue("parentModel").RequestForm.RequestorDetailsInfo
          .SupplierCountry;
      let SupplierCountry = "";
      if (SupplierCountryArray.length === 1) {
        SupplierCountry = SupplierCountryArray[0];
      }
      const formGroup = control.parent;
      if (isNA && SupplierCountry === "Ca" && formGroup) {
        const purchaseType = formGroup.get("OnetimeOrRecurringPurchases").value;
        const TotalSpendOfSupplier = formGroup.get(
          "TotalSpendOfSupplier"
        ).value;
        if (purchaseType === "10" && TotalSpendOfSupplier === "210") {
          config.errorMessage["EEstimatedMsg"] = this.languageService.getValue(
            "Validators.EEstimatedMsg.EEstimatedMsg"
          );
          const currentError = { EEstimatedMsg: false };
          return currentError;
        }
      }
    };
  }

  @CustomValidator({ name: "supplierCompanyNameExists", async: true })
  public supplierCompanyNameExists(
    config: ComponentConfig,
    context: DynamicContext
  ): AsyncValidatorFn {
    let prevValue;
    let currentError;
    return (
      control: AbstractControl
    ):
      | Promise<ValidationErrors | null>
      | Observable<ValidationErrors | null> => {
      return new Promise((resolve, reject) => {
        if (!control.value) {
          console.log("control.value");
          return resolve(null);
        }
        if (prevValue === control.value) {
          if (control.untouched) {
            return resolve(null);
          }
          if (!control.valid && currentError) {
            return resolve(currentError);
          }
        }
        prevValue = control.value;
        this.onBoardingService.checkCompanyName(control.value).then((res) => {
          if (!res) {
            config.errorMessage["supplierCompanyNameExists"] =
              this.languageService.getValue(
                "Validators.supplierCompanyNameExists.supplierCompanyNameExists"
              );
            currentError = { supplierCompanyNameExists: false };
            return resolve(currentError);
          } else {
            // console.log(res);
            currentError = null;
            return resolve(null);
          }
        });
      });
    };
  }

  @CustomValidator({ name: "matchNumericalAlphabet", async: false })
  public matchNumericalAlphabet(
    config: ComponentConfig,
    context: DynamicContext
  ): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const pattern = /^(?![^A-z]+$)(?!\D+$)[A-z\d]{10}$/;
      if (
        context.getValue("isIN") &&
        !!control.value &&
        !control.value.match(pattern)
      ) {
        config.errorMessage["matchNumericalAlphabet"] =
          this.languageService.getValue(
            "Validators.matchNumericalAlphabet.matchNumericalAlphabet"
          );
        const currentError = { matchNumericalAlphabet: true };
        return currentError;
      }
      return null;
    };
  }

  @CustomValidator({ name: "matchSpecialNumericalAlphabet", async: false })
  public matchSpecialNumericalAlphabet(
    config: ComponentConfig,
    context: DynamicContext
  ): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (control.value) {
        if (control.value.length === 15) {
          const value: string = control.value;
          const stateCode =
            context.getValue("parentModel").SupplierProfile.SupplierGeneral
              .IndiaState;
          const formGroup = control.parent;
          const PANCode = formGroup.get("CompanyPANNumber").value;
          const pattern = /^[A-z0-9]{1,}$/;
          if (
            value.substring(0, 2) === stateCode &&
            value.substring(2, 12) === PANCode &&
            !!value.substring(12, 15).match(pattern)
          ) {
            return null;
          } else {
            config.errorMessage["matchSpecialNumericalAlphabet"] =
              this.languageService.getValue(
                "Validators.SpecialNumericalAlphabet.SpecialNumericalAlphabet"
              );
            const currentError = { matchSpecialNumericalAlphabet: true };
            return currentError;
          }
        } else {
          config.errorMessage["matchSpecialNumericalAlphabet"] =
            this.languageService.getValue(
              "Validators.SpecialNumericalAlphabet.SpecialNumericalAlphabet"
            );
          const currentError = { matchSpecialNumericalAlphabet: true };
          return currentError;
        }
      }
      return null;
    };
  }

  @CustomValidator({ name: "GSTRegistrationNumberValidator", async: false })
  public GSTRegistrationNumberValidator(
    config: ComponentConfig,
    context: DynamicContext
  ): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (control.value) {
        if (control.value.length === 15) {
          const value: string = control.value;
          const formGroup = control.parent;
          const State = formGroup.get("AdditionalState").value;
          const PANCode = formGroup.parent.parent.get("CompanyPANNumber").value;
          const pattern = /^[A-z0-9]{1,}$/;
          if (
            value.substring(0, 2) === State &&
            value.substring(2, 12) === PANCode &&
            !!value.substring(12, 15).match(pattern)
          ) {
            return null;
          } else {
            config.errorMessage["matchSpecialNumericalAlphabet"] =
              this.languageService.getValue(
                "Validators.SpecialNumericalAlphabet.SpecialNumericalAlphabet"
              );
            const currentError = { matchSpecialNumericalAlphabet: true };
            return currentError;
          }
        } else {
          config.errorMessage["matchSpecialNumericalAlphabet"] =
            this.languageService.getValue(
              "Validators.SpecialNumericalAlphabet.SpecialNumericalAlphabet"
            );
          const currentError = { matchSpecialNumericalAlphabet: true };
          return currentError;
        }
      }
      return null;
    };
  }

  @CustomValidator({ name: "dateValidator", async: false })
  public dateValidator(
    config: ComponentConfig,
    context: DynamicContext
  ): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (control.dirty && !!control.value) {
        const formGroup = control.parent;
        const currentDate = moment(new Date()).utc().format("YYYY-MM-DD");
        // start date trigger
        if (config.name == 'plannedStartDate') {
          const utcStartDate: any = control.value
          const utcEndDate = formGroup.get('plannedEndDate').value
          // remove plannedEndDate errors 
          if (utcEndDate && !(utcEndDate.isBefore(utcStartDate))) {
            let endDateObj = formGroup.get('plannedEndDate')
            endDateObj.setErrors(null)
          }
          // start date must > current date
          if (utcStartDate.isBefore(currentDate)) {
            config.errorMessage["dateValidator"] =
              "Planned start date can not be less than Current System date";
            return { dateValidator: true };
          }
          // start date must < end date
          if (utcEndDate && utcStartDate.isAfter(utcEndDate)) {
            config.errorMessage['dateValidator'] = 'Planned start date can not be more than Planned end date'
            return { 'dateValidator': true }
          }
        }
        // end date trigger
        else if (config.name == 'plannedEndDate') {
          const utcEndDate: any = control.value
          const utcStartDate = formGroup.get('plannedStartDate').value
          // remove plannedStartDate errors 
          if (utcStartDate && !(utcStartDate.isBefore(currentDate) || utcStartDate.isAfter(utcEndDate))) {
            let startDateObj = formGroup.get('plannedStartDate')
            startDateObj.setErrors(null)
          }
          // start date must < end date
          if (utcStartDate && utcEndDate.isBefore(utcStartDate)) {
            config.errorMessage["dateValidator"] =
              "Planned end date can not be less than Planned start date";
            return { dateValidator: true };
          }
        }
      }
      return null;
    };
  }

  @CustomValidator({ name: "futureValidator", async: false })
  public featureValidator(
    config: ComponentConfig,
    context: DynamicContext
  ): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (control.dirty && !!control.value) {
        const selectedDate: any = control.value
        const currentDate = moment(new Date()).utc().format("YYYY-MM-DD")
        if (selectedDate && selectedDate.isBefore(currentDate)) {
          config.errorMessage['futureValidator'] = this.languageService.getValue('Validators.futureValidator.futureValidator')
          return { 'futureValidator': true }
        }
      }
      return null;
    };
  }

  @CustomValidator({ name: "NumberErrorMessage", async: false })
  public numberCheck(
    config: ComponentConfig,
    context: DynamicContext
  ): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (control.value) {
        if (control.value.length === 11) {
          const pattern = /^\d{11}$/;
          if (control.value.match(pattern)) {
            return null;
          } else {
            config.errorMessage["NumberErrorMessage"] =
              this.languageService.getValue(
                "Validators.NumberErrorMessage.NumberErrorMessage"
              );
            const currentError = { NumberErrorMessage: true };
            return currentError;
          }
        } else {
          config.errorMessage["NumberErrorMessage"] =
            this.languageService.getValue(
              "Validators.NumberErrorMessage.NumberErrorMessage"
            );
          const currentError = { NumberErrorMessage: true };
          return currentError;
        }
      }
      return null;
    };
  }

  @CustomValidator({ name: "CompanyLegalNameErrorMessage", async: false })
  public companyLegalNameErrorMessage(
    config: ComponentConfig,
    context: DynamicContext
  ): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (control.value) {
        const valueReg = /^[ 0-9\u0100-\u017F\a-z_A-Z\,.&\(\)\[\]\{\}\-\@~!#%<>*\?\/\\]+$/
        if (!valueReg.test(control.value)) {
          config.errorMessage["CompanyLegalNameErrorMessage"] =
            this.languageService.getValue(
              "Validators.CompanyLegalNameErrorMessage.CompanyLegalNameErrorMessage"
            );
          return { CompanyLegalNameErrorMessage: true };
        }
      }
      return null;
    };
  }

  @CustomValidator({ name: "subCategoryCRWarningMessage", async: false })
  public subCategoryCRWarningMessage(
    config: ComponentConfig
  ): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (control.value == '5002' || control.value == '5021') {
        SpendCategoryGroup['5002'] = false
        SpendCategoryGroup['5021'] = false
      }
      if (SpendCategoryGroup[control.value]) {
        config.errorMessage['subCategoryCRWarningMessage'] = this.languageService.getValue('Validators.WarningMessageForsubCategoryCR.WarningMessageForsubCategoryCR')
        return { subCategoryCRWarningMessage: false }
      }
    }
  }

  @CustomValidator({ name: "subCategoryCRWarningMessageIndependentContractor", async: false })
  public subCategoryCRWarningMessageIndependentContractor(
    config: ComponentConfig
  ): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (control.value == 'N') {
        config.errorMessage['subCategoryCRWarningMessageIndependentContractor'] = this.languageService.getValue('Validators.WarningMessageForsubCategoryCR.WarningMessageForsubCategoryCR')
        return { subCategoryCRWarningMessageIndependentContractor: false }
      }
    }
  }

}
