import { MultipleDropdownComponent } from "./../../../../shared/components/multiple-dropdown/multiple-dropdown.component";
import {
  Component,
  OnInit,
  HostListener,
  Input,
  ViewChild,
} from "@angular/core";
import { SupplierProfile } from "src/app/interfaces/supplierProfile";
import { BuyerService } from "src/app/services/buyer.service";
import { DashBoardService } from "../../services/dashboard.service";
import { CommonService } from "src/app/services/common.service";
import * as moment from "moment";
import { RoleName, SearchTermDS, RoleCode } from "src/app/interfaces/buyer";
import { AuthService } from "src/app/services/auth/auth.service";
import {
  SupplierStatus,
  privacyStatementState,
  InvitedStatusMapping,
} from "src/app/interfaces/mapping";
import { ActivatedRoute } from "@angular/router";
import { DictionaryService } from "src/app/services/dictionary.service";
import { cloneDeep } from "src/app/utils";
import { LoadingService } from "src/app/services/loading.service";
import { isArray } from "lodash";
import { LaunchDarklyService } from "src/app/services/launch-darkly.service";

type SearchTermType = { [key: string]: any };

export interface SearchTerm extends SearchTermType {
  supplierName?: string;
  companyCode?: Array<string>;
  SAPID?: string;
  category?: Array<string>;
  BRN?: string;
  createBuyer?: string;
  status?: string;
  detailStatus?: Array<string>;
  isMigrated?: boolean;
  isOnHold?: boolean;
  isInvited?: boolean;
  InvitedStatus?: string;
  includeDuplicateProfiles?: boolean;
  mixfasttrack?: string; // Reflecting the new database column
}

enum DropdownTermType {
  CompanyCode = "companyCode",
  Category = "category",
  DetailStatus = "detailStatus",
}

@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.sass"],
})
export class DashboardComponent implements OnInit {
  @ViewChild("multipleDropDown") multipleDropDown: MultipleDropdownComponent;
  isEmptyChoice: boolean;
  suppliers: any = [];
  searchTermDS: SearchTermDS = {};

  currentStatus: "all" | "Pending" | "Onboarded" = "all";
  dropdownTermType = DropdownTermType;

  privacyStatement = privacyStatementState;

  isRequestor = false;
  isMigratedAndInactive = false;
  isMigratedAndInvitedSent = false;
  isOnHold = false;
  showWarning = false;
  isSearch = false;
  isEmpty = false;
  includeDuplicateProfiles = false;
  isNewVersion: boolean = true;
  mixfasttrack= false;
  total = 0;
  isSCTeamRole= false;

  query: {
    searchTerm: SearchTerm;
    currentPage: number;
    itemsPerPage: number;
    orderType: string;
    needInit: boolean;
  } = {
      searchTerm: {},
      currentPage: 1,
      itemsPerPage: 9,
      orderType: "DESC",
      needInit: false,
    };
  dashBoardService: DashBoardService;
  dicts: any[] = []
  showIncludeDuplicate: boolean;
 
  constructor(
    private buyerService: BuyerService,
    private auth: AuthService,
    private _dashBoardService: DashBoardService,
    private activatedRoute: ActivatedRoute,
    private commonService: CommonService,
    private dictionaryService: DictionaryService,
    private authService: AuthService,
    public loadingService: LoadingService,
    private launchDarklyService: LaunchDarklyService
  ) {
    this.dashBoardService = _dashBoardService;
    this.launchDarklyService.ldChange.subscribe((any) => {
      this.refreshToggle();
    });
  }
  refreshToggle(): void {
    this.isNewVersion =
      this.launchDarklyService.getToggle(
        LaunchDarklyService.LD_ISNEWVERSION
      ) === "true";
  }

  async ngOnInit() {
    this.dashBoardService.isDashBoard = true;
    this.dashBoardService.supplierName = "";
    this.dashBoardService.isSearchInTsm = false;
    this.dashBoardService.isTsmEnterOnboarding = false;
    this.dicts = await this.dictionaryService.getDictionary("SearchTerm");
    if (this.dicts) {
      this.dicts.map((t) => {
        this.searchTermDS[t.Code] = cloneDeep(t.Items);
      });
    }
    this.showIncludeDuplicate = this.auth.passport.buyer.RoleCode !== RoleName.Requestor;
    this.isSCTeamRole = this.auth.passport.buyer.RoleCode == RoleCode.Supply_Chain_Team_Reader;
   
    if (this.auth.passport.buyer.Status === "new") {
      if (
        this.auth.passport.buyer.RoleCode === RoleName.Requestor ||
        this.auth.passport.buyer.RoleCode ===
        RoleName.SourcingCategoryManagement ||
        this.auth.passport.buyer.RoleCode === RoleName.BuyerAdmin ||
        this.auth.passport.buyer.RoleCode === RoleName.SystemAdmin
      ) {
        this.privacyStatement.privacyStatement = true;
        this.privacyStatement.isDisplayAccept = false;
      }
      this.privacyStatement.privacyStatement = true;
    }
    if (this.auth.passport.buyer.Status === "accept") {
      if (
        this.auth.passport.buyer.RoleCode === RoleName.Requestor ||
        this.auth.passport.buyer.RoleCode ===
        RoleName.SourcingCategoryManagement ||
        this.auth.passport.buyer.RoleCode === RoleName.BuyerAdmin ||
        this.auth.passport.buyer.RoleCode === RoleName.SystemAdmin
      ) {
        this.privacyStatement.isDisplayAccept = true;
      }
    }
    this.loadData();
  }


  // the basic search
  loadData() {
    this.loadingService.showLoading();
    if (this.currentStatus !== "all") {
      this.query.searchTerm.status = this.currentStatus;
    }
    if (this.currentStatus === "all") {
      delete this.query.searchTerm.status;
    }

    if (!this.isMigratedAndInactive || !this.isMigratedAndInvitedSent) {
      delete this.query.searchTerm.isMigrated;
      delete this.query.searchTerm.isInvited;
      delete this.query.searchTerm.InvitedStatus;
    }

    if (this.isMigratedAndInactive) {
      this.query.searchTerm.isMigrated = true;
      this.query.searchTerm.isInvited = false;
      this.query.searchTerm.InvitedStatus = InvitedStatusMapping.new;
    }
    if (this.isMigratedAndInvitedSent) {
      this.query.searchTerm.isMigrated = true;
      this.query.searchTerm.InvitedStatus = InvitedStatusMapping.invited;
    }
    if (this.query.searchTerm.SAPID === "") {
      delete this.query.searchTerm.SAPID;
    }
    if (this.query.searchTerm.BRN === "") {
      delete this.query.searchTerm.BRN;
    }
    if (this.dashBoardService.supplierName === "") {
      delete this.query.searchTerm.supplierName;
    }
    if (this.isOnHold) {
      this.query.searchTerm.isOnHold = true;
    }
    if (!this.isOnHold) {
      delete this.query.searchTerm.isOnHold;
    }
    if (this.includeDuplicateProfiles) {
      this.query.searchTerm.includeDuplicateProfiles = true;
    }
    if (!this.includeDuplicateProfiles) {
      delete this.query.searchTerm.includeDuplicateProfiles
    }
    if (this.mixfasttrack) {
      this.query.searchTerm.mixfasttrack = "true";
    } else {
      delete this.query.searchTerm.mixfasttrack;
    }
    let search: any;
    if (this.isNewVersion) {
      search = this.buyerService.newSupplierSearch(this.query)
    } else { 
      search = this.buyerService.supplierSearch(this.query)
    }
    search.then((data: any) => {
      this.suppliers = data.data.results;
      this.total = data.data.total;
      this.isEmpty = !!this.suppliers && this.suppliers.length <= 0 ? true : false;
      this.loadingService.hideLoading();

      setTimeout(() => {
        const dashdiv = document.getElementsByClassName("my-pagination")[0];
        if (dashdiv) {
          const dashcontrol = dashdiv.childNodes[0];
          if (dashcontrol) {
            const dashtemplate = dashcontrol.childNodes[0];
            if (dashtemplate) {
              const dashul = dashtemplate.childNodes[0];
              if (dashul) {
                for (let i = 0; i < dashul.childNodes.length; i++) {
                  let dashli = dashul.childNodes[i];
                  if (dashli.nodeName == 'LI') {
                    (dashli as any).role = "group";
                  }
                }
              }
            }
          }
        }
      });
    });
  }

  simpleSearch() {
    this.dashBoardService.supplierName =
      this.dashBoardService.supplierName.trim();
    if (
      !this.dashBoardService.supplierName ||
      this.dashBoardService.supplierName === ""
    ) {
      delete this.query.searchTerm.supplierName;
    } else {
      // Bug 2661210 - [Production Defect] Search Feature not working when input include special character '
      this.query.searchTerm.supplierName = /['%_\\]/.test(this.dashBoardService.supplierName)
        ? [...this.dashBoardService.supplierName].reduce((pre, cur) => {
          return pre + (/[%_]/.test(cur)
            ? '\\' + cur
            : /[\\]/.test(cur)
              ? `\\\\`
              : /[']/.test(cur)
                ? "''"
                : cur)
        }, '')
        : this.dashBoardService.supplierName;
      this.query.currentPage = 1;
    }
    this.loadData();
  }

  // search by conditions
  search(page: number = 1) {
    this.query.currentPage = page
        if (this.isRequestor) {
      this.query.searchTerm.createBuyer = this.auth.passport.buyer.Email;
    } else {
      delete this.query.searchTerm.createBuyer;
    }
 
    if (this.dashBoardService.supplierName !== "") {
      // fix bug 2661210 [Production Defect] Search Feature not working when input include special character '
      this.query.searchTerm.supplierName = /['%_\\]/.test(this.dashBoardService.supplierName)
        ? [...this.dashBoardService.supplierName].reduce((pre, cur) => {
          return pre + (/[%_]/.test(cur)
            ? '\\' + cur
            : /[\\]/.test(cur)
              ? `\\\\`
              : /[']/.test(cur)
                ? "''"
                : cur)
        }, '')
        : this.dashBoardService.supplierName;
    }

    this.loadData();
  }

  changeFilter(status) {
    this.currentStatus = status;
    this.search();
  }

  changeOrder(order) {
    this.query.orderType = order;
    this.search();
  }

  async clear() {
    this.dashBoardService.supplierName = "";
    this.isRequestor = false;
    this.query.searchTerm.detailStatus = [];
    this.query.searchTerm = {};
    this.includeDuplicateProfiles = false;
    this.isEmptyChoice = true;
    this.dicts = await this.dictionaryService.getDictionary("SearchTerm");
    if (this.dicts) {
      this.dicts.map((t) => {
        this.searchTermDS[t.Code] = cloneDeep(t.Items);
      });
    }
    this.isMigratedAndInactive = false;
    this.isMigratedAndInvitedSent = false;
    this.isOnHold = false;
    this.mixfasttrack= false;
  }

  saveSectionEvent(e, type) {
    this.isEmptyChoice = false;
    this.query.searchTerm[type] = e;
  }

  pageChange(event) {
    this.search(event);
  }

  accept() {
    this.privacyStatement.isDisplayAccept = false;
    this.auth.passport.buyer.Status = "understand";
    this.buyerService.updateUserStatus(this.auth.passport.buyer.Email);
  }
}
