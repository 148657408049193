import { Injectable } from "@angular/core";
import { Router, ActivatedRoute, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { LoginStatus, AuthService } from "src/app/services/auth/auth.service";
import {
  DialogService,
  ButtonCommands,
  DialogType,
} from "src/app/services/dialog.service";
import { SupplierService } from "src/app/services/supplier.service";
import { privacyStatementState } from "src/app/interfaces/mapping";
import { RebarAuthService } from "src/app/core/rebarauth/rebar.auth.service";
import { LaunchDarklyService } from "src/app/services/launch-darkly.service";

@Injectable({
  providedIn: "root",
})
export class SupplierGuardService {
  user: any = {};
  isNewVersion: boolean = true;
  private privacyStatement = privacyStatementState;
  constructor(
    private auth: AuthService,
    private dialogService: DialogService,
    private supplierService: SupplierService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private rebarService: RebarAuthService,
    private launchDarklyService: LaunchDarklyService
  ) {
    this.launchDarklyService.ldChange.subscribe(any => {
      this.refreshToggle()
    });
  }

  refreshToggle(): void {
    this.isNewVersion = this.launchDarklyService.getToggle(LaunchDarklyService.LD_ISNEWVERSION) === "true"
  }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    // if (!this.rebarService.isUserAuthenticated()) {
    //   await this.dialogService.dialog("Please login", ButtonCommands.Ok, DialogType.alert, "Confirm");
    //   this.router.navigate(["home"]);
    //   return false;
    // }

    const basicFlag = await this.rebarService.initialBasicInfo();
    if (!basicFlag) {
      this.router.navigate(["home"]);
      return basicFlag;
    }

    let multipleSupplierResult: any;
    if (route.paramMap.get("key1")) {
      this.privacyStatement.multipleSupplier = false;
      await this.initSupplier(
        route.params.supplierCode,
        this.auth.passport.profile.email
      );
      this.auth.passport.supplierList = []
      const supplierListData = this.auth.passport.supplier
      this.auth.passport.supplierList.push(supplierListData.Mapping)
    } else if(!window.location.href.includes("landing")) {
      multipleSupplierResult = await this.supplierService.multipleSupplierList(
        this.auth.passport.profile.email);   
      if (multipleSupplierResult.isSuccess) {        
        this.auth.passport.supplierList = multipleSupplierResult.data.result;
        if (multipleSupplierResult.data.result.length == 1){
          this.privacyStatement.multipleSupplier = false;
          await this.initSupplier(
            this.auth.passport.supplierList[0].supplierCode,
            this.auth.passport.profile.email
          );
        }
        else if (multipleSupplierResult.data.result.length > 1 ) {
          // this.privacyStatement.multipleSupplier = true
          // if (!window.location.href.includes("landing") && !window.location.href.includes("home")) {
            const currentSupplierCode = localStorage.getItem("currentSupplierCode");
            const supplier = this.auth.passport.supplierList.find(
              (s) => s.supplierCode == currentSupplierCode
            );
            if (!!currentSupplierCode && !!supplier) {
              await this.initSupplier(currentSupplierCode,
                supplier.supplierEmail || this.auth.passport.profile.email)
            }
          // }


        } else {
          this.privacyStatement.multipleSupplier = false
        }


      } else {
        if (route.paramMap.get("key1")) {
          this.auth.initCompleted.emit(false);
          await this.dialogService.dialog(
            multipleSupplierResult.error.errorMessage,
            ButtonCommands.Ok
          );
          window.location.href =
            "http://tsm-frontend-dev.s3-website.us-east-2.amazonaws.com/";
          return false;
        } else {
          if (
            window.location.href
              .split("/")
            [window.location.href.split("/").length - 1].includes("@") &&
            window.location.href.includes("landing")
          ) {
            return true;
          } else {
            this.auth.initCompleted.emit(false);
            await this.dialogService.dialog(
              multipleSupplierResult.error.errorMessage,
              ButtonCommands.Ok
            );
            this.router.navigate(["home"]);
            return false;
          }
        }
      }


    }

    // if (multipleSupplierResult.isSuccess) {
    //   this.auth.passport.supplierList = multipleSupplierResult.data.result;
    //   if (this.auth.passport.supplierList.length === 1) {
    //     this.privacyStatement.multipleSupplier = false;
    //     if (!this.auth.passport.supplier) {
    //       await this.initSupplier(
    //         this.auth.passport.supplierList[0].supplierCode,
    //         this.auth.passport.profile.email
    //       );
    //     }
    //   } else if (this.auth.passport.supplierList.length > 1 && !this.auth.passport.supplier) {
    //     if (!window.location.href.includes("landing") && !window.location.href.includes("home")) {
    //       const currentSupplierCode = localStorage.getItem("currentSupplierCode");
    //       const supplier = this.auth.passport.supplierList.find(
    //         (s) => s.supplierCode == currentSupplierCode
    //       );
    //       if (!!currentSupplierCode && !!supplier) {
    //         await this.initSupplier(currentSupplierCode,
    //           supplier.supplierEmail || this.auth.passport.profile.email)
    //       } else {
    //         this.privacyStatement.multipleSupplier = true;
    //       }
    //     }
    //     else {
    //       this.privacyStatement.multipleSupplier = true;
    //     }
    //   }
    // } else {
    //   if (route.paramMap.get("key1")) {
    //     this.auth.initCompleted.emit(false);
    //     await this.dialogService.dialog(
    //       multipleSupplierResult.error.errorMessage,
    //       ButtonCommands.Ok
    //     );
    //     window.location.href =
    //       "http://tsm-frontend-dev.s3-website.us-east-2.amazonaws.com/";
    //     return false;
    //   } else {
    //     if (
    //       window.location.href
    //         .split("/")
    //       [window.location.href.split("/").length - 1].includes("@") &&
    //       window.location.href.includes("landing")
    //     ) {
    //       return true;
    //     } else {
    //       this.auth.initCompleted.emit(false);
    //       await this.dialogService.dialog(
    //         multipleSupplierResult.error.errorMessage,
    //         ButtonCommands.Ok
    //       );
    //       this.router.navigate(["home"]);
    //       return false;
    //     }
    //   }
    // }
    return true;
  }

  async initSupplier(supplierCode: string, email: string) {
    let data: any;
    // call supplier init API based on SPO flag
    if(this.isNewVersion) {
      data = await this.supplierService.newInitSupplier(
        supplierCode,
        email
      );
    } else {
      data = await this.supplierService.initSupplier(
        supplierCode,
        email
      );
    }
    // console.log("initial supplier data is %s", data.data);
    if (data.isSuccess) {
      this.auth.passport.supplier = data.data.model;
      this.auth.passport.supplierMember = data.data.member;
      // console.log("data.data.member ===>", data.data.member);
      // console.log("!data.data.member.OID", !data.data.member.OID);
      if (data.data.member && !data.data.member.OID) {
        const smData = {
          supplierCode: this.auth.passport.supplier.Mapping.supplierCode,
          email: data.data.member.Email,
          OID: this.auth.passport.profile.sub,
        };
        this.supplierService.updateSupplierMemberOID(smData);
      }
      this.auth.initCompleted.emit(true);
    } else {
      this.auth.initCompleted.emit(false);
      await this.dialogService.dialog(
        data.error.errorMessage,
        ButtonCommands.Ok
      );
      this.router.navigate(["home"]);
      return false;
    }
    return data
  }
}
