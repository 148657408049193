import { Component, OnInit } from "@angular/core";
import { OnBoardingService } from "../../../../modules/buyer/services/onboarding.service";
import { BuyerService } from "../../../../services/buyer.service";
import { UntypedFormArray, UntypedFormGroup, UntypedFormControl } from "@angular/forms";
import { DynamicContext } from "../../../../dynamic-components/interfaces/DynamicContext";
import { ComponentConfig } from "../../../../dynamic-components/ComponentConfig";
import { BaseComponent } from "src/app/dynamic-components/components/base/base.component";
import { ProfileService } from "src/app/modules/supplier/services/profile.service";
import { cloneDeep } from "src/app/utils";
import { EmitService } from '../payment-banking-information-of-collapse/emit.service'

@Component({
  selector: "app-third-party-payment-option-of-collapse",
  templateUrl: "./third-party-payment-option-of-collapse.component.html",
  styleUrls: ["./third-party-payment-option-of-collapse.component.sass"],
  host: {
    "[style.width]": "config.width",
  },
})
export class ThirdPartyPaymentOptionOfCollapseComponent
  extends BaseComponent
  implements OnInit
{
  thirdPartyPaymentInformationListVO = [];
  thirdPartyPaymentInformationListFormArray: UntypedFormArray;
  thirdPartyPaymentInformationList: any[];
  hidePluBbutton = false;
  hideFlag = true;
  removeFlag = false;
  constructor(
    private onBoardingService: OnBoardingService,
    private buyerService: BuyerService,
    private profileService: ProfileService,
    private emitService: EmitService
  ) {
    super();
  }

  ngOnInit() {
    this.emitService.transferHide.subscribe((value) => {
      this.hideFlag = value
    })
    this.emitService.transferRemove.subscribe((value) => {
      this.removeFlag = value
    })
    this.thirdPartyPaymentInformationListFormArray =
      this.getThirdPartyPaymentInformationListFormArray();
    this.thirdPartyPaymentInformationList =
      this.getThirdPartyPaymentInformationList();
    if (
      this.thirdPartyPaymentInformationList &&
      this.thirdPartyPaymentInformationList.length > 0
    ) {
      this.initThirdPartyPaymentInformationBythirdPartyPaymentInformationList();
    }
    if(Array.isArray(this.context.get("parentModel").value.SupplierProfile.SupplierFinancial.PaymentBankingInformationList)) {
      const isOtherPESONetregistered =
      this.context.get("parentModel").value.SupplierProfile.SupplierFinancial
        .PaymentBankingInformationList[0].isOtherPESONetregistered;
      if (!!isOtherPESONetregistered && isOtherPESONetregistered == "N") {
        this.hidePluBbutton = true;
      }
    }
    // this is to hide add additional bank account action for CreditCardSupplier
    if(this.context.get("parentModel").value.RequestForm.RequestFormInfo.IsCreditCardSupplier === 'Y') {
      this.hidePluBbutton = true;
    }
  }

  ngAfterViewInit() {
    const isOtherPESONetregisteredControl = this.formGroup.get(
      "PaymentBankingInformationList.0.isOtherPESONetregistered"
    ) as UntypedFormControl;
    if (isOtherPESONetregisteredControl) {
      isOtherPESONetregisteredControl.valueChanges.subscribe((value) => {
        this.checkPESONetregistered(value);
      });
    }
  }

  getThirdPartyPaymentInformationList(): Array<any> {
    if (this.context.getValue("supplierModel")) {
      return this.context.getValue("supplierModel").SupplierFinancial
        .ThirdPartyPaymentInformationList;
    }
    return this.context.getValue("parentModel").SupplierProfile
      .SupplierFinancial.ThirdPartyPaymentInformationList;
  }
  getThirdPartyPaymentInformationListFormArray(): UntypedFormArray {
    return this.formGroup.get(this.config.name) as UntypedFormArray;
  }

  checkHideForSpecificCountry(hideFlag: boolean): boolean {
    const indiaCountry: string[] = ["IN", "LK"];
    const supplierCountry: string =
      (this.context.getValue("parentModel").RequestForm.RequestorDetailsInfo
        .SupplierCountry).toString();
        const isIndividual = this.context.getValue('isIndividual');
    return (indiaCountry.indexOf(supplierCountry) !== -1 && isIndividual) ? false : hideFlag;
  }

  isNotViewAndformView(): boolean {
    if (
      this.context.mode !== "view" &&
      this.context.mode !== "formView"
    ) {
      if (this.removeFlag) {
        this.thirdPartyPaymentInformationListVO = []
        this.thirdPartyPaymentInformationListFormArray.controls = []
      }
      return this.checkHideForSpecificCountry(this.hideFlag);
    } else {
      return false;
    }
  }
  checkPESONetregistered(v: any) {
    if (v == "N") {
      while (this.thirdPartyPaymentInformationListVO.length > 0) {
        this.removeThirdPartyPaymentInformation(
          this.thirdPartyPaymentInformationListVO[0]
        );
      }
      this.hidePluBbutton = true;
    } else {
      this.hidePluBbutton = false;
    }
  }
  initThirdPartyPaymentInformationBythirdPartyPaymentInformationList() {
    for (const thirdPartyPaymentInformation of this
      .thirdPartyPaymentInformationList) {
      const thirdPartyPaymentInformationformGroup = new UntypedFormGroup({});
      const idFormControl = new UntypedFormControl();
      idFormControl.patchValue(
        thirdPartyPaymentInformation.thirdPartyPaymentInformationId
      );
      thirdPartyPaymentInformationformGroup.addControl(
        "thirdPartyPaymentInformationId",
        idFormControl
      );
      idFormControl.patchValue(
        thirdPartyPaymentInformation.thirdPartyPaymentId
      );
      thirdPartyPaymentInformationformGroup.addControl(
        "thirdPartyPaymentId",
        idFormControl
      );
      this.thirdPartyPaymentInformationListFormArray.push(
        thirdPartyPaymentInformationformGroup
      );
      if (
        !thirdPartyPaymentInformation.ThirdPartyType &&
        !thirdPartyPaymentInformation.ThirdPartyCompanyName &&
        !thirdPartyPaymentInformation.ThirdPartyCompanyAddress &&
        !thirdPartyPaymentInformation.ThirdPartyPhoneNumber &&
        !thirdPartyPaymentInformation.ThirdPartyEmailAddress &&
        !thirdPartyPaymentInformation.PartyTax &&
        !thirdPartyPaymentInformation.ThirdPartyTaxAttachments &&
        !thirdPartyPaymentInformation.ThirdPartyBankCountry &&
        !thirdPartyPaymentInformation.ThirdPartyBankNm &&
        !thirdPartyPaymentInformation.ThirdPartyBankAds &&
        !thirdPartyPaymentInformation.ThirdPartyAccountHolderNm &&
        !thirdPartyPaymentInformation.ThirdPartyAccount &&
        !thirdPartyPaymentInformation.ThirdPartyBankKey &&
        !thirdPartyPaymentInformation.ThirdPartyIBAN &&
        !thirdPartyPaymentInformation.ThirdPartyBIC_SwiftCd &&
        !thirdPartyPaymentInformation.ThirdPartyABARouting &&
        !thirdPartyPaymentInformation.ThirdPartyCurrencyUsedForPayments &&
        !thirdPartyPaymentInformation.ThirdPartyAddFinancialAttachments &&
        !thirdPartyPaymentInformation.ThirdPartyAddressEvidenceAttachments
      ) {
        continue;
      }
      const context = new DynamicContext();
      context.parent = this.context;
      const thirdPartyPaymentInformationDTO = {
        thirdPartyPaymentInformation: thirdPartyPaymentInformation,
        formGroup: thirdPartyPaymentInformationformGroup,
        context: context,
        config: cloneDeep(this.config.fieldset),
        dashboradConfig: this.initViewConfig(
          thirdPartyPaymentInformation,
          cloneDeep(this.config.fieldset)
        ),
        isthirdPartyPaymentInformationShow: true,
      };
      this.thirdPartyPaymentInformationListVO.push(
        thirdPartyPaymentInformationDTO
      );
    }
    if (!this.profileService.isView) {
      this.context.mode = "edit";
    }
  }
  initThirdPartyPaymentInformationByclicking() {
    const thirdPartyPaymentInformationformGroup = new UntypedFormGroup({});
    const idFormControl = new UntypedFormControl();
    // let thirdIdFormControl = new FormControl()
    idFormControl.patchValue(Math.random(), {
      onlySelf: true,
      emitEvent: true,
    });
    thirdPartyPaymentInformationformGroup.addControl(
      "thirdPartyPaymentInformationId",
      idFormControl
    );
    // thirdIdFormControl.patchValue(Math.random(), { onlySelf: true, emitEvent: true })
    thirdPartyPaymentInformationformGroup.addControl(
      "thirdPartyPaymentId",
      idFormControl
    );
    this.thirdPartyPaymentInformationListFormArray.push(
      thirdPartyPaymentInformationformGroup
    );
    const context = new DynamicContext();
    context.parent = this.context;
    const thirdPartyPaymentInformationDTO = {
      thirdPartyPaymentInformation: {},
      formGroup: thirdPartyPaymentInformationformGroup,
      context: context,
      config: cloneDeep(this.config.fieldset),
      isthirdPartyPaymentInformationShow: true,
    };
    this.thirdPartyPaymentInformationListVO.push(
      thirdPartyPaymentInformationDTO
    );
  }
  // eslint-disable-next-line @typescript-eslint/no-shadow
  initViewConfig(
    thirdPartyPaymentInformation: any,
    config: Array<ComponentConfig>
  ) {
    const conf = new Array<ComponentConfig>();
    config.forEach((p) => {
      if (p.fieldset) {
        p.fieldset.forEach((z) => {
          if (
            thirdPartyPaymentInformation[z.name] instanceof Array &&
            thirdPartyPaymentInformation[z.name].length > 0
          ) {
            conf.push(z);
          }
          if (
            !(thirdPartyPaymentInformation[z.name] instanceof Array) &&
            thirdPartyPaymentInformation[z.name]
          ) {
            conf.push(z);
          }
        });
      } else {
        if (
          thirdPartyPaymentInformation[p.name] instanceof Array &&
          thirdPartyPaymentInformation[p.name].length > 0
        ) {
          conf.push(p);
        }
        if (
          !(thirdPartyPaymentInformation[p.name] instanceof Array) &&
          thirdPartyPaymentInformation[p.name]
        ) {
          conf.push(p);
        }
      }
    });
    return conf;
  }
  addThirdPartyPaymentInformation() {
    // let id
    // if (this.thirdPartyPaymentInformationListFormArray.length === 0 ) {
    //   id = 0
    // } else {
    //   id = this.thirdPartyPaymentInformationListFormArray.controls[this.thirdPartyPaymentInformationListFormArray.length - 1 ]['controls']['thirdPartyPaymentInformationId'].value + 1
    // }
    this.initThirdPartyPaymentInformationByclicking();
  }
  removeThirdPartyPaymentInformation(thirdPartyPaymentInformation: any) {
    if (this.thirdPartyPaymentInformationListFormArray.length === 0) {
      return;
    }
    const removedId = thirdPartyPaymentInformation.formGroup.get(
      "thirdPartyPaymentInformationId"
    ).value;
    this.thirdPartyPaymentInformationListVO =
      this.thirdPartyPaymentInformationListVO.filter(
        (p) =>
          p.formGroup.get("thirdPartyPaymentInformationId").value !== removedId
      );
    const index =
      this.thirdPartyPaymentInformationListFormArray.controls.findIndex(
        (p) => p.get("thirdPartyPaymentInformationId").value === removedId
      );
    this.thirdPartyPaymentInformationListFormArray.removeAt(index);
  }
}
