import { Component,Input,OnInit } from '@angular/core';
import {ProcessStatus,NewSetupProcessId} from "src/app/interfaces/workflow";
import { OnBoardingService } from "../../../services/onboarding.service";
import { PeoplePickerConfig } from 'src/app/interfaces/peoplePickerConfig';
import { AuthService } from "src/app/services/auth/auth.service";
import { BuyerService } from "src/app/services/buyer.service";
import { ActivatedRoute } from '@angular/router';
import { LanguageService } from "../../../../../dynamic-components/utils/language.service";
import { isPplusUser } from "../../../services/verifyProcurementPlus"
import { RoleName } from 'src/app/interfaces/buyer';
import { CommonService } from 'src/app/services/common.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-csns-onboarding-process',
  templateUrl: './csns-onboarding-process.component.html',
  styleUrls: ['./csns-onboarding-process.component.sass']
})

export class CsnsOnboardingProcessComponent implements OnInit {
 
  checkSupplier:any;
  showPopup:boolean=false;
  currentStatus:string="";
  currentStepTitle:string="";
  eStepStatus=ProcessStatus;
  steps:any;
  isLast:boolean=false;
  isPlusClicked:boolean=false;
  StepId = NewSetupProcessId;
  peoplePickerConfig: PeoplePickerConfig={      
    name: 'peoplePicker',  
    hide: false,  
    readOnly: false,
    showType: 'email'
  };  
  currentUserEmail: string = '';  
  isFieldInvalid: boolean = false;
  reason: string = '';  
  supplierCode: string;
  PPlusAction: any;
  currentApprover: boolean = false;
  private currentApproverSubscription: Subscription;
  
  constructor(private buyerService: BuyerService,
    private route: ActivatedRoute,
    private authService: AuthService,
    private onboardingService: OnBoardingService,
    private languageService: LanguageService,
    private commonService:CommonService
  ){
    this.currentApproverSubscription = this.commonService.currentApprover$.subscribe(
      (newValue: boolean) => {
        if (this.PPlusAction && this.PPlusAction.Status === "in_progress") {
          this.currentApprover = newValue;
        } else {
          this.currentApprover = false;
        }
      });
  }
  
  @Input() set supplierSteps(value)
  {
  this.steps=value;
  let currentStep= Object.assign([], this.supplierSteps).reverse().find((item) =>  (item.Status==this.eStepStatus.completed || item.Status==this.eStepStatus.rejected));
  if(currentStep!=undefined)
  {
    this.currentStatus=this.languageService.getValue("static.process.SupplierSetupSteps."+currentStep?.StepTitle+".header");
    if(currentStep.Status==this.eStepStatus.rejected)
    {
    this.currentStepTitle=this.languageService.getValue("static.process.SupplierSetupSteps."+currentStep?.StepTitle+".reject_body");
    }
    else
    {
      this.currentStepTitle=this.languageService.getValue("static.process.SupplierSetupSteps."+currentStep?.StepTitle+".body");
    }
  }
 let step=this.steps.filter(st=>st.StepId===this.StepId.Procurement_Plus_Review);
 if(step!=undefined)
 {
  this.PPlusAction=step[0];
 }

  }
  get supplierSteps()
  {
    return this.steps;
  }
  async togglePopUp()
  {
    // await this.onboardingService.loadNewSupplierRequestForm();
    // this.supplierSteps=this.onboardingService.supplierSteps;
    this.isPlusClicked=true;
    this.showPopup=true;
  }

  async ngOnInit(): Promise<void> 
  {
    this.supplierCode = this.route.snapshot.paramMap.get("supplierCode");
    this.checkSupplier = await this.buyerService.getExistingSupplier(this.supplierCode);
    console.log('checkSupplier', this.checkSupplier?.data);
    if (this.checkSupplier?.data === true && this.authService.passport.buyer.RoleCode === RoleName.ProcurementReviewerEditor) {
      await this.commonService.checkAccess(this.authService.passport.profile.email, this.supplierCode)
    };
  // let currentStep= Object.assign([], this.supplierSteps).reverse().find((item) =>  (item.Status==this.eStepStatus.completed || item.Status==this.eStepStatus.rejected));
  //  this.currentStatus="static.process.SupplierSetupSteps."+currentStep.StepTitle+".header";
  //  this.currentStepTitle="static.process.SupplierSetupSteps."+currentStep.StepTitle+".body";
  }

  onModelValueChange(selectedValue: string) {
    this.currentUserEmail = selectedValue;    
    console.log('Selected value: ', this.currentUserEmail);  
  }  

  validationState = {
    currentUserEmail: false,
    reason: false,
  };
  async SaveAdditionIntervantion(){
    this.resetValidationState();
    this.validationState.currentUserEmail = !this.currentUserEmail;
    this.validationState.reason = !this.reason;
    if (Object.values(this.validationState).some((isInvalid) => isInvalid)) {
      return;
    }

    const payload = {
      suppliercode: this.supplierCode,
      additionInterventionEmailId: this.currentUserEmail,
      reasons: this.reason,
      createdby : this.authService.passport.buyer.Email
    };
      let result: any;
      result = await this.buyerService.SaveAdditionIntervantion(payload);
      if (result.isSuccess){
        await this.onboardingService.loadNewSupplierRequestForm();
        this.supplierSteps=this.onboardingService.supplierSteps;
      }
      this.showPopup =false;
      this.currentUserEmail = null;
      this.reason = null;

        this.resetValidationState();
        console.log('API call success:', result);    
  }
  closePopup(): void {
    this.isPlusClicked=false;
    this.showPopup =false;
    this.currentUserEmail = null;
    this.reason = null;
  } 

  async RemoveStep(sequenceId){
    try
    {
    const payload = {
      suppliercode: this.supplierCode,
      sequenceId: sequenceId,
      reasons: "",
      createdby : this.authService.passport.buyer.Email
    };
      let result: any;
      result = await this.buyerService.RemoveAdditionIntervantion(payload);
      if (result.isSuccess){
        await this.onboardingService.loadNewSupplierRequestForm();
        this.supplierSteps=this.onboardingService.supplierSteps;
      }
    }
    catch(err)
    {
      console.log('Error in removestep:', err); 

    }
  }
  
  resetValidationState() {
    this.validationState = {
      currentUserEmail: false,
      reason: false,
    };
  }


  checkPplus() {
      if (this.checkSupplier?.data === true) {
        return isPplusUser(this.authService.passport.buyer.RoleCode)
      }
      else {
        return false;
      }
    }
}
