// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.block-display {
  display: block !important;
}

.custom-form-group {
  width: 100%;
  margin: 10px 0;
}
.custom-form-group label {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
}
.custom-form-group .required {
  color: #d30e0e;
}
.custom-form-group .styled-textarea {
  width: 100%;
  max-width: 1200px;
  height: 120px;
  resize: none;
  overflow-y: auto;
  display: block;
  white-space: pre-wrap;
  background-color: #F6F5FA;
  padding: 5px 10px;
  border-radius: 5px;
  border: 1px solid #414187;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
