import { Directive, HostListener  } from '@angular/core';

@Directive({
  selector: '[appPreventHtmlInjection]',
  standalone: true
})
export class PreventHtmlInjectionDirective {

  constructor() { }

  @HostListener('keydown', ['$event'])
  onKeydown(event: KeyboardEvent): void {
    if (event.key === '<' || event.key === '>') {
      event.preventDefault();
    }
  }

}
