import { Component, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { CommonService } from "../../../../../services/common.service";
import { SupplierService } from "../../../../../services/supplier.service";
import { GeneralStatus } from "../../../../../interfaces/supplier";
import { SupplierGeneral } from "../../../../../interfaces/supplierProfile";
import { ProfileService } from "../../../services/profile.service";
import {
  AP_ReviewStatus,
  SupplierStatus,
  LoadingUrlMapping,
} from "src/app/interfaces/mapping";
import { cloneDeep, trim } from "src/app/utils";
import {
  DialogService,
  ButtonCommands,
  DialogType,
} from "src/app/services/dialog.service";
import { BuyerService } from "src/app/services/buyer.service";
import { AuthService } from "src/app/services/auth/auth.service";
import VMController from "src/app/interfaces/vm";
import { CanComponentDeactivate } from "src/app/can-deactivate.guard";
import { SpendCategoryGroup } from "src/app/interfaces/mapping";
import { RiskAssessmentStatus } from "src/app/interfaces/buyer";
import { OnBoardingService } from "../../../../buyer/services/onboarding.service";
import { environment } from "src/environments/environment";
import { LoadingService } from "src/app/services/loading.service";
import { AllTaskKey } from "src/app/interfaces/workflow";
import { DynamicContext } from "src/app/dynamic-components/interfaces/DynamicContext";
import { DynamicFormComponent } from "src/app/dynamic-components/components/dynamic-form/dynamic-form.component";
import { MetadataService } from "src/app/services/metadata.service";
import { ComponentConfig } from "src/app/dynamic-components/ComponentConfig";
import { UntypedFormGroup } from "@angular/forms";
import { UploaderComponent } from "src/app/shared/custom/components/uploader/uploader.component";
import { SupplierCommonService } from "../../../services/supplierCommon.service";
import { LanguageService } from "../../../../../dynamic-components/utils/language.service";
import { showGlobalPrepopulateData } from "../../../../../../config/config";
import { MatDialog } from "@angular/material/dialog";
import { GlobalPopupComponent } from "src/app/global-popup/global-popup.component";
import { LaunchDarklyService } from "src/app/services/launch-darkly.service";
@Component({
  selector: "app-profile-general",
  templateUrl: "./general.component.html",
  styleUrls: ["./general.component.sass"],
})
export class ProfileGeneralComponent implements OnInit, CanComponentDeactivate {
  private generalStatus: GeneralStatus = {};
  public disabled = "disabled";
  dictSoftwareOrWBTool: any;
  AP_ReviewStatus = AP_ReviewStatus;
  SupplierStatus = SupplierStatus;
  globalAnwsers = [];
  globalNameElement: any;
  dialogRef: any;
  getglobalVal: any;
  // Spend Category Group
  private spendCategoryGroup = SpendCategoryGroup;
  private riskAssessmentStatus: RiskAssessmentStatus = {};
  showAlertPanel = false;
  isDisabledSaveBtn = false;
  isValidSecondaryEmail = true;
  isNewVersion: boolean = true;
  uploadingFiles?: Array<any> = [];
  context: DynamicContext;

  isResubmitDisabled = true;
  @ViewChild("dynamicForm") dynamicForm: DynamicFormComponent;

  @ViewChild("uploader")
  uploaderComponent: UploaderComponent;

  getConfigsInfo: ComponentConfig[] = [];
  originalConfig: ComponentConfig[] = [];
  formGroup: UntypedFormGroup = new UntypedFormGroup({});
  vm: VMController<SupplierGeneral> = new VMController();
  preventMultiSave: boolean = true;
  get mode(): string {
    return this.profileService.mode;
  }

  get generalInfo(): SupplierGeneral {
    return this.profileService.supplierModel.SupplierProfile.SupplierGeneral;
  }

  set generalInfo(val) {
    this.profileService.supplierModel.SupplierProfile.SupplierGeneral = val;
  }

  get spendCategoryGroupValue(): string {
    if (
      this.context.getValue("parentModel").RequestForm.RequestorDetailsInfo
        .IndependentContractor == "Y"
    ) {
      SpendCategoryGroup["5002"] = false;
      SpendCategoryGroup["5021"] = false;
    } else {
      SpendCategoryGroup["5002"] = true;
      SpendCategoryGroup["5021"] = true;
    }
    return this.profileService.supplierModel.RequestForm.RequestorDetailsInfo
      .SpendCategoryGroup;
  }

  get isView() {
    if (!this.profileService.isView) {
      this.getConfigsInfo = this.originalConfig;
    }
    this.context.mode = this.mode;
    return this.profileService.isView;
  }

  get isLatamMuSelected() {
    return this.context.getValue("isLATAM");
  }

  constructor(
    private router: Router,
    private commonS: CommonService,
    private supplierService: SupplierService,
    private buyerService: BuyerService,
    private profileService: ProfileService,
    private commonService: CommonService,
    private dialogService: DialogService,
    private auth: AuthService,
    private onBoardingService: OnBoardingService,
    private loadingService: LoadingService,
    private metadataService: MetadataService,
    private supplierCommonService: SupplierCommonService,
    private languageService: LanguageService,
    private dialog: MatDialog,
    private launchDarklyService: LaunchDarklyService
  ) {
    this.profileService.stepChangeEvent.emit(1);
    this.launchDarklyService.ldChange.subscribe((any) => {
      this.refreshToggle();
    });
  }

  refreshToggle(): void {
    console.log(this.launchDarklyService.getToggle(
      LaunchDarklyService.LD_ISNEWVERSION
    ), '... isNewVersion');
    this.isNewVersion =
      this.launchDarklyService.getToggle(
        LaunchDarklyService.LD_ISNEWVERSION
      ) === "true";
  }

  async ngOnInit() {
    this.loadingService.showLoading();
    this.profileService.current_step = 1;
    this.context = new DynamicContext();
    this.context.mode = this.mode;
    if (!this.generalInfo.Phone) {
      this.generalInfo.Phone = "";

      // Commenting the below code as per the bug: 368406
      // Primary contact phone is not visible when click on view details from supplier side
      // this.generalInfo.PrimaryContactPhone = {
      //   AreaCode: "",
      //   CountryCode: "",
      // };
    }
    const [getConfigsInformation] = await Promise.all([
      this.metadataService.getPageConfiguration("seller-profile-step1"),
      this.profileService.loadUserTasks(),
      this.supplierCommonService.SetContextValue(this.context),
    ]);
    if (this.generalInfo.SoftwareOrWBTool) {
      this.changeValueStatus();
    }

    this.profileService.current_step = 1;
    // This is used to set IsInformationSecurity context in SH currenlty RA we moved to SPH
    // this.loadCheckQuestion();

    this.commonS.ExitEvent.subscribe((e) => {
      this.vm.setOriginal(this.dynamicForm.originalValues());
      this.vm.setCurrent(this.dynamicForm.values());
      if (this.vm.isDirty()) {
        e.preventDefault();
        // Chrome requires returnValue to be set
        e.returnValue = "";
      }
    });
    if (!this.isView) {
      this.dynamicForm.subscribeToFormChange(() => {
        setTimeout(() => {
          this.isResubmitDisabled =
            JSON.stringify(this.dynamicForm.originalValues()) ===
            JSON.stringify(this.dynamicForm.values());
        });
      });
    }
    // this.apReviewStatus = this.profileService.supplierModel.Mapping.AP_reviewStatus
    // get Status from db
    // this.supplierStatus = this.profileService.supplierModel.Mapping.status
    this.context.set(
      "isThailand",
      this.profileService.supplierModel.RequestForm.RequestorDetailsInfo.SupplierCountry?.includes(
        "TH"
      )
    );
    this.context.set(
      "MPhoneIsNull",
      this.profileService.supplierModel.SupplierProfile.SupplierGeneral.MPhone
    );
    this.getConfigsInfo = getConfigsInformation;
    this.originalConfig = cloneDeep(this.getConfigsInfo);
    if (!this.isView) {
      this.dynamicForm.subscribeToModelChange("AddGeneralAttachments", (v) => {
        if (v === "") {
          this.dynamicForm.formGroup
            .get("AddGeneralAttachments")
            .patchValue([]);
        }
      });
    }
    this.loadingService.hideLoading();

    if (
      !this.isView &&
      showGlobalPrepopulateData
    ) {
      this.formAutofill();
    }
  }

  async formAutofill() {
    const data = this.profileService.supplierModel;
    console.log(data);
    let payload = {
      supplierCode: this.profileService.supplierCode,
      SupplierCompanyName:
        this.profileService.supplierModel.SupplierProfile.SupplierGeneral
          .CompanyName,
      supplierTsmId: this.profileService.supplierModel.Mapping.TsmId,
      countryServed:
        this.profileService.supplierModel.RequestForm.RequestorDetailsInfo
          .SupplierCountry,
      RoleCode: this.auth.passport.supplierMember.RoleCode,
    };

    if (payload.supplierCode && payload.supplierTsmId) {
      let getGlobalQAs;
      if (localStorage.getItem("globalqa") == null) {
         getGlobalQAs = await this.supplierService.getGlobalFieldData(
          payload
        );
        localStorage.setItem("globalqa", JSON.stringify(getGlobalQAs));
      } else {
        getGlobalQAs = JSON.parse(localStorage.getItem("globalqa"));
      }
      if (this.getConfigsInfo && this.getConfigsInfo.length && getGlobalQAs) {
        this.getConfigsInfo.forEach((item) => {
          item["fieldset"].forEach((field) => {
            if (field["global"]) {
              let filterAnwsers = getGlobalQAs.filter(
                (question) => question["QuestionName"] == field["name"]
              );
              if (filterAnwsers && filterAnwsers.length) {
                filterAnwsers = filterAnwsers[0];
                let a;
                if (
                  filterAnwsers.QuestionName == "UploadCompanysAddress" ||
                  filterAnwsers.QuestionName == "AddGeneralAttachments"
                ) {
                  try {
                    a = JSON.parse(filterAnwsers.Answer);
                    filterAnwsers.Answer = a;
                    this.globalAnwsers.push(filterAnwsers);
                  } catch (error) {
                    this.globalAnwsers.push(filterAnwsers);
                  }
                } else {
                  this.globalAnwsers.push(filterAnwsers);
                }
              }
            }
          });
        });
      }
      let generalInfoData = Object.assign({}, this.generalInfo);
      this.globalAnwsers.forEach((anwserItem) => {
        if (anwserItem != null && anwserItem != undefined) {
          if (
            this.generalInfo[anwserItem["QuestionName"]] == "" ||
            this.generalInfo[anwserItem["QuestionName"]] == null ||
            this.generalInfo[anwserItem["QuestionName"]] == undefined
          ) {
            generalInfoData[anwserItem["QuestionName"]] = anwserItem["Answer"];
          }
        }
      });
      this.generalInfo = generalInfoData;
      setTimeout(() => {
        console.log("formAutofill Calling updateFormValues");
        if(this.dynamicForm) {
          this.dynamicForm.updateFormValues();
        }
      });
    }
    setTimeout(() => {
        // In Future need to get localStorage value from database when userlogin into the system 
      if (
        !localStorage.getItem("generalsaved") &&
        localStorage.getItem("generalsaved") !== "yes"
      ) {
        this.SaveFirsttime();
      }
      console.log("settimeout");
      const elements: HTMLInputElement[] = [];

      for (let gobalVal of this.globalAnwsers) {
        let formControlType: string;

        if (gobalVal["AnswerInputField"] === "dropdown") {
          formControlType = "select";
        } else if (gobalVal["AnswerInputField"] === "input") {
          formControlType = "input";
        } else if (gobalVal["AnswerInputField"] === "radio") {
          formControlType = "radio";
        }

        const actualElement = document.querySelector<HTMLInputElement>(
          `[id^="${gobalVal["QuestionName"]}${formControlType}"]`
        );

        if (actualElement) {
          elements.push(actualElement);
        }
      }

      for (const element of elements) {
        element.addEventListener("change", (e) => {
          const targetElement = e.currentTarget as HTMLElement;
          if (targetElement) {
            targetElement.style.border = "solid 1px #414187";
          }
        });
      }
    });
  }

  highlightInput() {
    const selectedElements = this.globalAnwsers.filter(
      (globalval) =>
        this.dynamicForm.formGroup.controls[globalval.QuestionName].value !==
        globalval.Answer
    );

    if (selectedElements.length > 0) {
      this.globalAnwsers.forEach((eachItem) => {
        const actualElement = document.querySelector<HTMLInputElement>(
          `[id^="${eachItem["QuestionName"]}${this.formControlType(eachItem)}"]`
        );
        if (actualElement) {
          actualElement.style.border =
            this.formControlType(eachItem) === "label"
              ? "none"
              : "solid 1px #414187";
          this.dynamicForm.formGroup.controls[eachItem.QuestionName].setValue(
            eachItem.Answer
          );
        }
      });
      setTimeout(() => {
        selectedElements.forEach((element) => {
          const actualElement = document.querySelector<HTMLInputElement>(
            `[id^="${element["QuestionName"]}${this.formControlType(element)}"]`
          );
          if (actualElement) {
            actualElement.style.border = "2px solid orange";
          }
        });
      }, 200);
    }
  }

  formControlType(globalValue) {
    const inputField = globalValue["AnswerInputField"];
    switch (inputField) {
      case "dropdown":
        return "select";
      case "radio":
        return "label";
      case "input":
      case "textbox":
        return "input";
      default:
        return "";
    }
  }

   isGlobalAnswersChange(initglobalanswers, changedglobalanswers): boolean {
    const hasChanges = initglobalanswers.some(
      (item) =>
        changedglobalanswers[item.QuestionName] &&
        changedglobalanswers[item.QuestionName].value !== item.Answer
    );
    return hasChanges;
  }
  
  
  openGlobalPopup() {
    this.dialogRef = this.dialog.open(GlobalPopupComponent, {
      width: "450px",
    });

    this.dialogRef.afterClosed().subscribe((result) => {
      if (result === "onConfirmClick") {
        if (this.isGlobalAnswersChange(this.globalAnwsers, this.dynamicForm.formGroup.controls)) {
          this.saveAndNavigate();
        }
      } else if (result === "onCancelClick") {
        this.highlightInput();
        for (this.getglobalVal of this.globalAnwsers) {
          const supplierControl =
            this.dynamicForm.formGroup.controls[this.getglobalVal.QuestionName];
          supplierControl.setValue(this.getglobalVal.Answer);
        }
      }
    });
  }

  saveAndNavigate() {
    this.saveDraft().then((data: boolean) => {
      if (data) {
        this.vm.saved();
        if(this.profileService.supplierModel.Mapping.setUpCountry.toString()  == 'BR' || this.profileService.supplierModel.Mapping.setUpCountry.toString()  == 'MX') {
          this.router.navigate([
            `supplier/profile/${this.profileService.supplierCode}/step1.5`,
          ]);
        } else {
        this.router.navigate([
          `supplier/profile/${this.profileService.supplierCode}/step2`,
        ]);
      } 
      }
    });
  }

  openGlobalPopupForResubmit() {
    this.dialogRef = this.dialog.open(GlobalPopupComponent, {
      width: "450px",
    });

    this.dialogRef.afterClosed().subscribe((result) => {
        if (result === "onConfirmClick") {
          if (this.isGlobalAnswersChange(this.globalAnwsers, this.dynamicForm.formGroup.controls)) {
            this.changeRequest().then((data: boolean) => {
              if (data) {
                this.vm.saved();
                this.dialogService
                  .dialog(
                    this.languageService.getValue(
                      "static.supplier.components.general.change_request"
                    ),
                    ButtonCommands.Ok,
                    DialogType.success,
                    this.languageService.getValue(
                      "static.supplier.components.general.please_note"
                    )
                  )
                  .then((result: boolean) => {
                    this.router.navigate([`supplier/landing`]);
                  });
              }
            });
          }
        } else if (result === "onCancelClick") {
          this.highlightInput();
          for (this.getglobalVal of this.globalAnwsers) {
            const supplierControl =
              this.dynamicForm.formGroup.controls[this.getglobalVal.QuestionName];
            supplierControl.setValue(this.getglobalVal.Answer);
          }
        }
    });
  }

  checkAndOpenGlobalPopup() {
    const globalDirty = this.globalAnwsers.some((globalAnswerVal) => {
      const control =
        this.dynamicForm.formGroup.controls[globalAnswerVal.QuestionName];
      if (
        control &&
        this.dynamicForm.formGroup.controls[globalAnswerVal.QuestionName]
          .value !== globalAnswerVal.Answer
      ) {
        if (
          globalAnswerVal.QuestionName === "AddGeneralAttachments" &&
          globalAnswerVal.Answer == "" &&
          control.value.length == 0
        ) {
          return false;
        }
        return true;
      } else {
        return false;
      }
    });
    return globalDirty;
  }

  get showResubmitBtn() {
    if (this.dynamicForm) {
      if (
        !this.isView &&
        !this.dynamicForm.disabled &&
        this.dynamicForm.formGroup
      ) {
        this.isResubmitDisabled =
          JSON.stringify(this.dynamicForm.originalValues()) ===
          JSON.stringify(this.dynamicForm.values());
      }
    }
    return this.profileService.checkResubmit();

    // workflow comments: remove supplier change request
    // return (this.apReviewStatus === AP_ReviewStatus.SST_reject || this.supplierStatus === 'Onboarded') && !this.isView && !this.profileService.checkIsMigrateSubmit()
  }

  loadCheckQuestion() {
    if (this.spendCategoryGroup[this.spendCategoryGroupValue]) {
      this.riskAssessmentStatus.IsInformationSecurity = true;
      this.context.set("IsInformationSecurity", true);
    } else {
      this.riskAssessmentStatus.IsInformationSecurity = false;
      this.context.set("IsInformationSecurity", false);
    }
  }

  async canDeactivate() {
    if (this.isView) {
      return true;
    }
    this.vm.setOriginal(this.dynamicForm.originalValues());
    this.vm.setCurrent(this.dynamicForm.values());
    if (this.vm.isDirty()) {
      const result = await this.dialogService.saveConfirm();
      if (result === "save") {
        if (!(await this.saveDraft())) {
          return false;
        }
      }
      if (result === "cancel") {
        return false;
      }
    }

    return true;
  }

  change() {
    this.disabled = "";
  }

  trimChildValue(value) {
    return value.trim();
  }

  btnNext() {
    let nextNavigationUrl;
    if(this.isLatamMuSelected) {
      nextNavigationUrl = `supplier/profile/${this.profileService.supplierCode}/step1.5`;
    } else {
      nextNavigationUrl = `supplier/profile/${this.profileService.supplierCode}/step2`;
    }
    
    this.router.navigate([
      nextNavigationUrl
    ]);
  }

  async btnSave() {
    if (this.checkAndOpenGlobalPopup()) {
      this.openGlobalPopup();
    } else {
      this.saveAndNavigate();
    }
  }

  // resubmit request form
  async resubmitForm(): Promise<boolean> {
    this.context.changeSubmitState();
    this.dynamicForm.removeFormTouch();
    if (!this.validate()) {
      return false;
    }
    await this.updateSupplierMapping();
    // get executable task GUID: taskInstId
    this.profileService.getResubmitTask();
    if (!this.profileService.supplierModel.Mapping.executeTask) {
      return false;
    }
    if (this.isNewVersion) {
      this.bindDataToSupplierProfileModel();
      await this.savenewresubmit();
    } else {
      await this.saveExistingresubmit();
    }
    return true;
  }

  async saveExistingresubmit() {
    const saveUrl = `${environment.gateway}${LoadingUrlMapping.resubmitSupplierProfile}`;
    this.loadingService.openLoading(saveUrl);
    const saveList = [
      await this.buyerService.saveRequestFormDraft(
        this.profileService.supplierModel
      ),
      await this.profileService.resubmitForm(),
    ];
    Promise.all(saveList);
  }

  async savenewresubmit() {
    const saveUrl = `${environment.supplierGateway}${LoadingUrlMapping.supplierProfileResubmit}`;
    this.loadingService.openLoading(saveUrl);
    const saveList = [
      await this.profileService.newresubmitForm(),
    ];
    Promise.all(saveList);
    this.loadingService.closeLoading()
  }

  bindDataToSupplierProfileModel() {
    const originalValues: SupplierGeneral = this.dynamicForm.originalValues();
    const currentValues = this.dynamicForm.values();
    this.vm.setOriginal(originalValues);
    this.vm.setCurrent(currentValues);
    
    this.profileService.createSupplierProfileModel();
    this.profileService.supplierProfileModel.SupplierProfile.SupplierGeneral = this.profileService.supplierModel.SupplierProfile.SupplierGeneral;
    this.profileService.supplierProfileModel.Mapping = this.profileService.supplierModel.Mapping;
  	  // In Future need to get localStorage value from database when userlogin into the system 
	    // and this change is done as part of 555132 Bug
      if (
	      !localStorage.getItem("generalsaved") &&
	      localStorage.getItem("generalsaved") !== "yes"
	    ) {
	      this.profileService.supplierProfileModel.ChangeHistory = this.commonService.getChangeHistoryForFirstSave(originalValues, currentValues, "SupplierGeneral");
	    } else {
	      this.profileService.supplierProfileModel.ChangeHistory = {}
    }

    /**
     * For new supplier navigating from SPH to SH
     * we don't have saved data for that supplier.
     * Hence, we can initialize the model not required to save it in a database.
     * As we don't have data at initial step
     */
    if(!this.vm.isChange()) {
      // Ignore the API call to save the data
      return false;
    }

    this.profileService.supplierProfileModel.ChangeHistory = this.commonService.getChangeHistory(originalValues, currentValues, "SupplierGeneral");
  }

  // resubmit request form
  async changeRequest(): Promise<boolean> {
    this.context.changeSubmitState();
    this.dynamicForm.removeFormTouch();
    if (!this.validate()) {
      return false;
    }
    await this.updateSupplierMapping();
    if (this.isNewVersion) {
      this.bindDataToSupplierProfileModel();
      await this.newChangeRequestform();
    } else {
      await this.existingChangeRequestform();
    }
    
    return true;
  }

  async newChangeRequestform(){
    const saveUrl = `${environment.supplierGateway}${LoadingUrlMapping.newRequestChangeSupplierProfile}`;
    this.loadingService.openLoading(saveUrl);
    await this.profileService.newchangeRequestForm(),
    this.loadingService.closeLoading()
  }

  async existingChangeRequestform(){
    const saveUrl = `${environment.gateway}${LoadingUrlMapping.requestChangeSupplierProfile}`;
    this.loadingService.openLoading(saveUrl);
    const saveList = [
      await this.buyerService.saveRequestFormDraft(
        this.profileService.supplierModel
      ),
      await this.profileService.changeRequestForm(),
    ];
    Promise.all(saveList);
  }

  // save draft request form
  async saveDraft(): Promise<boolean> {
    this.context.changeSubmitState();
    this.dynamicForm.removeFormTouch();
    if (!this.validate()) {
      return false;
    }
    await this.updateSupplierMapping();
    if (this.isNewVersion) {
      this.bindDataToSupplierProfileModel();
      if (JSON.stringify(this.profileService.supplierProfileModel.ChangeHistory)!="{}") {
        console.log('savenewdraft')
        // if(this.preventMultiSave == true) {
          await this.saveNewDraft();
        // }

      }
    } else {
      await this.saveExistingDraft();
    }
    this.loadingService.closeLoading();
    this.loadingService.hideLoading();
    return true;
  }

  async SaveFirsttime(): Promise<boolean> {
    this.profileService.supplierModel.SupplierProfile.SupplierGeneral = trim(
      this.dynamicForm.values()
    );
    if (this.isNewVersion) {
      this.bindDataToSupplierProfileModel();
      await this.saveNewDraft();
    } else {
      await this.saveExistingDraft();
    }
    this.dynamicForm.updateFormValues();
    this.loadingService.closeLoading();
    this.loadingService.hideLoading();
    localStorage.setItem("generalsaved", "yes");
    return true;
  }

  async saveExistingDraft() {
    const saveUrl = `${environment.gateway}${LoadingUrlMapping.saveProfileDraft}`;
    this.loadingService.openLoading(saveUrl);
    const saveList = [
      await this.profileService.saveDraft(),
    ];
    return saveList;
  }

  async saveNewDraft() {
    const saveUrl = `${environment.supplierGateway}${LoadingUrlMapping.generalSaveDraft}`;
    this.loadingService.openLoading(saveUrl);
    // this.preventMultiSave = false;
    await this.profileService.saveGeneralDraft()
  }

  // update supplier mapping info
  private async updateSupplierMapping() {
    this.profileService.supplierModel.SupplierProfile.SupplierGeneral = trim(
      this.dynamicForm.values()
    );
    this.generalInfo.PrimaryContactPhone.AreaCode = this.trimChildValue(
      this.generalInfo.PrimaryContactPhone.AreaCode
    );
    if (this.generalInfo.Phone) {
      this.generalInfo.Phone = this.trimChildValue(this.generalInfo.Phone);
    }
    if (this.generalInfo.MPhone) {
      this.generalInfo.MPhone = this.trimChildValue(this.generalInfo.MPhone);
    }
    if (this.profileService.profile_step === 1) {
      this.profileService.supplierModel.Mapping.profile.createdOn =
        new Date().toUTCString();
    }
      this.profileService.supplierModel.RequestForm = await this.loadBuyer();
      this.profileService.supplierModel.RequestForm.RequestorDetailsInfo.SupplierCompanyName =
        this.profileService.supplierModel.SupplierProfile.SupplierGeneral.CompanyName;
      this.profileService.supplierModel.RequestForm.RequestorDetailsInfo.SupplierContactFirstName =
        this.profileService.supplierModel.SupplierProfile.SupplierGeneral.PrimaryContactFirstNm;
      this.profileService.supplierModel.RequestForm.RequestorDetailsInfo.SupplierContactLastName =
        this.profileService.supplierModel.SupplierProfile.SupplierGeneral.PrimaryContactLastNm;
      this.profileService.supplierModel.RequestForm.RequestorDetailsInfo.SupplierContactPoint =
        this.profileService.supplierModel.SupplierProfile.SupplierGeneral.PrimaryContactNm;
  
    this.profileService.supplierModel.SupplierProfile.SupplierGeneral.PrimaryContactNm =
      this.profileService.supplierModel.SupplierProfile.SupplierGeneral.PrimaryContactFirstNm.concat(
        " ",
        this.profileService.supplierModel.SupplierProfile.SupplierGeneral
          .PrimaryContactLastNm
      );
    this.profileService.supplierModel.SupplierProfile.SupplierGeneral.PrimaryContactNm =
      this.generalInfo.PrimaryContactNm;

    this.profileService.supplierModel.Mapping.requestor.updatedBy =
      this.auth.passport.profile.email;
    this.profileService.supplierModel.Mapping.searchTerm.supplierName =
      this.profileService.supplierModel.SupplierProfile.SupplierGeneral.CompanyName;
  }

  async resubmit() {
    this.context.changeSubmitState();
    this.dynamicForm.removeFormTouch();
    if (!this.validate()) {
      return;
    }
    // resubmit
    if (this.checkAndOpenGlobalPopup()) {
      this.openGlobalPopupForResubmit();
    }

    if (
      this.profileService.getUserTaskByTaskKey(AllTaskKey.SST_rejectToSupplier)
    ) {
      this.resubmitForm().then((data: boolean) => {
        if (data) {
          this.vm.saved();
          this.dialogService
            .dialog(
              this.languageService.getValue(
                "static.supplier.components.general.change_request"
              ),
              ButtonCommands.Ok,
              DialogType.success,
              this.languageService.getValue(
                "static.supplier.components.general.please_note"
              )
            )
            .then((result: boolean) => {
              this.router.navigate([`supplier/landing`]);
            });
        }
      });
    } else {
      this.changeRequest().then((data: boolean) => {
        if (data) {
          this.vm.saved();
          this.dialogService
            .dialog(
              this.languageService.getValue(
                "static.supplier.components.general.change_request"
              ),
              ButtonCommands.Ok,
              DialogType.success,
              this.languageService.getValue(
                "static.supplier.components.general.please_note"
              )
            )
            .then((result: boolean) => {
              this.router.navigate([`supplier/landing`]);
            });
        }
      });
    }
  }

  validate() {
    return this.dynamicForm.valide();
  }

  async loadBuyer() {
    const data: any = await this.buyerService.queryRequestForm(
      this.profileService.supplierCode
    );
    if (data.isSuccess) {
      return data.data.RequestForm;
    }
  }

  setDatePicker(event) {
    this.generalInfo.DateFounded = event;
  }

  changeValueStatus() {
    const tempValue = this.generalInfo.SoftwareOrWBTool;
    if (tempValue === "10") {
      this.generalStatus.SoftwareOrWBTool = true;
      this.context.set("SoftwareOrWBTool", true);
    } else {
      this.generalStatus.SoftwareOrWBTool = false;
      this.context.set("SoftwareOrWBTool", false);
      // this.generalInfo.AddGeneralAttachments = []
    }
  }
}
