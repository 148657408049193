import { DialogFormComponent } from "./shared/components/dialog-form/dialog-form.component";
import { DialogInvitationComponent } from "./shared/components/dialog-invitation/dialog-invitation.component";
import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { HttpClient, provideHttpClient, withInterceptorsFromDi } from "@angular/common/http";
import { CommonService } from "./services/common.service";
import { HttpService } from "./services/api/http.service";
import { SupplierModule } from "./modules/supplier/supplier.module";
import { AppComponent } from "./app.component";
import { ErrorComponent } from "./views/error/error.component";
import { LoginComponent } from "./views/login/login.component";
import { BuyerModule } from "./modules/buyer/buyer.module";
import { RouterModule, Routes } from "@angular/router";
import { SharedModule } from "./shared/shared.module";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import {
  DialogService,
  ConfirmService,
  APReivewService,
  ChangeRequestAPReviewService,
  SendInvitationService,
  PPReviewService,
  AdditionalInterventionReviewService,
} from "./services/dialog.service";
import { DialogComponent } from "./shared/components/dialog/dialog.component";
import { NotificationService } from "./services/notification.service";
import { SystemModule } from "./modules/system/system.module";
import { ChangeRequestDialogFormComponent } from "./shared/components/change-request-dialog-form/change-request-dialog-form.component";
import { HoldOnService } from "./services/HoldOn";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { LanguageService } from "./dynamic-components/utils/language.service";
import { LanguageSwitchComponent } from "./views/language-switch/language-switch.component";
import { AppConfigService } from "./core/services/app-config.service";
import { APP_INITIALIZER } from "@angular/core";
import { MsalRedirectComponent, MsalModule } from "@azure/msal-angular";
import { RebarAuthModule } from "./core/rebarauth/rebar.auth.module";
import { MainComponent } from "./views/main/main.component";
import { AppGuard } from "./app.guard";
import { DatadogSessionService } from "./core/services/datadog-session.service";
import { ToastComponent } from "./toast/toast.component";
import { AlertComponent } from "./alert/alert.component";
import { ModalComponent } from "./modal/modal.component";
import { RiskAssessmentComponentCR } from "./modules/buyer/views/change-request/risk-assessmentCR/risk-assessmentCR.component";
import { timeoutPopupService } from "./services/timeout-popup.service";
import { GlobalPopupComponent } from './global-popup/global-popup.component';
import { DataService } from "./services/dataService";
import { PreventHtmlInjectionDirective } from "./directives/prevent-html-injection.directive"; 

export const routes: Routes = [
  { path: "", redirectTo: "home", pathMatch: "full" },
  { path: "home", component: MainComponent, canActivate: [AppGuard] },
  { path: "code", component: LoginComponent },
  { path: "state/:tsm", component: LoginComponent },
  { path: "languageSwitch", component: LanguageSwitchComponent },
  { path: "error/:code", component: ErrorComponent },
  { path: "**", redirectTo: "/home" },
];

@NgModule({ declarations: [
        AppComponent,
        MainComponent,
        ErrorComponent,
        LoginComponent,
        DialogComponent,
        DialogFormComponent,
        ChangeRequestDialogFormComponent,
        DialogInvitationComponent,
        LanguageSwitchComponent,
        ToastComponent,
        AlertComponent,
        ModalComponent,
        RiskAssessmentComponentCR,
        GlobalPopupComponent
    ],
    bootstrap: [AppComponent, MsalRedirectComponent], 
    imports: [BrowserModule,
        RouterModule.forRoot(routes, { useHash: true }),
        BuyerModule,
        SharedModule,
        FormsModule,
        ReactiveFormsModule,
        SupplierModule,
        SystemModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: createTranslateLoader,
                deps: [HttpClient],
            },
            useDefaultLang: true,
        }),
        RebarAuthModule.forRoot(),
        MsalModule, PreventHtmlInjectionDirective], 
    providers: [
        HttpService,
        CommonService,
        DialogService,
        DataService,
        ConfirmService,
        APReivewService,
        NotificationService,
        ChangeRequestAPReviewService,
        PPReviewService,
        AdditionalInterventionReviewService,
        SendInvitationService,
        HoldOnService,
        LanguageService,
        timeoutPopupService,
        {
            provide: APP_INITIALIZER,
            useFactory: (config: AppConfigService) => () => config.load(),
            deps: [AppConfigService],
            multi: true,
        },
        DatadogSessionService,
        provideHttpClient(withInterceptorsFromDi()),
    ] })
export class AppModule {}

export function createTranslateLoader(http: HttpClient) {
  console.log(".... inside app.module.ts function");
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}
