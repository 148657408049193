import { DynamicComponentService } from "src/app/dynamic-components/services/dynamic-component.service";
import { Injectable } from "@angular/core";
import { CustomDatasource } from "src/app/dynamic-components/interfaces/Decorators";
import { ComponentConfig } from "src/app/dynamic-components/ComponentConfig";
import { DynamicContext } from "src/app/dynamic-components/interfaces/DynamicContext";
import { UntypedFormGroup } from "@angular/forms";
import { DictionaryService } from "src/app/services/dictionary.service";
import { CommonService } from "src/app/services/common.service";
import { ProfileService } from "src/app/modules/supplier/services/profile.service";

@Injectable()
export class CustomDatasources {
  constructor(
    private dictionaryService: DictionaryService,
    private commonService: CommonService,
    private profileService: ProfileService
  ) {}

  addBlankItem(d: Array<any>) {
    if (d[0].Code && d[0].Text) {
      d.unshift({ Code: "", Text: "" });
    }
  }

  addOldVaueItem(d: Array<any> , formGroup: UntypedFormGroup , config: ComponentConfig,) {
    d.unshift({ Code: formGroup.controls[config.name].value, Text: formGroup.controls[config.name].value});
}

  @CustomDatasource("dictionary")
  dictionary(
    config: ComponentConfig,
    context: DynamicContext,
    formGroup: UntypedFormGroup
  ) {
    return async (params: any): Promise<any> => {
      const data = this.dictionaryService.getDictionaryFromCache(params);
      if (config.type != "multiple") {
        this.addBlankItem(data);
        if(config.name === 'InformationSecurity_1ce_1' && this.profileService.isView) {
          this.addOldVaueItem(data, formGroup, config)
        }
      }
      return data;
    };
  }
  @CustomDatasource("peopleNumberBuildup")
  peopleNumberBuildup(
    config: ComponentConfig,
    context: DynamicContext,
    formGroup: UntypedFormGroup
  ) {
    return async (): Promise<any> => {
      const params: string = (context.getValue("parentModel").Mapping.setUpCountry.includes("SE")) ? 'supplier-step2.PeopleNoSweden' : 'supplier-step2.PeopleNo';
      const data = this.dictionaryService.getDictionaryFromCache(params);
      if (config.type != "multiple") {
         this.addBlankItem(data);
      }
      return data;
    };
  }
  @CustomDatasource("sortedDictionary")
  orderDictionary(
    config: ComponentConfig,
    context: DynamicContext,
    formGroup: UntypedFormGroup
  ) {
    return async (params: any): Promise<any> => {
      const data = this.dictionaryService
        .getDictionaryFromCache(params)
        .sort((a, b) => a.Text.localeCompare(b.Text));
      this.addBlankItem(data);
      return data;
    };
  }
  @CustomDatasource("BRTDictionary")
  BRTDropDownList(
    config: ComponentConfig,
    context: DynamicContext,
    formGroup: UntypedFormGroup
  ) {
    return async (params: any): Promise<any> => {
      const BRTDropDownList =
        this.dictionaryService.getDictionaryFromCache(params);
      const currentValues =
        formGroup.get("SupplierRegisteredCountry") &&
        formGroup.get("SupplierRegisteredCountry").value;
      if (currentValues) {
        if (context.getValue("isPH") && currentValues === "PH") {
          formGroup.controls["BusinessRegistrationType"].patchValue("TI");
          const data = BRTDropDownList.filter((dict) => dict.Code === "PH1")[0]
            .Items;
          this.addBlankItem(data);
          return data;
        }
        if (currentValues === "CN" && config.type === 'dropdown') {
          // CODE CN0~CN5 are history data,new dropdown list doesn't need
          const data = BRTDropDownList.filter((dict) => dict.Code === "CN")[0]
            .Items.filter((dict) => Number(dict.Code.substring(2) > 5));
          this.addBlankItem(data);
          return data;
        }
         if (currentValues === "RS" && config.type != 'multiple') {        
           if (context.getValue("parentModel").Mapping.setUpCountry.includes("RS")) {        
             const data = BRTDropDownList.filter((dict) => dict.Code === "RS_serbia")[0]        
             .Items;        
           this.addBlankItem(data);        
           return data;        
           }        
           const data = BRTDropDownList.filter((dict) => dict.Code === "RS")[0]       
             .Items;        
           this.addBlankItem(data);        
           return data;        
         }

        if (currentValues === "HK" && config.type === 'dropdown') {
          const data = BRTDropDownList.filter((dict) => dict.Code === "HK")[0]
            .Items.filter((dict) => Number(dict.Code.substring(2) > 5));
          this.addBlankItem(data);
          return data;
        }
        if (currentValues === "TW" && config.type === 'dropdown') {
          const data = BRTDropDownList.filter((dict) => dict.Code === "TW")[0]
            .Items.filter((dict) => Number(dict.Code.substring(2) > 5));
          this.addBlankItem(data);
          return data;
        }
        const data = BRTDropDownList.filter(
          (dict) => dict.Code === currentValues
        )[0].Items;
        this.addBlankItem(data);
        return data;
      } else {
        return "";
      }
    };
  }

  @CustomDatasource("BRTDictionaryForHistory")
  BRTDictionaryForHistory(
    config: ComponentConfig,
    context: DynamicContext,
    formGroup: UntypedFormGroup
  ) {
    return async (params: any): Promise<any> => {
      const BRTDropDownList =
        this.dictionaryService.getDictionaryFromCache(params);
      this.addBlankItem(BRTDropDownList);
      const data_array = [];
      BRTDropDownList.forEach((item: any) => {
        data_array.push.apply(data_array, item.Items);
      });
      return data_array;
    };
  }

  @CustomDatasource("SpendCategoryGroupDictionary")
  spendCategoryGroupDictionary(
    config: ComponentConfig,
    context: DynamicContext,
    formGroup: UntypedFormGroup
  ) {
    return async (params: any): Promise<any> => {
      const spendCommodityGroup =
        formGroup.get("SpendCommodityGroup") &&
        formGroup.get("SpendCommodityGroup").value;
      if (!spendCommodityGroup) {
        return [];
      }
      const dicts =
        this.dictionaryService.getDictionaryFromCache("requestor-details");
      const SpendCommodityGroup = dicts.filter(
        (dict) => dict.Code === "SpendCommodityGroup"
      )[0].Items;
      const formControl = formGroup.get(config.name);
      const data = SpendCommodityGroup.filter(
        (item) => item.Code === spendCommodityGroup
      )[0].Items.filter((item) => {
        if (formControl.value === item.Code && item.IsDeprecated) {
          formControl.patchValue("");
        }
        return !item.IsDeprecated;
      });
      this.addBlankItem(data);
      return data;
    };
  }

  @CustomDatasource("SupplierGeographicalDictionary")
  SupplierGeographicalDictionary(
    config: ComponentConfig,
    context: DynamicContext,
    formGroup: UntypedFormGroup
  ) {
    return async (params: any): Promise<any> => {
      const dicts =
        this.dictionaryService.getDictionaryFromCache("requestor-details");
      const data = dicts
        .filter((dict) => dict.Code === "SupplierGeographical")[0]
        .Items.filter((item) => item.Items.length > 0)
        .sort((a, b) => a.Text.charCodeAt(0) - b.Text.charCodeAt(0));
      if(data.length !== 0){
        this.addBlankItem(data);
      }
      return data;
    };
  }

  @CustomDatasource("SupplierCountryDictionary")
  SupplierCountryDictionary(
    config: ComponentConfig,
    context: DynamicContext,
    formGroup: UntypedFormGroup
  ) {
    return async (params: any): Promise<any> => {
      const supplierGeographical =
        formGroup.get("SupplierGeographical") &&
        formGroup.get("SupplierGeographical").value;
      if (!supplierGeographical) {
        return [];
      }
      const result = [];
      const selectedValues = [];
      const supplierGeographicalList =
        this.dictionaryService.getDictionaryFromCache(
          "requestor-details.SupplierGeographical"
        );
      const currentValues = formGroup.get("SupplierCountry").value;
      const newValues = [];
      supplierGeographicalList.forEach((geographical: any) => {
        if (supplierGeographical.includes(geographical.Code)) {
          geographical.Items.forEach((item: any) => {
            result.push(item);
            selectedValues.push(item.Code);
            if (currentValues && currentValues.includes(item.Code)) {
              newValues.push(item.Code);
            }
          });
        }
      });
      formGroup.get("SupplierCountry").patchValue(newValues);
      return result;
    };
  }
  @CustomDatasource("SupplierGeographicalUKIDictionary")
  SupplierGeographicalUKIDictionary(
    config: ComponentConfig,
    context: DynamicContext,
    formGroup: UntypedFormGroup
  ) {
    return async (params: any): Promise<any> => {
      const dicts =
        this.dictionaryService.getDictionaryFromCache("requestor-details");
      const data = dicts
        .filter((dict) => dict.Code === "SupplierGeographicalUKI")[0]
        .Items.filter((item) => item.Items.length > 0)
        .sort((a, b) => a.Text.charCodeAt(0) - b.Text.charCodeAt(0));
      this.addBlankItem(data);
      return data;
    };
  }

  @CustomDatasource("SupplierGeographicalANZDictionary")
  SupplierGeographicalANZDictionary(
    config: ComponentConfig,
    context: DynamicContext,
    formGroup: UntypedFormGroup
  ) {
    return async (params: any): Promise<any> => {
      const dicts =
        this.dictionaryService.getDictionaryFromCache("requestor-details");
      const data = dicts
        .filter((dict) => dict.Code === "SupplierGeographicalANZ")[0]
        .Items.filter((item) => item.Items.length > 0)
        .sort((a, b) => a.Text.charCodeAt(0) - b.Text.charCodeAt(0));
      this.addBlankItem(data);
      return data;
    };
  }

  @CustomDatasource("SupplierCountryUKIDictionary")
  SupplierCountryUKIDictionary(
    config: ComponentConfig,
    context: DynamicContext,
    formGroup: UntypedFormGroup
  ) {
    return async (params: any): Promise<any> => {
      const supplierGeographical =
        formGroup.get("SupplierGeographical") &&
        formGroup.get("SupplierGeographical").value;
      if (!supplierGeographical) {
        return [];
      }
      const result = [];
      const selectedValues = [];
      const supplierGeographicalList =
        this.dictionaryService.getDictionaryFromCache(
          "requestor-details.SupplierGeographicalUKI"
        );
      const currentValues = formGroup.get("SupplierCountry").value;
      const newValues = [];
      supplierGeographicalList.forEach((geographical: any) => {
        if (supplierGeographical.includes(geographical.Code)) {
          geographical.Items.forEach((item: any) => {
            result.push(item);
            selectedValues.push(item.Code);
            if (currentValues && currentValues.includes(item.Code)) {
              newValues.push(item.Code);
            }
          });
        }
      });
      formGroup.get("SupplierCountry").patchValue(newValues);
      return result;
    };
  }

  @CustomDatasource("SupplierCountryANZDictionary")
  SupplierCountryANZDictionary(
    config: ComponentConfig,
    context: DynamicContext,
    formGroup: UntypedFormGroup
  ) {
    return async (params: any): Promise<any> => {
      const supplierGeographical =
        formGroup.get("SupplierGeographical") &&
        formGroup.get("SupplierGeographical").value;
      if (!supplierGeographical) {
        return [];
      }
      const result = [];
      const selectedValues = [];
      const supplierGeographicalList =
        this.dictionaryService.getDictionaryFromCache(
          "requestor-details.SupplierGeographicalANZ"
        );
      const currentValues = formGroup.get("SupplierCountry").value;
      const newValues = [];
      supplierGeographicalList.forEach((geographical: any) => {
        if (supplierGeographical.includes(geographical.Code)) {
          geographical.Items.forEach((item: any) => {
            result.push(item);
            selectedValues.push(item.Code);
            if (currentValues && currentValues.includes(item.Code)) {
              newValues.push(item.Code);
            }
          });
        }
      });
      formGroup.get("SupplierCountry").patchValue(newValues);
      return result;
    };
  }

  @CustomDatasource("CompanyCodeDictionary")
  companyCodeDictionary(
    config: ComponentConfig,
    context: DynamicContext,
    formGroup: UntypedFormGroup
  ) {
    return async (params: any): Promise<any> => {
      const supplierCountry =
        formGroup.get("SupplierCountry") &&
        formGroup.get("SupplierCountry").value;
      if (!supplierCountry) {
        return [];
      }
      let result = [];
      const selectedValues = [];
      const companyCodes = this.dictionaryService.getDictionaryFromCache(
        "requestor-details.CompanyCode"
      );
      const currentValues = formGroup.get("CompanyCode").value;
      const newValues = [];
      companyCodes.forEach((company: any) => {
        if (supplierCountry.includes(company.Code)) {
          company.Items.forEach((item: any) => {
            if (!item.IsDeprecated) {
              result.push(item);
              selectedValues.push(item.Code);
              if (currentValues && currentValues.includes(item.Code)) {
                newValues.push(item.Code);
              }
            }
          });
        }
      });
      formGroup.get("CompanyCode").patchValue(newValues);
      return result;
    };
  }

  @CustomDatasource("CompanyCodeDetailDictionary")
  CompanyCodeDetailDictionary(
    config: ComponentConfig,
    context: DynamicContext,
    formGroup: UntypedFormGroup
  ) {
    return async (params: any): Promise<any> => {
      const supplierCountry =
        formGroup.get("SupplierCountry") &&
        formGroup.get("SupplierCountry").value;
      if (!supplierCountry) {
        return [];
      }
      let result = [];
      const selectedValues = [];
      const companyCodes = this.dictionaryService.getDictionaryFromCache(
        "requestor-details.CompanyCode"
      );
      const currentValues = formGroup.get("CompanyCode").value;
      const newValues = [];
      companyCodes.forEach((company: any) => {
        if (supplierCountry.includes(company.Code)) {
          company.Items.forEach((item: any) => {
              result.push(item);
              selectedValues.push(item.Code);
              if (currentValues && currentValues.includes(item.Code)) {
                newValues.push(item.Code);
              }
          });
        }
      });
      formGroup.get("CompanyCode").patchValue(newValues);
      return result;
    };
  }

  @CustomDatasource("dictionaryBuildup")
  dictionaryBuildup(
    config: ComponentConfig,
    context: DynamicContext,
    formGroup: UntypedFormGroup
  ) {
    return async (params: any): Promise<any> => {
      let data: any;
      const dicts = this.dictionaryService.getDictionaryFromCache();
      if (params == "dictCountry") {
        data = dicts
          .filter((dict) => dict.Code === "supplier-step2")[0]
          .Items.filter((country) => country.Code === "Country")[0]
          .Items.sort((a, b) => a.Text.localeCompare(b.Text));
        this.addBlankItem(data);
      }
      if (params == "dictPhone") {
        data = dicts.filter((dict) => dict.Code === "CountryCode")[0].Items;
        this.addBlankItem(data);
      }
      if (params == "dictSoftwareOrWBTool") {
        data = dicts.filter((dict) => dict.Code === "SoftwareOrWBTool")[0]
          .Items;
        this.addBlankItem(data);
      }
      if (params == "IndiaState") {
        data = dicts
          .filter((dict) => dict.Code === "supplier-step2")[0]
          .Items.filter((country) => country.Code === "IndiaState")[0]
          .Items.sort((a, b) => a.Text.localeCompare(b.Text));
        this.addBlankItem(data);
      }
      return data;
    };
  }

  @CustomDatasource("totalSpendOfSupplierDictionary")
  totalSpendOfSupplierDictionary(
    config: ComponentConfig,
    context: DynamicContext,
    formGroup: UntypedFormGroup
  ) {
    return async (params: any): Promise<any> => {
      const TotalSpendOfSupplier =
        formGroup.get("TotalSpendOfSupplier") &&
        formGroup.get("TotalSpendOfSupplier").value;
      const oldVersionDicts = this.dictionaryService.getDictionaryFromCache(
        "requestor-form.EstimatedSpend"
      );
      const oldValue = oldVersionDicts.find(
        (item) => item.Code === TotalSpendOfSupplier
      );
      if (oldValue && TotalSpendOfSupplier) {
        this.addBlankItem(oldVersionDicts);
        return oldVersionDicts;
      }
      if (context.getValue("isGallia")) {
        const versionDictsGallia = this.dictionaryService.getDictionaryFromCache(
          "requestor-form.EstimatedSpend_v3"
        );
        this.addBlankItem(versionDictsGallia);
        return versionDictsGallia;
      }
      const newVersionDicts = this.dictionaryService.getDictionaryFromCache(
        "requestor-form.EstimatedSpend_v2"
      );
      this.addBlankItem(newVersionDicts);
      return newVersionDicts;
    };
  }

  @CustomDatasource("BankNameDictionary")
  BankNameDictionary(
    config: ComponentConfig,
    context: DynamicContext,
    formGroup: UntypedFormGroup
  ) {
    return async (params: any): Promise<any> => {
      const supplierCountry = formGroup.get("BankCountry").value;       
      if (!supplierCountry.length && (!supplierCountry.includes("BR") || !supplierCountry.includes("MX"))) {
        return [];
      }
      
      let result = [];
      let dictionaryKey = supplierCountry.includes("BR") ? 'bankNameBR' : supplierCountry.includes("MX") ? 'bankNameMX' : '';
      if(!dictionaryKey) return [];

      result = this.dictionaryService.getDictionaryFromCache(
        `supplier-step3.${dictionaryKey}`
      );
      
      if(result.length > 0) {
        return result;
      }
      return [];
    };
  }

  @CustomDatasource("RegionDictionary")
  RegionDictionary(
    config: ComponentConfig,
    context: DynamicContext,
    formGroup: UntypedFormGroup
  ) {
    return async (params: any): Promise<any> => { 
      const supplierCountry = formGroup.get("Country").value;     
      if (!supplierCountry.length && (!supplierCountry.includes("BR") || !supplierCountry.includes("MX"))) {
        return [];
      }
      
      let result = [];
      let dictionaryKey = supplierCountry.includes("BR") ? 'regionsForBrazil' : supplierCountry.includes("MX") ? 'regionsForMexico' : '';
      if(!dictionaryKey) return [];

      result = this.dictionaryService.getDictionaryFromCache(
        `supplier-step1.${dictionaryKey}`
      );
      
      if(result.length > 0) {
        return result;
      }
      return [];
    };
  }
  
  @CustomDatasource("SupplierTypeOfComanyDictionary")
  SupplierTypeOfComanyDictionary(
    config: ComponentConfig,
    context: DynamicContext,
    formGroup: UntypedFormGroup
  ) {
    return async (params: any): Promise<any> => {
      // retrieve GU
      const supplierGeographical =
        context.getValue("parentModel").Mapping.geographicalUnit;
      // console.log(
      //   " SupplierTypeOfComanyDictionary supplierGeographical:" +
      //     supplierGeographical,
      //   typeof supplierGeographical
      // );

      if (!supplierGeographical) {
        return [];
      }

      const dicts =
        this.dictionaryService.getDictionaryFromCache("supplier-step2");

      // filter deprecated item by GU
      switch (supplierGeographical) {
        case "12":
          let asCompanyTypeList = dicts.filter(
            (dict) => dict.Code === "ASCompanyType"
          )[0].Items;
          asCompanyTypeList = this.filterIsDeprecated(asCompanyTypeList);
          this.addBlankItem(asCompanyTypeList);
          return asCompanyTypeList;
        case "26":
          let ukCompanyTypeList = dicts.filter(
            (dict) => dict.Code === "UKCompanyType"
          )[0].Items;
          ukCompanyTypeList = this.filterIsDeprecated(ukCompanyTypeList);
          this.addBlankItem(ukCompanyTypeList);
          return ukCompanyTypeList;
        case "10":
          let africaCompanyTypeList = dicts.filter(
            (dict) => dict.Code === "UKCompanyType"
          )[0].Items;
          africaCompanyTypeList = this.filterIsDeprecated(
            africaCompanyTypeList
          );
          this.addBlankItem(africaCompanyTypeList);
          return africaCompanyTypeList;
        default:
          let companyTypeList = dicts.filter(
            (dict) => dict.Code === "CompanyType"
          )[0].Items;
          companyTypeList = this.filterIsDeprecated(companyTypeList);
          this.addBlankItem(companyTypeList);
          return companyTypeList;
      }
    };
  }

  // filter deprecated item
  private filterIsDeprecated(list: any[]) {
    if (this.profileService.isView) {
      return list;
    } else {
      return list.filter((item) => !item.IsDeprecated);
    }
  }
}
