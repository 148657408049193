import { Injectable } from '@angular/core';
import { SapResultItem, SapResultModel, SupplierMappingDTO, InvitedStatusMapping } from 'src/app/interfaces/mapping';
import {SapIdStatus,AP_ReviewStatus} from '../../../../interfaces/mapping'
@Injectable({
  providedIn: 'root'
})
export class SupplierDetailService {

  constructor() {}

  filterSapIdStatus(sapResultList: SapResultModel[], mappingDTO: SupplierMappingDTO): { activeSapResultList: SapResultItem[], blockedSapResultList: SapResultItem[] } {
    const activeSapResultList: SapResultItem[] = [];
    const blockedSapResultList: SapResultItem[] = [];
    const isMigrated: boolean = mappingDTO?.IsMigrated;
  
    if (sapResultList.length === 0) {
      return { activeSapResultList, blockedSapResultList };
    }
  
    const filteredSapResult = sapResultList[sapResultList.length - 1].sapResultList;
  
    filteredSapResult.forEach((res) => {
      if (res.sapIDStatus === SapIdStatus.Active) {
        console.log('Active', res.sapIDStatus);
        activeSapResultList.push(res);
      } else if (res.sapIDStatus === SapIdStatus.Blocked) {
        console.log('Blocked', res.sapIDStatus);
        blockedSapResultList.push(res);
      } else if (isMigrated && (!mappingDTO.IsInvited || mappingDTO.InvitedStatus === InvitedStatusMapping.invited)) {
        // If supplier is migrated and not invited or invitation sent then keep it ACTIVE
        activeSapResultList.push(res);
      }
    });
  
    return { activeSapResultList, blockedSapResultList };
  }
}
