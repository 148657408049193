import { Injectable, EventEmitter } from "@angular/core";
import { HttpService } from "./api/http.service";
import { environment } from "src/environments/environment";
import { OnBoardingStatus } from "../interfaces/supplier";
import { SupplierService } from "./supplier.service";
import { BehaviorSubject } from "rxjs";

@Injectable()
export class CommonService {
  ExitEvent = new EventEmitter();
  private showTransactions: boolean = true;

  constructor(private http: HttpService) { }

  getShowTransactions(): boolean {
    return this.showTransactions;
  }

  setShowTransactions(show: boolean): void {
    this.showTransactions = show;
  }

  exit(event) {
    this.ExitEvent.emit(event);
  }

  // getDictionary(code = null): Promise<any> {
  //   let url = `${environment.gateway}/dropdown/dictionary`

  //   if (code) {
  //     url = `${url}/${code}`
  //   }
  //   return this.http.GetPromise(url)
  // }

  checkTokenExpired() {
    const url = `${environment.gateway}/token/isExpired`;
    return this.http.GetPromise(url);
  }

  // logoutToken() {
  //   const url = `${environment.gateway}/token/invalid`
  //   return this.http.GetPromise(url)
  // }

  isBuyer() {
    if (environment.role === "buyer") {
      return true;
    }
    return false;
  }
  getSupplierAttribute(data: any): Promise<any> {
    const url = `${environment.gateway}/b2c/getSupplierAttribute`;
    return this.http.PostPromise(url, data);
  }

  logoutToken() {
    const url = `${environment.gateway}/token/invalid`;
    return this.http.GetPromise(url);
  }

  public getCSNSP3Flag(currentDate: string) {
    const url = `${environment.buyerGateway}/feature/${currentDate}/CSNSP3`;
    return this.http.GetPromise(url);
  }

  getChangeHistory(initialModel: any, newModel: any, forKey: string): any {
    const differences: any = {};
    let changeHistory: any = {}
    console.log('getChangeHistoryinitialModel', initialModel);
    console.log('getChangeHistorynewModel', newModel);

    const handleArrayComparison = ( key: string, idField: string, comparisonFunction: Function ) => {
      const value1 = initialModel[key] ?? "";
      const value2 = newModel[key] ?? "";
      console.log('handleArrayComparisonvalue1', value1);
      console.log('handleArrayComparisonvalue2', value2);
      if (Array.isArray(value1) && Array.isArray(value2)) {
          let { oldArray, newArray } = comparisonFunction.call(this, value1, value2, idField);
          if (oldArray.length !== 0 || newArray.length !== 0) {
              differences[key] = {
                  oldValue: oldArray,
                  newValue: newArray
              };
          }
      } else if (value1 !== value2) {
          differences[key] = {
              oldValue: value1,
              newValue: value2
          };
      }
    };

    for (const key of Object.keys(newModel)) {
      const value1 = initialModel[key] ?? ""
      const value2 = newModel[key] ?? ""
      console.log('getChangeHistoryvalue1', value1);
      console.log('getChangeHistoryvalue2', value2);
      if (key === "PaymentBankingInformationList") {
        handleArrayComparison(key, "paymentBankingInformationId", this.getchangedDataforArrayOfObjects);
      } else if (key === "AdditionalGSTRegistrationList") {
          handleArrayComparison(key, "additionalGSTRegistrationInformationId", this.getchangedDataforArrayOfObjectsOrganisation);
      } else if (key === "ThirdPartyPaymentInformationList") {
          handleArrayComparison(key, "thirdPartyPaymentInformationId", this.getchangedDataforArrayOfObjects);
      } else if (key === "MixCertificationAndLicenseList") {
          handleArrayComparison(key, "certificationAndLicenseListId", this.getchangedDataforArrayOfObjects);
      } else if (key == "PaymentTermList") {
          handleArrayComparison(key, "", this.getchangedDataforArrayOfObjectsForPL)
      } else if (Array.isArray(value1) && Array.isArray(value2)) {
          if (JSON.stringify(value1) !== JSON.stringify(value2)) {
            differences[key] = {
              oldValue: value1,
              newValue: value2
            };
          }
      } else if (typeof value1 === 'object' && value1 !== null && typeof value2 === 'object' && value2 !== null) {
        if (JSON.stringify(value1) !== JSON.stringify(value2)) {
          differences[key] = {
            oldValue: value1,
            newValue: value2
          };
        }
      } else if (value1 !== value2) {
        differences[key] = {
          oldValue: value1 ?? null,
          newValue: value2 ?? null
        };
      }
    }

    if (JSON.stringify(differences) !== "{}") {
      changeHistory[forKey] = differences;
    }
    return changeHistory;
  }

  getchangedDataforArrayOfObjects(value1: any, value2: any, sortingKey: string) {
    let oldcopy = [...value1]
    let newCopy = [...value2]
    let idKeys: Set<number> = new Set();
   
    oldcopy.forEach((v) => idKeys.add(v[sortingKey]));
    newCopy.forEach((v) => idKeys.add(v[sortingKey]));
    console.log("++",idKeys)
    let oldArray = [];
    let newArray = [];
    for (const id of idKeys) {
      let oldObj = oldcopy.filter((v) => v[sortingKey] == id);
      let newObj = newCopy.filter((v) => v[sortingKey] == id);
      if (oldObj.length != 0 && newObj.length == 0) {
        let [newdiff, olddiff] = this.createEmptyObject(oldObj[0],sortingKey);
        oldArray.push(olddiff)
        newArray.push(newdiff)
      } else if (oldObj.length == 0 && newObj.length != 0) {
        let [olddiff, newdiff] = this.createEmptyObject(newObj[0],sortingKey);
        oldArray.push(olddiff)
        newArray.push(newdiff)
      } else {
        let [olddiff, newdiff] = this.compareJsons(oldObj[0], newObj[0], sortingKey)
        if (JSON.stringify(olddiff) != '{}' && JSON.stringify(newdiff) != '{}') {
          oldArray.push(olddiff)
          newArray.push(newdiff)
        }
      }
    }
    return { oldArray, newArray }
  } 


 getchangedDataforArrayOfObjectsOrganisation(value1: any, value2: any, sortingKey: string) {
  let oldcopy = [...value1];
  let newCopy = [...value2];
  let oldArray = [];
  let newArray = [];
  let idKeys: Set<number> = new Set();

  oldcopy.forEach((v) => idKeys.add(v[sortingKey]));
  newCopy.forEach((v) => idKeys.add(v[sortingKey]));

  const oldMap = new Map(oldcopy.map((v) => [v[sortingKey], v]));
  const newMap = new Map(newCopy.map((v) => [v[sortingKey], v]));

  for (const id of idKeys) {
     let oldObj = oldMap.get(id);
     let newObj = newMap.get(id);

     if (oldObj && !newObj) {
        let [newdiff, olddiff] = this.createEmptyObject(oldObj, sortingKey);
        oldArray.push(olddiff);
        newArray.push(newdiff);
     }
     else if (!oldObj && newObj) {
        let [olddiff, newdiff] = this.createEmptyObject(newObj, sortingKey);
        oldArray.push(olddiff);
        newArray.push(newdiff);
     }
     else if (oldObj && newObj) {
        let [olddiff, newdiff] = this.compareJsons(oldObj, newObj, sortingKey);
        if (JSON.stringify(olddiff) !== '{}' && JSON.stringify(newdiff) !== '{}') {
           oldArray.push(olddiff);
           newArray.push(newdiff);
        }
     }
  }

  return { oldArray, newArray };
}

getchangedDataforArrayOfObjectsForPL(value1: any, value2: any) {
  console.log("getchangedDataforArrayOfObjectsForPLvalue1", value1);
  console.log("getchangedDataforArrayOfObjectsForPLvalue2", value2);
  let oldcopy = [...value1];
  let newCopy = [...value2];
  let oldArray = [];
  let newArray = [];
  const maxLength = Math.max(oldcopy.length, newCopy.length);
  for (let i = 0; i < maxLength; i++) {
      let oldObj = oldcopy[i] || {};
      let newObj = newCopy[i] || {};
      let [olddiff, newdiff] = this.compareJsonsforPL(oldObj, newObj);
      if (JSON.stringify(olddiff) !== '{}' || JSON.stringify(newdiff) !== '{}') {
          oldArray.push(olddiff);
          newArray.push(newdiff);
      }
  }
  console.log("oldArray", oldArray);
  console.log("newArray", newArray);
  return { oldArray, newArray };
}
compareJsonsforPL(initialModel: any, newModel: any): any {
  const olddifferences: any = {};
  let newdifferences: any = {};
  for (const key of Object.keys(newModel)) {
      const value1 = initialModel[key] ?? "";
      const value2 = newModel[key] ?? "";
      console.log("compareJsonsforPLvalue1", value1);
      console.log("compareJsonsforPLvalue2", value2);
      if (key === "ApproveAttachments" || key ==="ProcurementApproval") {
        if (initialModel[key] && !newModel[key]) {
          continue;
        }
      }
      if (value1 === value2) {
        continue;
      }
      if (Array.isArray(value1) && Array.isArray(value2)) {
        console.log("line222");
          if (JSON.stringify(value1) !== JSON.stringify(value2)) {
              olddifferences[key] = value1;
              newdifferences[key] = value2;
          }
      }
      else if (typeof value1 === 'object' && value1 && typeof value2 === 'object' && value2) {
        console.log("line229");
          if (JSON.stringify(value1) !== JSON.stringify(value2)) {
              olddifferences[key] = value1;
              newdifferences[key] = value2;
          }
      }
      else if (value1 !== value2) {
        console.log("line236");
          olddifferences[key] = value1;
          newdifferences[key] = value2;
      }
  }
  console.log("olddifferences", olddifferences);
  console.log("newdifferences", newdifferences);
  return [olddifferences, newdifferences];
}

  createEmptyObject(obj: any,sortingKey:string): any {
    const keys = Object.keys(obj)
    let emptObj = {}
    for (const key of keys) {
      if (key==sortingKey) {
        emptObj[key]=obj[key]
        continue;
      }
      if (obj[key] !== null && obj[key] !== "" && (!Array.isArray(obj[key]) || (Array.isArray(obj[key]) && obj[key].length > 0))) {
        emptObj[key] = ""
      } else {
        delete obj[key]
      }
    }
    return [emptObj, obj];
  }

  compareJsons(initialModel: any, newModel: any,sortingKey:string): any {
    const olddifferences: any = {};
    let newdifferences: any = {}

    for (const key of Object.keys(newModel)) {
      const value1 = initialModel[key] ?? ""
      const value2 = newModel[key] ?? ""

      if (Array.isArray(value1) && Array.isArray(value2)) {
        if (JSON.stringify(value1) !== JSON.stringify(value2)) {
          olddifferences[key] = value1;
          newdifferences[key] = value2;
        }
      } else if (typeof value1 === 'object' && value1 !== null && typeof value2 === 'object' && value2 !== null) {
        if (JSON.stringify(value1) !== JSON.stringify(value2)) {
          olddifferences[key] = value1;
          newdifferences[key] = value2;
        }
      } else if (value1 != value2) {
        olddifferences[key] = value1;
        newdifferences[key] = value2;
      }
    }
    if (JSON.stringify(olddifferences) != '{}' && JSON.stringify(newdifferences) != '{}') {
      olddifferences[sortingKey] = newModel[sortingKey];
      newdifferences[sortingKey] = newModel[sortingKey];
    }

    return [olddifferences, newdifferences];
  }

  getChangeHistoryForFirstSave(initialModel: any, newModel: any, forKey: string): any {
    const differences: any = {};
    let changeHistory: any = {}
    for (const key of Object.keys(newModel)) {
      if (newModel[key] == '') {
        newModel[key] = null
      }
      if (newModel[key] !== null) {
        initialModel[key] = "";
        const value1 = initialModel[key]
        const value2 = newModel[key]
        if (Array.isArray(value1) && Array.isArray(value2)) {
          if (JSON.stringify(value1) !== JSON.stringify(value2)) {
            differences[key] = {
              oldValue: value1,
              newValue: value2
            };
          }
        } else if (typeof value1 === 'object' && value1 !== null && typeof value2 === 'object' && value2 !== null) {
          if (JSON.stringify(value1) !== JSON.stringify(value2)) {
            differences[key] = {
              oldValue: value1,
              newValue: value2
            };
          }
        } else if (value1 !== value2) {
          differences[key] = {
            oldValue: value1 ?? null,
            newValue: value2 ?? null
          };
        }
      }
    }
    if (JSON.stringify(differences) !== "{}") {
      changeHistory[forKey] = differences;
    }
    return changeHistory;
  }


  getRAData() {
    const url = `${environment.gateway}/getRaStatus`;
    return this.http.GetPromise(url);
  }

  updateRAStatus(updatedRecords: any[]) {
    return this.http.PostPromise<any>(`${environment.gateway}/updateRAStatus`, updatedRecords);
  }
  public getCurrentApprover(suppliercode:any): Promise<any>{
    const url = `${environment.stakeholderGateway}/getCurrentApprover/${suppliercode}`;
    return this.http.GetPromise(url);
  }

  private currentApproverSubject = new BehaviorSubject<boolean>(false);
  currentApprover$ = this.currentApproverSubject.asObservable();

  async checkAccess(loggedInUser,supplierCode){
    try {
      let getCurrentApprover = await this.getCurrentApprover(supplierCode);
      if(getCurrentApprover.data.length>0){
        const currentApprover = JSON.parse(getCurrentApprover.data[0].email_list);
        //const currentApprover = getCurrentApprover.data[0].email_list;
        // if(currentApprover){
        if(Array.isArray(currentApprover)){
          const emails = currentApprover.join(';');
          this.currentApproverSubject.next(emails.includes(loggedInUser));
          // this.currentApproverSubject.next(currentApprover.includes(loggedInUser));
        }else{
          this.currentApproverSubject.next(false);
        }
      }else{
        this.currentApproverSubject.next(false);
      }
    } catch (error) {
      console.error('Error fetching current approver data', error);
      this.currentApproverSubject.next(false);
      throw error;
    }
  }
}