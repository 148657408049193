// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.managing-group-members-container {
  background: linear-gradient(to right, #764BA2, #3C73D2);
  padding: 20px;
  min-height: 585px;
}

.parent {
  min-height: 555px;
  background: #fff;
}

.parentTitle {
  color: black;
  font-size: 20px;
  font-weight: 100;
  height: 50px;
  padding: 15px 0px 0px 15px;
}

.group-container {
  width: 100%;
  height: 50px;
}
.group-container h4 {
  position: relative;
  text-align: center;
  display: inline-block;
  font-size: 17px;
  top: 10px;
}
.group-container .group-input {
  height: 40px;
  width: 100%;
  font-size: 17px;
  padding: 4px;
}
.group-container .dropdown-list {
  border: 1px solid black;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  margin: 0;
  padding: 0;
  max-height: 130px;
  overflow-y: auto;
  z-index: 9999;
  background-color: white;
  border-top: none;
  box-sizing: border-box;
  top: 40px;
}
.group-container .dropdown-list li {
  list-style: none;
  padding: 0px 4px 0px 4px;
  width: 100%;
  height: 30px;
  color: black;
  border: 1px black;
  font-weight: 100;
  font-size: 17px;
  border-top: none;
  line-height: 25px;
}
.group-container .dropdown-list li:hover {
  cursor: pointer;
  background-color: rgb(211, 211, 211);
}

.group-create {
  text-align: center;
}

.createButton {
  height: 40px;
  width: 50%;
  color: #fff;
  background: #0056b3;
  border: none;
  cursor: pointer;
  font-size: 14px;
}
.createButton:hover {
  background: #0056b3;
}

.plus-sign:before {
  content: "+";
  font-size: 25px;
  color: black;
  cursor: pointer;
  font-weight: bold;
}

.group-members-container {
  width: 100%;
  display: inline-block;
  text-align: center;
}

.group-members {
  max-width: 80%;
  margin: 0 auto;
  position: relative;
  top: 30px;
}
.group-members h4 {
  text-align: left;
}

.tableInnerStyle {
  width: 100%;
  max-height: 300px;
  overflow-y: auto;
  min-height: 250px;
  border: none;
  margin: 0 auto;
  position: relative;
  top: 30px;
}

.tableInnerStyle::-webkit-scrollbar {
  width: 8px;
}

.tableInnerStyle::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

.tableInnerStyle::-webkit-scrollbar-thumb {
  background: #6457B1;
  border-radius: 10px;
}

.tableInnerStyle::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.grid-header {
  background-color: #4B0082;
  color: white;
  height: 30px;
}

table {
  width: 100%;
}

th {
  padding: 10px;
  background-color: #6457B1;
  color: white;
  text-align: left;
  font-size: 15px;
}

tbody {
  background-color: #f9f9f9;
  font-size: 15px;
}

tr {
  border-bottom: 1px solid #ddd;
}

tr:hover {
  background-color: #f1f1f1;
}

td {
  padding: 12px;
  text-align: left;
  border: 1px solid;
  width: 50%;
}

tbody tr:nth-child(even) {
  background-color: #f9f9f9;
  font-size: 14px;
}

tbody tr:nth-child(odd) {
  background-color: #fefefe;
  font-size: 14px;
}

.groupMembeAddBtn {
  height: -webkit-fill-available;
  width: 80px;
  color: #fff;
  background: #0056b3;
  border: none;
  cursor: pointer;
  font-size: 14px;
  margin: 0px 10px;
}

.buttons {
  display: block;
  height: 40px;
  position: relative;
  text-align: center;
  top: 10px;
}
.buttons button {
  height: -webkit-fill-available;
  width: 80px;
  color: white;
  background: #0056b3;
  border: none;
  cursor: pointer;
  font-size: 14px;
  margin: 0px 10px;
}
.buttons button:hover {
  background: #0056b3;
}

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 2%;
  border: 1px solid black;
  background: white;
  z-index: 1000;
  overflow-y: auto;
  background: #7e7171;
  align-items: center;
  opacity: 0.96;
  margin: 0 auto;
}
.popup-overlay .popup-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(127, 124, 124, 0.1);
  max-width: 400px;
  max-width: 100%;
  position: relative;
  margin: 0 auto;
  height: auto;
  top: 25%;
  width: 50%;
}
.popup-overlay .popup-content .form-items {
  display: inline-block;
  height: 60px;
  width: 100%;
}
.popup-overlay .popup-content .form-items .group-name, .popup-overlay .popup-content .form-items .group-email {
  font-size: 17px;
  display: flex;
  margin-bottom: 10px;
}
.popup-overlay .popup-content .form-items .input-box-container {
  width: 50%;
  display: flex;
  flex-direction: column;
}
.popup-overlay .popup-content .form-items .input-box {
  padding: 2px 4px 2px 4px;
  height: 50%;
  width: 100%;
  font-size: 16px;
  border-radius: 4px;
  box-sizing: border-box;
  position: relative;
  display: inline-block;
  bottom: 0;
}
.popup-overlay .popup-content h3 {
  display: flex;
  justify-content: space-around;
  font-size: 13px;
}

.error-message, .success-message {
  font-size: 13px;
  margin: 20px 0px;
}

.success-message {
  color: green;
}

.error-message {
  color: red;
}

.disable-pointer {
  pointer-events: none;
}

.popup-content {
  pointer-events: auto;
}

h4 {
  color: black;
  font-size: 20px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
