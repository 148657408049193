import { CountrySpecificUploaderConfig } from "./CountrySpecificUploaderConfig";

export class BaseConfig {
  width?: string = "50%";
}

export class ComponentConfig extends BaseConfig {
  name?: string;
  type?: string;
  viewOnlyType?: string;
  label?: string;
  hide?: boolean;
  required?: boolean;
  global?: boolean;
  errorMessage?: any;
  specialcharacterscompanyname?:boolean;
  specialcharactersstreetads?:boolean;  
  specialcharactersfirstname?:boolean;
  specialcharacterslastname?:boolean;
  specialCharactersCity?:boolean;
  specialCharactersZipcode?:boolean;
  specialCharactersAccountNo?:boolean;
  specialCharacterAccountHolderNm?:boolean;
  email?: boolean;
  minlength?: number;
  maxlength?: number;
  min?: number;
  max?: number;
  dateformat?: string;
  numberformat?: string;
  tooltip?: string;
  placeholder?: string;
  customStyle?: any;
  customClass?: string;
  selectAll?: boolean;
  options?: any[];
  dataSource?: DataSource;
  fieldset?: ComponentConfig[];
  filters?: FilterDefinition[];
  eventListeners?: EventListener[];
  validators?: any[];
  hooks?: HookConfig[];
  width?: string;
  defaultValue?: any;
  subLabel?: string;
  templateOptions?: any;
  message?: any;
  validateOn?: any;
  datatype?: string;
  pattern?: string;
  id?: string;
  staticMessage?: string;
  languageKey?: string;
  showType?:string;
  Level?:string;
  countrySpecificConfig?: CountrySpecificUploaderConfig;
  maxFileNumber?: number;
}
export class Style {}

export class DataSource {
  name: string;
  params?: any;
  loadOnInit = true;
}

export class FilterDefinition {
  name: string;
  params?: any;
}

export class TemplateConfig {
  label: string;
  type: string;
  hide: boolean;
}

export class EventListener {
  event: string;
  actions: EventListenerAction[];
}

export class EventListenerAction {
  name: string;
  params?: string | boolean | number | Array<string | number | boolean>;
}
export class ValidatorMap {
  name: string;
  errorMsg: string;
}

export enum ErrorKey {
  Required = "required",
  Maxlength = "maxlength",
  Minlength = "minlength",
  Email = "email",
}
export class HookConfig {
  name: string;
  trigger: string;
  params: any;
  condition: string;
}
