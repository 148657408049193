import { Injectable } from "@angular/core";
import { HttpService } from "./api/http.service";
import { environment } from "src/environments/environment";
import { HttpClient } from '@angular/common/http';

@Injectable({
    providedIn: "root",
})

export class LatamCategoriesService {
    baseUrl = environment.gateway;
    stakeholderUrl = environment.stakeholderGateway;

    constructor(private http: HttpService,
        private httpClient: HttpClient
    ) {}

    public getAllLatamCategories() {
        const url = `${this.stakeholderUrl}/getLatamCategories`;
        return this.http.GetPromise(url);
    }

    public updateLatamCategory(id, updatedRecord) {
        return this.httpClient
          .put<any>(
            `${this.stakeholderUrl}/updateLatamCategory/${id}`,
            updatedRecord
          )
          .toPromise();
    }
    
    public saveLatamCategory(newRecord) {
        return this.httpClient
        .post<any>(
          `${this.stakeholderUrl}/saveLatamCategory`,
          newRecord
        )
        .toPromise();

    }

} 