import { Component, OnInit } from '@angular/core';
import { LatamCategoriesService } from 'src/app/services/latam-categories.service';
import { DictionaryService } from "src/app/services/dictionary.service";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { environment } from "src/environments/environment";
import { HttpClient } from '@angular/common/http';
import * as _ from "lodash";
import { Router } from "@angular/router";
import { NzMessageService } from 'ng-zorro-antd/message'

@Component({
  selector: 'app-latam-categories',
  standalone: true,
  imports: [],
  templateUrl: './latam-categories.component.html',
  styleUrl: './latam-categories.component.sass'
})
export class LatamCategoriesComponent implements OnInit{
  latamCategoriesList: any[] = [];
  isDialogVisible = false;
  isFormDirty = false;
  loading = false;
  updatedCategories: any[] = [];
  latamCategoryModalVisible = false;
  latamCategoryForm: UntypedFormGroup;
  selectedLatamCategory: any | undefined;
  l1List: any[] = [];
  l2List: any[] = [];
  l3List: any[] = [];
  riskList: any[] = [];
  categoryList: any[] = [];
  commodityList: any[] = [];
  isActiveList: any[] = [];
  cnaeList: any[] = [];
  dictionaryData: any[] = [];
  currentSelectedId;
  l1CatData;
  l2CatData;
  l3CatData;
  commodityData;
  categoryData;
  cnaeData;
  riskData;
  isActiveData;

  selectedL1Cat: string;
  selectedL2Cat: string;
  selectedL3Cat: string;
  CategoryRisk: string;
  selectedCommodity: string;
  selectedCategory: string;
  selectedCnae: string;
  selectedIsActive: boolean;
  activeChecked: string = 'checked';

  saveLatamCategoryDisabled = false;
  saveLatamCategoryLoading = false;
  saveCompanyDisabled = true;
  modalTitle: string;

  constructor(private catService: LatamCategoriesService,
    private dictionaryService: DictionaryService,
    private httpClient: HttpClient,
    private readonly message: NzMessageService,
    private router: Router,
  ) {}

  async ngOnInit() {
    this.getAllLatamCategoryData();
    this.latamCategoryFormBuilder();
  }

  setFieldData(dictData, field: string) {
    let result = dictData.filter((element) => element.Code === field);
    return result[0].Items
  }

  assignCategoryValues(catList, categoryData, categoryKey, valueKey) {
    catList.forEach(category => {
      const matchedCategory = categoryData.find(data => data.Code === category[categoryKey]);
      if (matchedCategory) {
        category[valueKey] = matchedCategory.Items;
      }
    });
  }

  mapCategoryData(categoryData) {
    return categoryData.map(item => {
      return {
        id: item.Code,
        name: item.Items
      };
    });
  }

  handleValueChanges(valueChangesObservable, categoryData, formControlName) {
    valueChangesObservable.subscribe((selectedName) => {
      let category = _.find(categoryData, function(x) {
        return x.Items === selectedName;
      });
      if (category) {
        this.latamCategoryForm.patchValue({
          [formControlName]: category.Code
        });
      }
    });
  }

  async getAllLatamCategoryData() {
    this.loading = true;
    this.latamCategoriesList = [];
    this.dictionaryData = [];

    this.catService.getAllLatamCategories().then((result: any) => {
        let catList = result.data;

        this.dictionaryService.getDictionary().then((dict_result: any) => {
          
          this.dictionaryData = dict_result;

          this.l1CatData = this.setFieldData(dict_result, "LatamL1Category");
          this.l2CatData = this.setFieldData(dict_result, "LatamL2Category");
          this.l3CatData = this.setFieldData(dict_result, "LatamL3Category");
          this.commodityData = this.setFieldData(dict_result, "SpendCategoryGroup");
          this.categoryData = this.setFieldData(dict_result, "SpendCommodityGroup");
          this.cnaeData = this.setFieldData(dict_result, "CnaeCode");
          this.riskData = this.setFieldData(dict_result, "CategoryRisk");

          this.assignCategoryValues(catList, this.l1CatData, 'LatamL1Category', 'l1Value');
          this.assignCategoryValues(catList, this.l2CatData, 'LatamL2Category', 'l2Value');
          this.assignCategoryValues(catList, this.l3CatData, 'LatamL3Category', 'l3Value');
          this.assignCategoryValues(catList, this.cnaeData, 'LatamCnaeCode', 'cnaeValue');

          catList.forEach(category => {
            const matchedData = this.commodityData.find(comData => comData.Code === category.Commodity);
            if (matchedData) {
              category.commodityValue = matchedData.Text;
            }
          });

          catList.forEach(category => {
            const matchedData = this.categoryData.find(catData => catData.Code === category.Category);
            if (matchedData) {
              category.categoryValue = matchedData.Text;
            }
          });

          this.latamCategoriesList = catList;
          this.loading = false;

          this.l1List = this.mapCategoryData(this.l1CatData);
          this.l2List = this.mapCategoryData(this.l2CatData);
          this.l3List = this.mapCategoryData(this.l3CatData);
          this.cnaeList = this.mapCategoryData(this.cnaeData);
          
          this.categoryList = this.categoryData.map(item => {
            return {
              id: item.Code,
              name: item.Text
            }
          })
          this.isActiveList = [
            {name: true},
            {name: false}
          ];
        })
        
    });
  }  

  updateFormCategory(categoryData, name, formControlName) {
    let category = _.find(categoryData, function(x) {
      return x.Items === name;
    });
    if (category) {
      this.latamCategoryForm.patchValue({
        [formControlName]: category.Code
      });
    }
  }

  private latamCategoryFormBuilder() {
    this.latamCategoryForm = new UntypedFormGroup({
      LatamL1Category: new UntypedFormControl(null, [Validators.required]),
      LatamL2Category: new UntypedFormControl(null, [Validators.required]),
      LatamL3Category: new UntypedFormControl(null, [Validators.required]),
      CategoryRisk: new UntypedFormControl(null, [Validators.required]),
      LatamCnaeCode: new UntypedFormControl(null, [Validators.required]),
      Commodity: new UntypedFormControl(null, [Validators.required]),
      Category: new UntypedFormControl(null, [Validators.required]),
      IsActive: new UntypedFormControl(null, [Validators.required]),
      l1Value: new UntypedFormControl(null, [Validators.required]),
      l2Value: new UntypedFormControl(null, [Validators.required]),
      l3Value: new UntypedFormControl(null, [Validators.required]),
      categoryValue: new UntypedFormControl(null, [Validators.required]),
      commodityValue: new UntypedFormControl(null, [Validators.required]),
      LatamCnaeValue: new UntypedFormControl(null, [Validators.required])

    });

    const l1Value = this.latamCategoryForm.get("l1Value");
    const l2Value = this.latamCategoryForm.get("l2Value");
    const l3Value = this.latamCategoryForm.get("l3Value");
    const categoryValue = this.latamCategoryForm.get("categoryValue");
    const commodityValue = this.latamCategoryForm.get("commodityValue");
    const LatamCnaeValue = this.latamCategoryForm.get("LatamCnaeValue");

    l1Value.valueChanges.subscribe((l1Name) => {
      if(l1Name) {
        this.updateFormCategory.call(this, this.l1CatData, l1Name, 'LatamL1Category');
      }
    })

    l2Value.valueChanges.subscribe((l2Name) => {
      if(l2Name) {
        this.updateFormCategory.call(this, this.l2CatData, l2Name, 'LatamL2Category');
      }      
    })

    l3Value.valueChanges.subscribe((l3Name) => {
      if(l3Name) {
        this.updateFormCategory.call(this, this.l3CatData, l3Name, 'LatamL3Category');
      }      
    })

    LatamCnaeValue.valueChanges.subscribe((cnae) => {
      if(cnae) {
        this.updateFormCategory.call(this, this.cnaeData, cnae, 'LatamCnaeCode');
      }      
    })

    categoryValue.valueChanges.subscribe((cate) => {
      if(this.selectedCategory !== undefined || this.selectedCategory !== cate) {
        const commodityControl = this.latamCategoryForm.get("commodityValue");
        commodityControl.patchValue('');
      }
      this.getSpendCommodityGroups(this.dictionaryData, cate);
      let catData = _.find(this.categoryData, function(x) {
        if(x.Text === cate) {
          return x.Code;
        }
      })
      if(catData) {
        this.latamCategoryForm.patchValue({
          Category: catData.Code
        })
      }
    });

    commodityValue.valueChanges.subscribe((com) => {
      let cd = _.find(this.commodityList, function(x) {
        if(x.name === com) {
          return x;
        }
      });
      if(cd) {
        this.latamCategoryForm.patchValue({
          Commodity : cd.id
        })
        this.selectedCommodity = cd.name;
      }
    });

    this.latamCategoryForm.statusChanges.subscribe((status) => {
      this.saveLatamCategoryDisabled = status !== "VALID";
    });
    
  }

  public getSpendCommodityGroups(dictionary, selectedCat) {

    const spendCommodityGroups = _.chain(dictionary)
        .filter({ Code: 'SpendCommodityGroup' })
        .map('Items')
        .compact()
        .flatMap()
        .value()
    let selCategory = spendCommodityGroups.filter(obj => {
      return obj.Text === selectedCat
    });
    if(selCategory && selCategory[0]) {
      let subCat = selCategory[0].Items;
      this.commodityList = subCat.map(item => {
        return {
          id: item.Code,
          name: item.Text
        }
      });
    }    

    return spendCommodityGroups;
  }

  
  async submit() {
      this.loading = true;
      this.saveLatamCategoryLoading = true;
      let params = this.getLatamCategoryFormParams();
      let result;

      if(params.ID === undefined) {
        result = await this.catService.saveLatamCategory(params);
      }
      else {
        if(this.selectedLatamCategory) {
          result = await this.catService.updateLatamCategory(this.selectedLatamCategory.ID, params);

        }        
      }   
      
      if(result && result.isSuccess) {
        this.message.success('Success');
        this.loading = false;
        this.saveLatamCategoryLoading = false;
        this.saveCompanyDisabled = true;
        this.closeLatamCategoryModal();
        this.getAllLatamCategoryData();
        
      } else {
        this.message.success('Error saving record!!');
        this.loading = false;
        this.saveLatamCategoryLoading = false;
        this.closeLatamCategoryModal();
      }
      
  }

  public closeLatamCategoryModal() {
    this.latamCategoryModalVisible = false;
    this.selectedLatamCategory = undefined;
    this.saveCompanyDisabled = true;
    this.latamCategoryForm.reset();
  }

  public openLatamCategoryModal(data?: any) {
    this.commodityList = [];
    this.saveLatamCategoryDisabled = true;
    if(data) {
      this.modalTitle = "Update Category"
      this.selectedLatamCategory = data;
      this.currentSelectedId = data.ID;
    } else {
      this.modalTitle = "Save Category";
      this.selectedCommodity = '';
    }

    let form: any = {
      ID: "",
      CategoryRisk: "",
      Commodity: "",
      Category: "",
      IsActive: true,
      l1Value: "",
      l2Value: "",
      l3Value: "",
      categoryValue: "",
      commodityValue: "",
      cnaeValue: ""
    };
    if (data) {
      form = {
        ID: data.ID,
        CategoryRisk: data.CategoryRisk,
        Commodity: data.Commodity,
        Category: data.Category,
        IsActive: data.IsActive,
        l1Value: data.l1Value,
        l2Value: data.l2Value,
        l3Value: data.l3Value,
        categoryValue: data.categoryValue,
        commodityValue: data.commodityValue,
        cnaeValue: data.cnaeValue
      };
    }
    
    this.latamCategoryForm.reset(form);

    this.selectedL1Cat = form.l1Value;
    this.selectedL2Cat = form.l2Value;
    this.selectedL3Cat = form.l3Value;
    this.CategoryRisk = form.CategoryRisk;
    this.selectedCnae = form.cnaeValue;
    this.selectedCategory = form.categoryValue;
    this.selectedCommodity = form.commodityValue;
    this.selectedIsActive = form.IsActive;

    this.latamCategoryModalVisible = true;
  }

  private getLatamCategoryFormParams() {
    const {         
      ID,
      LatamL1Category,
      LatamL2Category,
      LatamL3Category,
      CategoryRisk,
      LatamCnaeCode,
      Commodity,
      Category,
      IsActive
    } = this.latamCategoryForm.value;

    const params = {
        ID: ID,
        LatamL1Category: LatamL1Category,
        LatamL2Category: LatamL2Category,
        LatamL3Category: LatamL3Category,
        CategoryRisk: CategoryRisk,
        LatamCnaeCode: LatamCnaeCode,
        Commodity: Commodity,
        Category: Category,
        IsActive: IsActive
    };
    params.ID = this.currentSelectedId;
    
    return params;
  }

  navigateToDashboard() {
    this.router.navigate(["buyer"]);
  }

}
