// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rounded-circle {
  border-radius: 50% !important;
  height: 7.4rem;
  width: 7.4rem;
  font-size: 1rem;
  padding: 12px 4px;
  text-align: center;
  line-height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  margin-top: 6px;
}

.circle-todo {
  color: #555;
  font-weight: 500;
  box-shadow: 0px 0px 5px #000;
}

.circle-done {
  border: solid 5px #65BEE1;
  font-weight: bold;
  color: #555555;
}

.circle-terminated {
  border: solid 5px red !important;
  color: #555555;
}

.circle-rejected {
  border: solid 5px orange;
  color: #555555;
}

.line-done {
  width: calc(135px - 7.4rem);
  height: 6px;
  background-color: #4FABE3;
  z-index: 0;
}

.line-rejected {
  background-color: orange;
  width: calc(135px - 7.4rem);
  height: 6px;
  z-index: 0;
}

.line-todo {
  width: calc(135px - 7.4rem);
  height: 6px;
  background-color: #EEEEEE;
  z-index: 0;
}

.item {
  margin-top: 10px;
  line-height: 20px;
  width: 135px;
  color: #666;
  padding: 0 10px;
  font-size: 1rem;
}

.date {
  margin: 6px 0px;
  color: #737777;
}

.tooltip {
  position: relative;
  left: 30%;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0;
}

.tooltip.show {
  opacity: 0.9;
}

.tooltip .arrow {
  position: relative;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
  left: auto;
  padding-left: 2px;
}

.tooltip .arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^=bottom] {
  padding: 0.4rem 0;
}

.bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^=bottom] .arrow {
  top: 0;
}

.bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^=bottom] .arrow::before {
  bottom: 0;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #000;
}

.tooltip-inner {
  width: max-content;
  max-width: 400px;
  color: #fff;
  text-align: left;
  background-color: #000;
  border-radius: 0.25rem;
  padding: 8px;
}

.word-break {
  word-break: break-all;
}

.white-space {
  white-space: pre-wrap;
}

.dashed-line {
  width: 100%;
  border-bottom: 1px dashed #fff;
  margin: 5px 0;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
