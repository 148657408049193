import { Injectable } from "@angular/core";
import { CustomExpression } from "../../dynamic-components/interfaces/Decorators";
import { ComponentConfig } from "../../dynamic-components/ComponentConfig";
import { DynamicContext } from "../../dynamic-components/interfaces/DynamicContext";
import { UntypedFormGroup } from "@angular/forms";
import { DynamicComponentService } from "../../dynamic-components/services/dynamic-component.service";
import {
  getDefaultValue,
  clearValidators,
  attachValidators,
} from "../../dynamic-components/utils/index";
import { Datasources } from "src/app/dynamic-components/datasource/Datasources";
import { LanguageService } from "src/app/dynamic-components/utils/language.service";
import { environment } from "src/environments/environment";
import { CountrySpecificUploaderConfigService } from "src/app/services/country-specific-uploader-config.service";
import { OnBoardingService } from "src/app/modules/buyer/services/onboarding.service";
import { isOrganizationEmpty } from "src/app/utils/organizationCheck";

@Injectable()
export class CustomExpressions {
  constructor(
    private service: DynamicComponentService,
    private languageService: LanguageService,
    private countryConfigService: CountrySpecificUploaderConfigService,
    private onboardingService: OnBoardingService,
  ) { }

  @CustomExpression("setCustomVisible")
  setCustomVisible(
    value: any,
    config: ComponentConfig,
    context: DynamicContext,
    formGroup: UntypedFormGroup
  ) {
    return (params: any): void => {
      let result = false;
      if (
        !formGroup.get("SupplierCountry").value ||
        !formGroup.get("SupplierCountry").value.length
      ) {
        return;
      } else {
        const countryList = formGroup.get("SupplierCountry").value;
        result = !countryList.some(
          (countryCode: string) => countryCode === "PH"
        );
      }
      config.hide = result;
      const control = formGroup.get(config.name);
      if (!control) {
        return;
      }
      if (result) {
        const defaultValue = getDefaultValue(control.value, config.datatype);
        control.patchValue(defaultValue, { onlySelf: true, emitEvent: true });
        control.clearValidators();
        control.clearAsyncValidators();
        control.setErrors(null);
        control.updateValueAndValidity({ onlySelf: true, emitEvent: true });
        control.markAsPristine();
        control.markAsUntouched();
      } else {
        control.setValidators(
          this.service.validatorFactory.createValidators(config, context)
        );
        control.setAsyncValidators(
          this.service.validatorFactory.createAsyncValidators(config, context)
        );
        control.setErrors(null);
        control.updateValueAndValidity({ onlySelf: true, emitEvent: true });
      }
    };
  }

  @CustomExpression("resetBusinessRegistrationNbLabel")
  resetATBusinessRegistrationNbLabel(
    value: any,
    config: ComponentConfig,
    context: DynamicContext,
    formGroup: UntypedFormGroup
  ) {
    return (params: any): void => {
      if (!context["resetBusinessRegistrationNbLabel"]) {
        context["resetBusinessRegistrationNbLabel"] = config.label;
      }

      if (formGroup.controls["SupplierRegisteredCountry"].value === "JP") {
        config.tooltip = environment.languageSwitch
          ? this.languageService.getValue(
            "Expression.resetBusinessRegistrationNbLabel.SupplierRegisteredCountry_JP_tooltip"
          )
          : "The registration number consists of the following<br>1.Taxable business operator with corporate number :\"T\"+ corporate number (13 digits)<br>2.Taxable business operator without corporate number (individual, corporations without personality, etc.): \"T\"+13 digits (Note)<br>Note: The 13-digit number does not use My Number (Individual Number), and it is a number for each business operator that does not overlap with the corporate number.<br>3.If none of the above conditions are satisfied, please fill in “Not applicable”.<br>"
      }

      // eslint-disable-next-line max-len
      if (
        context
          .getValue("parentModel")
          .RequestForm.RequestorDetailsInfo.SupplierCountry.find(
            (P) => P === "Ca"
          ) &&
        (formGroup.controls["SupplierRegisteredCountry"].value !== "Ca" ||
          formGroup.controls["SupplierRegisteredCountry"].value !== "US" ||
          formGroup.controls["SupplierRegisteredCountry"].value !== "PR")
      ) {
        config.errorMessage["required"] = environment.languageSwitch
          ? this.languageService.getValue(
            "Expression.resetBusinessRegistrationNbLabel.SupplierCountryCA_errorMessage"
          )
          : " Please input VAT/Registration Number/Tax Identification Number";
        config.label = environment.languageSwitch
          ? this.languageService.getValue(
            "Expression.resetBusinessRegistrationNbLabel.SupplierCountryCA_label"
          )
          : "VAT/Registration Number/Tax Identification Number (Local country Tax ID)";
        config.templateOptions.IsVerticalShowSublable = true;

        return;
      }
      if (
        !(
          formGroup.controls["SupplierRegisteredCountry"].value === "AT" ||
          formGroup.controls["SupplierRegisteredCountry"].value === "CH" ||
          formGroup.controls["SupplierRegisteredCountry"].value === "DE" ||
          formGroup.controls["SupplierRegisteredCountry"].value === "BG" ||
          formGroup.controls["SupplierRegisteredCountry"].value === "HR" ||
          formGroup.controls["SupplierRegisteredCountry"].value === "CZ" ||
          formGroup.controls["SupplierRegisteredCountry"].value === "GR" ||
          formGroup.controls["SupplierRegisteredCountry"].value === "HU" ||
          formGroup.controls["SupplierRegisteredCountry"].value === "PL" ||
          formGroup.controls["SupplierRegisteredCountry"].value === "RO"
        ) &&
        (context.getValue("isUKIA") || context.getValue("isJapan") || context.getValue("isGallia"))
      ) {
        config.label = environment.languageSwitch
          ? this.languageService.getValue(
            "Expression.resetBusinessRegistrationNbLabel.UKIAorAfrica_label"
          )
          : "Business Registration/Identification Number";
        config.errorMessage["required"] = environment.languageSwitch
          ? this.languageService.getValue(
            "Expression.resetBusinessRegistrationNbLabel.UKIAorAfrica_errorMessage"
          )
          : "Please input Business Registration/Identification Number";
        return;
      }
      if (
        context.getValue("isMET") &&
        !(
          formGroup.controls["SupplierRegisteredCountry"].value === "BG" ||
          formGroup.controls["SupplierRegisteredCountry"].value === "HR" ||
          formGroup.controls["SupplierRegisteredCountry"].value === "CZ" ||
          formGroup.controls["SupplierRegisteredCountry"].value === "GR" ||
          formGroup.controls["SupplierRegisteredCountry"].value === "HU" ||
          formGroup.controls["SupplierRegisteredCountry"].value === "PL" ||
          formGroup.controls["SupplierRegisteredCountry"].value === "RO" ||
          formGroup.controls["SupplierRegisteredCountry"].value === "CH" ||
          formGroup.controls["SupplierRegisteredCountry"].value === "DE" ||
          formGroup.controls["SupplierRegisteredCountry"].value === "AT" ||
          formGroup.controls["SupplierRegisteredCountry"].value === "DK" ||
          formGroup.controls["SupplierRegisteredCountry"].value === "FI" ||
          formGroup.controls["SupplierRegisteredCountry"].value === "LV" ||
          formGroup.controls["SupplierRegisteredCountry"].value === "EE" ||
          formGroup.controls["SupplierRegisteredCountry"].value === "NO" ||
          formGroup.controls["SupplierRegisteredCountry"].value === "SE"
        )
      ) {
        config.label = environment.languageSwitch
          ? this.languageService.getValue(
            "Expression.resetBusinessRegistrationNbLabel.MET_label"
          )
          : "Business Registration/Identification Number";
        config.errorMessage["required"] = environment.languageSwitch
          ? this.languageService.getValue(
            "Expression.resetBusinessRegistrationNbLabel.MET_errorMessage"
          )
          : "Please input Registration/Identification Number";
        return;
      }
      if (formGroup.controls["SupplierRegisteredCountry"].value === "AT") {
        config.label = environment.languageSwitch
          ? this.languageService.getValue(
            "Expression.resetBusinessRegistrationNbLabel.SupplierRegisteredCountryAT_label"
          )
          : "VAT Registration Number (UID-NR)";
        config.errorMessage["required"] = environment.languageSwitch
          ? this.languageService.getValue(
            "Expression.resetBusinessRegistrationNbLabel.SupplierRegisteredCountryAT_errorMessage"
          )
          : "Please input VAT Registration Number (UID-NR)";
        return;
      }
      if (formGroup.controls["SupplierRegisteredCountry"].value === "DE") {
        config.label = environment.languageSwitch
          ? this.languageService.getValue(
            "Expression.resetBusinessRegistrationNbLabel.SupplierRegisteredCountryDE_label"
          )
          : "VAT Number (USt-IdNr)";
        config.errorMessage["required"] = environment.languageSwitch
          ? this.languageService.getValue(
            "Expression.resetBusinessRegistrationNbLabel.SupplierRegisteredCountryDE_errorMessage"
          )
          : "Please input VAT Number (USt-IdNr)";
        return;
      }
      if (formGroup.controls["SupplierRegisteredCountry"].value === "CH") {
        config.label = environment.languageSwitch
          ? this.languageService.getValue(
            "Expression.resetBusinessRegistrationNbLabel.SupplierRegisteredCountryCH_label"
          )
          : "UID Number";
        config.errorMessage["required"] = environment.languageSwitch
          ? this.languageService.getValue(
            "Expression.resetBusinessRegistrationNbLabel.SupplierRegisteredCountryCH_errorMessage"
          )
          : "Please input UID Number";
        return;
      }
      if (
        formGroup.controls["SupplierRegisteredCountry"].value === "BG" ||
        formGroup.controls["SupplierRegisteredCountry"].value === "HR" ||
        formGroup.controls["SupplierRegisteredCountry"].value === "CZ" ||
        formGroup.controls["SupplierRegisteredCountry"].value === "GR" ||
        formGroup.controls["SupplierRegisteredCountry"].value === "HU" ||
        formGroup.controls["SupplierRegisteredCountry"].value === "PL" ||
        formGroup.controls["SupplierRegisteredCountry"].value === "RO"
      ) {
        config.label = environment.languageSwitch
          ? this.languageService.getValue(
            "Expression.resetBusinessRegistrationNbLabel.SupplierRegisteredCountryBG_HR_CZ_label"
          )
          : "Business Registration/Identification Number";
        config.tooltip = environment.languageSwitch
          ? this.languageService.getValue(
            "Expression.resetBusinessRegistrationNbLabel.SupplierRegisteredCountryBG_HR_CZ_tooltip"
          )
          : `(DO NOT input Personal ID or Passport number)`;
        config.errorMessage["required"] = environment.languageSwitch
          ? this.languageService.getValue(
            "Expression.resetBusinessRegistrationNbLabel.SupplierRegisteredCountryBG_HR_CZ_errorMessage"
          )
          : "Please input Business Registration/Identification Number";
        return;
      }
      if (
        (formGroup.controls["SupplierRegisteredCountry"].value === "DK" ||
          formGroup.controls["SupplierRegisteredCountry"].value === "FI" ||
          formGroup.controls["SupplierRegisteredCountry"].value === "LV" ||
          formGroup.controls["SupplierRegisteredCountry"].value === "EE" ||
          formGroup.controls["SupplierRegisteredCountry"].value === "NO" ||
          formGroup.controls["SupplierRegisteredCountry"].value === "SE") &&
        !context.getValue("isMET")
      ) {
        config.label = environment.languageSwitch
          ? this.languageService.getValue(
            "Expression.resetBusinessRegistrationNbLabel.SupplierRegisteredCountryDK_FI_LV_label"
          )
          : "Company VAT Number";
        config.tooltip = environment.languageSwitch
          ? this.languageService.getValue(
            "Expression.resetBusinessRegistrationNbLabel.SupplierRegisteredCountryDK_FI_LV_tooltip"
          )
          : `<li> Enter company VAT registration number or N/A if Not VAT registered. </li>`;
        config.errorMessage["required"] = environment.languageSwitch
          ? this.languageService.getValue(
            "Expression.resetBusinessRegistrationNbLabel.SupplierRegisteredCountryDK_FI_LV_errorMessage"
          )
          : "Please input Company VAT Number";
        return;
      }

      // if none of above condition effects, show the original label, while tooltip depends on GU
      config.label = context["resetBusinessRegistrationNbLabel"];
      // refer to PBI 550995
      if (context.getValue("isANZ") || context.getValue("isGreaterChina")) {
        config.tooltip = environment.languageSwitch
          ? this.languageService.getValue(
            "Expression.resetBusinessRegistrationNbLabel.ANZ_tooltip"
          )
          : `(DO NOT input Personal ID or Passport number)`;
      }
      // refer to PBI 570651
      else if (context.getValue("isPH")) {
        config.tooltip = environment.languageSwitch
          ? this.languageService.getValue(
            "Expression.resetBusinessRegistrationNbLabel.PH_tooltip"
          )
          : "Tax Number should match the with your Tax Registration Certificate";
      }
      // refer to PBI 508361
      else if (context.getValue("isSG")) {
        config.tooltip = environment.languageSwitch
          ? this.languageService.getValue(
            "Expression.resetBusinessRegistrationNbLabel.SG_tooltip"
          )
          : "DO NOT input Personal ID or Passport number";
      } else {
        config.tooltip = environment.languageSwitch
          ? this.languageService.getValue(
            "Expression.resetBusinessRegistrationNbLabel.Default_tooltip"
          )
          : `(DO NOT input Personal ID or Passport number)`;
      }

      if (
        context.getValue("isGreaterChina") &&
        (formGroup.controls["SupplierRegisteredCountry"].value === "HK" ||
          formGroup.controls["SupplierRegisteredCountry"].value === "TW")
      ) {
        delete config.tooltip
      }

      if (!config.errorMessage) {
        config.errorMessage = {};
      }
      config.errorMessage["required"] = environment.languageSwitch
        ? this.languageService.getValue(
          "Expression.resetBusinessRegistrationNbLabel.Default_errorMessage"
        )
        : "Please input Business Registration/Tax Identification Number";
    };
  }

  @CustomExpression("resetWidthAndVisibility")
  setVisibility(
    value: any,
    config: ComponentConfig,
    context: DynamicContext,
    formGroup: UntypedFormGroup
  ) {
    return (params: any): void => {
      const fn = `return !(${params})`;
      const runtime = new Function(
        "config",
        "context",
        "$value",
        "$formGroup",
        fn
      );
      const result = runtime(config, context, value, formGroup);
      config.hide = result;
      const control = formGroup.get(config.name);
      if (!control) {
        return;
      }
      if (result) {
        config.width = "0%";
        // eslint-disable-next-line @typescript-eslint/no-shadow
        const value = getDefaultValue(control.value, config.datatype);
        if (config.defaultValue) {
          control.patchValue(config.defaultValue, {
            onlySelf: true,
            emitEvent: true,
          });
        } else {
          control.patchValue(value, { onlySelf: true, emitEvent: true });
        }
        control.clearValidators();
        control.clearAsyncValidators();
        control.updateValueAndValidity({ onlySelf: true, emitEvent: true });
      } else {
        config.width = config.templateOptions.hideWidth;
        // clear before value  and default value from before save
        if (config.defaultValue) {
          if (control.value === config.defaultValue) {
            control.patchValue(config.defaultValue, {
              onlySelf: true,
              emitEvent: true,
            });
          }
        } else if (config.type === "textbox" || config.type === "textboxview") {
          return;
          // view page shouldn't clean value
        } else if ((control.value !== '' || control.value !== null) && config.name == 'IntermediaryBankCountry') {
          return;
        } else if (config.type === "searchableDropdown") {
          context.emit(config.name, "");
        } else if (config.type === "multiple") {
          // Handle 'multiple' type
          control.patchValue([], { onlySelf: true, emitEvent: true }); 
        } else {
          control.patchValue("", { onlySelf: true, emitEvent: false });
        }
        control.setValidators(
          this.service.validatorFactory.createValidators(config, context)
        );
        control.setAsyncValidators(
          this.service.validatorFactory.createAsyncValidators(config, context)
        );
        control.updateValueAndValidity({ onlySelf: true, emitEvent: true });
      }
    };
  }

  @CustomExpression("setVisibleByJapan")
  setVisibleByJapan(
    value: any,
    config: ComponentConfig,
    context: DynamicContext,
    formGroup: UntypedFormGroup
  ) {
    return (params: any): void => {
      const fn = `return !(${params})`;
      const runtime = new Function(
        "config",
        "context",
        "$value",
        "$formGroup",
        fn
      );
      let guResult = true;
      // let country=context.parent.getValue('parentModel').SupplierProfile.SupplierGeneral.Country;
      let country = formGroup.get('Country').value
      //const result = runtime(config, context, value, formGroup);
      const result = !guResult;
      config.hide = result;
      const control = formGroup.get(config.name);
      if (!control) {
        return;
      }
      if (context.getValue("isJapan") && country === 'JP') {
        config.hide = true;
        const defaultValue = getDefaultValue(control.value, config.datatype);
        control.patchValue(defaultValue, { onlySelf: true, emitEvent: true });
        control.clearValidators();
        control.clearAsyncValidators();
        control.setErrors(null);
        control.updateValueAndValidity({ onlySelf: true, emitEvent: true });
        control.markAsPristine();
        control.markAsUntouched();
      }
      else {
        if (!control.value) {
          if (config.defaultValue && !control.value) {
            control.patchValue(config.defaultValue, {
              onlySelf: true,
              emitEvent: true,
            });
          } else if (control.dirty) {
            control.patchValue("", { onlySelf: true, emitEvent: true });
          }
        }
        control.setValidators(
          this.service.validatorFactory.createValidators(config, context)
        );
        control.setAsyncValidators(
          this.service.validatorFactory.createAsyncValidators(config, context)
        );
        control.setErrors(null);
        control.updateValueAndValidity({ onlySelf: true, emitEvent: true });
      }
    };
  }

  @CustomExpression("resetWidthAndVisibilityByChina")
  setVisibilityByChina(
    value: any,
    config: ComponentConfig,
    context: DynamicContext,
    formGroup: UntypedFormGroup
  ) {
    return (params: any): void => {
      const fn = `return !(${params})`;
      const runtime = new Function(
        "config",
        "context",
        "$value",
        "$formGroup",
        fn
      );
      let result2 = false;
      const BankCountry = formGroup.get('BankCountry').value;
      if (BankCountry && (BankCountry === "CN" || BankCountry === "HK" || BankCountry === "TW")) {
        if(!(context.parent.getValue('isNewSupplier') && context.parent.getValue('isIndividual') && BankCountry === "HK")){
          result2 = true;
        }
      }
      const accountHoldName = formGroup.get('AccountHolderNm').value;
      const companyName = context.parent.getValue('parentModel').SupplierProfile.SupplierGeneral.CompanyName;
      let result1 = false;
      if (accountHoldName && companyName !== accountHoldName) {
        result1 = true;
      }
      let result3 = false;
      const GU = context.parent.getValue('parentModel').RequestForm.RequestorDetailsInfo.SupplierGeographical;
      if (GU == 18) {
        result3 = true;
      }
      const result = !(result2 && result1 && result3);
      config.hide = result;
      const control = formGroup.get(config.name);
      if (!control) {
        return;
      }
      if (result) {
        config.width = "0%";
        // eslint-disable-next-line @typescript-eslint/no-shadow
        const value = getDefaultValue(control.value, config.datatype);
        if (config.defaultValue) {
          control.patchValue(config.defaultValue, {
            onlySelf: true,
            emitEvent: true,
          });
        } else {
          control.patchValue(value, { onlySelf: true, emitEvent: true });
        }
        control.clearValidators();
        control.clearAsyncValidators();
        control.updateValueAndValidity({ onlySelf: true, emitEvent: true });
      } else {
        config.width = config.templateOptions.hideWidth;
        // clear before value  and default value from before save
        if (config.defaultValue) {
          if (control.value === config.defaultValue) {
            control.patchValue(config.defaultValue, {
              onlySelf: true,
              emitEvent: true,
            });
          }
        } else if (config.type === "textbox" || config.type === "textboxview") {
          return;
          // view page shouldn't clean value
        } else if (config.type === "searchableDropdown") {
          context.emit(config.name, "");
        } else {
          control.patchValue("", { onlySelf: true, emitEvent: false });
        }
        control.setValidators(
          this.service.validatorFactory.createValidators(config, context)
        );
        control.setAsyncValidators(
          this.service.validatorFactory.createAsyncValidators(config, context)
        );
        control.updateValueAndValidity({ onlySelf: true, emitEvent: true });
      }
    };
  }

  @CustomExpression("resetWidthAndVisibilityBySriLanka")
  resetWidthAndVisibilityBySriLanka(
    value: any,
    config: ComponentConfig,
    context: DynamicContext,
    formGroup: UntypedFormGroup
  ) {
    return (params: any): void => {
      const fn = `return !(${params})`;
      const runtime = new Function(
        "config",
        "context",
        "$value",
        "$formGroup",
        fn
      );
      let result;
      let checkBankCountry = false;
      const BankCountry = formGroup.get('BankCountry').value;
      if (BankCountry && BankCountry === "LK") {
        checkBankCountry = true;
      }
      const supplierRegisteredCountry = context.parent.getValue("parentModel").SupplierProfile.SupplierOrganization.SupplierRegisteredCountry;
      let checkSupplierRegisteredCountry = false;
      if (supplierRegisteredCountry && supplierRegisteredCountry === 'LK') {
        checkSupplierRegisteredCountry = true;
      }
      let checkOnboardedCountryy = false;
      const onboardedCountry = context.parent.getValue('parentModel').RequestForm.RequestorDetailsInfo.SupplierCountry;
      if (onboardedCountry.includes("LK")) {
        checkOnboardedCountryy = true;
      }
      if(context.parent.getValue('parentModel').RequestForm.RequestFormInfo.IsCreditCardSupplier === "Y") {
        result = true;
      } else {
        result = (checkOnboardedCountryy && checkSupplierRegisteredCountry && checkBankCountry);
      }
      config.hide = result;
      const control = formGroup.get(config.name);
      if (!control) {
        return;
      }
      if (result) {
        config.width = "0%";
        // eslint-disable-next-line @typescript-eslint/no-shadow
        const value = getDefaultValue(control.value, config.datatype);
        if (config.defaultValue) {
          control.patchValue(config.defaultValue, {
            onlySelf: true,
            emitEvent: true,
          });
        } else {
          control.patchValue(value, { onlySelf: true, emitEvent: true });
        }
        control.clearValidators();
        control.clearAsyncValidators();
        control.updateValueAndValidity({ onlySelf: true, emitEvent: true });
      } else {
        config.width = config.templateOptions.hideWidth;
        // clear before value  and default value from before save
        if (config.defaultValue) {
          if (control.value === config.defaultValue) {
            control.patchValue(config.defaultValue, {
              onlySelf: true,
              emitEvent: true,
            });
          }
        } else if (config.type === "textbox" || config.type === "textboxview") {
          return;
          // view page shouldn't clean value
        } else if (config.type === "searchableDropdown") {
          context.emit(config.name, "");
        } else {
          control.patchValue("", { onlySelf: true, emitEvent: false });
        }
        control.setValidators(
          this.service.validatorFactory.createValidators(config, context)
        );
        control.setAsyncValidators(
          this.service.validatorFactory.createAsyncValidators(config, context)
        );
        control.updateValueAndValidity({ onlySelf: true, emitEvent: true });
      }
    };
  }

  @CustomExpression("resetWidthAndVisibilityByChinaByIntermediary")
  setVisibilityByChinaByIntermediary(
    value: any,
    config: ComponentConfig,
    context: DynamicContext,
    formGroup: UntypedFormGroup
  ) {
    return (params: any): void => {
      const fn = `return !(${params})`;
      const runtime = new Function(
        "config",
        "context",
        "$value",
        "$formGroup",
        fn
      );
      let result2 = false;
      const BankCountry = formGroup.get('IntermediaryBankCountry').value;
      if (BankCountry && (BankCountry === "CN" || BankCountry === "HK" || BankCountry === "TW")) {
        result2 = true;
      }
      const accountHoldName = formGroup.get('IntermediaryAccountHolderNm').value;
      const companyName = context.parent.getValue('parentModel').SupplierProfile.SupplierGeneral.CompanyName;
      let result1 = false;
      if (accountHoldName && companyName !== accountHoldName) {
        result1 = true;
      }
      let result3 = false;
      const GU = context.parent.getValue('parentModel').RequestForm.RequestorDetailsInfo.SupplierGeographical;
      if (GU == 18) {
        result3 = true;
      }
      const result = !(result2 && result1 && result3);
      config.hide = result;
      const control = formGroup.get(config.name);
      if (!control) {
        return;
      }
      if (result) {
        config.width = "0%";
        // eslint-disable-next-line @typescript-eslint/no-shadow
        const value = getDefaultValue(control.value, config.datatype);
        if (config.defaultValue) {
          control.patchValue(config.defaultValue, {
            onlySelf: true,
            emitEvent: true,
          });
        } else {
          control.patchValue(value, { onlySelf: true, emitEvent: true });
        }
        control.clearValidators();
        control.clearAsyncValidators();
        control.updateValueAndValidity({ onlySelf: true, emitEvent: true });
      } else {
        config.width = config.templateOptions.hideWidth;
        // clear before value  and default value from before save
        if (config.defaultValue) {
          if (control.value === config.defaultValue) {
            control.patchValue(config.defaultValue, {
              onlySelf: true,
              emitEvent: true,
            });
          }
        } else if (config.type === "textbox" || config.type === "textboxview") {
          return;
          // view page shouldn't clean value
        } else if (config.type === "searchableDropdown") {
          context.emit(config.name, "");
        } else {
          control.patchValue("", { onlySelf: true, emitEvent: false });
        }
        control.setValidators(
          this.service.validatorFactory.createValidators(config, context)
        );
        control.setAsyncValidators(
          this.service.validatorFactory.createAsyncValidators(config, context)
        );
        control.updateValueAndValidity({ onlySelf: true, emitEvent: true });
      }
    };
  }

  @CustomExpression("resetWidthAndVisibilityByChinaByThirdParty")
  setVisibilityByChinaByThirdParty(
    value: any,
    config: ComponentConfig,
    context: DynamicContext,
    formGroup: UntypedFormGroup
  ) {
    return (params: any): void => {
      const fn = `return !(${params})`;
      const runtime = new Function(
        "config",
        "context",
        "$value",
        "$formGroup",
        fn
      );
      let result2 = false;
      const BankCountry = formGroup.get('ThirdPartyBankCountry').value;
      if (BankCountry && (BankCountry === "CN" || BankCountry === "HK" || BankCountry === "TW")) {
        result2 = true;
      }
      const accountHoldName = formGroup.get('ThirdPartyAccountHolderNm').value;
      const companyName = context.parent.getValue('parentModel').SupplierProfile.SupplierGeneral.CompanyName;
      let result1 = false;
      if (accountHoldName && companyName !== accountHoldName) {
        result1 = true;
      }
      let result3 = false;
      const GU = context.parent.getValue('parentModel').RequestForm.RequestorDetailsInfo.SupplierGeographical;
      if (GU == 18) {
        result3 = true;
      }
      const result = !(result2 && result1 && result3);
      config.hide = result;
      const control = formGroup.get(config.name);
      if (!control) {
        return;
      }
      if (result) {
        config.width = "0%";
        // eslint-disable-next-line @typescript-eslint/no-shadow
        const value = getDefaultValue(control.value, config.datatype);
        if (config.defaultValue) {
          control.patchValue(config.defaultValue, {
            onlySelf: true,
            emitEvent: true,
          });
        } else {
          control.patchValue(value, { onlySelf: true, emitEvent: true });
        }
        control.clearValidators();
        control.clearAsyncValidators();
        control.updateValueAndValidity({ onlySelf: true, emitEvent: true });
      } else {
        config.width = config.templateOptions.hideWidth;
        // clear before value  and default value from before save
        if (config.defaultValue) {
          if (control.value === config.defaultValue) {
            control.patchValue(config.defaultValue, {
              onlySelf: true,
              emitEvent: true,
            });
          }
        } else if (config.type === "textbox" || config.type === "textboxview") {
          return;
          // view page shouldn't clean value
        } else if (config.type === "searchableDropdown") {
          context.emit(config.name, "");
        } else {
          control.patchValue("", { onlySelf: true, emitEvent: false });
        }
        control.setValidators(
          this.service.validatorFactory.createValidators(config, context)
        );
        control.setAsyncValidators(
          this.service.validatorFactory.createAsyncValidators(config, context)
        );
        control.updateValueAndValidity({ onlySelf: true, emitEvent: true });
      }
    };
  }

  @CustomExpression("resetWidth")
  resetWidth(
    value: any,
    config: ComponentConfig,
    context: DynamicContext,
    formGroup: UntypedFormGroup
  ) {
    return (params: any): void => {
      const fn = `return !(${params})`;
      const runtime = new Function("config", "context", "$value", fn);
      const result = runtime(config, context, value);
      const control = formGroup.get(config.name);
      if (!control) {
        return;
      }

      config.width = config.templateOptions.width;
      if (result) {
        config.customStyle = config.templateOptions.customStyle;
        // eslint-disable-next-line @typescript-eslint/no-shadow
        const value = getDefaultValue(control.value, config.datatype);
        if (config.defaultValue) {
          control.patchValue(config.defaultValue, {
            onlySelf: true,
            emitEvent: true,
          });
        } else if (config.type === "textbox" || config.type === "textboxview") {
          return;
          // view page shouldn't clean value
        } else {
          control.patchValue(value, { onlySelf: true, emitEvent: true });
        }
        control.clearValidators();
        control.clearAsyncValidators();
        control.setErrors(null);
        control.updateValueAndValidity({ onlySelf: true, emitEvent: true });
        control.markAsPristine();
        control.markAsUntouched();
      } else {
        config.customStyle = {};
        // clear before value  and default value from before save
        if (config.defaultValue) {
          if (control.value === config.defaultValue) {
            control.patchValue(config.defaultValue, {
              onlySelf: true,
              emitEvent: true,
            });
          }
        } else if (config.type === "textbox" || config.type === "textboxview") {
          return;
          // view page shouldn't clean value
        } else {
          control.patchValue("", { onlySelf: true, emitEvent: true });
        }
        control.setValidators(
          this.service.validatorFactory.createValidators(config, context)
        );
        control.setAsyncValidators(
          this.service.validatorFactory.createAsyncValidators(config, context)
        );
        control.setErrors(null);
        control.updateValueAndValidity({ onlySelf: true, emitEvent: true });
      }
    };
  }

  @CustomExpression("addInformationIconForVAT")
  addInformationIconForVAT(
    value: any,
    config: ComponentConfig,
    context: DynamicContext,
    formGroup: UntypedFormGroup
  ) {
    return (params: any): void => {
      if (formGroup.controls["SupplierRegisteredCountry"].value === "CH") {
        config.tooltip = environment.languageSwitch
          ? this.languageService.getValue(
            "Expression.addInformationIconForVAT.SupplierRegisteredCountryCH"
          )
          : `<li>Your company may be exempt from paying VAT in Switzerland if:</li><li> - Annual turnover from taxable supplies generated in Switzerland and/or abroad is less than CHF 100'000 (CHF 150'000 for charitable institutions and non-profit-oriented sports and cultural associations).</li><li>- Your company only supplies goods or services in Switzerland that are exempt from VAT (e.g. medical treatments, educational services) and therefore do not have to register for VAT.</li>`;
      }
    };
  }

  @CustomExpression("changeLabelForUploader")
  changeLabelForUploader(
    value: any,
    config: ComponentConfig,
    context: DynamicContext,
    formGroup: UntypedFormGroup
  ) {
    return (params: any): void => {
      if (
        !(
          formGroup.controls["SupplierRegisteredCountry"].value === "BG" ||
          formGroup.controls["SupplierRegisteredCountry"].value === "HR" ||
          formGroup.controls["SupplierRegisteredCountry"].value === "CZ" ||
          formGroup.controls["SupplierRegisteredCountry"].value === "GR" ||
          formGroup.controls["SupplierRegisteredCountry"].value === "HU" ||
          formGroup.controls["SupplierRegisteredCountry"].value === "PL" ||
          formGroup.controls["SupplierRegisteredCountry"].value === "RO" ||
          formGroup.controls["SupplierRegisteredCountry"].value === "AE" ||
          formGroup.controls["SupplierRegisteredCountry"].value === "SA" ||
          formGroup.controls["SupplierRegisteredCountry"].value === "QA" ||
          formGroup.controls["SupplierRegisteredCountry"].value === "BH" ||
          formGroup.controls["SupplierRegisteredCountry"].value === "EG" ||
          formGroup.controls["SupplierRegisteredCountry"].value === "AT" ||
          formGroup.controls["SupplierRegisteredCountry"].value === "CH" ||
          formGroup.controls["SupplierRegisteredCountry"].value === "DE" ||
          formGroup.controls["SupplierRegisteredCountry"].value === "BG"
        ) &&
        context.getValue("isMET")
      ) {
        config.label = environment.languageSwitch
          ? this.languageService.getValue(
            "Expression.changeLabelForUploader.SupplierRegisteredCountry_MET_BG"
          )
          : "Please upload evidence of your company’s VAT or Business Registration Number :";
        return;
      }
      if (
        !(
          formGroup.controls["SupplierRegisteredCountry"].value === "AT" ||
          formGroup.controls["SupplierRegisteredCountry"].value === "CH" ||
          formGroup.controls["SupplierRegisteredCountry"].value === "DE" ||
          formGroup.controls["SupplierRegisteredCountry"].value === "BG" ||
          formGroup.controls["SupplierRegisteredCountry"].value === "HR" ||
          formGroup.controls["SupplierRegisteredCountry"].value === "CZ" ||
          formGroup.controls["SupplierRegisteredCountry"].value === "GR" ||
          formGroup.controls["SupplierRegisteredCountry"].value === "HU" ||
          formGroup.controls["SupplierRegisteredCountry"].value === "PL" ||
          formGroup.controls["SupplierRegisteredCountry"].value === "RO"
        ) &&
        (context.getValue("isUKIA") || context.getValue("isJapan") || context.getValue("isGallia"))
      ) {
        config.label = environment.languageSwitch
          ? this.languageService.getValue(
            "Expression.changeLabelForUploader.SupplierRegisteredCountry_UKIA_Africa"
          )
          : "Please upload evidence of your company’s VAT or Business Registration Number :";
        return;
      }

      if (
        context.getValue("isMET") &&
        !(
          formGroup.controls["SupplierRegisteredCountry"].value === "NO" ||
          formGroup.controls["SupplierRegisteredCountry"].value === "DK" ||
          formGroup.controls["SupplierRegisteredCountry"].value === "FI" ||
          formGroup.controls["SupplierRegisteredCountry"].value === "LV" ||
          formGroup.controls["SupplierRegisteredCountry"].value === "EE" ||
          formGroup.controls["SupplierRegisteredCountry"].value === "SE" ||
          formGroup.controls["SupplierRegisteredCountry"].value === "BG" ||
          formGroup.controls["SupplierRegisteredCountry"].value === "HR" ||
          formGroup.controls["SupplierRegisteredCountry"].value === "CZ" ||
          formGroup.controls["SupplierRegisteredCountry"].value === "GR" ||
          formGroup.controls["SupplierRegisteredCountry"].value === "HU" ||
          formGroup.controls["SupplierRegisteredCountry"].value === "PL" ||
          formGroup.controls["SupplierRegisteredCountry"].value === "RO"
        )
      ) {
        config.label = environment.languageSwitch
          ? this.languageService.getValue(
            "Expression.changeLabelForUploader.SupplierRegisteredCountry_MET_NO"
          )
          : "Please upload evidence of your Business Registration Number:";
        return;
      }
      if (
        formGroup.controls["SupplierRegisteredCountry"].value === "DK" ||
        formGroup.controls["SupplierRegisteredCountry"].value === "FI" ||
        formGroup.controls["SupplierRegisteredCountry"].value === "LV" ||
        formGroup.controls["SupplierRegisteredCountry"].value === "EE" ||
        formGroup.controls["SupplierRegisteredCountry"].value === "NO" ||
        formGroup.controls["SupplierRegisteredCountry"].value === "SE"
      ) {
        config.label = environment.languageSwitch
          ? this.languageService.getValue(
            "Expression.changeLabelForUploader.SupplierRegisteredCountry_DK_FI"
          )
          : "Please upload evidence of your VAT Number:";
        return;
      }
    };
  }

  @CustomExpression("changeLabelRequired")
  changeLabelRequired(
    value: any,
    config: ComponentConfig,
    context: DynamicContext,
    formGroup: UntypedFormGroup
  ) {
    return (params: any): void => {
      const fn = `return !(${params})`;
      const runtime = new Function("config", "context", "$value", fn);
      const result = runtime(config, context, value);
      const control = formGroup.get(config.name);
      if (result) {
        config.required = false;
        control.clearValidators();
        control.clearAsyncValidators();
        control.updateValueAndValidity({ onlySelf: true, emitEvent: true });
      } else {
        config.required = true;
        control.setValidators(
          this.service.validatorFactory.createValidators(config, context)
        );
        control.setAsyncValidators(
          this.service.validatorFactory.createAsyncValidators(config, context)
        );
        control.updateValueAndValidity({ onlySelf: true, emitEvent: true });
      }
    };
  }

  @CustomExpression("onChangeCountryValue")
  onChangeCountryValue(
    value: any,
    config: ComponentConfig,
    context: DynamicContext,
    formGroup: UntypedFormGroup
  ) {
    return (params: any): void => {
      if (config.name === this.onboardingService.supplierModel.Mapping.requestor.tsmTaxCategoryFieldName) {
        if (value === this.onboardingService.supplierModel.RequestForm.RequestorDetailsInfo.SupplierCountry[0]) {
          formGroup.get(config.name).setValue(this.onboardingService.supplierModel.Mapping.requestor.tsmTaxId);
        } else {
          formGroup.get(config.name).setValue('');
        }
      }
    }
  }

  @CustomExpression("customhookforsomeotherfields")
  customhookforsomeotherfields(
    value: any,
    config: ComponentConfig,
    context: DynamicContext,
    formGroup: UntypedFormGroup
  ) {
    return (params: any): void => {
      let requestorFieldName, requestorFieldValue, requestorBusinessRegistrationType;
      const originalModel = context.getValue("parentModel");
      const mappingObj:any = originalModel.Mapping.taxIdsFromTsm;
      let taxValuesFromTsm, taxFieldName;
      if(mappingObj && mappingObj.length > 0){
        taxValuesFromTsm = mappingObj.filter((item) => item.b4ptaxmaster_taxcategory !== '');
      }
      if(taxValuesFromTsm && taxValuesFromTsm.length > 0){
        taxFieldName = taxValuesFromTsm.filter((item) => item.b4ptaxmaster_taxcategory === config.name);
      }
      // const requestorObj = this.onboardingService.supplierModel.Mapping.requestor
      // const responseOrganizationFieldName = requestorObj.tsmTaxCategoryFieldName;
      const supplierOrganization = originalModel.SupplierProfile.SupplierOrganization;
      const supplierObj = originalModel.SupplierProfile.SupplierOrganization;
      if(config.name === 'BusinessRegistrationNb' && taxValuesFromTsm && taxValuesFromTsm.length > 0) {
        const businessRegistrationField = taxValuesFromTsm.filter((item) => item.b4ptaxmaster_taxcategory === 'Business Registration');
        if(businessRegistrationField && businessRegistrationField.length > 0){
          requestorFieldName = businessRegistrationField[0].b4ptaxmaster_taxcategory;
          requestorFieldValue = businessRegistrationField[0].tsmtaxmapping_tax_registration_number;
          requestorBusinessRegistrationType = businessRegistrationField[0].b4ptaxmaster_businessregistrationtype;
        }
      } else {
        if(taxFieldName && taxFieldName.length > 0){
          requestorFieldName = taxFieldName[0].b4ptaxmaster_taxcategory;
          requestorFieldValue = taxFieldName[0].tsmtaxmapping_tax_registration_number;
        }
      }

      // const requestorObj = this.onboardingService.supplierModel.Mapping.requestor
      // const requestorFieldName = requestorObj.tsmTaxCategoryFieldName;
      // const requestorFieldValue = requestorObj.tsmTaxId;
      // const requestorBusinessRegistrationType = requestorObj.businessregistrationtype;

      if (requestorFieldName == 'Business Registration') {
        if (value === requestorBusinessRegistrationType && isOrganizationEmpty(supplierOrganization)) {
          formGroup.get(config.name).setValue(requestorFieldValue)
        }else if (value === supplierOrganization.BusinessRegistrationType  && !isOrganizationEmpty(supplierOrganization)){
          formGroup.get(config.name).setValue(supplierOrganization[config.name])
        }else {
          formGroup.get(config.name).setValue('')
        }
      }
    };
  }

  @CustomExpression("changeLabel")
  changeLabel(
    value: any,
    config: ComponentConfig,
    context: DynamicContext,
    formGroup: UntypedFormGroup
  ) {
    return (params: any): void => {
      const fn = `return !(${params})`;
      const runtime = new Function("config", "context", "$value", fn);
      const result = runtime(config, context, value);
      const defaultLabel = config.label;
      if (!context["label" + config.name]) {
        context["label" + config.name] = config.label;
        if (!config.errorMessage) {
          config.errorMessage = {};
        }
        context["errorMessage" + config.name] = config.errorMessage["required"];
      }
      if (result) {
        config.label = environment.languageSwitch
          ? this.languageService.getValue(
            config.languageKey + ".templateOptions.label"
          )
          : config.templateOptions.label;
        if (config.templateOptions.errorMessage) {
          config.errorMessage["required"] = environment.languageSwitch
            ? this.languageService.getValue(
              config.languageKey + ".templateOptions.errorMessage"
            )
            : config.templateOptions.errorMessage;
        }
      } else {
        config.label = context["label" + config.name];
        if (!config.errorMessage) {
          config.errorMessage = {};
        }
        config.errorMessage["required"] = context["errorMessage" + config.name];
      }
    };
  }
  @CustomExpression("changeSubLabel")
  changeSubLabel(
    value: any,
    config: ComponentConfig,
    context: DynamicContext,
    formGroup: UntypedFormGroup
  ) {
    return (params: any): void => {
      const fn = `return !(${params})`;
      const runtime = new Function("config", "context", "$value", fn);
      const result = runtime(config, context, value);
      if (!context["subLabel" + config.name]) {
        context["subLabel" + config.name] = config.subLabel;
      }
      if (result) {
        config.subLabel = environment.languageSwitch
          ? this.languageService.getValue(
            config.languageKey + ".templateOptions.subLabel"
          )
          : config.templateOptions.subLabel;
      } else {
        config.subLabel = context["subLabel" + config.name];
      }
    };
  }

  @CustomExpression("changeTooltip")
  changeTooltip(
    value: any,
    config: ComponentConfig,
    context: DynamicContext,
    formGroup: UntypedFormGroup
  ) {
    return (params: any): void => {
      const fn = `return !(${params})`;
      const runtime = new Function("config", "context", "$value", fn);
      const result = runtime(config, context, value);
      if (result) {
        config.tooltip = environment.languageSwitch
          ? this.languageService.getValue(
            "Expression.changeTooltip.ABARouting_IN"
          )
          : "IFSC code consists of 11 alpha-numeric characters and is used to identify a specific bank and branch in which an account is held for NEFT, RTGS and IMPS finance transfer systems.";
      } else {
        config.tooltip = environment.languageSwitch
          ? this.languageService.getValue(
            "Expression.changeTooltip.ABARouting_NOT_IN"
          )
          : "A routing number is used to identify banks in North America. In the United States this is a 9 digit number. In Canada this is an 8 digit number and is also known as an institution/transit number";
      }
    };
  }

  @CustomExpression("customloadDatasource")
  customloadDatasource(
    value: any,
    config: ComponentConfig,
    context: DynamicContext,
    formGroup: UntypedFormGroup
  ) {
    return (params: any): void => {
      const fn = `return !(${params})`;
      const runtime = new Function("config", "context", "$value", fn);
      const result = runtime(config, context, value);
      delete config["options"];
      delete config["dataSource"];
      if (config.type === "textbox" || config.type === "textboxview") {
        return;
      }
      if (result) {
        config.type = config.templateOptions.type;
        config.dataSource = config.templateOptions.dataSource;
        Datasources.loadData(config, context, formGroup).then((ds) => {
          config.options = ds;
          context.emit(`DataSourceLoaded:${config.name}`, ds);
        });
      } else {
        formGroup
          .get(config.name)
          .patchValue("", { onlySelf: true, emitEvent: false });
        config.type = "input";
      }
    };
  }

  @CustomExpression("setVariableLabel")
  setVariableLabel(
    value: any,
    config: ComponentConfig,
    context: DynamicContext,
    formGroup: UntypedFormGroup
  ) {
    return (params: any): void => {
      let country;
      if (config.name === "BankKey") {
        country = formGroup.controls["BankCountry"].value;
      } else if (config.name === "ThirdPartyBankKey") {
        country = formGroup.controls["ThirdPartyBankCountry"].value;
      }

      switch (country) {
        case "AU":
        case "NZ":
          if (config.name === "BankKey") {
            config.label = environment.languageSwitch
              ? this.languageService.getValue(
                "Expression.setVariableLabel.BankKey_AU_NZ_label"
              )
              : "Bank State Branch Number";
          } else if (config.name === "ThirdPartyBankKey") {
            config.label = environment.languageSwitch
              ? this.languageService.getValue(
                "Expression.setVariableLabel.ThirdPartyBankKey_AU_NZ_label"
              )
              : "Third-Party Bank State Branch Number";
          }
          config.tooltip = "";
          break;
        case "IE":
        case "GB":
          if (config.name === "BankKey") {
            config.label = environment.languageSwitch
              ? this.languageService.getValue(
                "Expression.setVariableLabel.BankKey_IE_GB_label"
              )
              : "Sort Code";
          } else if (config.name === "ThirdPartyBankKey") {
            config.label = environment.languageSwitch
              ? this.languageService.getValue(
                "Expression.setVariableLabel.ThirdPartyBankKey_IE_GB_label"
              )
              : "Third-Party Sort Code";
          }
          config.tooltip = "";
          break;
        case "TR":
          if (context.parent.getValue('parentModel').RequestForm.RequestorDetailsInfo.SupplierCountry.includes("TR") && (context.parent.getValue("parentModel").SupplierProfile.SupplierOrganization.SupplierRegisteredCountry === "TR")) {
            if (config.type === "input") {
              delete config.tooltip

            }
          }
          else if (config.type === "input") {
            config.tooltip = environment.languageSwitch
              ? this.languageService.getValue(
                "Expression.setVariableLabel.Default_Input_tooltip"
              )
              : "A Bank Branch Code is a unique identifying code for a given branch of a bank. If not applicable please key N/A.";
          }
          break;
        default:
          if (config.name === "BankKey") {
            config.label = environment.languageSwitch
              ? this.languageService.getValue(
                "Expression.setVariableLabel.BankKey_Default_label"
              )
              : "Bank Branch Code";
          } else if (config.name === "ThirdPartyBankKey") {
            config.label = environment.languageSwitch
              ? this.languageService.getValue(
                "Expression.setVariableLabel.ThirdPartyBankKey_Default_label"
              )
              : "Third-Party Bank Branch Code";
          }
          if (config.type === "input") {
            config.tooltip = environment.languageSwitch
              ? this.languageService.getValue(
                "Expression.setVariableLabel.Default_Input_tooltip"
              )
              : "A Bank Branch Code is a unique identifying code for a given branch of a bank. If not applicable please key N/A.";
          }
          break;
      }
    };
  }

  @CustomExpression("setVariableLabelByTurkey")
  setVariableLabelByTurkey(
    value: any,
    config: ComponentConfig,
    context: DynamicContext,
    formGroup: UntypedFormGroup
  ) {
    return (params: any): void => {
      let country;

      if (config.name === "BankKey") {
        country = formGroup.controls["BankCountry"].value;
        if (country === 'TR' && context.parent.getValue('parentModel').RequestForm.RequestorDetailsInfo.SupplierCountry.includes('TR') && (context.parent.getValue("parentModel").SupplierProfile.SupplierOrganization.SupplierRegisteredCountry == 'TR')) {
          config.label = environment.languageSwitch
            ? this.languageService.getValue(
              "Expression.setVariableLabel.BankKey_ASG_TR_label"
            )
            : "Branch Code";
        }
      }
      else if (config.name === "IntermediaryBankKey") {
        country = formGroup.controls["IntermediaryBankCountry"].value;
        if (country === 'TR' && context.parent.getValue('parentModel').RequestForm.RequestorDetailsInfo.SupplierCountry.includes('TR') && (context.parent.getValue("parentModel").SupplierProfile.SupplierOrganization.SupplierRegisteredCountry == 'TR')) {
          config.label = environment.languageSwitch
            ? this.languageService.getValue(
              "Expression.setVariableLabel.IntermediaryBankKey_ASG_TR_label"
            )
            : "Intermediary Branch Code";
        }
      }
      else if (config.name === "ThirdPartyBankKey") {
        country = formGroup.controls["ThirdPartyBankCountry"].value;
        if (country === 'TR' && context.parent.getValue('parentModel').RequestForm.RequestorDetailsInfo.SupplierCountry.includes('TR') && (context.parent.getValue("parentModel").SupplierProfile.SupplierOrganization.SupplierRegisteredCountry == 'TR')) {
          config.label = environment.languageSwitch
            ? this.languageService.getValue(
              "Expression.setVariableLabel.ThirdPartyBankKey_ASG_TR_label"
            )
            : "Third-Party Branch Code";
        }
      }
    }
  }


  @CustomExpression("contractStatusIsDisable")
  contractStatusIsDisable(
    value: any,
    config: ComponentConfig,
    context: DynamicContext,
    formGroup: UntypedFormGroup
  ) {
    return (params: any): void => {
      let result = false;
      if (
        ((formGroup.get("SupplierGeographical").value === "13" ||
          formGroup.get("SupplierGeographical").value === "20" ||
          formGroup.get("SupplierGeographical").value === "21" ||
          formGroup.get("SupplierGeographical").value === "25") &&
          (formGroup.get("SignContract").value === "12" ||
            formGroup.get("SignContract").value === "")) ||
        formGroup.get("SupplierGeographical").value === "28") {
        result = true;
      }
      config.hide = result;
      const control = formGroup.get(config.name);
      if (!control) {
        return;
      }
      if (result) {
        const defaultValue = getDefaultValue(control.value, config.datatype);
        control.patchValue(defaultValue, { onlySelf: true, emitEvent: true });
        control.clearValidators();
        control.clearAsyncValidators();
        control.setErrors(null);
        control.updateValueAndValidity({ onlySelf: true, emitEvent: true });
        control.markAsPristine();
        control.markAsUntouched();
      } else {
        control.setValidators(
          this.service.validatorFactory.createValidators(config, context)
        );
        control.setAsyncValidators(
          this.service.validatorFactory.createAsyncValidators(config, context)
        );
        control.setErrors(null);
        control.updateValueAndValidity({ onlySelf: true, emitEvent: true });
      }
    };
  }

  @CustomExpression("setVisibleBasedOnPaymentTerm")
  setVisibleBasedOnPaymentTerm(
    value: any,
    config: ComponentConfig,
    context: DynamicContext,
    formGroup: UntypedFormGroup
  ) {
    return (params: any): void => {
      if (
        formGroup.get("Country").value === "Denmark" ||
        formGroup.get("Country").value === "Finland" ||
        formGroup.get("Country").value === "Norway" ||
        formGroup.get("Country").value === "Sweden" ||
        formGroup.get("Country").value === "Latvia"
      ) {
        const fn = `return !(${params})`;
        const runtime = new Function("config", "context", "$value", fn);
        const result = runtime(config, context, value);
        config.templateOptions.hide = result;
        if (!result && value !== "") {
          config.label = environment.languageSwitch
            ? this.languageService.getValue(
              "Expression.setVisibleBasedOnPaymentTerm.Nordics_label"
            )
            : "Please select the Non-Standard Payment Term agreed and upload evidence you have approval to use this:";
        } else {
          config.label = environment.languageSwitch
            ? this.languageService.getValue(
              "Expression.setVisibleBasedOnPaymentTerm.NOT_Nordics_label"
            )
            : "Please select an alternative Payment Term";
        }
      }
    };
  }

  @CustomExpression("setVisibleBasedOnPaymentTermForDetails")
  setVisibleBasedOnPaymentTermForDetails(
    value: any,
    config: ComponentConfig,
    context: DynamicContext,
    formGroup: UntypedFormGroup
  ) {
    return (params: any): void => {
      const fn = `return !(${params})`;
      const runtime = new Function("config", "context", "$value", fn);
      const result = runtime(config, context, value);
      if (!config.templateOptions) {
        config.templateOptions = {};
      }
      config.templateOptions.hide = result;
      if (!result && value !== "") {
        config.label = environment.languageSwitch
          ? this.languageService.getValue(
            "Expression.setVisibleBasedOnPaymentTermForDetails.Has_No_Result_label"
          )
          : "Please select the Non-Standard Payment Term agreed and upload evidence you have approval to use this:";
      } else {
        config.label = environment.languageSwitch
          ? this.languageService.getValue(
            "Expression.setVisibleBasedOnPaymentTermForDetails.Has_Result_label"
          )
          : "Please select an alternative Payment Term";
      }
    };
  }

  @CustomExpression("setVisibleForAttachment")
  setVisibleForAttachment(
    value: any,
    config: ComponentConfig,
    context: DynamicContext,
    formGroup: UntypedFormGroup
  ) {
    return (params: any): void => {
      let result = false;
      if (
        formGroup.get("Country").value === "Denmark" ||
        formGroup.get("Country").value === "Finland" ||
        formGroup.get("Country").value === "Norway" ||
        formGroup.get("Country").value === "Sweden" ||
        formGroup.get("Country").value === "Latvia"
      ) {
        const fn = `return !(${params})`;
        const runtime = new Function("config", "context", "$value", fn);
        result = runtime(config, context, value);
        if (formGroup.get("HasNonStandardPaymentTerms").value === "N") {
          config.hide = true;
          config.required = false;
        } else {
          config.hide = result;
          config.required = !result;
        }
        const control = formGroup.get(config.name);
        if (!control) {
          return;
        }
        if (result) {
          control.setValidators(
            this.service.validatorFactory.createValidators(config, context)
          );
          control.setAsyncValidators(
            this.service.validatorFactory.createAsyncValidators(config, context)
          );
          control.setErrors(null);
          control.updateValueAndValidity({ onlySelf: true, emitEvent: true });
        }
      }
    };
  }

  @CustomExpression("setVisibleForVAT")
  setVisibleForVAT(
    value: any,
    config: ComponentConfig,
    context: DynamicContext,
    formGroup: UntypedFormGroup
  ) {
    return (params: any): void => {
      const result = false;
      if (
        context
          .getValue("parentModel")
          .RequestForm.RequestorDetailsInfo.SupplierCountry.includes("US") ||
        context
          .getValue("parentModel")
          .RequestForm.RequestorDetailsInfo.SupplierCountry.includes("PR")
      ) {
        const fn = `return !(${params})`;
        const runtime = new Function("config", "context", "$value", fn);
        config.hide = runtime(config, context, value);
      } else {
        config.hide = result;
        config.required = !result;
      }
      const control = formGroup.get(config.name);
      if (!control) {
        return;
      }
      if (!config.hide) {
        control.setValidators(
          this.service.validatorFactory.createValidators(config, context)
        );
        control.setAsyncValidators(
          this.service.validatorFactory.createAsyncValidators(config, context)
        );
        control.setErrors(null);
        control.updateValueAndValidity({ onlySelf: true, emitEvent: true });
      } else {
        const defaultValue = getDefaultValue(control.value, config.datatype);
        control.patchValue(defaultValue, { onlySelf: true, emitEvent: true });
        control.clearValidators();
        control.clearAsyncValidators();
        control.setErrors(null);
        control.updateValueAndValidity({ onlySelf: true, emitEvent: true });
        control.markAsPristine();
        control.markAsUntouched();
      }
    };
  }

  @CustomExpression("fileSizeForSpecificCountry")
  fileSizeForSpecificCountry(
    value: string,
    config: ComponentConfig,
    context: DynamicContext,
    formGroup: UntypedFormGroup
  ) {
    return (): void => {
      const countryID: number = Number(value);
      const { tooltipMsg } = this.countryConfigService.initConfig(config, countryID);
      config.tooltip = tooltipMsg;
    };
  }

  @CustomExpression("setVisibleForBRIN")
  setVisibleForBRIN(
    value: any,
    config: ComponentConfig,
    context: DynamicContext,
    formGroup: UntypedFormGroup
  ) {
    return (params: any): void => {
      const countries: Array<string> =
        context.get("parentModel").value.RequestForm.RequestorDetailsInfo
          .SupplierCountry;
      const control = formGroup.get(config.name);
      if (countries.includes("US")) {
        if (value === "US") {
          config.hide = true;
          config.width = "";
          clearValidators(control);
        } else {
          config.hide = false;
          config.width = "";
          attachValidators(this.service, control, config, context);
        }
      } else if (countries.includes("PR")) {
        if (value === "PR") {
          config.hide = true;
          config.width = "";
          clearValidators(control);
        } else {
          config.hide = false;
          attachValidators(this.service, control, config, context);
        }
      }
    };
  }

  @CustomExpression("setVisibleForUsaEmpIdNum")
  setVisibleForUsaEmpIdNum(
    value: any,
    config: ComponentConfig,
    context: DynamicContext,
    formGroup: UntypedFormGroup
  ) {
    return (params: any): void => {
      const countries: Array<string> =
        context.get("parentModel").value.RequestForm.RequestorDetailsInfo
          .SupplierCountry;
      const control = formGroup.get(config.name);
      if (countries.length === 1 && countries.includes("PR")) {
        config.hide = false;
        config.width = "";
        attachValidators(this.service, control, config, context);
        return;
      }
      if (
        countries.includes("PR") &&
        !countries.includes("US") &&
        value !== "PR"
      ) {
        config.hide = true;
        clearValidators(control);
      } else {
        config.hide = false;
        config.width = "";
        attachValidators(this.service, control, config, context);
      }
    };
  }

  @CustomExpression("changeSubLabelForUploader")
  changeSubLabelForUploader(
    value: any,
    config: ComponentConfig,
    context: DynamicContext,
    formGroup: UntypedFormGroup
  ) {
    return (params: any): void => {
      let subLabel = environment.languageSwitch
        ? this.languageService.getValue(
          "Expression.changeSubLabelForUploader.Base_subLabel"
        )
        : "Upload any document which matches with the provided (VAT/TIN/GST, etc.) in the Registration section, like an invoice copy/letter head /VAT certificate/IRS W9 or Service Tax certificate; with the tax details on it.<br><br>";
      const tempLabel = environment.languageSwitch
        ? this.languageService.getValue(
          "Expression.changeSubLabelForUploader.SupplierRegisteredCountry_NOT_CA_subLabel"
        )
        : `· VAT/Registration Number/Tax Identification Number (Local country Tax ID)<br>`;
      const setupCountry: Array<string> =
        context.getValue("parentModel").RequestForm.RequestorDetailsInfo
          .SupplierCountry;
      const registeredCountry =
        formGroup.controls["SupplierRegisteredCountry"].value;
      if (setupCountry.includes("Ca")) {
        subLabel =
          subLabel +
          (environment.languageSwitch
            ? this.languageService.getValue(
              "Expression.changeSubLabelForUploader.SupplierCountryCA_subLabel"
            )
            : `· Canada: Goods and Service Tax (GST) Number<br>· Canada: Provincial Sales Tax (PST) Number<br>· Canada: Quebec Sales Tax (QST)  Number <br>
        · Canada: Harmonized Sales Tax (HST) Number<br>`);
        if (registeredCountry !== "Ca") {
          subLabel = subLabel + tempLabel;
        }
      }
      if (setupCountry.includes("US") && registeredCountry !== "US") {
        const indexVAT = subLabel.indexOf(tempLabel);
        if (indexVAT > -1) {
          subLabel = subLabel.substring(0, indexVAT);
        }
        subLabel =
          subLabel +
          (environment.languageSwitch
            ? this.languageService.getValue(
              "Expression.changeSubLabelForUploader.SupplierCountryUS_SupplierRegisteredCountry_NOTUS_subLabel"
            )
            : `· Employer Identification Number (EIN/TIN)/Social Security Number (SSN)<br>
        · VAT/Registration Number/Tax Identification Number (Local country Tax ID)<br>`);
      }
      if (setupCountry.includes("PR") && registeredCountry !== "PR") {
        const indexVAT = subLabel.indexOf(tempLabel);
        if (indexVAT === -1) {
          subLabel =
            subLabel +
            (environment.languageSwitch
              ? this.languageService.getValue(
                "Expression.changeSubLabelForUploader.SupplierCountryPR_SupplierRegisteredCountry_NOTPR_subLabel"
              )
              : `· VAT/Registration Number/Tax Identification Number (Local country Tax ID)<br>`);
        }
      }
      if(context.getValue("parentModel").RequestForm.RequestorDetailsInfo.SpendCommodityGroup=="22"){
        subLabel= this.languageService.getValue("Expression.changeSubLabelForUploader.isManufacturingAutomation_subLabel")
      }
      subLabel =
        subLabel +
        (environment.languageSwitch
          ? this.languageService.getValue(
            "Expression.changeSubLabelForUploader.Default_subLabel"
          )
          : `<br>Please note, if a valid document is not attached, we will be unable to complete your onboarding until this is rectified.`);
      config.subLabel = subLabel;
    };
  }

  @CustomExpression("changeSubLabelForUploaderTR")
  changeSubLabelForUploaderTR(
    value: any,
    config: ComponentConfig,
    context: DynamicContext,
    formGroup: UntypedFormGroup
  ) {
    return (params: any): void => {
      let subLabel = ''
      const setupCountry: Array<string> =
        context.getValue("parentModel").RequestForm.RequestorDetailsInfo
          .SupplierCountry;
      const registeredCountry =
        formGroup.controls["SupplierRegisteredCountry"].value;
      if (setupCountry.includes("TR") && registeredCountry === "TR") {
        subLabel =
          subLabel +
          (environment.languageSwitch
            ? this.languageService.getValue(
              "Hook.changeConfig.UploadRegistrationDocuments.isTurkey_subLabel"
            )
            : `You are required to upload <u><b>ANY ONE</b></u> document`);
      } else {
        subLabel =
          subLabel +
          (environment.languageSwitch
            ? this.languageService.getValue(
              "Hook.changeConfig.UploadRegistrationDocuments.isICEGOrASGOrUKIA_subLabel"
            )
            : `You are required to upload <u><b>ANY ONE</b></u> document`);
      }
      if(context.getValue("parentModel").RequestForm.RequestorDetailsInfo.SpendCommodityGroup=="22"){
        subLabel= this.languageService.getValue("Hook.changeConfig.UploadRegistrationDocuments.isManufacturingAutomation_subLabel")
      }
      config.subLabel = subLabel;
    };
  }

  //get HK TW CN  sublable msg
  getCNHKTWSublabelMsg(registeredCountry: string, registeredType: string) {
    let sublabelMsg = null;
    if (registeredCountry === "HK" || registeredCountry === "TW" || registeredCountry === "CN") {
      if (registeredCountry === "HK" && registeredType !== "HK9") {
        sublabelMsg = this.languageService.getValue("Hook.changeConfig.UploadRegistrationDocuments.isRegistedCountryHKANDtypeNotIndividual_subLabel")
      }
      else if (registeredCountry === "HK" && registeredType === "HK9") {
        sublabelMsg = this.languageService.getValue("Hook.changeConfig.UploadRegistrationDocuments.isRegistedCountryHKANDtypeIndividual_subLabel")
      }
      else if (registeredCountry === "TW" && registeredType !== "TW8") {
        sublabelMsg = this.languageService.getValue("Hook.changeConfig.UploadRegistrationDocuments.isRegistedCountryTWANDtypeNotIndividualsubLabel")
      }
      else if (registeredCountry === "TW" && registeredType === "TW8") {
        sublabelMsg = this.languageService.getValue("Hook.changeConfig.UploadRegistrationDocuments.isRegistedCountryTWANDtypeIndividual_subLabel")
      }
      else if (registeredCountry === "CN") {
        sublabelMsg = this.languageService.getValue("Hook.changeConfig.UploadRegistrationDocuments.isRegistedCountryCN_subLabel")
      }
    }
    return sublabelMsg;
  }
  // PBI 2250797
  @CustomExpression("changeSubLabelForUploaderFR")
  changeSubLabelForUploaderFR(
    value: any,
    config: ComponentConfig,
    context: DynamicContext,
    formGroup: UntypedFormGroup
  ) {
    return (params: any): void => {
      const setupCountry: Array<string> =
        context.getValue("parentModel").RequestForm.RequestorDetailsInfo
          .SupplierCountry;
      //GET 1st setupCountry if there is only one supplier Country
      let supplierCountry0 = null;
      if (context.getValue("parentModel").RequestForm.RequestorDetailsInfo.SupplierCountry.length === 1) {
        supplierCountry0 = context.getValue("parentModel").RequestForm.RequestorDetailsInfo.SupplierCountry[0];
      }
      const registeredCountry = formGroup.controls["SupplierRegisteredCountry"].value;
      //PBI 2663701
      const registeredType = formGroup.controls["BusinessRegistrationType"].value;
      if(context.getValue("parentModel").RequestForm.RequestorDetailsInfo.SpendCommodityGroup=="22"){
        config.subLabel= this.languageService.getValue("Hook.changeConfig.UploadRegistrationDocuments.isManufacturingAutomation_subLabel")
      }
      //Gallia
      else if (context.getValue("isGallia")) {
        if (setupCountry.includes("FR") && registeredCountry === "FR") {
          let translation = this.languageService.getValue("Hook.changeConfig.UploadRegistrationDocuments.isFR_subLabel")
          config.subLabel = translation
        }
        else if (registeredCountry === "CN" || registeredCountry === "HK" || registeredCountry === "TW") {
          config.subLabel = this.getCNHKTWSublabelMsg(registeredCountry, registeredType)
        }
        else {
          config.subLabel = this.languageService.getValue("Hook.changeConfig.UploadRegistrationDocuments.isICEGOrASGOrUKIA_subLabel")
        }
      }
      //PH
      else if (context.getValue("isPH")) {
        if (registeredCountry === "CN" || registeredCountry === "HK" || registeredCountry === "TW") {
          config.subLabel = this.getCNHKTWSublabelMsg(registeredCountry, registeredType)
        }
        else {
          config.subLabel = this.languageService.getValue("Hook.changeConfig.UploadRegistrationDocuments.isPH_subLabel")
        }
      }
      //Iberia
      else if (context.getValue("isIberia")) {
        if (registeredCountry === "CN" || registeredCountry === "HK" || registeredCountry === "TW") {
          config.subLabel = this.getCNHKTWSublabelMsg(registeredCountry, registeredType);
        }
        else {
          config.subLabel = this.languageService.getValue("Hook.changeConfig.UploadRegistrationDocuments.isICEGOrASG_subLabel")
        }
      }
      //ICEG ASG UKIA Nordics MET Africa
      else if (context.getValue("isICEG") || context.getValue("isASG") || context.getValue("isUKIA") || context.getValue("isJapan") || context.getValue("isNordics") || context.getValue("isMET") || context.getValue("isIberia")) {
        if (registeredCountry === "CN" || registeredCountry === "HK" || registeredCountry === "TW") {
          config.subLabel = this.getCNHKTWSublabelMsg(registeredCountry, registeredType)
        }
        else {
          config.subLabel = this.languageService.getValue("Hook.changeConfig.UploadRegistrationDocuments.isICEGOrASGOrUKIA_subLabel")
        }
      }
      //NA
      else if ((context.getValue("isCanada") || context.getValue("isUnitedStates"))) {
        if (registeredCountry === "CN" || registeredCountry === "HK" || registeredCountry === "TW") {
          config.subLabel = this.getCNHKTWSublabelMsg(registeredCountry, registeredType)
        }
        else if (supplierCountry0 === "Ca") {
          config.subLabel = this.languageService.getValue("Hook.changeConfig.UploadRegistrationDocuments.isNAOrCa_subLabel")
        }
        else if (supplierCountry0 === "PR") {
          config.subLabel = this.languageService.getValue("Hook.changeConfig.UploadRegistrationDocuments.isUnitedStatesAndPR_subLabel")
        }
        else if (supplierCountry0 === "US") {
          config.subLabel = this.languageService.getValue("Hook.changeConfig.UploadRegistrationDocuments.isUnitedStatesAndUS_subLabel")
        }
      }
      //Africa
      // else if (context.getValue("isAfrica") && context.getValue("parentModel").RequestForm.RequestorDetailsInfo.SupplierCountry.includes("ZA")) {
      //   config.subLabel = this.languageService.getValue("Hook.changeConfig.UploadRegistrationDocuments.isAfricaAndZA_subLabel")
      // }
      //Other GU
      else {
        if (registeredCountry === "CN" || registeredCountry === "HK" || registeredCountry === "TW") {
          config.subLabel = this.getCNHKTWSublabelMsg(registeredCountry, registeredType)
        } else {
          config.subLabel = this.languageService.getValue("supplier.step2.UploadRegistrationDocuments.subLabel")
        }
      }
    };
  }

  @CustomExpression("changeSubLabelForCN")
  changeSubLabelForCN(
    value: any,
    config: ComponentConfig,
    context: DynamicContext,
    formGroup: UntypedFormGroup
  ) {
    return (params: any): void => {
      const registeredCountry = formGroup.controls["SupplierRegisteredCountry"].value;
      //PBI 2663701
      const registeredType = formGroup.controls["BusinessRegistrationType"].value;
      if (registeredCountry === "HK" || registeredCountry === "TW" || registeredCountry === "CN") {
        config.subLabel = this.getCNHKTWSublabelMsg(registeredCountry, registeredType);
      }
    };
  }

  @CustomExpression("changeSubLabelForUploadCompanysAddressFR")
  changeSubLabelForUploadCompanysAddressFR(
    value: any,
    config: ComponentConfig,
    context: DynamicContext,
    formGroup: UntypedFormGroup
  ) {
    return (params: any): void => {
      const setupCountry: Array<string> =
        context.getValue("parentModel").RequestForm.RequestorDetailsInfo
          .SupplierCountry;
      const supplierGeneralCountry =
        formGroup.controls["Country"].value;

      if (context.getValue("isGallia") && setupCountry.includes("FR") && supplierGeneralCountry === "FR") {
        let translation = this.languageService.getValue("Hook.changeConfig.UploadCompanysAddress.isFR")
        config.subLabel = translation
      }
      else if (context.getValue("isGallia")) {
        config.subLabel = this.languageService.getValue("Hook.changeConfig.UploadCompanysAddress.isUKIAorNordicsorASGorICEGorMET")
      }
      else if (context.getValue("isIN")) {
        config.subLabel = this.languageService.getValue("Hook.changeConfig.UploadCompanysAddress.isIN")
      }
      else if (context.getValue("isUKIA") || context.getValue("isJapan") || context.getValue("isGallia") || context.getValue("isNordics") || context.getValue("isASG") || context.getValue("isICEG") || context.getValue("isMET") || context.getValue("isIberia")) {
        config.subLabel = this.languageService.getValue("Hook.changeConfig.UploadCompanysAddress.isUKIAorNordicsorASGorICEGorMET")
      }
      else if (context.getValue("isCanada") && context.getValue("parentModel").RequestForm.RequestorDetailsInfo.SupplierCountry.length === 1 && !!context.getValue("parentModel").RequestForm.RequestorDetailsInfo.SupplierCountry.includes("Ca")) {
        config.subLabel = this.languageService.getValue("Hook.changeConfig.UploadCompanysAddress.isNAandCa")
      }
      else if (context.getValue("isUnitedStates") && context.getValue("parentModel").RequestForm.RequestorDetailsInfo.SupplierCountry.length === 1 && !!context.getValue("parentModel").RequestForm.RequestorDetailsInfo.SupplierCountry.includes("PR")) {
        config.subLabel = this.languageService.getValue("Hook.changeConfig.UploadCompanysAddress.isNAandPR")
      }
      else if (context.getValue("isUnitedStates") && context.getValue("parentModel").RequestForm.RequestorDetailsInfo.SupplierCountry.length === 1 && !!context.getValue("parentModel").RequestForm.RequestorDetailsInfo.SupplierCountry.includes("US")) {
        config.subLabel = this.languageService.getValue("Hook.changeConfig.UploadCompanysAddress.isNAandUS")
      }
      else if (context.getValue("isUKIA") && !!context.getValue("parentModel").RequestForm.RequestorDetailsInfo.SupplierCountry.includes("ZA")) {
        config.subLabel = this.languageService.getValue("Hook.changeConfig.UploadCompanysAddress.isUKIAandZA")
      }
      else {
        config.subLabel = this.languageService.getValue("supplier.step1.UploadCompanysAddress.subLabel")
      }
    };
  }

  @CustomExpression("changeSubLabelForUploadCompanysAddressMix")
  changeSubLabelForUploadCompanysAddressMix(
    value: any,
    config: ComponentConfig,
    context: DynamicContext,
    formGroup: UntypedFormGroup
  ) {
    return (params: any): void => {
      if (context.getValue("parentModel").RequestForm.RequestorDetailsInfo.SpendCommodityGroup=="22") {
        config.subLabel = this.languageService.getValue("Hook.changeConfig.UploadCompanysAddress.isManufacturingAutomation_subLabel")
      }
    };
  }
  @CustomExpression("setIntermediaryIntermediary")
  setIntermediaryIntermediary(
    value: any,
    config: ComponentConfig,
    context: DynamicContext,
    formGroup: UntypedFormGroup
  ) {
    return (params: any): void => {
      switch (value) {
        case "AU":
        case "NZ":
          config.label = environment.languageSwitch
            ? this.languageService.getValue(
              "Expression.setIntermediaryIntermediary.AU_NZ_label"
            )
            : "Intermediary Bank State Branch Number";
          config.tooltip = "";
          break;
        case "IE":
        case "GB":
          config.label = environment.languageSwitch
            ? this.languageService.getValue(
              "Expression.setIntermediaryIntermediary.IE_GB_label"
            )
            : "Intermediary Sort Code";
          config.tooltip = "";
          break;
        default:
          config.label = environment.languageSwitch
            ? this.languageService.getValue(
              "Expression.setIntermediaryIntermediary.Default_label"
            )
            : "Intermediary Bank Branch Code";
          break;
      }
    };
  }

  @CustomExpression("changeZipCdCaLabel")
  changeZipCdCaLabel(
    value: any,
    config: ComponentConfig,
    context: DynamicContext,
    formGroup: UntypedFormGroup
  ) {
    return (params: any): void => {
      const fn = `return (${params})`;
      const runtime = new Function("config", "context", "$value", fn);
      const result = runtime(config, context, value);
      const defaultLabel = config.label;
      if (!context["label" + config.name]) {
        context["label" + config.name] = config.label;
        if (!config.errorMessage) {
          config.errorMessage = {};
        }
        context["errorMessage" + config.name] = config.errorMessage["required"];
      }
      if (result) {
        config.label = environment.languageSwitch
          ? this.languageService.getValue(
            "Expression.changeZipCdCaLabel.CA_label"
          )
          : "Postal Code";
        if (config.templateOptions.errorMessage) {
          config.errorMessage["required"] = environment.languageSwitch
            ? this.languageService.getValue(
              "Expression.changeZipCdCaLabel.CA_errorMessage"
            )
            : "please input Postal Code";
        }
      }
    };
  }

  @CustomExpression("setVisibleForExplain")
  setVisibleForExplain(
    value: any,
    config: ComponentConfig,
    context: DynamicContext,
    formGroup: UntypedFormGroup
  ) {
    return (params: any): void => {
      const control = formGroup.get(config.name);
      const valueList: string[] = value;
      switch (config.name) {
        case "InformationSecurity_1cb_other":
          config.hide = !valueList.includes("Other (Please explain)");
          break;
        case "InformationSecurity_1c_2_other":
          config.hide = !valueList.includes("17");
          break;
        case "InformationSecurity_1ca_2_other":
        case "InformationSecurity_1cd_5_other":
          config.hide = !valueList.includes("Other – Please explain");
          break;
        default:
          config.hide = true;
      }
      if (!config.hide) {
        attachValidators(this.service, control, config, context);
      } else {
        clearValidators(control);
      }
    };
  }

  @CustomExpression("setVisibleForInformationSecurity_1ca_2")
  setVisibleForInformationSecurity_1ca_2(
    value: any,
    config: ComponentConfig,
    context: DynamicContext,
    formGroup: UntypedFormGroup
  ) {
    return (params: any): void => {
      const control = formGroup.get(config.name);
      const valueList: string[] = value;
      if (formGroup.get("InformationSecurity_1c_new").value === "10") {
        config.hide = true;
      }
      if (
        formGroup.get("InformationSecurity_1c_new").value === "11" &&
        formGroup
          .get("InformationSecurity_1ca_2")
          .value.includes("Other – Please explain")
      ) {
        config.hide = false;
      }
    };
  }

  @CustomExpression("setVisibleForPerformed")
  setVisibleForPerformed(
    value: any,
    config: ComponentConfig,
    context: DynamicContext,
    formGroup: UntypedFormGroup
  ) {
    return (params: any): void => {
      const countries: Array<string> =
        context.get("parentModel").value.RequestForm.RequestorDetailsInfo
          .SupplierCountry;
      const control = formGroup.get(config.name);
      if (countries.length > 1 && countries.includes("US")) {
        if (value !== "US" && value !== "PR") {
          config.hide = false;
          config.width = "";
          attachValidators(this.service, control, config, context);
        } else {
          config.hide = true;
          clearValidators(control);
        }
      } else {
        if (value !== "US" && value !== "PR" && value !== "Ca") {
          config.hide = false;
          config.width = "";
          attachValidators(this.service, control, config, context);
        } else {
          config.hide = true;
          clearValidators(control);
        }
      }
    };
  }

  @CustomExpression("changeZipCdInLabel")
  changeZipCdInLabel(
    value: any,
    config: ComponentConfig,
    context: DynamicContext,
    formGroup: UntypedFormGroup
  ) {
    return (params: any): void => {
      const fn = `return (${params})`;
      const runtime = new Function("config", "context", "$value", fn);
      const result = runtime(config, context, value);
      const defaultLabel = config.label;
      if (!context["label" + config.name]) {
        context["label" + config.name] = config.label;
        if (!config.errorMessage) {
          config.errorMessage = {};
        }
        context["errorMessage" + config.name] = config.errorMessage["required"];
      }
      if (result) {
        config.label = environment.languageSwitch
          ? this.languageService.getValue(
            "Expression.changeZipCdInLabel.IN_label"
          )
          : "Zip/Postal code";
        if (config.templateOptions.errorMessage) {
          config.errorMessage["required"] = environment.languageSwitch
            ? this.languageService.getValue(
              "Expression.changeZipCdInLabel.IN_errorMessage"
            )
            : "please input Zip/Postal Code";
        }
      }
    };
  }

  @CustomExpression("changeDistrictLabel")
  changeDistrictLabel(
    value: any,
    config: ComponentConfig,
    context: DynamicContext,
    formGroup: UntypedFormGroup
  ) {
    return (params: any): void => {
      const fn = `return !(${params})`
      const onboardedCountry: Array<string> = context.getValue("parentModel")?.RequestForm?.RequestorDetailsInfo?.SupplierCountry;
      if (!context["label" + config.name]) {
        context["label" + config.name] = config.label;
        if (!config.errorMessage) {
          config.errorMessage = {};
        }
        context["errorMessage" + config.name] = config.errorMessage["required"];
      }
      if ((onboardedCountry.includes("RS") && formGroup.controls["Country"].value === "RS") || (onboardedCountry.includes("TR") && formGroup.controls["Country"].value === "TR")) {
        config.label = environment.languageSwitch
          ? this.languageService.getValue(
            config.languageKey + ".templateOptions.label"
          )
          : config.templateOptions.label;
        if (config.templateOptions.errorMessage) {
          config.errorMessage["required"] = environment.languageSwitch
            ? this.languageService.getValue(
              config.languageKey + ".templateOptions.errorMessage"
            )
            : config.templateOptions.errorMessage;
        }
      } else {
        config.label = context["label" + config.name];
        if (!config.errorMessage) {
          config.errorMessage = {};
        }
        config.errorMessage["required"] = context["errorMessage" + config.name];
      }
    };
  }

  @CustomExpression("checkGSTRegistrationNumberValid")
  checkGSTRegistrationNumberValid(
    value: any,
    config: ComponentConfig,
    context: DynamicContext,
    formGroup: UntypedFormGroup
  ) {
    return (params: any) => {
      const control = formGroup.get(config.name);
      if (control.value) {
        const stateCode =
          context.getValue("parentModel").SupplierProfile.SupplierGeneral
            .IndiaState;
        const PANCode = formGroup.get("CompanyPANNumber").value;
        const pattern = /^[A-z0-9]{1,}$/;
        if (
          !!value &&
          !(
            control.value.substring(0, 2) === stateCode &&
            control.value.substring(2, 12) === PANCode &&
            !!control.value.substring(12, 15).match(pattern)
          )
        ) {
          config.errorMessage["matchSpecialNumericalAlphabet"] =
            environment.languageSwitch
              ? this.languageService.getValue(
                "Expression.checkGSTRegistrationNumberValid.matchSpecialNumericalAlphabet"
              )
              : `Please input correct 15 characters GST Registration Number,
          format is: state GST state code(2 characters) + PAN number(10 characters) + 3 numerical or alphabet characters`;
          const currentError = { matchSpecialNumericalAlphabet: true };
          control.setErrors(currentError);
          control.updateValueAndValidity({ onlySelf: true, emitEvent: true });
          control.markAsDirty();
          control.markAsTouched();
        } else {
          control.setErrors(null);
          control.updateValueAndValidity({ onlySelf: true, emitEvent: true });
          control.markAsPristine();
          control.markAsUntouched();
        }
      }
    };
  }

  @CustomExpression("additionalGSTRegistrationNumberValid")
  additionalGSTRegistrationNumberValid(
    value: any,
    config: ComponentConfig,
    context: DynamicContext,
    formGroup: UntypedFormGroup
  ) {
    return (params: any) => {
      const control = formGroup.get(config.name);
      if (control.value) {
        const stateCode = formGroup.get("AdditionalState").value;
        const PANCode = formGroup.parent.parent.get("CompanyPANNumber").value;
        const pattern = /^[A-z0-9]{1,}$/;

        if (
          !!value &&
          !(
            control.value.substring(0, 2) === stateCode &&
            control.value.substring(2, 12) === PANCode &&
            !!control.value.substring(12, 15).match(pattern)
          )
        ) {
          config.errorMessage["matchSpecialNumericalAlphabet"] =
            environment.languageSwitch
              ? this.languageService.getValue(
                "Expression.additionalGSTRegistrationNumberValid.matchSpecialNumericalAlphabet_errorMessage"
              )
              : `Please input correct 15 characters GST Registration Number,
          format is: state GST state code(2 characters) + PAN number(10 characters) + 3 numerical or alphabet characters`;
          const currentError = { matchSpecialNumericalAlphabet: true };

          control.setErrors(currentError);
          control.updateValueAndValidity({ onlySelf: true, emitEvent: true });
          control.markAsDirty();
          control.markAsTouched();
        } else {
          control.setErrors(null);
          control.updateValueAndValidity({ onlySelf: true, emitEvent: true });
          control.markAsPristine();
          control.markAsUntouched();
        }
      }
    };
  }

  @CustomExpression("setCollapseVisible")
  setCollapseVisible(
    value: any,
    config: ComponentConfig,
    context: DynamicContext,
    formGroup: UntypedFormGroup
  ) {
    return (params: any): void => {
      const fn = `return !(${params})`;
      const runtime = new Function("config", "context", "$value", fn);
      const result = runtime(config, context, value);
      config.hide = result;
      if (result) {
        const component = context.getComponent(config.name);
        if (component) {
          component.resetComponent();
        }
      }
    };
  }

  @CustomExpression("changeGstVendorForIN")
  changeGstVendorForIN(
    value: any,
    config: ComponentConfig,
    context: DynamicContext,
    formGroup: UntypedFormGroup
  ) {
    return (params: any): void => {
      if (!context.getValue("isIN")) {
        return;
      }
      const runtime = new Function(
        "config",
        "context",
        "$value",
        `return (${params})`
      );
      const result = runtime(config, context, value);
      if (result) {
        config.subLabel = "";
        formGroup
          .get(config.name)
          .patchValue("", { onlySelf: true, emitEvent: true });
        formGroup.get(config.name).enable();
      } else {
        config.subLabel = environment.languageSwitch
          ? this.languageService.getValue(
            "Expression.changeGstVendorForIN.NOT_IN_subTitle"
          )
          : '(As per Indian tax laws, GST classification for suppliers outside India will be "5 - Foreign")';
        formGroup
          .get(config.name)
          .patchValue("5", { onlySelf: true, emitEvent: true });
        formGroup.get(config.name).disable();
      }
    };
  }

  @CustomExpression("changeByGstVendorClassification")
  changeByGstVendorClassification(
    value: any,
    config: ComponentConfig,
    context: DynamicContext,
    formGroup: UntypedFormGroup
  ) {
    return (params: any): void => {
      const fn = `return (${params})`;
      const runtime = new Function("config", "context", "$value", fn);
      const result = runtime(config, context, value);
      const control = formGroup.get(config.name);
      config.required = !result;
      if (
        context.getValue("isIN") &&
        formGroup.get("SupplierRegisteredCountry").value === "IN"
      ) {
        if (result) {
          clearValidators(control);
          if (
            config.templateOptions.underSubLabel ||
            config.templateOptions.underSubLabel === ""
          ) {
            config.templateOptions.underSubLabel = environment.languageSwitch
              ? this.languageService.getValue(
                "Expression.changeByGstVendorClassification.SupplierRegisteredCountryIN_underSubLabel"
              )
              : "(Enter N/A for Vendor classification type - Unregistered, Foreign & Government/Local Body)";
          }
        } else {
          config.templateOptions.underSubLabel = "";
          attachValidators(this.service, control, config, context);
        }
      }
    };
  }

  @CustomExpression("changeAddGstNumber")
  changeAddGstNumber(
    value: any,
    config: ComponentConfig,
    context: DynamicContext,
    formGroup: UntypedFormGroup
  ) {
    return (params: any): void => {
      const control = formGroup.get(config.name);
      const fn = `return (${params})`;
      const runtime = new Function("config", "context", "$value", fn);
      const result = runtime(config, context, value);
      const defaultLabel = config.label;
      if (result) {
        config.required = false;
        config.subLabel = environment.languageSwitch
          ? this.languageService.getValue(
            "Expression.changeAddGstNumber.Result_subLabel"
          )
          : "(Enter N/A for Vendor classification type - Unregistered, Foreign & Government/Local Body)";
        clearValidators(control);
      } else {
        config.required = true;
        config.subLabel = "";
        attachValidators(this.service, control, config, context);
      }
    };
  }

  @CustomExpression("changeAddGSTCertificateAttachments")
  changeAddGSTCertificateAttachments(
    value: any,
    config: ComponentConfig,
    context: DynamicContext,
    formGroup: UntypedFormGroup
  ) {
    return (params: any): void => {
      const control = formGroup.get(config.name);
      const fn = `return (${params})`;
      const runtime = new Function("config", "context", "$value", fn);
      const result = runtime(config, context, value);
      const defaultLabel = environment.languageSwitch
        ? this.languageService.getValue(
          "Expression.changeAddGSTCertificateAttachments.Result_Default_label"
        )
        : "Please upload your company's GST Certificate for additional State registration";
      if (result) {
        config.required = false;
        if (value.includes("04")) {
          config.label = environment.languageSwitch
            ? this.languageService.getValue(
              "Expression.changeAddGSTCertificateAttachments.Result_04_label"
            )
            : "Please upload letterhead declaration for Unregistered GST classification";
        } else {
          config.label = defaultLabel;
        }
        clearValidators(control);
      } else {
        config.required = true;
        config.label = defaultLabel;
        attachValidators(this.service, control, config, context);
      }
    };
  }

  @CustomExpression("changeINStreetAdsLabel")
  changeINStreetAdsLabel(
    value: any,
    config: ComponentConfig,
    context: DynamicContext,
    formGroup: UntypedFormGroup
  ) {
    return (params: any): void => {
      if (context.getValue("isIN")) {
        const fn = `return (${params})`;
        const runtime = new Function("config", "context", "$value", fn);
        const result = runtime(config, context, value);
        if (result) {
          config.label = environment.languageSwitch
            ? this.languageService.getValue(
              "Expression.changeINStreetAdsLabel.isIN"
            )
            : "Head Office Street Address";
        } else {
          config.label = environment.languageSwitch
            ? this.languageService.getValue(
              "Expression.changeINStreetAdsLabel.isNotIN"
            )
            : "Street Address";
        }
      }
    };
  }

  @CustomExpression("removeSubLabelName")
  removeSubLabelName(
    value: any,
    config: ComponentConfig,
    context: DynamicContext,
    formGroup: UntypedFormGroup
  ) {
    return (params: any): void => {
      const fn = `return ${params}`;
      const runtime = new Function("config", "context", "$value", fn);
      const result = runtime(config, context, value);
      if (!context["subLabelName" + config.name]) {
        context["subLabelName" + config.name] =
          config.templateOptions.subLabelName;
      }
      if (result) {
        config.templateOptions.subLabelName =
          config.templateOptions.subLabelNameEmpty;
      } else {
        config.templateOptions.subLabelName =
          context["subLabelName" + config.name];
      }
    };
  }

  @CustomExpression("setChangedFlagForHiddenYear")
  setChangedFlagForHiddenYear(
    value: any,
    config: ComponentConfig,
    context: DynamicContext,
    formGroup: UntypedFormGroup
  ) {
    return (params: any): void => {
      const currentValue = formGroup.controls[config.name].value;
      if (currentValue && currentValue != "") {
        const v = value.replace("-", "").trim();
        formGroup.get(config.name).setValue(currentValue + v);
      }
    };
  }

  @CustomExpression("updateSublabel")
  updateSublabel(
    value: any,
    config: ComponentConfig,
    context: DynamicContext,
    formGroup: UntypedFormGroup
  ) {
    return (params: any): void => {
      const fn = `return (${params})`;
      const runtime = new Function("config", "context", "$value", fn);
      const result = runtime(config, context, value);
      if (result) {
        if (context.parent.getValue('isGallia')) {
          if (value === 'FR') {
            config.subLabel = this.languageService.getValue('Expression.updateSublabel.isGallia_sublabel')
          } else {
            config.subLabel = this.languageService.getValue('supplier.step3.IntermediaryAddFinancialAttachments.subLabel')
          }
        } else if (context.parent.getValue('isIN')) {
          if (value === 'IN') {
            config.subLabel = this.languageService.getValue('Expression.updateSublabel.isIN_IN_sublabel')
          } else {
            config.subLabel = this.languageService.getValue('Expression.updateSublabel.isIN_NonIN_sublabel')
          }
        }
      }
    };
  }

  @CustomExpression("updateSublabelWithBankCountry")
  updateSublabelWithBankCountry(
    value: any,
    config: ComponentConfig,
    context: DynamicContext,
    formGroup: UntypedFormGroup
  ) {
    return (params: any): void => {
      const fn = `return (${params})`;
      const runtime = new Function("config", "context", "$value", fn);
      const result = runtime(config, context, value);
      if (result) {

        if (value === 'CN') {
          config.subLabel = this.languageService.getValue("Hook.changeConfig.AddFinancialAttachments.isChina_Mainland_sublabel")
        } else if (value === 'TW') {
          config.subLabel = this.languageService.getValue("Hook.changeConfig.AddFinancialAttachments.isChina_TW_sublabel")
        } else if (value === 'HK') {
          config.subLabel = this.languageService.getValue("Hook.changeConfig.AddFinancialAttachments.isChina_HK_sublabel")
        } else if (value === 'JP') {
          config.subLabel = this.languageService.getValue("Hook.changeConfig.AddFinancialAttachments.isJP_sublabel")
        } else if (value === 'FR' && context.parent.getValue('parentModel').RequestForm.RequestorDetailsInfo.SupplierCountry.includes('FR')) {
          config.subLabel = this.languageService.getValue('Expression.updateSublabelFrance.france_sublabel')
        } else if (context.parent.getValue('isGallia')) {
          config.subLabel = this.languageService.getValue('Hook.changeConfig.AddFinancialAttachments.isUKIA_isICEG_isASG_isNordics_isMET_isCanada_isUnitedStates_subLabel')
        } else if (value === 'RS' && context.parent.getValue('parentModel').RequestForm.RequestorDetailsInfo.SupplierCountry.includes('RS') && (context.parent.getValue("parentModel").SupplierProfile.SupplierOrganization.SupplierRegisteredCountry == 'RS')) {
          config.subLabel = this.languageService.getValue('Hook.changeConfig.AddFinancialAttachments.isRS_subLabel')
        } else if (value === 'TR' && context.parent.getValue('parentModel').RequestForm.RequestorDetailsInfo.SupplierCountry.includes('TR') && (context.parent.getValue("parentModel").SupplierProfile.SupplierOrganization.SupplierRegisteredCountry == 'TR')) {
          config.subLabel = this.languageService.getValue('Hook.changeConfig.AddFinancialAttachments.isTR_subLabel')
        }
        else {
          config.subLabel = this.languageService.getValue("supplier.step3.AddFinancialAttachments.subLabel")
        }
        if (context.parent.getValue('isIN')) {
          if (value === 'IN') {

            config.subLabel = environment.languageSwitch
              ? this.languageService.getValue(
                'Expression.updateDetailsContentIndia.IN_subLabel'
              )
              : `To provide evidence of the bank details you are required to upload
                <u>ANY ONE</u> of the following documents:<br><br>·
                Cancelled cheque; or<br>· Company letterhead quoting bank details with bank attestation; or
                <br>· Supplier bank details on bank letter head duly signed and stamped<br><br>
                Please note, if a valid document is not attached, we will be unable to complete your onboarding until this is rectified.`
          } else if (value === 'LK') {
            config.subLabel = this.languageService.getValue("Hook.changeConfig.AddFinancialAttachments.isLK_sublabel")
          } else if (value !== 'CN' && value !== 'TW' && value !== 'HK') {

            config.subLabel = environment.languageSwitch
              ? this.languageService.getValue(
                'Expression.updateDetailsContentIndia.NOT_IN_subTitle'
              )
              : `To provide evidence of the bank details you are required to upload <u>ANY ONE</u>
                of the following documents:<br><br>· Company letterhead quoting bank details; or<br>· Supplier bank details on bank letter head<br><br>
                Please note, if a valid document is not attached, we will be unable to complete your onboarding until this is rectified.`
          }
        }
      }
    };
  }

  @CustomExpression("updateSublabelChina")
  updateSublabelChina(
    value: any,
    config: ComponentConfig,
    context: DynamicContext,
    formGroup: UntypedFormGroup
  ) {
    return (params: any): void => {
      const fn = `return (${params})`;
      const runtime = new Function("config", "context", "$value", fn);
      const result = runtime(config, context, value);
      if (result) {
        if (value === 'CN') {
          config.subLabel = this.languageService.getValue("Hook.changeConfig.AddFinancialAttachments.isChina_Mainland_sublabel")
        } else if (value === 'TW') {
          config.subLabel = this.languageService.getValue("Hook.changeConfig.AddFinancialAttachments.isChina_TW_sublabel")
        } else if (value === 'HK') {
          config.subLabel = this.languageService.getValue("Hook.changeConfig.AddFinancialAttachments.isChina_HK_sublabel")
        } else if (value === 'LK') {
          config.subLabel = this.languageService.getValue("Hook.changeConfig.AddFinancialAttachments.isLK_sublabel")

        }
        else if (value === 'JP') {
          config.subLabel = this.languageService.getValue("Hook.changeConfig.AddFinancialAttachments.isJP_sublabel")
        } else {
          if (config.name === 'ThirdPartyAddFinancialAttachments') {
            config.subLabel = this.languageService.getValue("supplier.step3.ThirdPartyAddFinancialAttachments.subLabel")
          } else {
            config.subLabel = this.languageService.getValue("supplier.step3.AddFinancialAttachments.subLabel")
          }
        }
      }
    };
  }

  @CustomExpression("updateProcurementNotEngagedTooltips")
  updateProcurementNotEngagedTooltips(
    value: any,
    config: ComponentConfig,
    context: DynamicContext,
    formGroup: UntypedFormGroup
  ) {
    return (params: any): void => {
      const fn = `return (${params})`;
      const runtime = new Function("config", "context", "$value", fn);
      const result = runtime(config, context, value);
      if (result) {
        let helpPageLink = window.location.origin + '/#/buyer/helpPage?QuestionShow17=true'
        config.tooltip = `If you need more information which option to select, please click <a style="color: #006caf" target="_blank" href="${helpPageLink}">here</a>`
      }
      else {
        delete config.tooltip
      }
    };
  }

  @CustomExpression("changeBusinessRegistrationTypeForCN")
  changeGstVendorForCN(
    value: any,
    config: ComponentConfig,
    context: DynamicContext,
    formGroup: UntypedFormGroup
  ) {
    return (params: any): void => {
      if (value !== 'CN') {
        formGroup.get(config.name).enable();
        return;
      }
      const runtime = new Function(
        "config",
        "context",
        "$value",
        `return (${params})`
      );
      const result = runtime(config, context, value);
      if (result) {
        formGroup
          .get(config.name)
          .patchValue("CN6", { onlySelf: true, emitEvent: true });
        formGroup.get(config.name).disable();
      } else {
        formGroup
          .get(config.name)
          .patchValue("", { onlySelf: true, emitEvent: true });
        formGroup.get(config.name).enable();

      }
    };
  }

  @CustomExpression("changeBusinessRegistrationTypeForJP")
  changeGstVendorForJP(
    value: any,
    config: ComponentConfig,
    context: DynamicContext,
    formGroup: UntypedFormGroup
  ) {
    return (params: any): void => {
      if (value !== 'JP') {
        formGroup.get(config.name).enable();
        return;
      }
      const runtime = new Function(
        "config",
        "context",
        "$value",
        `return (${params})`
      );
      const result = runtime(config, context, value);
      // if (result) {
      //   formGroup
      //     .get(config.name)
      //     .patchValue("JP6", { onlySelf: true, emitEvent: true });
      //   formGroup.get(config.name).disable();
      if (result && formGroup.controls.BusinessRegistrationType.value === 'JP6') {
        formGroup.get(config.name).disable();
      } else {
        formGroup
          .get(config.name)
          .patchValue("", { onlySelf: true, emitEvent: true });
        formGroup.get(config.name).enable();

      }
    };
  }
  @CustomExpression("changePrimaryContactTypeForJP")
  changePrimaryContactTypeForJP(
    value: any,
    config: ComponentConfig,
    context: DynamicContext,
    formGroup: UntypedFormGroup
  ) {
    return (params: any): void => {
      const runtime = new Function(
        "config",
        "context",
        "$value",
        `return (${params})`
      );
      const result = runtime(config, context, value);
      if (result) {
        formGroup
          .get(config.name)
          .get(config.fieldset[0].name)
          .patchValue("+81", { onlySelf: true, emitEvent: true });
        formGroup.get(config.name).get(config.fieldset[0].name).disable();
      } else if (!result && context.mode === "view") {
        formGroup
          .get(config.name)
          .get(config.fieldset[0].name)
          .patchValue(formGroup.get(config.name).get(config.fieldset[0].name).value, { onlySelf: true, emitEvent: true });
        formGroup.get(config.name).get(config.fieldset[0].name).enable();
      } else {
        formGroup
          .get(config.name)
          .get(config.fieldset[0].name)
          .patchValue("", { onlySelf: true, emitEvent: true });
        formGroup.get(config.name).get(config.fieldset[0].name).enable();
      }
    };
  }
  @CustomExpression("setAccountHolderNamePlaceholderByJP")
  setAccountHolderNamePlaceholderByJP(
    value: any,
    config: ComponentConfig,
    context: DynamicContext,
    formGroup: UntypedFormGroup
  ) {
    return (params: any): void => {
      const fn = `return (${params})`;
      const runtime = new Function("config", "context", "$value", fn);
      const result = runtime(config, context, value);
      const GU = context.parent.getValue('parentModel').RequestForm.RequestorDetailsInfo.SupplierGeographical
      if (result && GU === '22') {
        config.placeholder = this.languageService.getValue("supplier.step3.AccountHolderNm.placeholder")
      }
      else {
        delete config.placeholder
      }
    };
  }

  @CustomExpression("setIntermediaryBankAccountHolderNamePlaceholderByJP")
  setIntermediaryBankAccountHolderNamePlaceholderByJP(
    value: any,
    config: ComponentConfig,
    context: DynamicContext,
    formGroup: UntypedFormGroup
  ) {
    return (params: any): void => {
      const fn = `return (${params})`;
      const runtime = new Function("config", "context", "$value", fn);
      const result = runtime(config, context, value);
      const GU = context.parent.getValue('parentModel').RequestForm.RequestorDetailsInfo.SupplierGeographical
      if (result && GU === '22') {
        config.placeholder = this.languageService.getValue("supplier.step3.IntermediaryAccountHolderNm.placeholder")
      }
      else {
        delete config.placeholder
      }
    };
  }

  @CustomExpression("setThirdPartyBankAccountHolderNamePlaceholderByJP")
  setThirdPartyBankAccountHolderNamePlaceholderByJP(
    value: any,
    config: ComponentConfig,
    context: DynamicContext,
    formGroup: UntypedFormGroup
  ) {
    return (params: any): void => {
      const fn = `return (${params})`;
      const runtime = new Function("config", "context", "$value", fn);
      const result = runtime(config, context, value);
      const GU = context.parent.getValue('parentModel').RequestForm.RequestorDetailsInfo.SupplierGeographical
      if (result && GU === '22') {
        config.placeholder = this.languageService.getValue("supplier.step3.ThirdPartyAccountHolderNm.placeholder")
      }
      else {
        delete config.placeholder
      }
    };
  }

  //Add GST field
  @CustomExpression("setVisibleForSG")
  setVisibleForSG(
    value: any,
    config: ComponentConfig,
    context: DynamicContext,
    formGroup: UntypedFormGroup
  ) {
    return (params: any): void => {
      let result = false; // Initialize the result to false (hidden by default)
      // Check if the "SupplierRegisteredCountry" form control has a value and if it is "SG"
      if (formGroup.get("SupplierRegisteredCountry").value === "SG") {
        result = true; // Set the result to true (visible when country is "SG")
      }
      config.hide = !result; // Update the "hide" property of the "config" object
      const control = formGroup.get(config.name); // Get the form control specified in "config.name"
      if (!control) {
        return; // Return if the control is not found
      }
      if (result) {
        // If the result is true (control should be visible)
        control.setValidators(
          this.service.validatorFactory.createValidators(config, context)
        );
        control.setAsyncValidators(
          this.service.validatorFactory.createAsyncValidators(config, context)
        );
        control.setErrors(null);
        control.updateValueAndValidity({ onlySelf: true, emitEvent: true });
      } else {
        // If the result is false (control should be hidden)
        const defaultValue = getDefaultValue(control.value, config.datatype);
        control.patchValue(defaultValue, { onlySelf: true, emitEvent: true });
        control.clearValidators();
        control.clearAsyncValidators();
        control.setErrors(null);
        control.updateValueAndValidity({ onlySelf: true, emitEvent: true });
        control.markAsPristine();
        control.markAsUntouched();
      }
    };
  }

  @CustomExpression("changeVATLabel")
  changeVATLabel(
    value: any,
    config: ComponentConfig,
    context: DynamicContext,
    formGroup: UntypedFormGroup
  ) {
    return (params: any): void => {
      const fn = `return !(${params})`;
      const runtime = new Function("config", "context", "$value", fn);
      const result = runtime(config, context, value);
      const defaultLabel = config.label;
      const onboardedCountry: Array<string> = context.getValue("parentModel")?.RequestForm?.RequestorDetailsInfo?.SupplierCountry;
      if (!context["label" + config.name]) {
        context["label" + config.name] = config.label;
        if (!config.errorMessage) {
          config.errorMessage = {};
        }
        context["errormessage" + config.name] = config.errorMessage["required"];
      }
      if (formGroup.controls["SupplierRegisteredCountry"].value === "LK" && onboardedCountry.includes("LK")) {
        if (result) {
          config.label = environment.languageSwitch
            ? this.languageService.getValue(
              config.languageKey + ".templateOptions.VatLabel"
            )
            : config.templateOptions.VatLabel;
          if (config.templateOptions.VatErrormessage) {
            config.errorMessage["required"] = environment.languageSwitch
              ? this.languageService.getValue(
                config.languageKey + ".templateOptions.VatErrormessage"
              )
              : config.templateOptions.VatErrormessage;
          }
        } else {
          config.label = context["label" + config.name];
          if (!config.errorMessage) {
            config.errorMessage = {};
          }
          config.errorMessage["required"] = context["errormessage" + config.name];
        }
      }
    };
  }

  @CustomExpression("changeTooltipForUploaderTR")
  changeTooltipForUploaderTR(
    value: any,
    config: ComponentConfig,
    context: DynamicContext,
    formGroup: UntypedFormGroup
  ) {
    return (params: any): void => {
      const fn = `return !(${params})`;
      const runtime = new Function("config", "context", "$value", fn);
      let result = runtime(config, context, value);
      const onboardedCountry: Array<string> = context.getValue("parentModel").RequestForm.RequestorDetailsInfo.SupplierCountry;
      const supplierRegisteredCountry = formGroup.controls["SupplierRegisteredCountry"].value;
      if (onboardedCountry.includes("TR") && supplierRegisteredCountry === "TR") {
        config.tooltip = environment.languageSwitch
          ? this.languageService.getValue(
            "<li>Size: Maximum 10 MB</li><li>Format: PDF, XLS, XLSX, CSV, ODT, JPG, JPEG, PPT, PPTX, TXT, DOC, DOCX, RTF, MSG </li><li>Please provide one of the below 5 document types: </li><li>Tax Certificate,  Company Registration certificate,</li> <li> Bank Certificate or Bank statement,  Valid invoice payable by Accenture,</li><li> Company letter head duly signed by the authorized signatories</li>"
          )
          : "Expression.UploadRegistrationDocuments.Turkey_Tooltip"
      }
      else {
        config.tooltip = environment.languageSwitch
          ? this.languageService.getValue(
            "<li>Size: Maximum 10 MB</li><li>Format: PDF, XLS, XLSX, CSV, ODT, JPG, JPEG, PPT, PPTX, TXT, DOC, DOCX, RTF, MSG </li><li>Please provide one of the below 5 document types: </li><li>VAT Registration certificate,  Company Registration certificate,</li> <li> Bank Certificate or Bank statement,  Valid invoice payable by Accenture,</li><li> Company letter head duly signed by the authorized signatories</li>"
          )
          : "Expression.UploadRegistrationDocuments.tooltip"
      }
    };
  }

  @CustomExpression("tooltipHideandShow")
  tooltipHideandshow(
    value: any,
    config: ComponentConfig,
    context: DynamicContext,
    formGroup: UntypedFormGroup
  ) {
    return (params: any): void => {
      const fn = `return (${params[0]})`;
      const runtime = new Function("config", "context", "$value", fn);
      const result = runtime(config, context, value);
      if (result) {
        config.tooltip = (environment.languageSwitch
          ? this.languageService.getValue(
            params[1]
          )
          : `Tooltip`);
      }
      else {
        delete config.tooltip
      }
    };
  }

  @CustomExpression("removeNonStandardValueBasedOnStandardPaymentTerm")
  removeNonStandardValueBasedOnStandardPaymentTerm(
    value: any,
    config: ComponentConfig,
    context: DynamicContext,
    formGroup: UntypedFormGroup
  ) {
    return (params: any): void => {
      let standardPaymentTermValue = formGroup.get("TimeLimit").value;
      if (config["options"]) {
        const removeThisIndexValue = config["options"].findIndex((d) => d.Text === standardPaymentTermValue);
        if (removeThisIndexValue != -1) {
          config["options"].splice(removeThisIndexValue, 1)
        }
      }
    };
  }

  @CustomExpression("AMLBankCountryValidation")
  AMLBankCountryValidation(
    value: any,
    config: ComponentConfig,
    context: DynamicContext,
    formGroup: UntypedFormGroup
  ) {
    return (params: any): void => {
      console.log("AMLBankCountryValidation Called");
      console.log("AMLBankCountryValidation value", value);
      const SupplierRegisteredCountry = context.parent.getValue("parentModel").SupplierProfile.SupplierOrganization.SupplierRegisteredCountry; //?
      const BankCountry = value;
      for (var contextVal of context.parent['componentMap']) {
        console.log("AMLBankCountryValidation contextVal", contextVal);
        if (contextVal[0] == 'BankCountryAndRegisteredCountryValidation') {
          const BankCountryText = this.languageService.getValue(`dropdown.supplier-step2.Country.${BankCountry}.Text`);
          const SupplierRegisteredCountryText = this.languageService.getValue(`dropdown.supplier-step2.Country.${SupplierRegisteredCountry}.Text`);
          contextVal[1].config.hide = true
          contextVal[1].config.label = `Supplier Location ${SupplierRegisteredCountryText} does not match with Bank Country/Location ${BankCountryText}. Select any one of the Reasons-`
        }
      }
    };
  }


  @CustomExpression("AMLAccountNameValidation")
  AMLAccountNameValidation(
    value: any,
    config: ComponentConfig,
    context: DynamicContext,
    formGroup: UntypedFormGroup
  ) {
    return (params: any): void => {
      console.log("AMLAccountNameValidation Called");
      console.log("AMLAccountNameValidation value", value);
      const CompanyName = context.parent.getValue("parentModel").SupplierProfile.SupplierGeneral.CompanyName;
      const AccountHolderNm = value;
      for (var contextVal of context.parent['componentMap']) {
        console.log("AMLAccountNameValidation contextVal", contextVal);
        if (contextVal[0] == 'CompanyNameAndAccountHolderNameValidation') {
          contextVal[1].config.hide = true
          contextVal[1].config.label = `Supplier Name ${CompanyName} does not match with Bank Account Holder’s Name ${AccountHolderNm}. Select any one of the Reasons-`
        }
      }
    };
  }

  @CustomExpression("setVisibleForLATAM")
  setVisibleForLATAM(
    value: any,
    config: ComponentConfig,
    context: DynamicContext,
    formGroup: UntypedFormGroup
  ) {
    return (params: any): void => {
      const control = formGroup.get(config.name);
      if (context.getValue("isLATAM") == true) {
        if (value === "BR" || value === "MX") {
          config.hide = true;
          config.width = "";
          clearValidators(control);
        } else {
          config.hide = false;
          config.width = "";
          attachValidators(this.service, control, config, context);
        }
      }
      }
    
  }
  @CustomExpression("setVisibleForUSPR")
  setVisibleForUSPR(
    value: any,
    config: ComponentConfig,
    context: DynamicContext,
    formGroup: UntypedFormGroup
  ) {
    return (params: any): void => {
      const control = formGroup.get(config.name);
        if (value === "US" || value === "PR") {
          config.hide = false;
          attachValidators(this.service, control, config, context);

        } else {
          config.hide = true;
          config.width = "";
          clearValidators(control);
        }
      }
  }
}