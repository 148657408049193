import { CommonModule } from "@angular/common";
import { Routes, RouterModule } from "@angular/router";
import { ManagementLayoutComponent } from "./views/management-layout/management-layout.component";
import { UsersComponent } from "./views/users/users.component";
import { HiperosComponent } from "./views/hiperos/hiperos.component";
import { SharedModule } from "src/app/shared/shared.module";
import { SystemGuard } from "./system.guard";
import { BuyerAdminGuard } from "./buyerAdmin.guard";
import { AlertComponent } from "./views/alert/alert.component";
import { NgModule } from "@angular/core";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatMomentDateModule } from "@angular/material-moment-adapter";
import { FormsModule } from "@angular/forms";
import { NgxPaginationModule } from "ngx-pagination";
import { InvitationStatusComponent } from "./views/invitationStatus/invitationStatus.component";
import { DeleteUserInfoComponent } from "./views/delete-user-info/delete-user-info.component";
import { UploadFileComponent } from "./views/upload-file/upload-file.component";
import { TerminationComponent } from "./views/termination/termination.component";
import { AuditTrailComponent } from "./views/audit-trail/audit-trail.component";
import { DataMigrationComponent } from "./views/dataMigration/dataMigration.component";
import { en_US, NZ_I18N } from "ng-zorro-antd/i18n";
import { NgZorroAntdModule } from "./ng-zooro-antd.module";
import { CompanyComponent } from "./views/company/company.component";
import { StandardPaymentTermComponent } from "./views/standard-payment-term/standard-payment-term.component";
import { DictionaryService } from "./services";
import { MergeProfilesComponent } from "./views/merge-profiles/merge-profiles.component";
import { RaTriggerComponent } from "./views/ra-trigger/ra-trigger.component";
import { ManageGroupMembersComponent } from "./views/manage-group-members/manage-group-members.component";
import { LatamCategoriesComponent } from "./views/latam-categories/latam-categories.component";

const routes: Routes = [
  {
    path: "system",
    component: ManagementLayoutComponent,
    children: [
      {
        path: "users",
        component: UsersComponent,
        canActivate: [SystemGuard],
      },
      { path: "ratrigger", 
        component: RaTriggerComponent,
        canActivate: [SystemGuard],
      },
      {
        path: "alert",
        component: AlertComponent,
        canActivate: [SystemGuard],
      },
      {
        path: "hiperos",
        component: HiperosComponent,
        canActivate: [SystemGuard],
      },
      {
        path: "isInvited",
        component: InvitationStatusComponent,
        canActivateChild: [BuyerAdminGuard],
      },
      {
        path: "isInvited",
        component: InvitationStatusComponent,
        canActivate: [SystemGuard],
      },
      {
        path: "deleteUserInfo",
        component: DeleteUserInfoComponent,
        canActivate: [SystemGuard],
      },
      {
        path: "updateFile",
        component: UploadFileComponent,
        canActivate: [SystemGuard],
      },
      {
        path: "dataMigration",
        component: DataMigrationComponent,
        canActivateChild: [SystemGuard],
      },
      {
        path: "termination",
        component: TerminationComponent,
        canActivateChild: [SystemGuard],
      },
      {
        path: "companies",
        component: CompanyComponent,
        canActivate: [SystemGuard],
      },
      {
        path: "standardPaymentTerms",
        component: StandardPaymentTermComponent,
        canActivateChild: [SystemGuard],
      },
      {
        path: "mergeProfiles",
        component: MergeProfilesComponent,
        canActivateChild: [SystemGuard]
      },
      {
        path: "manageGroupMembers",
        component : ManageGroupMembersComponent,
        canActivateChild: [SystemGuard]
      },
      {
        path: "categories",
        component: LatamCategoriesComponent,
        canActivate: [SystemGuard]
      }
    ],
  },
];

@NgModule({
  declarations: [
    ManagementLayoutComponent,
    UsersComponent,
    HiperosComponent,
    AlertComponent,
    InvitationStatusComponent,
    DeleteUserInfoComponent,
    UploadFileComponent,
    TerminationComponent,
    AuditTrailComponent,
    DataMigrationComponent,
    CompanyComponent,
    StandardPaymentTermComponent,
    MergeProfilesComponent,
    RaTriggerComponent,
    ManageGroupMembersComponent,
    LatamCategoriesComponent
  ],
  imports: [
    FormsModule,
    CommonModule,
    NgZorroAntdModule,
    SharedModule,
    MatDatepickerModule,
    MatMomentDateModule,
    NgxPaginationModule,
    RouterModule.forRoot(routes, { useHash: true }),
  ],
  providers: [{ provide: NZ_I18N, useValue: en_US }, DictionaryService],
  exports: [RouterModule],
  // ],
  // providers: [
  //   { provide: MY_FORMATS, useValue: MY_FORMATS }
  // ]
})
export class SystemModule {}
