import {
  Component,
  ElementRef,
  HostListener,
  OnInit,
  ViewChild,
} from "@angular/core";
import { BaseComponent } from "src/app/dynamic-components/components/base/base.component";
import { Datasources } from "src/app/dynamic-components/datasource/Datasources";
import { LanguageService } from "src/app/dynamic-components/utils/language.service";
import { BuyerService } from "src/app/services/buyer.service";
import { LoadingService } from "src/app/services/loading.service";

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.sass'],
  host: {
    '[style.width]': 'config.width',
  }
})
export class InputComponent extends BaseComponent implements OnInit {
  showTooltip: boolean;
  searchText: string;
  selectedItems: any[] = [];
  selectedText = "";
  datalist: any[] = [];
  showPanel = false;
  valueKey = "Code";
  textKey = "Text";
  rand =Math.round((Math.random()*10000)).toString();
  @ViewChild("inputPanel") inputPanel: ElementRef;
  @ViewChild("dropPpanel") dropPpanel: ElementRef;
  pPlusRosterMembers : string[] = [];
  filteredData: string[] = [];
  textData :string;
  isListVisible: boolean = false;
  validationMessage: boolean = false;
  groupName : string = 'PplusRoster' // group name from b4p.email_group table
  constructor(private languageService: LanguageService, private buyerService : BuyerService, 
    public loadingService: LoadingService  
  ) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
    this.refresh();
    this.context.on(this.config.name, (str: any) => {
      this.resetAll();
    });
    this.rand =Math.round((Math.random()*10000)).toString();
    if (this.config.name === 'ProcurementContact') {
      this.textData = this.context.getModel().ProcurementContact;
      this.buyerService.pPlusRoster(this.groupName).then(data => {
        this.loadingService.showLoading();
        this.pPlusRosterMembers = (data as any).data.map((member: any) => member.email);
        this.loadingService.hideLoading();
      }).catch(error => {
        console.error('Error fetching PplusRoster Members:', error)
      })
      this.loadingService.hideLoading();
    }
  }
  ngAfterViewInit() {
    this.subscribeToValueChange(this.config.name, (v) => {
      this.onValueChange(v);
    });
  }

  loadDatasource() {
    if (this.config.options) {
      Datasources.loadData(this.config, this.context, this.formGroup).then(
        (ds) => this.onDatasourceLoaded(ds)
      );
    }
  }

  onDatasourceLoaded(ds: any) {
    this.datalist = ds;
    this.config.options = ds;
    this.refresh();
  }

  checkedChanged(event, item: any) {
    this.config.options = this.datalist;
    const target = this.config.options.find((d) => d.Code === item.Code);
    target.checked = !target.checked;
    this.selectedItems = this.config.options.filter((d) => d.checked);
    this.value = this.selectedItems.map((i) => i[this.valueKey]);
    this.refresh();
  }

  chooseItem(event, item: any) {
    this.formGroup.get(this.config.name).markAsDirty();
    this.value = item.Code;
    this.showPanel = false;
    this.refresh();
  }

  refresh() {
    if (!this.config.options || this.config.options.length === 0) {
      return;
    }
    this.resetAll();
    this.setDisableByExclusiveKey();
    this.selectedItems = this.config.options.filter(
      (d) => d.Code === this.value
    );
    if (this.selectedItems[0] && this.selectedItems[0].languageKey) {
      this.selectedText = this.languageService.getValue(
        this.selectedItems[0].languageKey
      );
    } else {
      this.selectedText = this.selectedItems[0].Text;
    }  
  }

  resetAll() {
    this.selectedItems = [];
    this.selectedText = "";
    this.config.options.forEach((d) => {
      d.checked = false;
      d.disabled = false;
    });
  }

  
  setDisableByExclusiveKey() {
    if (
      this.config.templateOptions &&
      this.config.templateOptions.exclusiveKey
    ) {
      const exclusiveKey = this.config.templateOptions.exclusiveKey;
      if (!this.value || this.value.length === 0) {
        this.config.options.forEach((item) => (item.disabled = false));
        return;
      }
      if (this.value.includes(exclusiveKey)) {
        const itemsToDisable = this.config.options.filter(
          (o) => o[this.valueKey] !== exclusiveKey
        );
        itemsToDisable.forEach((itd) => (itd.disabled = true));
      } else {
        const itemsToDisable = this.config.options.filter(
          (o) => o[this.valueKey] === exclusiveKey
        );
        itemsToDisable.forEach((itd) => (itd.disabled = true));
      }
    }
  }

  onValueChange(v) {
    this.refresh();
  }

  togglePanel() {
    this.showPanel = !this.showPanel;
    this.searchText = "";
    this.filter("null");
    if (this.config.options) {
      // this.config.dataSource = this.config.templateOptions.dataSource
      Datasources.loadData(this.config, this.context, this.formGroup).then(
        (ds) => this.onDatasourceLoaded(ds)
      );

      // this.datalist = this.config.options
      // this.refresh()
    }
  }

  filter(keyword: any) {
    if ("null" === keyword) {
      this.searchText = "";
      this.config.options = this.datalist;
      return;
    }
    if (keyword.currentTarget.value) {
      this.searchText = keyword.currentTarget.value;
    }
    if (!this.searchText) {
      this.config.options = this.datalist;
      return;
    }
    this.config.options = this.datalist.filter(
      (kv) =>
        kv[this.textKey]
          .toLowerCase()
          .indexOf(keyword.currentTarget.value.toLowerCase()) >= 0
    );
  }

  estimateChooseOver(e, estimateSpendTooltip) {
    const TooltipEle = estimateSpendTooltip;
    if (estimateSpendTooltip) {
      if (
        e.clientX < TooltipEle.nativeElement.getBoundingClientRect().left ||
        e.clientX >
          TooltipEle.nativeElement.getBoundingClientRect().left +
            TooltipEle.nativeElement.offsetWidth -
            5 ||
        e.clientY < TooltipEle.nativeElement.getBoundingClientRect().top ||
        e.clientY >
          TooltipEle.nativeElement.getBoundingClientRect().top +
            TooltipEle.nativeElement.offsetHeight -
            5
      ) {
        this.showTooltip = false;
      }
    } else {
      this.showTooltip = false;
    }
  }

  @HostListener("document:click", ["$event"])
  documentClick(event) {
    if (this.inputPanel && this.dropPpanel) {
      if (
        this.inputPanel.nativeElement.contains(event.target) ||
        this.dropPpanel.nativeElement.contains(event.target)
      ) {
        return;
      }
    }
    this.showPanel = false;
  }

  onInputChange(){
    if (this.textData) {
      this.filteredData = this.pPlusRosterMembers.filter(item =>
        item.toLowerCase().includes(this.textData.toLowerCase())
      );
      this.isListVisible = this.filteredData.length > 0;
    } else {
      this.isListVisible = false;
      this.filteredData = [];
    }
    if (this.textData === "<" || this.textData === ">") {
      this.textData = this.textData.replace(/[<>]/g, '');
    }
  }
 
  onItemSelect(item: string) {
    this.textData = item.split('@')[0];
    this.isListVisible = false;
  }
}
