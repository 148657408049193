import { BuyerACLPermissionService } from "./services/buyerACLPermission.service";
import { Injectable, EventEmitter } from "@angular/core";
import { Router, RouterStateSnapshot, ActivatedRouteSnapshot } from "@angular/router";
import { LoginStatus, AuthService } from "src/app/services/auth/auth.service";
import { DialogService, ButtonCommands } from "src/app/services/dialog.service";
import { BuyerService } from "src/app/services/buyer.service";
import {
  BuyerRoleACLPagePath,
  BuyerRoleACLPageName,
  RoleName,
  RoleCode
} from "src/app/interfaces/buyer";
import { environment } from "src/environments/environment";
import { RebarAuthService } from "src/app/core/rebarauth/rebar.auth.service";
import { LaunchDarklyService } from "src/app/services/launch-darkly.service";

@Injectable({
  providedIn: "root",
})
export class BuyerGuardService {
  isNewVersion: boolean = true;

  constructor(
    private auth: AuthService,
    private rebarService: RebarAuthService,
    private dialogService: DialogService,
    private buyerService: BuyerService,
    private router: Router,
    private buyerACLPermissionService: BuyerACLPermissionService,
    private launchDarklyService: LaunchDarklyService
  ) { 
    this.launchDarklyService.ldChange.subscribe(any => {
      this.refreshToggle()
    });
  }

  refreshToggle(): void {
    this.isNewVersion = this.launchDarklyService.getToggle(LaunchDarklyService.LD_ISNEWVERSION) === "true"
  }

  async canActivate() {
    // if (!this.rebarService.isUserAuthenticated()) {
    //   await this.dialogService.dialog("Please login", ButtonCommands.Ok);
    //   this.router.navigate(["home"]);
    //   return false;
    // }

    const basicFlag = await this.rebarService.initialBasicInfo();
    if (!basicFlag) {
      this.router.navigate(["home"], {replaceUrl: true});
      return basicFlag;
    }

    if (!this.auth.passport.buyer) {
      let data: any;
      if(this.isNewVersion) { 
        data = await this.buyerService.newInitBuyer(
          this.auth.passport.profile.email
        );
      } else {
        data = await this.buyerService.initBuyer(
          this.auth.passport.profile.email
        );
      }

      if (data && data.isSuccess) {
        const role = this.auth.passport.profile.role === "Default_Access" ? "requestor": this.auth.passport.profile.role
        this.auth.passport.buyer = {
          Email: data.data.user.Email,
          Status: data.data.user.Status,
          RoleCode: role,
          CompanyNb: data.data.user.CompanyNb,
          ID: data.data.user.ID,
          OID: data.data.user.OID,
          RoleName: this.getRoleName(role)
        };
        if (data.data.user.Name !== this.auth.passport.profile.name) {
          await this.buyerService.syncBuyer(
            this.auth.passport.profile.email,
            this.auth.passport.profile.name
          );
        }
        this.auth.initCompleted.emit(true);
        this.auth.passport.buyerRoles = data.data.roles;
      } else {
        this.auth.initCompleted.emit(false);
        return false;
      }
    }
    return true;
  }

  getRoleName(role: string) {
    switch (role) {
      case "requestor":
        role = RoleCode.requestor;
        break;
      case "buyerAdmin":
        role = RoleCode.buyerAdmin;
        break;
      case "systemAdmin":
        role = RoleCode.systemAdmin;
        break;
      case "AP_SST":
        role = RoleCode.AP_SST;
        break;
      case "AP_QC":
        role = RoleCode.AP_QC;
        break;
      case "IS_Legal_GDPR":
        role = RoleCode.IS_Legal_GDPR;
        break;
      case "DS":
        role = RoleCode.DS;
        break;
      case "S_CM":
        role = RoleCode.S_CM;
        break;
      case "BI":
        role = RoleCode.BI;
        break;
    }
    return role;
  }

  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    childState: RouterStateSnapshot
  ) {
    let pageIndex: number;
    if (childRoute.routeConfig.path === BuyerRoleACLPagePath.DashboardPath) {
      pageIndex = this.buyerACLPermissionService.pagePermissions.indexOf(
        BuyerRoleACLPageName.DashboardName
      );
    }
    if (
      childRoute.routeConfig.path === BuyerRoleACLPagePath.OnboardingProcessPath
    ) {
      pageIndex = this.buyerACLPermissionService.pagePermissions.indexOf(
        BuyerRoleACLPageName.OnboardingProcessName
      );
    }
    if (
      childRoute.routeConfig.path === BuyerRoleACLPagePath.RequestorFormPath
    ) {
      pageIndex = this.buyerACLPermissionService.pagePermissions.indexOf(
        BuyerRoleACLPageName.RequestorFormName
      );
    }
    if (
      childRoute.routeConfig.path ===
      BuyerRoleACLPagePath.TransactionHistoryPath
    ) {
      pageIndex = this.buyerACLPermissionService.pagePermissions.indexOf(
        BuyerRoleACLPageName.TransactionHistoryName
      );
    }
    if (
      childRoute.routeConfig.path === BuyerRoleACLPagePath.UserManagementPath
    ) {
      pageIndex = this.buyerACLPermissionService.pagePermissions.indexOf(
        BuyerRoleACLPageName.UserManagementName
      );
    }
    if (
      childRoute.routeConfig.path ===
      BuyerRoleACLPagePath.ViewSupplierProfilePath
    ) {
      pageIndex = this.buyerACLPermissionService.pagePermissions.indexOf(
        BuyerRoleACLPageName.ViewSupplierProfileName
      );
    }
    return pageIndex === -1 ? false : true;
  }
}
