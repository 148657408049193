import {
  Component,
  OnInit,
  HostListener,
  ElementRef,
  ViewChild,
  Input,
} from "@angular/core";
import { environment } from "src/environments/environment";
import { LanguageService } from "../../../../dynamic-components/utils/language.service";
import { Router } from "@angular/router";
import { ProfileService } from "../../../../modules/supplier/services/profile.service";
import { RebarAuthService } from "src/app/core/rebarauth/rebar.auth.service";
import { languageList } from "./language-list";
import { Language } from "./language.interface";

@Component({
  selector: "app-language",
  templateUrl: "./language.component.html",
  styleUrls: ["./language.component.sass"],
})
export class LanguageComponent implements OnInit {
  @Input() switchLanguageCallback: any
  @ViewChild("menuDrop") menuPanel: ElementRef;
  @ViewChild("menuButton") menuButton: ElementRef;
  showPanel = false;
  currentLanguage = "en";
  cuurentLanguageValue = "English";
  shouldShowChangeLanguageButton = false;
  constructor(
    private rebarService: RebarAuthService,
    private languageService: LanguageService,
    private router: Router,
    private profileService: ProfileService
  ) { }
  languageList = [{ key: "en", value: "English" }];
  languageListDefault: Array<Language> = languageList;

  ngOnInit() {
    this.currentLanguage = localStorage.getItem("language") || "en";
    this.cuurentLanguageValue = this.languageListDefault.find(
      (l) => l.key === this.currentLanguage
    ).value;
    if (!this.cuurentLanguageValue) {
      this.cuurentLanguageValue = "English"
    }
    // Route to the current page
    if (
      (this.router.url.includes("/supplier/landing") ||
        this.router.url.includes("/supplier/profile")) &&
      this.profileService.supplierModel
    ) {
      // GU is ICEG and the only country is Italy
      if (
        this.profileService.supplierModel.Mapping.geographicalUnit === "20" &&
        this.profileService.supplierModel.Mapping.setUpCountry.length === 1 &&
        this.profileService.supplierModel.Mapping.setUpCountry[0] === "IT"
      ) {
        // dropdown list
        this.languageList = [
          { key: "en", value: "English" },
          { key: "it", value: "Italiano" },
        ];
        // Show only supplier page
        this.shouldShowChangeLanguageButton = environment.role === "supplier";
      } else if (
        this.profileService.supplierModel.Mapping.geographicalUnit === "19"
      ) {
        if (
          this.profileService.supplierModel.Mapping.setUpCountry.includes("PT")
        ) {
          // GU is Iberia, countries include Portugal including Andorra and Spain
          if (
            this.profileService.supplierModel.Mapping.setUpCountry.includes(
              "AD"
            ) ||
            this.profileService.supplierModel.Mapping.setUpCountry.includes(
              "ES"
            )
          ) {
            this.languageList = [
              { key: "en", value: "English" },
              { key: "es", value: "Spanish" },
              { key: "pt", value: "Portuguese" },
            ];
          } else {
            this.languageList = [
              { key: "en", value: "English" },
              { key: "pt", value: "Portuguese" },
            ];
          }
          this.shouldShowChangeLanguageButton = environment.role === "supplier";
        }
        // GU is Iberia, country only includes Andorra or Spain
        else if (
          this.profileService.supplierModel.Mapping.setUpCountry.includes(
            "AD"
          ) ||
          this.profileService.supplierModel.Mapping.setUpCountry.includes("ES")
        ) {
          this.languageList = [
            { key: "en", value: "English" },
            { key: "es", value: "Spanish" },
          ];
          this.shouldShowChangeLanguageButton = environment.role === "supplier";
        }
      }
      // GU is Gallia, country include France
      else if (this.profileService.supplierModel.Mapping.geographicalUnit === "16" && this.profileService.supplierModel.Mapping.setUpCountry.includes("FR")) {
        this.languageList = [
          { key: "en", value: "English" },
          { key: "fr", value: "Français" },
        ];
        this.shouldShowChangeLanguageButton = environment.role === "supplier";
      } else if (this.profileService.supplierModel.Mapping.geographicalUnit === "18") {
        if (this.profileService.supplierModel.Mapping.setUpCountry.includes("CN")) {
          this.languageList = [
            { key: "cn", value: "简体中文" },
            { key: "en", value: "English" },
          ];
          this.currentLanguage = localStorage.getItem("language");
          this.cuurentLanguageValue = this.languageListDefault.find(
            (l) => l.key === this.currentLanguage
          ).value;
        } else {
          this.languageList = [
            { key: "en", value: "English" },
            { key: "cn", value: "简体中文" },
          ];
        }
        this.shouldShowChangeLanguageButton = environment.role === "supplier";
      } else if (
        this.profileService.supplierModel.Mapping.geographicalUnit === "22"
      ) {
        this.languageList = [
          { key: "en", value: "English" },
          { key: "jp", value: "日本語" }
        ];
        this.shouldShowChangeLanguageButton = environment.role === "supplier";
      } else if (
        this.profileService.supplierModel.Mapping.geographicalUnit === "30"
      ) {
        if (this.profileService.supplierModel.Mapping.setUpCountry.includes("BR")) {
         
            this.languageList = [
              { key: "en", value: "English" },
              { key: "br", value: "Português (Brasil)" },
            ];        
        } else if (this.profileService.supplierModel.Mapping.setUpCountry.includes("MX")) {
          this.languageList = [
            { key: "en", value: "English" },
            { key: "mx", value: "LATAM Español" },
          ];        
        } else {
          this.languageList = [{ key: "en", value: "English" }];
        }
        this.shouldShowChangeLanguageButton = environment.role === "supplier";
      }

    }

    // The current supplier has multiple forms
    this.languageService.initEmit.subscribe((value) => {
      if (value.flag) {
        if (
          value.supplierInfo.geographicalUnit === "20" &&
          value.supplierInfo.setUpCountry.length === 1 &&
          value.supplierInfo.setUpCountry[0] === "IT"
        ) {
          this.languageList = [
            { key: "en", value: "English" },
            { key: "it", value: "Italiano" }
          ];
          // LocalStorage is not Italy and English, Change current language
          if (
            localStorage.getItem("language") !== "it" &&
            localStorage.getItem("language") !== "en"
          ) {
            this.setLanguage();
          }
        } else if (value.supplierInfo.geographicalUnit === "19") {
          if (value.supplierInfo.setUpCountry.includes("PT")) {
            if (
              value.supplierInfo.setUpCountry.includes("AD") ||
              value.supplierInfo.setUpCountry.includes("ES")
            ) {
              this.languageList = [
                { key: "en", value: "English" },
                { key: "es", value: "Spanish" },
                { key: "pt", value: "Portuguese" },
              ];
              if (
                localStorage.getItem("language") !== "es" &&
                localStorage.getItem("language") !== "pt" &&
                localStorage.getItem("language") !== "en"
              ) {
                this.setLanguage();
              }
            } else {
              this.languageList = [
                { key: "en", value: "English" },
                { key: "pt", value: "Portuguese" },
              ];
              if (
                localStorage.getItem("language") !== "pt" &&
                localStorage.getItem("language") !== "en"
              ) {
                this.setLanguage();
              }
            }
          } else if (
            value.supplierInfo.setUpCountry.includes("AD") ||
            value.supplierInfo.setUpCountry.includes("ES")
          ) {
            this.languageList = [
              { key: "en", value: "English" },
              { key: "es", value: "Spanish" },
            ];
            if (
              localStorage.getItem("language") !== "es" &&
              localStorage.getItem("language") !== "en"
            ) {
              this.setLanguage();
            }
          }
        } else if (value.supplierInfo.geographicalUnit === "16" && value.supplierInfo.setUpCountry.includes("FR")) {
          this.languageList = [
            { key: "en", value: "English" },
            { key: "fr", value: "Français" },
          ];
          if (
            localStorage.getItem("language") !== "fr" &&
            localStorage.getItem("language") !== "en"
          ) {
            this.setLanguage();
          }
        } else if (value.supplierInfo.geographicalUnit === "18") {
          if (value.supplierInfo.setUpCountry.includes("CN")) {
            this.languageList = [
              { key: "cn", value: "简体中文" },
              { key: "en", value: "English" },
            ];

            if (value.supplierInfo.defaults === 'checked') {
              localStorage.setItem("language", localStorage.getItem("language"));
              this.currentLanguage = localStorage.getItem("language");
              this.cuurentLanguageValue = this.languageListDefault.find(
                (l) => l.key === this.currentLanguage
              ).value;
              this.languageService.setCurrentLanguage(this.currentLanguage);
            } else {
              localStorage.setItem("language", 'cn');
              this.cuurentLanguageValue = '简体中文';
              this.languageService.setCurrentLanguage('cn');
            }
          } else {
            this.languageList = [
              { key: "en", value: "English" },
              { key: "cn", value: "简体中文" },
            ];
            if (value.supplierInfo.defaults === 'checked') {
              localStorage.setItem("language", localStorage.getItem("language"));
              this.currentLanguage = localStorage.getItem("language");
              this.cuurentLanguageValue = this.languageListDefault.find(
                (l) => l.key === this.currentLanguage
              ).value;
              this.languageService.setCurrentLanguage(this.currentLanguage);
            } else {
              this.setLanguage()
            }
          }

          if (
            localStorage.getItem("language") !== "cn" &&
            localStorage.getItem("language") !== "en"
          ) {
            this.setLanguage();
          }
        } else if (value.supplierInfo.geographicalUnit === "22") {
          this.languageList = [
            { key: "en", value: "English" },
            { key: "jp", value: "日本語" }
          ];
          if (
            localStorage.getItem("language") !== "jp" &&
            localStorage.getItem("language") !== "en"
          ) {
            this.setLanguage();
          }
        } else if (value.supplierInfo.geographicalUnit === "30") {
          if (value.supplierInfo.setUpCountry.includes("BR") ) {
           
              this.languageList = [
                { key: "en", value: "English" },
                { key: "br", value: "Português (Brasil)" },
              ];
              if (
                localStorage.getItem("language") !== "br" &&
                localStorage.getItem("language") !== "en"
              ) {
                this.setLanguage();
              }
            }
           else if (value.supplierInfo.setUpCountry.includes("MX")) {
            this.languageList = [
              { key: "en", value: "English" },
              { key: "mx", value: "LATAM Español" },
            ];
            if (
              localStorage.getItem("language") !== "mx" &&
              localStorage.getItem("language") !== "en"
            ) {
              this.setLanguage();
            }
          } else {
            this.languageList = [{ key: "en", value: "English" }];
            if (localStorage.getItem("language") !== "en") {
              this.setLanguage();
            }
          }
        }
        this.shouldShowChangeLanguageButton = environment.role === "supplier";
       } else {

        if (!value.changelanguagevalue) {
          this.setLanguage();

        }
        if (value.flag === false) {
          this.shouldShowChangeLanguageButton = false;
        }
      }
      this.currentLanguage = localStorage.getItem("language");
      this.cuurentLanguageValue = this.languageListDefault.find(
        (l) => l.key === this.currentLanguage
      ).value;
    });
  }
  showDropdown() {
    if (this.isLogin) {
      this.showPanel = !this.showPanel;
    }
  }
  get isLogin() {
    return this.rebarService.isUserAuthenticated();
  }
  @HostListener("document:click", ["$event"])
  documentClick(evt) {
    if (this.menuButton) {
      if (this.menuButton.nativeElement.contains(event.target)) {
        return;
      }

      this.showPanel = false;
    }
  }

  changeLanguage(language: any) {
    if (language.key === this.currentLanguage) {
      return;
    }
    if (this.switchLanguageCallback) {
      localStorage.setItem("language", language.key);
      this.currentLanguage = language.key;
      this.cuurentLanguageValue = this.languageList.find(
        (l) => l.key === this.currentLanguage
      ).value;
      this.switchLanguageCallback(language.key);
      return;
    }
    const currentPage = this.router.url;
    this.router
      .navigateByUrl(`/languageSwitch?language=${language.key}`, {
        skipLocationChange: true,
      })
      .then((changed) => {
        if (changed) {
          localStorage.setItem("language", language.key);
          localStorage.setItem("languageChangeClicked", "true");
          this.languageService.setCurrentLanguage(language.key);
          this.currentLanguage = language.key;
          this.cuurentLanguageValue = this.languageList.find(
            (l) => l.key === this.currentLanguage
          ).value;
        }
        this.router.navigateByUrl(currentPage);
      });
  }

  // set localStorage Language
  setLanguage() {
    const language = "en";
    localStorage.setItem("language", language);
    this.languageService.setCurrentLanguage(language);
    this.currentLanguage = language;
    this.cuurentLanguageValue = this.languageList.find(
      (l) => l.key === this.currentLanguage
    ).value;
    if (!this.cuurentLanguageValue) {
      this.cuurentLanguageValue = 'English';
    }
  }
}
